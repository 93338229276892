import React, {useContext, useEffect, useRef, useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import DisponibilidadService from "../../services/DisponibilidadService";
import './ConsultaDisponibilidad.css';
import {ConsultaDisponibilidadContentResponse} from "./ConsultaDisponibilidadContentResponse";
import {SidebarComponent} from "../../components/sidebar/SidebarComponent";
import {Bienvenida} from "../../Bienvenida";
import AppContext from "../../store/AppContext";
import RegistroDominioService from "../../services/RegistroDominioService";
import {getOidcState} from "../../security/OidcManager";
import {useLocation} from "react-router-dom";
import {TipoMensaje} from "../../store/AppProvider";
import CarritoService from "../../services/CarritoService";

const REACT_APP_SITE_KEY = process.env.REACT_APP_SITE_KEY;
export const ConsultaDisponibilidadContent = () => {
    const appCtx = useContext(AppContext);
    const recaptchaRef = useRef();
    const [nombreDominio, setNombreDominio] = useState('');
    const [resultadoDisponibilidadDominio, setResultadoDisponibilidadDominio] = useState(undefined);
    const [dominioBuscadoDisponible, setDominioBuscadoDisponible] = useState(false);
    const [listaDominios, setlistaDominio] = useState([]);
    const [checked, setChecked] = useState(listaDominios);
    const [captchaResponse, setCaptchaResponse] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(true);
    const location = useLocation();

    useEffect(() =>{
        mostrarCaptcha();
        if (appCtx.venta !== undefined && appCtx.venta.uuidVenta !== undefined && appCtx.venta.uuidVenta !== "" && getOidcState().is_authenticated) {
            RegistroDominioService.asociarUsuarioAVenta(appCtx.venta.uuidVenta).then();
        }
    }, [appCtx])

    useEffect(() => {
        if (appCtx.venta !== undefined && appCtx.venta.uuidVenta !== undefined && appCtx.venta.uuidVenta !== "") {
            refreshCarrito();
        }
        mostrarCaptcha();
        if (location.state != null && location.state.dominioDisponibilidadWhoIs !== '') {
            let dominioDisponibilidadWhoIs = location.state.dominioDisponibilidadWhoIs;
            const arrayDominio = dominioDisponibilidadWhoIs.split(".");
            let tld;
            if (arrayDominio.length > 2) {
                tld = '.' + arrayDominio[1] + '.' + arrayDominio[2];
            } else {
                tld = '.' + arrayDominio[1];
            }
            setChecked([tld]);
            consultaDisponibilidad(dominioDisponibilidadWhoIs, tld);
            obtenerDominiosHabilitados(false);
        } else {
            obtenerDominiosHabilitados(true);
        }
        appCtx.ocultarMensaje();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const consultarDisponibilidadSubmit = (e) => {
        e.preventDefault();

        if (!nombreDominio) {
            return;
        }

        const dominio = eliminarEspacios(nombreDominio);
        setNombreDominio(dominio);
        consultaDisponibilidad(dominio, '');
    };

    const consultaDisponibilidad = (dominio, tld) => {
        let arregloDominios = [];
        let tlds = checked;
        const dominoConsulta = dominio.toLowerCase();
        const nombreSinTLD = dominoConsulta.split(".")[0];
        setNombreDominio(nombreSinTLD);

        if (tld !== '') {
            tlds = [];
            tlds.push(tld);
        } else {
            if (checked.length === 0) {
                tlds = listaDominios;
            }
        }

        for (let index = 0; index < tlds.length; index++) {
            arregloDominios.push(nombreSinTLD + tlds[index]);
        }

        DisponibilidadService.getDisponibilidad(arregloDominios, captchaResponse)
            .then((response) => {
                let disponible = false;
                if (showCaptcha) {
                    recaptchaRef.current.reset();
                }
                if (response.respuestaOK) {
                    appCtx.ocultarMensaje();
                    for (let index = 0; index < response.dominioDisponibilidadDTO.length; index++) {
                        if (!disponible) {
                            disponible = response.dominioDisponibilidadDTO[index].disponible;
                        }
                    }
                    setDominioBuscadoDisponible(disponible);
                    setResultadoDisponibilidadDominio(response.dominioDisponibilidadDTO);
                } else {
                    appCtx.mostrarMensaje(response.mensaje, TipoMensaje.error);
                }
        });
    }

    const obtenerDominiosHabilitados = (chequear) => {
        DisponibilidadService.getDominiosHabilitados().then((response) => {
            if (response && response.respuestaOK) {
                let domsHabilitados = [];
                for (let index = 0; index < response.dominiosHabilitados.length; index++) {
                    domsHabilitados.push(response.dominiosHabilitados[index]);
                }
                setlistaDominio(domsHabilitados);
                if (chequear){
                    setChecked(domsHabilitados);
                }
            }
        })
    }

    const mostrarCaptcha = () => {
        DisponibilidadService.mostrarCaptcha().then(response => {
            if (response !== undefined) {
                if (response.codigo === 'OK') {
                    setShowCaptcha(response.resultado);
                }
            }
        })
    }

    const refreshCarrito = () => {
        CarritoService.refrescarVentaCarrito(appCtx.venta.uuidVenta).then(response => {
            if (response.respuestaOK) {
                appCtx.refrescarCarrito(response.registrosPendientes, response.renovacionesPendientes, response.transferenciasTitularesPendientes);
            }
        })
    }

    const handleOnChange = (event) => {
        let updatedList = [...checked];
        if (!event.target.checked) {
            updatedList.splice(checked.indexOf(event.target.id), 1);
        } else {
            updatedList = [...checked, event.target.id];
        }
        setChecked(updatedList);
    };

    const handleOnChangeText = (e) => {
        const { value } = e.target;
        setNombreDominio(value);
    }

    function onChange(value) {
        setCaptchaResponse(value);
    }

    function eliminarEspacios(cadena) {
        return cadena.replace(/\s/g, '');
    }

    return <>
        <div className="box-domain">
            <form id="id-form-availability-query" onSubmit={ consultarDisponibilidadSubmit } >
                <div style={{position: "relative"}}>
                    <label htmlFor="domain-txt">Consultar disponibilidad:</label>
                    <input id="domain-txt" type="text" name="dominio" maxLength={50} value={nombreDominio}
                           placeholder="Nombre del dominio" onChange={handleOnChangeText} />
                    <div id="div-watermark" className="domain-txt-label" style={{display: "none"}}>Nombre del dominio</div>
                    <input type="submit" value="Buscar"/>
                </div>
                <div className="domain-tips">
                    {listaDominios.length > 0 ?
                        listaDominios.map((item, index) => (
                            <div key={index} className={"domain-tips-inline"}>
                                <input id={item} className={"checkbox-space"} type="checkbox" checked={checked.includes(item)} onChange={handleOnChange}/>
                                <label htmlFor={item}>{item}</label>
                            </div>
                        ))
                        :
                        <div key={0} className={"domain-tips-inline"}>
                        </div>
                    }
                </div>
                { showCaptcha &&
                    <div className={"box-captcha cfx"}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={REACT_APP_SITE_KEY}
                            onChange={onChange}
                        />
                    </div>
                }
            </form>
        </div>

        { resultadoDisponibilidadDominio?
            <ConsultaDisponibilidadContentResponse resultadoDominios={resultadoDisponibilidadDominio} dominioBuscadoDisponible={dominioBuscadoDisponible} />
            :
            <>
                <Bienvenida/>
                <SidebarComponent/>
            </>
        }

    </>
}