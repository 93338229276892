import React, {useEffect, useState} from 'react';
import {ModalAgregarNameserverDominio} from "./modales/ModalAgregarNameserverDominio";
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";

export const VerDominioConfiguracionDelegado = ( { dominio, nombreDominio, infoDominio, setError, limpiarMensajes, irVerDominio }) => {

    const [nameservers, setNameservers] = useState([]);
    const [nameserverEditar, setNameserverEditar] = useState(null);
    const [indexNameserverEditar, setIndexNameserverEditar] = useState(null);

    const [isOpenEditarNameserver, setIsOpenEditarNameserver] = useState(false);
    const [isOpenAgregarNameserver, setIsOpenAgregarNameserver] = useState(false);

    useEffect(() => {
        setNameservers(infoDominio.nameservers);
    }, [infoDominio]);

    const irEditarNameserver = (nameserver, index) => {
        setNameserverEditar(nameserver);
        setIndexNameserverEditar(index);
        setIsOpenEditarNameserver(true);
        limpiarMensajes();
    };

    const eliminarNameserver = (index) => {
        limpiarMensajes();

        let nameserversAux = nameservers.slice();
        nameserversAux.splice(index, 1)
        let delegarDominioDTO = {
            nombreDominio : nombreDominio,
            nameservers : nameserversAux,
            cantMinimaNameservers : infoDominio.cantMinimaNameservers
        };

        AdministracionUsuarioService.delegarDominio(delegarDominioDTO)
            .then(response => {
                if (!response.esRespOK) {
                    setError({
                        error: true,
                        mensaje: response.mensaje
                    })
                } else {
                    let mensajeOk = {
                        mostrarMensaje : true,
                        mensaje : "El servidor de nombres se eliminó correctamente."
                    };
                    irVerDominio(mensajeOk, nombreDominio)

                }
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Se produjo un error al eliminar el nameserver."
                })
            })

    };

    const irAgregarNameserver = () => {
        setIsOpenAgregarNameserver(true);
        limpiarMensajes();
    };

    return <>

        { isOpenEditarNameserver && <ModalAgregarNameserverDominio isOpen={isOpenEditarNameserver} handleOnClose={()=>{setIsOpenEditarNameserver(false)}}
                                                                   nombreDominio={nombreDominio}
                                                                   nameserverEditar={nameserverEditar}
                                                                   index={indexNameserverEditar}
                                                                   nameservers={nameservers}
                                                                   cantMinimaNameservers={infoDominio.cantMinimaNameservers}
                                                                   isCrear={false}
                                                                   irVerDominio={irVerDominio}
        /> }

        { isOpenAgregarNameserver && <ModalAgregarNameserverDominio isOpen={isOpenAgregarNameserver} handleOnClose={()=>{setIsOpenAgregarNameserver(false)}}
                                                                    nombreDominio={nombreDominio}
                                                                    nameservers={nameservers}
                                                                    cantMinimaNameservers={infoDominio.cantMinimaNameservers}
                                                                    isCrear={true}
                                                                    irVerDominio={irVerDominio}

        /> }

        <div>
            <fieldset>
                <legend>Configuraci&oacute;n servidores de nombre</legend>

                    <table className="data-domain">
                        <tbody>
                        <tr>
                            <td><strong>Nameserver</strong></td>
                            <td><strong>Tipo IP</strong></td>
                            <td><strong>IP</strong></td>
                            <td></td>
                        </tr>

                        {nameservers !== undefined && nameservers.map((nameserver, index) => (
                            <tr>
                                <td>{nameserver.nombre}</td>
                                <td>{nameserver.tipoIP}</td>
                                <td>{nameserver.ip}</td>
                                <td>
                                    <a className="link"
                                       to="#" onClick={() => irEditarNameserver(nameserver, index)}>Editar</a>
                                </td>
                                <td>
                                    {nameservers.length > infoDominio.cantMinimaNameservers &&
                                        <a className="link"
                                           to="#" onClick={() => eliminarNameserver(index)}>Eliminar</a>
                                    }
                                </td>
                            </tr>
                        ))
                        }

                        <tr>
                            <td colSpan="4">
                                <a className="link" onClick={() => irAgregarNameserver()}>Agregar nameserver</a>
                            </td>
                        </tr>

                        </tbody>
                    </table>

            </fieldset>

        </div>

    </>
};