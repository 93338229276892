import React from "react";
import PropTypes from 'prop-types';
import Modal from "../../../components/Modal";
import UsuarioService from "../../../services/UsuarioService";

const ModalEditarContactoBE = (props) => {
    const {setIsOpen, contactoActualizar, cantidadActualizables} = props;

    const editarContactoBE = (event) => {
        event.preventDefault();
        UsuarioService.actualizarContactoBEUsuario(contactoActualizar)
            .then(response => {
                if (!response.esRespOK) {
                    console.log("Error al actualizar contacto")
                } else {
                    setIsOpen(false);
                }
            })
            .catch(() => {
                console.log("Error al actualizar contacto")
            })
    }

    return (
        <Modal handleClose={props.handleOnClose} isOpen={props.isOpen} closeOnClickOutside={false}
               className="modal" modalContentClassName="modal-content" closeClassName="close-modal">
            <div className="message-box modal-be" id="register-warning">
                <h3>Los datos se han guardado correctamente y se utilizarán por defecto para futuros registros.</h3>
                <h3>En caso de que su cuenta Vera figure como contacto Administrativo, Financiero o Técnico de un
                    dominio, se utilizarán los nuevos datos como datos de contacto. Si su cuenta Vera figura como
                    contacto Titular únicamente se actualizarán los datos Dirección, Teléfono y correo electrónico,
                    quedando Nombre y Apellido incambiados.</h3>
                <h3>En caso de proceder con la operación, se actualizaran { cantidadActualizables } datos de contactos. ¿Desea aplicar los cambios a todos sus
                    dominios?
                </h3>
            </div>
            <hr className={"linea"}></hr>
            <div className="right">
                <button className={'btn'} onClick={editarContactoBE}>Aceptar</button>
                <button className={'btn'} onClick={props.handleOnClose}>Cancelar</button>
            </div>
        </Modal>
    )
}

ModalEditarContactoBE.propTypes = {
    handleOnClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default ModalEditarContactoBE;