import React, {useContext} from 'react'
import {NavTop} from "./NavTop";
import {Header} from "./Header";
import {Nav} from "./Nav";
import {Footer} from "./Footer";
import AppContext from "../store/AppContext";

const TheLayout = (props) => {
    const appCtx = useContext(AppContext);

    return (
      <div id="wrapper">
        <div className="page">
          <NavTop/>

          <Header/>

          <Nav/>

          <div id="main" className="cfx">
              {

                  appCtx.mensajes.mostrarMensaje?
                      <div className="message-box" id={appCtx.mensajes.tipoMensaje}>
                          <h3>
                              <ul className="actionMessage">
                                  <li><span>{appCtx.mensajes.mensaje}</span></li>
                              </ul>
                          </h3>
                      </div> : null
              }
              {
                  appCtx.mensajes.mostrarMensajeLogo?
                      <div className="message-box" id={appCtx.mensajes.tipoMensaje}>
                          <h3>
                              <ul className="actionMessage">
                                  <li><span>{appCtx.mensajes.mensaje}<a><img
                                      style={{width: "10%", verticalAlign: "middle", border: "none"}} alt="Dominiosuy"
                                      src="/assets/logo.png"/></a></span></li>
                              </ul>
                          </h3>
                      </div> : null
              }

              {/*<Route path="/indexa" exact render={props => <IniciarTransferencia {...props}/>} />*/}
              {props.children}
          </div>
        </div>

        <Footer/>

      </div>
  );
}

export default TheLayout;
