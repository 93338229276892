import React from "react";

export const CarritoComprasTotal = (props) => {
    const subTotal = props.subTotal;
    const descuento = props.descuento;

    return (
        <>
            <div id="idCartCuadroSubtotal">
                <table className="cart-total">
                    <tbody>
                    <tr className="subtotal">
                        <td>Subtotal:</td>
                        <td>$ {subTotal}</td>
                    </tr>

                    <tr className="cod-prom">
                        <td>Código promocional:</td>
                        <td>- $ {descuento}</td>
                    </tr>
                    { subTotal > 0 &&
                        <tr className="cod-prom">
                            <td></td>
                            <td>El IVA está incluido.</td>
                        </tr>
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>TOTAL:</td>
                        <td>$ {subTotal - descuento}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );
};