import {createPortal} from 'react-dom';
import useReactPortalConfiguration from "./ReactPortalConfiguration";

function ReactPortal({ children, wrapperId, onClose, positionRelativeTo = null, closeOnClickOutside = false}) {
    const { wrapperElement } = useReactPortalConfiguration(wrapperId, onClose, closeOnClickOutside);

    // wrapperElement state will be null on the very first render.
    if (wrapperElement === null) return null;

    if (positionRelativeTo) {
        positionElementRelativeTo(wrapperElement, document.getElementById(positionRelativeTo));
    }

    return createPortal(children, wrapperElement);
}

function positionElementRelativeTo(element, relativeElement) {
    const relativeRect = relativeElement.getBoundingClientRect();
    const { width, height } = element.getBoundingClientRect();

    const top = window.pageYOffset + relativeRect.top + relativeRect.height;
    const left = window.pageXOffset + relativeRect.left;

    element.style.position = 'absolute';
    element.style.top = `${top + 15}px`;
    element.style.left = `${left - 10}px`;
    element.style.width = `${width}px`;
    element.style.height = `${height}px`;
}

export default ReactPortal;