import React from "react";
import {MensajesGlobales} from "./components/mensajes/MensajesGlobales";

export const Bienvenida = () => {

    return <>
        <div id="content" className="fluid">
            <div className="inner-block">
                {/*caja  mensajes*/}
                <MensajesGlobales/>
                <div className="box">
                    <a href="/"><img src="/imgs/dns-home.png" alt={"bannerBienvenida"} /></a>
                </div>
            </div>
        </div>
    </>
}