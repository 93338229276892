import React, {useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import AppContext from "../../store/AppContext";
import CarritoService from "../../services/CarritoService";

export const ValidacionesCarrito = (props) => {

    const appCtx = useContext(AppContext);
    const history = useNavigate();

    useEffect(() =>{
        if (props.paso >= 5) {
            return;
        }
        if (props.paso === 1 || props.paso === 2){
            validarItemsCarrito(props);
        } else if (props.paso === 3){
            validarTitularesItemsCarrito(props);
        } else if (props.paso === 4){
            validarTitularesItemsCarrito(props);
            validarFormaPagoGuardadaVenta(props);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validarItemsCarrito = (props) => {
        //validar que los items en el carrito no esten vacios
        if (appCtx.carrito.registrosPendientes.length === 0
                && appCtx.carrito.renovacionesPendientes.length === 0
                && appCtx.carrito.transferenciasTitularPendientes.length === 0) {
            props.setError({
                error: true,
                mensaje: "No hay artículos en el carro de compra para continuar."
            })
            return;
            //history("/v2/carrito-compra-paso-uno", {state: {errorValidacionCarrito: error}});
        }
    }

    const validarTitularesItemsCarrito = (props) => {
        if (appCtx.venta === undefined || appCtx.venta.uuidVenta === '') {
            props.setError({
                error: true,
                mensaje: "No se ha ingresado un titular para todos los dominios"
            })
            //history("/v2/carrito-compra-paso-dos", {state: {errorValidacionCarrito: error}});
            return;
        }

        CarritoService.validarDominiosConContacto(appCtx.venta.uuidVenta)
            .then((response) => {
                if (!response.respuestaOK) {
                    props.setError({
                        error: true,
                        mensaje: "No se ha ingresado un titular para todos los dominios"
                    })
                    //history("/v2/carrito-compra-paso-dos", {state: {errorValidacionCarrito: error}});
                }
            }).catch(() => {
        });
    }

    const validarFormaPagoGuardadaVenta = (props) => {
        if (appCtx.venta === undefined || appCtx.venta.uuidVenta === '') {
            props.setError({
                error: true,
                mensaje: "Debe seleccionar una cuenta o crear una nueva"
            })
            //history("/v2/carrito-compra-paso-tres", {state: {errorValidacionCarrito: error}});
            return;
        }

        CarritoService.obtenerFormaPagoGuardadaVenta(appCtx.venta.uuidVenta)
            .then(response => {
                if (!response.esRespOK) {
                    props.setError({
                        error: true,
                        mensaje: "Debe seleccionar una cuenta o crear una nueva"
                    })
                    //history("/v2/carrito-compra-paso-tres", {state: {errorValidacionCarrito: error}});
                }
            })
    }

    return <></>
}

