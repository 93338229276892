import React, {useState} from 'react';
import TransferenciaRegistradorService from "../../../../services/TransferenciaRegistradorService";

function TransferirDominio({nombreDominio, dominio, onCloseModal, transferenciaExitosa, setTransferenciaExitosa,setTransferenciaData, setError, setMensajeOk}) {
    const [responseData, setResponseData] = useState(null);

    const handleSolicitarCodigo = (e) => {
        e.preventDefault();
        TransferenciaRegistradorService.solicitarCodigoAutorizacion(nombreDominio)
            .then(response => {
                if (!response.error) {
                    onCloseModal();
                    setTransferenciaExitosa(true);
                    setResponseData(response);
                    setTransferenciaData(response);
                    setError({
                        error: false,
                        mensaje: ''
                    });
                    setMensajeOk({
                        mostrarMensaje: true,
                        mensaje: 'La solicitud del Código de Autorización se procesó exitosamente.'
                    });
                    window.scrollTo(0, 0);
                } else {
                    setError({
                        error: true,
                        mensaje: `Se produjo un error al solicitar el Código de Autorización para el dominio ${nombreDominio}`
                    });
                    onCloseModal();
                    window.scrollTo(0, 0);

                }

            })
            .catch(error => {
                setError({
                    error: true,
                    mensaje: `Se produjo un error al solicitar el Código de Autorización para el dominio ${nombreDominio}`
                });
                onCloseModal();
                window.scrollTo(0, 0);

            });
    };

    return (
        !transferenciaExitosa
            ? (
            <fieldset>
                <legend>Transferir dominio</legend>
                <form id="id-form-solicitar-cod-trans" name="id-form-solicitar-cod-trans"
                      onSubmit={handleSolicitarCodigo}
                      action="/frontend/private/misDominiosTransRegistradorSolicitarCodAut.action"
                      method="post">
                    <div className="form-item">
                        <p>¿Confirma que desea iniciar el proceso de Transferencia de Registrador del dominio {nombreDominio}?</p>
                    </div>
                    <div className="form-item">
                        <input type="submit" value="Solicitar Código de Autorización" id="id-submit-solicitar-trans-registrador" className="btn" />
                    </div>
                </form>
            </fieldset>
        )
        : null
    );
}

export default TransferirDominio;
