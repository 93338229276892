import {useState, useEffect} from "react";
import Modal from "../../../../components/Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
const ModalEliminarFormaPago = ({handleOnClose, isOpen, setIsOpen, dominiosSeleccionados, mapPermisosDominio, rolSeleccionado, respuestaAccionOk, setError }) => {
    const [dominiosAplicaAccion, setDominiosAplicaAccion] = useState([]);
    const [dominiosNoAplicaAccion, setDominiosNoAplicaAccion] = useState([]);
    const [respuestaObtenida, setRespuestaObtenida] = useState(false);

    useEffect(() => {
        obtenerPermisosDominios();
    }, [])
    const onAccept = () => {
        // eliminar la cuenta de pago
        eliminarCuentaDominios();
        setIsOpen(false);
        // respuestaAccionOk('Cuentas de pago eliminadas con éxito.');
    };
    const eliminarCuentaDominios = () => {
        AdministracionUsuarioService.eliminarFormaPago(dominiosSeleccionados)
            .then(response => {
                // console.log(response);
            }).finally(() => {
            respuestaAccionOk('Cuentas de pago eliminadas con éxito.');
        }
        )
    }

    const onCancel = () => {
        setIsOpen(false);
    };

    const obtenerPermisosDominios = () => {
        AdministracionUsuarioService.obtenerPermisosDominio(mapPermisosDominio, dominiosSeleccionados, rolSeleccionado, 'editarCuentaPago')
            .then(response => {
                if (response.codigo === 'OK') {
                    setDominiosAplicaAccion(response.dominiosAplicaAccion);
                    setDominiosNoAplicaAccion(response.dominiosNoAplicaAccion);
                }
            }).finally(() => {
            setRespuestaObtenida(true)
        })
    }


    return (
        <Modal
            idDivModal="idModalEliminarFormaPago"
            handleClose={onCancel}
            isOpen={isOpen}
        >
            {respuestaObtenida ? (
                dominiosAplicaAccion && dominiosAplicaAccion.length > 0 ? (
                    <fieldset>
                        <legend>Eliminar cuentas de pago para los dominios seleccionados</legend>
                        <div className="right">
                            <button className="btn" onClick={onAccept}>Aceptar</button>
                            <button className="btn" onClick={onCancel}>Cancelar</button>
                        </div>
                    </fieldset>
                ) : (
                    <div className="message-box" id="register-error">
                        <h3>No tiene permisos para eliminar la cuenta de pago del dominio seleccionado.</h3>
                    </div>
                )
            ) : null}
        </Modal>
    );




}

export default ModalEliminarFormaPago;

