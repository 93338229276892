import {useLayoutEffect, useRef, useState} from 'react';

function useReactPortalConfiguration(wrapperId, onClose, closeOnClickOutside = false) {
    const modalContainerRef = useRef(null);
    const [wrapperElement, setWrapperElement] = useState(null);

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        modalContainerRef.current = element;

        let systemCreated = false;
        // if element is not found with wrapperId or wrapperId is not provided, create and append to body
        if (!element) {
            systemCreated = true;
            element = createWrapperAndAppendToBody(wrapperId);
        }
        setWrapperElement(element);

        // Este método se define al final porque se necesite que todos los componentes estén montados
        // para chequear correctamente si el click se hace dentro o fuera del modal
        const handleClickOutside = (event) => {
            // If the click is outside the modal container, close the modal
            //if (modalContainerRef.current && !modalContainerRef.current.contains(event.target)) {
            if (closeOnClickOutside && modalContainerRef.current && !event.target.closest(`#${wrapperId}`)) {
                onClose();
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            // delete the programatically created element
            if (systemCreated && element.parentNode) {
                element.parentNode.removeChild(element);
            }

            // remove the event listener
            document.removeEventListener('click', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperId]);

    return { wrapperElement };
}

function createWrapperAndAppendToBody(wrapperId) {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);

    return wrapperElement;
}

export default useReactPortalConfiguration;
