import React, { useState, useEffect } from 'react';
import VerEditarDatosContacto from "./verEditarDatosContacto";
import ModalTransferirTitular from "./modales/ModalTransferirTitular";
import ModalActualizarContacto from "./modales/ModalActualizarContacto";
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";
import { useValidacionContacto } from '../../validaciones/validacionesContacto';

export const VerDominioContactos = ({ dominio, dominioBE, cuentasContactos, permisos, setMensajeOk, setError }) => {
    const [showEditarDatosContacto, setShowEditarDatosContacto] = useState(false);
    const [showTransferirContacto, setshowTransferirContacto] = useState(false);
    const [showTransferirTitular, setShowTransferirTitular] = useState(false);
    const [tipoDeContactoActual, setTipoDeContactoActual] = useState(null);
    const [contactoActual, setContactoActual] = useState(null);
    const { getNombreApellido } = useValidacionContacto();
    const [esTitular, setesTitular] = useState(false);
    const [titular, setTitular] = useState(getNombreApellido(dominio.dominioBE.titular));
    const [administrador, setAdministrador] = useState(dominio.dominioBE.administrador);
    const [financiero, setFinanciero] = useState(dominio.dominioBE.financiero);
    const [tecnico, setTecnico] = useState(dominio.dominioBE.tecnico);
    const [cuentas, setCuentas] = useState(null);
    const [transferenciaTitularEnabled, setTransferenciaTitularEnabled] = useState(false);

    useEffect(() => {
        isTransferenciaTitularEnabled();
        setCuentas(cuentasContactos);
    }, [cuentasContactos]);

    const handleEditarContacto = (tipoContacto) => {
        setTipoDeContactoActual(tipoContacto);

        let currentContact;
        switch (tipoContacto) {
            case 'Titular':
                currentContact = titular;
                setesTitular(true);
                break;
            case 'Administrador':
                currentContact = administrador;
                setesTitular(false);
                break;
            case 'Técnico':
                currentContact = tecnico;
                setesTitular(false);
                break;
            case 'Financiero':
                currentContact = financiero;
                setesTitular(false);
                break;
            default:
                currentContact = null;
        }
        setContactoActual(currentContact);
        setShowEditarDatosContacto(true);
    }

    const isTransferenciaTitularEnabled = () =>
    {
        AdministracionUsuarioService.isTransferenciaTitularEnabled().then(response => {
            setTransferenciaTitularEnabled(response.code === 'true');
        }).catch(ignoreError => {
        });
    }


    const handleTransferirContacto = () => {
        setshowTransferirContacto(false)
    }

    const handleCambiarContacto = (tipoContacto) => {
        setTipoDeContactoActual(tipoContacto);

        let currentContact;
        switch (tipoContacto) {
            case 'Titular':
                currentContact = dominio.dominioBE.titular;
                setesTitular(true);
                break;
            case 'Administrador':
                currentContact = dominio.dominioBE.administrador;
                setesTitular(false);
                break;
            case 'Técnico':
                currentContact = dominio.dominioBE.tecnico;
                setesTitular(false);
                break;
            case 'Financiero':
                currentContact = dominio.dominioBE.financiero;
                setesTitular(false);
                break;
            default:
                currentContact = null;
        }
        setContactoActual(currentContact);
        setshowTransferirContacto(true);
    }
    const handleCloseEditarDatosContacto = () => {
        setShowEditarDatosContacto(false);
    };
    const handleCloseTransferirContacto = () => {
        setshowTransferirContacto(false);
    };

    const handleTransferirTitular = () => {
        setShowTransferirTitular(true);
    };

    const handleCloseTransferirTitular = () => {
        setShowTransferirTitular(false);
    };

    const actualizarContacto = (contacto, tipo) => {
        setContactoActual(contacto);
        switch (tipo) {
            case 'Titular':
                setTitular(contacto);
                break;
            case 'Administrador':
                setAdministrador(contacto);
                break;
            case 'Técnico':
                setTecnico(contacto);
                break;
            case 'Financiero':
                setFinanciero(contacto);
                break;
            default:
                break;
        }
    };

    function crearLinkEditarContacto(tipoContacto, label) {
        return <a to="#" className={"link"}
                  onClick={() => handleEditarContacto(tipoContacto)}>{label}</a>;
    }

    function crearLinkCambiarContacto(tipoContacto, label) {
        return <a to="#" className={"link"}
                  onClick={() => handleCambiarContacto(tipoContacto)}>{label}</a>;
    }

    return (
        <fieldset>
            <legend>Contactos</legend>
            <table className="data-domain" style={{width: '100%'}}>
                <tbody>

                <tr>
                    <td><strong>Titular</strong></td>
                    <td><strong>Administrativo</strong></td>
                    <td><strong>Técnico</strong></td>
                    <td><strong>Financiero</strong></td>
                </tr>

                <tr>
                    <td>{dominio.dominioBE.titular.nicHandler || 'N/A'}</td>
                    <td>{dominio.dominioBE.administrador.nicHandler || 'N/A'}</td>
                    <td>{dominio.dominioBE.tecnico.nicHandler || 'N/A'}</td>
                    <td>{dominio.dominioBE.financiero.nicHandler || 'N/A'}</td>
                </tr>
                <tr>
                    <td>{titular.nombre + " " + titular.apellido}</td>
                    <td>{administrador.nombre || 'N/A'} {administrador.apellido || 'N/A'}</td>
                    <td>{tecnico.nombre || 'N/A'} {tecnico.apellido || 'N/A'}</td>
                    <td>{financiero.nombre || 'N/A'} {financiero.apellido || 'N/A'}</td>
                </tr>

                <tr>
                    <td>{titular.direccion || 'N/A'}</td>
                    <td>{administrador.direccion || 'N/A'}</td>
                    <td>{tecnico.direccion || 'N/A'}</td>
                    <td>{financiero.direccion || 'N/A'}</td>
                </tr>

                <tr>
                    <td>{titular.telefono || 'N/A'}</td>
                    <td>{administrador.telefono || 'N/A'}</td>
                    <td>{tecnico.telefono || 'N/A'}</td>
                    <td>{financiero.telefono || 'N/A'}</td>
                </tr>
                <tr>
                    <td>{titular.emailPublico ? titular.emailPublico : dominio.dominioBE.titular.email || 'N/A'}
                    </td>
                    <td>{administrador.emailPublico ? administrador.emailPublico : dominio.dominioBE.administrador.email || 'N/A'}</td>
                    <td>{tecnico.emailPublico ? tecnico.emailPublico : dominio.dominioBE.tecnico.email || 'N/A'}</td>
                    <td>{financiero.emailPublico ? financiero.emailPublico : dominio.dominioBE.financiero.email || 'N/A'}</td>
                </tr>
                <tr>
                    <td>Cuenta Vera: {cuentas?.cuentaTitular || 'N/A'}</td>
                    <td>Cuenta Vera: {cuentas?.cuentaAdministrador || 'N/A'}</td>
                    <td>Cuenta Vera: {cuentas?.cuentaTecnico || 'N/A'}</td>
                    <td>Cuenta Vera: {cuentas?.cuentaFinanciero || 'N/A'}</td>
                </tr>
                <tr>
                    <td>
                        <div style={{minHeight: '50px'}}>
                            <div style={{minHeight: '25px'}}>
                                {(permisos.puedeEditarTitular) || (permisos.puedeEditarAdmin)
                                    ? <>{crearLinkEditarContacto('Titular', 'Editar')}</>
                                    : <>&nbsp;</>
                                }
                            </div>
                            <div style={{minHeight: '25px'}}>
                                {permisos.puedeTransferirTitular && transferenciaTitularEnabled
                                    ? <a to="#" className={"link"} onClick={handleTransferirTitular}>Transferir
                                        titular</a>
                                    : <>&nbsp;</>
                                }
                            </div>
                        </div>
                    </td>
                    <td>
                        <div style={{ minHeight: '50px' }}>
                            <div style={{ minHeight: '25px' }}>
                                {permisos.puedeEditarAdmin
                                    ? <>{crearLinkEditarContacto('Administrador', 'Editar')}</>
                                    : <>&nbsp;</>
                                }
                            </div>
                            <div style={{ minHeight: '25px' }}>
                                {permisos.puedeInvitarAdmin
                                    ? <>{crearLinkCambiarContacto('Administrador', 'Cambiar contacto')}</>
                                    : <>&nbsp;</>
                                }
                            </div>
                        </div>
                    </td>
                    <td>
                        <div style={{ minHeight: '50px' }}>
                            <div style={{ minHeight: '25px' }}>
                                {permisos.puedeEditarTecnico
                                    ? <>{crearLinkEditarContacto('Técnico', 'Editar')}</>
                                    : <>&nbsp;</>
                                }
                            </div>
                            <div style={{ minHeight: '25px' }}>
                                {permisos.puedeInvitarTecnico
                                    ? <>{crearLinkCambiarContacto('Técnico', 'Cambiar contacto')}</>
                                    : <>&nbsp;</>
                                }
                            </div>
                        </div>
                    </td>
                    <td>
                        <div style={{ minHeight: '50px' }}>
                            <div style={{ minHeight: '25px' }}>
                                {permisos.puedeEditarFinanciero
                                    ? <>{crearLinkEditarContacto('Financiero', 'Editar')}</>
                                    : <>&nbsp;</>
                                }
                            </div>
                            <div style={{ minHeight: '25px' }}>
                                {permisos.puedeInvitarFinanciero
                                    ? <>{crearLinkCambiarContacto('Financiero', 'Cambiar contacto')}</>
                                    : <>&nbsp;</>
                                }
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            {showEditarDatosContacto && <VerEditarDatosContacto
                contacto={contactoActual}
                onClose={handleCloseEditarDatosContacto}
                titular={esTitular}
                tipo={tipoDeContactoActual}
                dominio={dominio}
                setMensajeOk={setMensajeOk}
                setError={setError}
                setcontactoActual={actualizarContacto}
            />}
            {showTransferirContacto && <ModalActualizarContacto
                contacto={contactoActual}
                onClose={handleCloseTransferirContacto}
                tipo={tipoDeContactoActual}
                dominio={dominio}
                setMensajeOk={setMensajeOk}
                setError={setError}
            />}
            {showTransferirTitular && <ModalTransferirTitular
                nombreDominio={dominio.dominioBE.nombre}
                idDominioCore={dominio.dominioBE.uid}
                mensajeTransferencia={""}
                handleOnClose={handleCloseTransferirTitular}
                isOpen={showTransferirTitular}
                setMensajeOk={setMensajeOk}
                setError={setError}
            />}
        </fieldset>
    );
};