import {
    buildCancelarTransferenciaRequest,
    buildObtenerDatosResponse,
    buildSolicitudAutorizacion,
    buildSolicitudCodAutorizacion,
    buildSolicitudAutorizacionResponse,
    buildSolicitudTransferenciaResponse,
    buildSolicitudTransferenciaResponseError,
    builEsDominioTransferibleaRequest, buildSolicitudRechazarTransferencia
} from "../dto/tar/TransferenciaRegistradorDTOBuilder";

import http from '../support/HttpService';

const SERVICE_TRANSF_REG_URL = process.env.REACT_APP_URL_TRANSF_REG;
const SERVICE_SOLICITUD_AUTORIZACION_URL = process.env.REACT_APP_URL_SOLICITUD_AUTORIZACION;
const SERVICE_SOLICITUD_OBTENER_AUTORIZACION_URL = process.env.REACT_APP_URL_TRANSF;
const REACT_APP_URL_TRANSF_REQUESTAUTH = process.env.REACT_APP_URL_TRANSF_REQUESTAUTH;
const SERVICE_TRANSF_REG_OBTENER_REQUESTAUTH_URL = process.env.REACT_APP_URL_OBTENER_TRANSF_REQUESTAUTH;
const REACT_APP_URL_TRANSF_RECHAZAR = process.env.REACT_APP_URL_TRANSF_RECHAZAR;

const TransferenciaRegistradorService = {

    getNotificacionSolicitudConfirmacion: async function (nombreDominio) {
        try {
            const response = await http.post(SERVICE_TRANSF_REG_URL, buildSolicitudAutorizacion(nombreDominio));
            return buildSolicitudAutorizacionResponse(response.data);
        } catch (error) {
            console.error(JSON.stringify(error));
            throw error;
        }
    },

    aprobarTransferencia: async function (nombreDominio, codigoAutorizacion) {
        try {
            const response = await http.post(SERVICE_TRANSF_REG_URL + 'solicitud/aprobacion', buildSolicitudAutorizacion(nombreDominio,codigoAutorizacion));
            console.log("response: ", response);
            return (response.data);
        } catch (error) {
            console.error(JSON.stringify(error));
            throw error;
        }
    },



    solicitarCodigoAutorizacion: async function (nombreDominio) {
        try {
            const response = await http.post(REACT_APP_URL_TRANSF_REQUESTAUTH, buildSolicitudCodAutorizacion(nombreDominio));
            return response.data;
        } catch (error){
            throw error;
        }
    },

    obtenerCodigoAutorizacion: async function (nombreDominio) {
        try {
            const response = await http.get(SERVICE_SOLICITUD_OBTENER_AUTORIZACION_URL+ nombreDominio);
            return response.data;
        } catch (error) {
            return { error: true, mensaje: error.message };
        }
    },

    rechazarSolicitudTransferencia: async function (nombreDominio, codigo) {
        try {
            const response = await http.post(REACT_APP_URL_TRANSF_RECHAZAR, buildSolicitudAutorizacion(nombreDominio,codigo));
            return response.data;
        } catch (error) {
            return { error: true, mensaje: error.message };
        }
    },

    obtenerHistorialCodigosAutorizacion: async function (nombreDominio) {
        try {
            const response = await http.get(SERVICE_SOLICITUD_OBTENER_AUTORIZACION_URL+ nombreDominio + '/list');
            return response.data;
        } catch (error) {
            return { error: true, mensaje: error.message };
        }
    },






    isTAREnabled: async function () {
        try {
            const response = await http.get(SERVICE_TRANSF_REG_URL + 'is-tar-enabled');
            return response.data;
        } catch (error) {
            console.error(JSON.stringify(error));
            throw error;
        }
    },

    solicitarTransferencia: async function (requestTar) {
        try {
            const response = await http.post(SERVICE_TRANSF_REG_URL + 'solicitud', requestTar);

            return buildSolicitudTransferenciaResponse(response.data);
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx

                // Se conservan estas líneas para saber todos los datos que se obtienen del error
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
                if (error.response.data === 'La cuenta no es válida') {
                    throw buildSolicitudTransferenciaResponseError("Ocurrió un error al procesar el pago. Le pedimos que verifique el método de pago utilizado.");
                } else if (error.response.data === 'Ya existe una solicitud de importación de dominio en estado pendiente') {
                    throw buildSolicitudTransferenciaResponseError("Ya existe una solicitud de importación de dominio en estado pendiente");
                } else {
                    throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in Node.js
                /*console.error('The request was made but no response was received');
                console.log(error.request);*/

                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            } else {
                // Something happened in setting up the request that triggered an Error
                /*console.error('Something happened in setting up the request that triggered an Error');
                console.log('Error', error.message);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            }
            //console.log(error.config);
        }
    },

    cancelarTransferencia: async function (dominio, codigoAutorizacion) {
        try {
            const response = await http.post(
                SERVICE_TRANSF_REG_URL + 'solicitud/cancelar',
                buildCancelarTransferenciaRequest(dominio, codigoAutorizacion)
            );

            return buildSolicitudTransferenciaResponse(response.data);
        } catch (error) {

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx

                // Se conservan estas líneas para saber todos los datos que se obtienen del error
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in Node.js
                /*console.error('The request was made but no response was received');
                console.log(error.request);*/

                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            } else {
                // Something happened in setting up the request that triggered an Error
                /*console.error('Something happened in setting up the request that triggered an Error');
                console.log('Error', error.message);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            }
            //console.log(error.config);
        }
    },

    eliminarTransferencia: async function (dominio, codigoAutorizacion) {
        try {
            const response = await http.post(
                SERVICE_TRANSF_REG_URL + 'solicitud/eliminar',
                buildCancelarTransferenciaRequest(dominio, codigoAutorizacion)
            );

            return buildSolicitudTransferenciaResponse(response.data);
        } catch (error) {

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx

                // Se conservan estas líneas para saber todos los datos que se obtienen del error
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in Node.js
                /*console.error('The request was made but no response was received');
                console.log(error.request);*/

                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            } else {
                // Something happened in setting up the request that triggered an Error
                /*console.error('Something happened in setting up the request that triggered an Error');
                console.log('Error', error.message);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            }
            //console.log(error.config);
        }
    },

    obtenerDatosTransferencia: async function (dominio, codAuth) {
        try {
            const response = await http.get(
                SERVICE_TRANSF_REG_URL + 'datos/' + dominio + '/' + codAuth,
            );

            return buildObtenerDatosResponse(response.data);
        } catch (error) {

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx

                // Se conservan estas líneas para saber todos los datos que se obtienen del error
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in Node.js
                /*console.error('The request was made but no response was received');
                console.log(error.request);*/

                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            } else {
                // Something happened in setting up the request that triggered an Error
                /*console.error('Something happened in setting up the request that triggered an Error');
                console.log('Error', error.message);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            }
            //console.log(error.config);
        }
    },

    obtenerSolicitudesTransferenciaXUsuario: async function () {
        try {
            const response = await http.get(
                SERVICE_TRANSF_REG_URL + 'datos/obtener-dominios-transferencia-x-usuario',
            );

            return response.data;
        } catch (error) {

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx

                // Se conservan estas líneas para saber todos los datos que se obtienen del error
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in Node.js
                /*console.error('The request was made but no response was received');
                console.log(error.request);*/

                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            } else {
                // Something happened in setting up the request that triggered an Error
                /*console.error('Something happened in setting up the request that triggered an Error');
                console.log('Error', error.message);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la solicitud de transferencia');
            }
            //console.log(error.config);
        }
    },

    obtenerCantSolicitudesTransferenciaXUsuario: async function () {
        try {
            const response = await http.get(
                SERVICE_TRANSF_REG_URL + 'datos/obtener-dominios-transferencia-x-usuario/cant',
            );

            return response.data;
        } catch (error) {
            throw {
                ok: false,
            }
        }
    },

    esDominioTransferible: async function (dominio, captchaResponse) {
        try {
            const response = await http.post(
                SERVICE_TRANSF_REG_URL + 'esTransferible',
                builEsDominioTransferibleaRequest(dominio, captchaResponse)
            );

            if (!response.data.ok) {
                return {
                    ok: false,
                    mensaje: 'Se produjo un error al consultar si el dominio ' + dominio + ' es transferible'
                }
            }

            const isTransferible = 'DOM_TRANSFERIBLE' === response.data.code;
            const mensaje = isTransferible ?
                'El dominio ' + dominio + ' está habilitado para ser transferido' :
                'CAPTCHA_INCORRECTO' === response.data.code ?
                    'El captcha es incorrecto' :
                    'DOM_NOT_TRANSFERIBLE_NOT_FOUND' === response.data.code ?
                        'El dominio ' + dominio + ' no puede ser transferido porque aún no está registrado' :
                        'DOM_NOT_TRANSFERIBLE_PENDING' === response.data.code ?
                            'El dominio ' + dominio + ' no puede ser transferido porque tiene una solicitud de importación en estado pendiente' :
                            'El dominio ' + dominio + ' no puede ser transferido porque ya pertenece a '; /* DOM_NOT_TRANSFERIBLE_SAME_REGISTRAR */

            return {
                ok: isTransferible,
                mensaje: mensaje,
                registros: response.data.registros,
                errorCaptcha: response.data.code === 'CAPTCHA_INCORRECTO',
            };
        } catch (error) {
            return {
                ok: false,
                mensaje: 'Se produjo un error al consultar si el dominio ' + dominio + ' es transferible'
            }
        }
    },

    consultarSolicitudTransferencia: async function (dominio, codAuth) {
        try {
            const response = await http.get(
                SERVICE_TRANSF_REG_URL + 'solicitud/consultar/' + dominio + '/' + codAuth,
            );

            if (response.data.code === '2201'){
                return {
                    ok: false,
                    code: response.data.code,
                    mensaje: 'El código de autorización no es válido'
                }
            }

            if (!response.data.ok) {
                return {
                    ok: false,
                    code: response.data.code,
                    mensaje: 'Se produjo un error al consultar la solicitud de transferencia'
                }
            }

            return {
                ok: true,
                code: response.data.code,
                mensaje: "Ok",
                nombre: response.data.registrantName,
                email: response.data.registrantEmail
            };
        } catch (error) {

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx

                // Se conservan estas líneas para saber todos los datos que se obtienen del error
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la consulta de solicitud de transferencia');
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in Node.js
                /*console.error('The request was made but no response was received');
                console.log(error.request);*/

                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la consulta de solicitud de transferencia');
            } else {
                // Something happened in setting up the request that triggered an Error
                /*console.error('Something happened in setting up the request that triggered an Error');
                console.log('Error', error.message);*/
                throw buildSolicitudTransferenciaResponseError('Se produjo un error al procesar la consulta de solicitud de transferencia');
            }
            //console.log(error.config);
        }
    },
}
export default TransferenciaRegistradorService;