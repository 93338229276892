import React, { useState, useEffect } from 'react';
import TransferirDominio from "./modales/TransferirDominio";
import BotonesTransferencia from './verBotonesTransferencia.js';
import TransferenciaRegistradorService from "../../../services/TransferenciaRegistradorService";




export const VerDominioTransferenciaRegistrador = ( {nombreDominio, dominio, setError, setMensajeOk}) => {
    // const [dominio] = useState(undefined)
    const [showModal, setShowModal] = useState(false);
    const [transferenciaExitosa, setTransferenciaExitosa] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const [transferenciaData, setTransferenciaData] = useState(null);
    const [mostrarHistorial, setMostrarHistorial] = useState(false);
    const datosHistorialPrueba = [];
    const [tuArrayHistorial, setTuArrayHistorial] = useState([]);
    const [reloadTrigger, setReloadTrigger] = useState(false);



    function handleVerHistorialCodigos(e) {
        e.preventDefault();
        setMostrarHistorial(prevState => !prevState);
    }


    const handleSolicitarCodigo = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    function obtenerHistorialPrueba() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(datosHistorialPrueba);
            }, 1000);
        });
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        handleObtenerCodigo();
        handleObtenerHistorialCodigos();
    }, [reloadTrigger]);

    const handleObtenerHistorialCodigos = () => {
        TransferenciaRegistradorService.obtenerHistorialCodigosAutorizacion(nombreDominio)
            .then(historial => {
                const hoy = new Date();
                const historialTransformado = historial
                    .filter(item => {
                        const fechaExpiracion = new Date(item.expirationDate);
                        return fechaExpiracion < hoy || item.state === "USED_FOR_REJECTION" || item.state === "USED_FOR_APPROVAL";
                    })
                    .map(item => ({
                        codigo: item.authorizationCode,
                        fechaSolicitud: item.requestDate,
                        fechaExpiracion: item.expirationDate,
                        // estado:  item.state
                    }));

                console.log(historialTransformado);
                setTuArrayHistorial(historialTransformado);
            })
            .catch(error => {
                console.error('Error al obtener el historial de códigos de autorización', error);
            });
    };


    const handleObtenerCodigo = () => {
        const result = TransferenciaRegistradorService.obtenerCodigoAutorizacion(nombreDominio)
            .then(response => {
                if (response.code === 'OK') {
                    setTransferenciaData(response);
                    if (response.authorizationCode !== null && response.state!== "USED_FOR_REJECTION" && response.state!== "USED_FOR_APPROVAL")
                    {
                        setTransferenciaExitosa(true);
                    }

                } else {
                    console.error('Error en la solicitud ' + response.mensaje);
                }
            })
            .catch(error => {
                console.error('Error en la solicitud HTTP', error);
            });
    };
    function esFechaVigente(fecha) {
        const hoy = new Date();
        const fechaExpiracion = new Date(fecha);
        return fechaExpiracion >= hoy;
    }

    function HistorialCodigos({ historial }) {
        return (
            <table className="data-domain historial-codigos">
                <thead>
                <tr>
                    <th>Código de Autorización</th>
                    <th>Fecha de solicitud</th>
                    <th>Fecha de expiración</th>
                    {/*<th>Estado</th>*/}
                </tr>
                </thead>
                <tbody>
                {historial.map((item, index) => (
                    <tr key={index}>
                        <td>{item.codigo}</td>
                        <td>{item.fechaSolicitud}</td>
                        <td>{item.fechaExpiracion}</td>
                        {/*<td>{item.estado}</td>*/}
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }



    return (
        <div>
            <fieldset>
                <legend>Transferencia de Registrador</legend>
                <div className="cfx">
                    {transferenciaExitosa ? (
                        <table className="data-domain transferencia-registrador-con-codigo">
                            <tbody>
                            <tr style={{width: "50%"}}>
                                <td>Código de Autorización</td>
                                <td>{transferenciaData && transferenciaData.authorizationCode}</td>
                            </tr>
                            <tr>
                                <td>Fecha de solicitud</td>
                                <td>{transferenciaData && transferenciaData.requestDate}</td>
                            </tr>
                            <tr>
                                <td>Fecha de expiración</td>
                                <td>{transferenciaData && transferenciaData.expirationDate}</td>
                            </tr>
                            </tbody>
                        </table>
                    ) : (
                        <p>Para iniciar la Transferencia de Registrador debe&nbsp;
                            <a id="idTransReg" href="#" onClick={handleSolicitarCodigo}>Solicitar un Código de Autorización</a>
                        </p>
                    )}
                </div>
                <BotonesTransferencia estado={transferenciaData && transferenciaData.state} transferdata={transferenciaData} nombreDominio={nombreDominio} setError={setError} setMensajeOk={setMensajeOk} setReloadTrigger={setReloadTrigger}/>
                {tuArrayHistorial && tuArrayHistorial.length > 0 && (
                    <p style={{textAlign: "left", margin: "0", padding: "0"}}>
                        <a href="#" onClick={handleVerHistorialCodigos}>
                            {mostrarHistorial ? "Ocultar historial de Códigos de Autorización" : "Ver historial de Códigos de Autorización"}
                        </a>
                    </p>
                )}
                <div>
                    {mostrarHistorial && <HistorialCodigos historial={tuArrayHistorial}/>}
                </div>
            </fieldset>
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close-modal" onClick={handleCloseModal}></span>
                        <TransferirDominio
                            nombreDominio={nombreDominio}
                            dominio={dominio}
                            onCloseModal={handleCloseModal}
                            transferenciaExitosa={transferenciaExitosa}
                            setTransferenciaExitosa={setTransferenciaExitosa}
                            setTransferenciaData={setTransferenciaData}
                            setError={setError}
                            setMensajeOk={setMensajeOk}

                        />


                    </div>

                </div>

            )}

        </div>
    );


}