import {MensajeError} from "../../../MensajeError";
import {MensajeOk} from "../../../MensajeOk";
import React, {useState} from "react";
import stylesHome from "../../HomeTabTAR.module.css";
import {oidc_get_username} from "../../../../security/OidcManager";
import FeedbackSpinner from "../../../../components/feedback/FeedbackSpinner";
import {Link} from "react-router-dom";
import {
    localStorageImportarDominioClear,
    obtenerDominioValidado,
    obtenerMediopago,
    obtenerReqTransf
} from "../SolTransLocalStorageUtils";
import {
    buildDatosContactoSolicitudTAR,
    buildSolicitudTransferenciaRequest
} from "../../../../dto/tar/TransferenciaRegistradorDTOBuilder";
import transferenciaService from "../../../../services/TransferenciaRegistradorService";

const Confirmacion = () => {
    const URL_MIS_DOMINIOS = process.env.REACT_APP_URL_MIS_DOMINIOS;
    const URL_TICKETS = process.env.REACT_APP_URL_ACCEDER_TICKETS + "TICKET_AYUDA";

    const [isProcesando, setIsProcesando] = useState(true);
    const [isRequestOk, setIsRequestOk] = useState(true);
    const [mensajeError, setMensajeError] = useState(undefined);

    const procesarSolicitudTransferencia = (requestTAR) => {
        transferenciaService.solicitarTransferencia(requestTAR).then(response => {
            setIsRequestOk(response.isOk);
            setMensajeError(response.message);
        }).catch(error => {
            setIsRequestOk(false);
            setMensajeError(error.errorMessage);
        }).finally(() => {
            localStorageImportarDominioClear();
            setIsProcesando(false);
        });
    }

    React.useEffect(() => {
        const dominio = obtenerDominioValidado();
        const reqTransf = obtenerReqTransf();
        const datosPago = obtenerMediopago().templateCuentaPagoDTO;

        const datosContactoDTO = buildDatosContactoSolicitudTAR(
            reqTransf.nombre,
            reqTransf.apellido,
            reqTransf.dir,
            reqTransf.tel,
            reqTransf.email
        );

        const requestTAR = buildSolicitudTransferenciaRequest(dominio, reqTransf.codEpp, datosContactoDTO, datosPago);

        procesarSolicitudTransferencia(requestTAR);
    }, []); // -> Sin dependencias, solo se ejecuta una vez

    return <>
        {
            isProcesando?
                <div>
                    <FeedbackSpinner open={isProcesando} message={'Procesando la importación del dominio...'}/>
                </div> :
                <div id="step-content" className={stylesHome.stepsContainer}>
                    <div id="step5" className="step-content" style={{width: "100%"}}>
                        {!isRequestOk && <MensajeError mensajeError={mensajeError}/>}
                        {isRequestOk && <MensajeOk mensajeOk={'La solicitud de transferencia fue procesada exitosamente'}/>}

                        {
                            isRequestOk? <>
                                <p className={stylesHome.info}>
                                    <strong>{oidc_get_username()}</strong>, te informamos que tu solicitud de transferencia de dominio se procesó exitosamente.</p>

                                <p className={stylesHome.info}>Para finalizar la importación del dominio debes confirmar la solicitud en el lugar donde solicitaste el código EPP <strong>en los próximos 10 días</strong>. Se debe tener en cuenta que en caso de no confirmar la solicitud en el plazo indicado, se debe solicitar la cancelación del cargo de la misma</p>
                                <p className={stylesHome.info}>A través de la sección de <Link to="/transferir-dominio/lista-solicitudes">Administración de solicitudes de transferencia de Dominio</Link> podrás accedes y gestionar la Solicitud de la Transferencia.</p>
                                <p className={stylesHome.info}>Luego de aprobar la Solicitud de Transferencia, podrás comenzar a administrar tu dominio desde la sección de <Link to="/administrar/mis-dominios">Mis Dominios</Link>. Y no te preocupes, que todos los datos de configuración de tu dominio se quedarán igual.</p>
                            </> : <>
                                <p className={stylesHome.info}>La solicitud de transferencia de dominio no se pudo procesar en este momento, puedes intentarlo nuevamente en unos minutos.</p>
                                <p className={stylesHome.info}>Si el problema persiste, no dudes en ponerte en <a href={URL_TICKETS}>contacto</a> con nosotros.</p>
                            </>
                        }
                    </div>
                </div>
        }
    </>
}

export default Confirmacion;