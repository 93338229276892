import React, {useContext} from "react";
import AppContext from "../../../store/AppContext";

export const ListaRenovacionDominiosPasoDos = (props) => {
    const appCtx = useContext(AppContext);
    const items = appCtx.carrito.renovacionesPendientes;

    return (
        <>
            {items == null || items.length === 0 ?
                <></> :
                <>
                    <table id="id-tabla-ren-dom" className="cart">
                        <caption>Renovación de dominios</caption>
                        <thead>
                            <tr>
                                <th>Artículo</th>
                                <th>Duración</th>
                                <th className="tdright">Precio</th>
                            </tr>
                        </thead>
                        <tbody id="register_domains_tbody">
                            { items.map((dominio, index) => (

                                <tr className="last" key={dominio.nombre}>
                                    <td>{ dominio.nombre }</td>
                                    <td>{dominio.anios} {(dominio.anios > 1) ? "años" : "año"}</td>
                                    <td className="tdright">$ { dominio.precio * dominio.anios}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td className="tdright">Subtotal:</td>
                                <td className="price" id="id_subtotal_renovaciones">$ {props.subTotal}</td>
                            </tr>
                        </tfoot>
                    </table>
                </>
            }
        </>
    );
}