import {
    buildAgregarRegistroDominio,
    buildAgregarRegistroDominioResponse
} from "../dto/AgregarRegistroDominioDTOBuilder";
import {
    buildEliminarRegistroDominio,
    buildEliminarRegistroDominioResponse,
    buildObtenerPrecioProductoResponse
} from "../dto/EliminarRegistroDominioDTOBuilder";
import {buildAsociarUsuarioVentaResponse} from "../dto/AsociarUsuarioVentaDTOBuilder";
import http from '../support/HttpService';

const REACT_APP_AGREGAR_REGISTRO_DOMINIO = process.env.REACT_APP_AGREGAR_REGISTRO_DOMINIO;
const REACT_APP_ELIMINAR_REGISTRO_DOMINIO = process.env.REACT_APP_ELIMINAR_REGISTRO_DOMINIO;
const REACT_APP_OBTENER_TARIFAS = process.env.REACT_APP_OBTENER_TARIFAS;
const REACT_APP_ASOCIAR_USUARIO_VENTA = process.env.REACT_APP_ASOCIAR_USUARIO_VENTA;

const RegistroDominioService = {
    agregarRegistroDominio: async function(registrarDominios, venta) {
        try {
            const response = await http.post(REACT_APP_AGREGAR_REGISTRO_DOMINIO, buildAgregarRegistroDominio(registrarDominios, venta));
            return buildAgregarRegistroDominioResponse(response.data)
        } catch (error) {
            console.log("Error al agregar dominio");
        }
    },

    eliminarRegistroDominio: async function(idDominio, uuidVenta) {
        try {
            const response = await http.post(REACT_APP_ELIMINAR_REGISTRO_DOMINIO, buildEliminarRegistroDominio(idDominio, uuidVenta));
            return buildEliminarRegistroDominioResponse(response.data);
        } catch (error) {
            console.log("Error al eliminar dominio");
        }
    },

    obtenerTarifas: async function(extension) {
        try {
            const response = await http.get(REACT_APP_OBTENER_TARIFAS + extension);
            return buildObtenerPrecioProductoResponse(response.data);
        } catch (error) {
            console.log("Error al obtener tarifas de productos");
        }
    },

    asociarUsuarioAVenta: async function(uuidVenta) {
        try {
            const response = await http.get(REACT_APP_ASOCIAR_USUARIO_VENTA + uuidVenta);
            return buildAsociarUsuarioVentaResponse(response.data);
        } catch (error) {
            console.log("Error al obtener tarifas de productos");
        }
    }

}
export default RegistroDominioService;