import React from 'react';
import Modal from "../../../components/Modal";
import './ModalRechazarInvitacion.css';
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";
import { useNavigate } from 'react-router-dom';


export const ModalRechazarInvitacion = ({ isOpen, handleOnClose, invitacion, setError, setMensajeOK }) => {
    const navigate = useNavigate();

    //request.status
    const handleCancelar = () => {
        AdministracionUsuarioService.cancelarInvitacionPendiente(invitacion.codigoInvitacion)
            .then(response => {
                navigate('/administrar/mis-dominios', { state: { mensajeOk: { mostrarMensaje: true, mensaje: 'La invitación ha sido rechazada exitosamente.' } } });

            })
            .catch((error) => {
                setError({
                    error: true,
                    mensaje: "La invitación ya no es válida."
                });
            });
        handleOnClose();
    };

    return (
        <Modal
            idDivModal="idModalLiberar"
            handleClose={handleOnClose}
            isOpen={isOpen}
            closeOnClickOutside={false}
        >
            <div className="modal-content">
                <p className="modal-text">
                    ¿Desea rechazar la invitación para el dominio <strong>{invitacion?.nombreDominio}</strong>?
                </p>
                <hr className="linea"/>
                <div className="modal-actions">
                    <button className='btn confirm' onClick={handleCancelar}>Rechazar</button>
                    <button className='btn cancel' onClick={handleOnClose}>Cancelar</button>
                </div>
            </div>
        </Modal>
    );
};
