import React, {useState} from 'react'
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";

export const ModalRecuperarDominio = ({ nombreDominio, handleOnClose, isOpen, setError, respuestaAccionOk, irVerDominio, setIsProcesando, setMensajeProcesando }) => {

    const [motivo, setMotivo] = useState("")
    const [estaRecuperando, setEstaRecuperando] = useState(false);


    const handleRecuperarDominioVencido = (e) => {
        e.preventDefault();

        const estaRecuperandoLocalStorage = localStorage.getItem('estaRecuperando') === 'true';
        // Verifica si ya está en proceso de recuperacion
        if (estaRecuperandoLocalStorage) {
            setError({
                error: true,
                mensaje: "La recuperación del dominio ya está en proceso."
            });
            handleOnClose();
            return;
        }
        localStorage.setItem('estaRecuperando', 'true');

        let accionDominioDTO = {
            nombreDominio : nombreDominio,
            motivo : motivo
        };
        setError({error : false, mensaje: "Sin errores"})

        if (setIsProcesando !== undefined){
            setMensajeProcesando('Procesando recuperación')
            setIsProcesando(true);
        }
        AdministracionUsuarioService.recuperarDominio(accionDominioDTO)
            .then(response => {
                if (!response.esRespOK) {
                    setError({
                        error: true,
                        mensaje: response.mensaje
                    })
                } else {
                    if (respuestaAccionOk !== undefined) {
                        respuestaAccionOk(response.mensaje);
                    }
                    if (irVerDominio !== undefined){
                        irVerDominio({
                            mostrarMensaje: true,
                            mensaje : response.mensaje
                        });
                    }
                }
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al recuperar el dominio."
                });
            })
            .finally(() => {
                localStorage.removeItem('estaRecuperando');
                if (setIsProcesando !== undefined){
                    setIsProcesando(false);
                }
            });
        handleOnClose();

    }

    return <>
        <Modal
            idDivModal="idModalRecuperar"
            handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
            <p>
                <span>¿Está seguro que desea recuperar el dominio {nombreDominio}?</span>
            </p>
            <hr className={"linea"}></hr>
            <div className="right">
                <button className={'btn'} onClick={handleRecuperarDominioVencido}>Confirmar</button>
                <button className={'btn'} onClick={handleOnClose}>Cancelar</button>
            </div>
        </Modal>
    </>
}