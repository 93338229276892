import React from 'react';

export const NotFound = () => {
    return(
        <div className="NotFound">
            <div className="lander">
                <h1>Pagina no encontrada</h1>
            </div>
        </div>
    )
}