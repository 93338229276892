import http from '../support/HttpService';
import {buildNuevoTicket} from "../dto/TicketsDTOBuilder";

const REACT_APP_TICKET = process.env.REACT_APP_TICKET;

const TicketsService = {

    generarTicket: async function(ticket, tipoOperacion, captchaResponse) {
        try {
            const response = await http.post(REACT_APP_TICKET, buildNuevoTicket(ticket, tipoOperacion, captchaResponse));
            return response.data;
        } catch (error) {
            console.error("Error generando ticket");
            return {
                respuestaOK : false,
                mensajeRespuesta : "Se produjo un error al procesar su solicitud. Por favor intentelo nuevamente más tarde."
            }
        }
    },

}
export default TicketsService;
