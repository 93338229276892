import React, {useContext, useEffect, useState} from "react";
import {CarritoComprasPasos} from "../CarritoComprasPasos";
import {ListaRegistroDominiosPasoCuatro} from "./ListaRegistroDominiosPasoCuatro";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {CarritoComprasTotal} from "../CarritoComprasTotal";
import AppContext from "../../../store/AppContext";
import CarritoService from "../../../services/CarritoService";
import {MensajeError} from "../../MensajeError";
import {ValidacionAutenticado} from "../../../components/validaciones/ValidacionAutenticado";
import {ValidacionesCarrito} from "../../../components/validaciones/ValidacionesCarrito";
import {ListaRenovacionDominiosPasoDos} from "../paso_02/ListaRenovacionDominiosPasoDos";
import {ListaRenovacionDominiosPasoCuatro} from "./ListaRenovacionDominiosPasoCuatro";
import FeedbackSpinner from "../../../components/feedback/FeedbackSpinner";
import {ListaTransferenciasTitularPasoCuatro} from "./ListaTransferenciasTitularPasoCuatro";

export const CarritoComprasPasoCuatro = () => {
    const appCtx = useContext(AppContext);
    const history = useNavigate();
    const location = useLocation();
    const [templateCuentaPago, setTemplateCuentaPago] = useState();
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const [compraConfirmada, setCompraConfirmada] = useState(false);
    let carritoRegistros = appCtx.carrito.registrosPendientes;
    let carritoRenovaciones = appCtx.carrito.renovacionesPendientes;
    let carritoTransferenciasTitular = appCtx.carrito.transferenciasTitularPendientes;
    let venta = appCtx.venta;
    let subTotal = 0;
    let subTotalRegistros = 0;
    let subTotalRenovaciones = 0;
    let subTotalTransferenciasTitular = 0;

    let descuento = 0;

    const [isProcesandoCompra, setIsProcesandoCompra] = useState(false);

    useEffect(() => {
        setIsProcesandoCompra(false);

        if (location.state && location.state.errorValidacionCarrito.error) {
            setError(location.state.errorValidacionCarrito);
        }

        if (venta !== undefined && venta.uuidVenta !== "") {
            obtenerFormaPagoGuradadaVenta(venta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function calcularSubtotal(items) {
        let subTotalAux = 0;
        if (items.length > 0) {
            items.forEach((dominio) => {
                if (dominio.anios === undefined ){
                    subTotalAux = subTotalAux + (dominio.precio);
                }else{
                    subTotalAux = subTotalAux + (dominio.precio * dominio.anios);
                }
            })
        }
        return subTotalAux;
    }

    subTotalRegistros = calcularSubtotal(carritoRegistros);
    subTotalRenovaciones = calcularSubtotal(carritoRenovaciones);
    subTotalTransferenciasTitular = calcularSubtotal(carritoTransferenciasTitular);

    subTotal = subTotalRegistros + subTotalRenovaciones + subTotalTransferenciasTitular;

    const utilizaDatosFacturacion = false;

    const obtenerFormaPagoGuradadaVenta = (venta) => {
        CarritoService.obtenerFormaPagoGuardadaVenta(venta.uuidVenta)
            .then(response => {
                if (response.esRespOK) {
                    setTemplateCuentaPago(response.cuentaPago);
                } else {
                    setError({
                        error: true,
                        mensaje: "Error al obtener la forma de pago guardada"
                    });
                }
            })
        return templateCuentaPago;
    }

    const confirmarCompra = (event) => {

        event.preventDefault();
        if (compraConfirmada) {
            return;
        }
        setCompraConfirmada(true);
        setIsProcesandoCompra(true);
        CarritoService.finalizarCompra(appCtx.venta.uuidVenta)
            .then(response => {
                appCtx.clean();
                if (response.respuestaOK && response.codigo === 'OK') {
                    setIsProcesandoCompra(false);
                    history('/carrito-compra-paso-cinco',
                        {
                            state: {
                                mensajeOk: { mostrarMensaje: true, mensaje: 'Tu compra se realizó con éxito.' },
                                error: { error: false, mensaje: 'Sin errores.' }
                            }
                        });
                }else{
                    history('/carrito-compra-paso-cinco',
                        {
                            state: {
                                mensajeOk: { mostrarMensaje: false, mensaje: '' },
                                error: { error: true, mensaje: 'Error al procesar la confirmaci\u00F3n de la compra.' }
                            }
                        });
                }
            }).catch(() => {
            setError({
                error: true,
                mensaje: "Error al procesar la confirmaci\u00F3n de la compra"
            });
        }).finally(() => {
            setCompraConfirmada(false);
            setIsProcesandoCompra(false);

        });
    }

    const validarFormaDePagoCarrito = (template) => {
        //validar que la venta tenga template de pago
        return template !== undefined
                    && template !== null
                    && template.cuenta !== undefined
                    && template.cuenta !== null;
    }

    return (
      <>
        {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

        { isProcesandoCompra?
            <div>
                <FeedbackSpinner open={isProcesandoCompra} message={'Procesando ...'}/>
            </div> : <></>
        }

        <ValidacionAutenticado/>

        <ValidacionesCarrito paso={4} setError={setError}/>

        <CarritoComprasPasos paso={4}
                               tituloCarrito={'Carrito de compras'}
                               parrafoCarrito={''}
          />

        <br clear="all"/>

        <form id="id-form-finalizar-venta">

            { utilizaDatosFacturacion &&
                <fieldset className="left half">
                    <legend>Datos de facturación</legend>
                    <div className="form-item">
                        <label htmlFor="">Apellido</label> Apellido
                    </div>

                    <div className="form-item">
                        <label htmlFor="">Nombre</label> Nombre
                    </div>

                    <div className="form-item">
                        <label htmlFor="">Domicilio constituido en Uruguay</label> Dirección
                        <p>Los datos ingresados deben ser verdaderos, son tenidos en cuenta para cualquier gestión</p>
                    </div>

                    <div className="form-item">
                        <label htmlFor="">Ciudad</label> Ciudad
                    </div>

                    <div className="form-item">
                        <label htmlFor="">País</label> País
                    </div>

                    <div className="form-item">
                        <label htmlFor="">Tipo de documento</label> Tipo de documento
                    </div>

                    <div className="form-item">
                        <label htmlFor="">Número de documento</label> Número de documento
                    </div>
                </fieldset>
            }

            { validarFormaDePagoCarrito(templateCuentaPago) &&
                <fieldset className="left half">
                  <legend>Forma de pago</legend>
                  <p><strong>Débito en la factura de telefonía fija o datos de Antel.</strong></p>
                  <br/>
                      <div className="form-item">
                          <label>Pa&iacute;s</label>{templateCuentaPago !== undefined ? templateCuentaPago.cuenta.pais.nombre : ''}
                      </div>
                      <div className="form-item">
                          <label>Tipo de documento</label>{templateCuentaPago !== undefined ? templateCuentaPago.cuenta.tipoDocumento.nombre : ''}
                      </div>
                      <div className="form-item">
                          <label>Número de documento</label>{templateCuentaPago !== undefined ? templateCuentaPago.cuenta.nroDocumento : ''}
                      </div>
                      <div className="form-item">
                          <label>Nro. de Cuenta</label>{templateCuentaPago !== undefined ? templateCuentaPago.cuenta.cuenta : ''}
                      </div>
                </fieldset>
            }

            <br clear="all"/>
            <hr/>

            <ListaRegistroDominiosPasoCuatro subTotal={subTotalRegistros}></ListaRegistroDominiosPasoCuatro>
            <ListaRenovacionDominiosPasoCuatro subTotal={subTotalRenovaciones} errorEnCarrito={setError}/>
            <ListaTransferenciasTitularPasoCuatro subTotal={subTotalTransferenciasTitular} errorEnCarrito={setError}/>

            <CarritoComprasTotal subTotal={subTotal} descuento={descuento}/>

            <br clear="all"/>
            <div className="right">
                <button id="id-submit-carrito" className="btn" onClick={confirmarCompra} disabled={compraConfirmada}>{!compraConfirmada ? "Confirmar compra": "Procesando..."}</button>
            </div>
            <br clear="all"/>
            <p>
                <Link to={{ pathname: "/carrito-compra-paso-tres"}}>Volver al paso anterior</Link>
            </p>

        </form>

        <div className="right"></div>
      </>
    );
};