import React from 'react'
import {Link} from "react-router-dom";

export const Footer = () => {

    const currentDate = new Date();
    const year = currentDate.getFullYear();

    return <>
        <div id="footer">
            <div className="cfx">
                <ul>
                    <li className="tit">Mi cuenta</li>
                    <li><Link to={{ pathname: "/administrar/mis-dominios"}}>Mis dominios</Link></li>
                    <li><Link to={{ pathname: "/administrar/mensajes"}}>Mensajes</Link></li>
                    <li><Link to={{ pathname: "/administrar/mis-datos"}}>Mis datos</Link></li>

                </ul>
                <ul>
                    <li className="tit">Dominios</li>
                    <li><Link to={{ pathname: "/consulta-disponibilidad"}}>Registrar dominio</Link></li>
                    <li><Link to={{ pathname: "/consulta-whois"}}>Consultar Whois</Link></li>
                </ul>
                <ul>
                    <li className="tit">Soporte</li>
                    <li><Link to="/ayuda">Ayuda</Link></li>
                    {/*<li><a href="http://ayuda.adinet.com.uy/ayuda/index/197" target="_blank" rel="noreferrer">Ayuda</a>*/}
                    {/*</li>*/}
                    <li><Link to="/terminos-condiciones">Términos y condiciones</Link></li>
                </ul>
            </div>
        </div>


        <div id="footer-info">
            <div>
                <p>© {year}  ANTEL, LA EMPRESA DE COMUNICACIÓN DE LOS URUGUAYOS</p>
                <p>Guatemala 1075 - Complejo Torre de las Telecomunicaciones | Montevideo, Uruguay</p>
            </div>
        </div>
    </>
}