import React, {useContext, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import DominioRegistroService from "../../../services/RegistroDominioService";
import {TipoItemCarrito} from "../../../store/AppProvider";
import AppContext from "../../../store/AppContext";
import {Link} from "react-router-dom";
import Modal from "../../../components/Modal";

const ModalCarritoNavTop = (props) => {

    const appCtx = useContext(AppContext);
    let itemsRegistroDominio = appCtx.carrito.registrosPendientes;
    let itemsRenovacionDominio = appCtx.carrito.renovacionesPendientes;
    let itemsTransferenciaTitular = appCtx.carrito.transferenciasTitularPendientes;

    let uuidVenta = appCtx.venta.uuidVenta;

    const [totalItems, setTotalItems] = useState(false);
    const [subTotal, setSubTotal] = useState(false);
    const [subTotalRenovaciones, setSubTotalRenovaciones] = useState(false);
    const [subTotalTransferenciasTitular, setSubTotalTransferenciasTitular] = useState(false);

    const [haveRegistrosDominio, setHaveRegistroDominio] = useState(false);
    const [haveRenovacionesDominio, setHaveRenovacionesDominio] = useState(false);
    const [haveTransferenciasTitular, setHaveTransferenciasTitular] = useState(false);

    useEffect(() => {
        let totalItems = 0;
        let subTotal = 0;
        if (itemsRegistroDominio && itemsRegistroDominio.length > 0){
            totalItems = totalItems + itemsRegistroDominio.length;
            subTotal = itemsRegistroDominio.reduce((sum, item) => sum + item.precio, subTotal);
            setHaveRegistroDominio(true);
        }else{
            setHaveRegistroDominio(false);
        }

        let subTotalRenovaciones = 0;
        if (itemsRenovacionDominio && itemsRenovacionDominio.length > 0){
            totalItems = totalItems + itemsRenovacionDominio.length;
            subTotalRenovaciones = itemsRenovacionDominio.reduce((sum, item) => sum + item.precio * item.anios, subTotalRenovaciones);
            setHaveRenovacionesDominio(true);
        }else{
            setHaveRenovacionesDominio(false);
        }

        let subTotalTransferenciasTitular = 0;
        if (itemsTransferenciaTitular && itemsTransferenciaTitular.length > 0){
            totalItems = totalItems + itemsTransferenciaTitular.length;
            subTotalTransferenciasTitular = itemsTransferenciaTitular.reduce((sum, item) => sum + item.precio, subTotalTransferenciasTitular);
            setHaveTransferenciasTitular(true);
        }else{
            setHaveTransferenciasTitular(false);
        }
        setTotalItems(totalItems);
        setSubTotal(subTotal);
        setSubTotalRenovaciones(subTotalRenovaciones);
        setSubTotalTransferenciasTitular(subTotalTransferenciasTitular)
    },[itemsRegistroDominio, itemsRenovacionDominio, itemsTransferenciaTitular])


    const removeFromCarrito = (e)=> {
        e.preventDefault();
        const idDominio = e.target.id;
        DominioRegistroService.eliminarRegistroDominio(idDominio, uuidVenta)
            .then((response) => {
                if (response.respuestaOK) {
                    appCtx.eliminarItemCarrito(idDominio, TipoItemCarrito.registro);
                }
            }).catch(() => {
            console.log("Error eliminando registro de dominio");
        });
    }

    const removeRenovacionFromCarrito = (e)=> {
        e.preventDefault();
        const idDominio = e.target.id;
        DominioRegistroService.eliminarRegistroDominio(idDominio, uuidVenta)
            .then((response) => {
                if (response.respuestaOK) {
                    appCtx.eliminarItemCarrito(idDominio, TipoItemCarrito.renovacion);
                }
            }).catch(() => {
            console.log("Error eliminando renovacion de dominio");
        });
    }

    const removeTransferenciaTitularFromCarrito = (e)=> {
        e.preventDefault();
        const idDominio = e.target.id;
        DominioRegistroService.eliminarRegistroDominio(idDominio, uuidVenta)
            .then((response) => {
                if (response.respuestaOK) {
                    appCtx.eliminarItemCarrito(idDominio, TipoItemCarrito.transferenciaTitular);
                }
            }).catch(() => {
            console.log("Error eliminando transferencia de titular de dominio");
        });
    }

    return (
        <Modal handleClose={props.handleOnClose} isOpen={props.isOpen} modalRelativeTo="id-link-carrito"
               idDivModal="id_nav_top_cart_over" className="modal-nav-cart" closeClassName="close" closeOnClickOutside={false}
               {...(props.wrapperId ? { wrapperId: props.wrapperId } : {})}>
            <div>
                {totalItems > 0 ?
                    <p className="add">Tienes {totalItems} artículos en tu carrito</p>
                    :
                    <p className="add">No tienes artículos en tu carrito</p>
                }

                {haveRegistrosDominio &&
                    <p style={{marginTop: "10px"}}>Registrar dominios</p>
                }

                <table className="cart-list">
                    <tbody>
                    {haveRegistrosDominio
                        && itemsRegistroDominio.map((item) => (
                            <tr key={item.nombre}>
                                <td className="domain">
                                    {/* eslint-disable-next-line */}
                                    <a id={item.id} name="link-delete-sidebar" className="close"
                                       onClick={removeFromCarrito}></a>
                                    <span>{item.nombre}</span>
                                </td>
                                <td className="year"> 1 Año</td>
                                <td className="price">$ {item.precio}</td>
                            </tr>
                        ))}

                    </tbody>

                    {subTotal > 0 &&
                        <tfoot>
                        <tr>
                            <td className="tdright" colSpan="2">Subtotal:</td>
                            <td>$ {subTotal}
                            </td>
                        </tr>
                        </tfoot>
                    }
                </table>

                {haveRenovacionesDominio &&
                    <p style={{marginTop: "10px"}}>Renovación de dominios</p>
                }

                <table className="cart-list">
                    <tbody>
                    {haveRenovacionesDominio
                        && itemsRenovacionDominio.map((item) => (
                            <tr key={item.nombre}>
                                <td className="domain">
                                    {/* eslint-disable-next-line */}
                                    <a id={item.id} name="link-delete-sidebar" className="close"
                                       onClick={removeRenovacionFromCarrito}></a>
                                    <span>{item.nombre}</span>
                                </td>
                                <td className="year"> {item.anios} {(item.anios > 1) ? "Años" : "Año"}</td>
                                <td className="price">$ {item.precio * item.anios}</td>
                            </tr>
                        ))}
                    </tbody>

                    {subTotalRenovaciones > 0 &&
                        <tfoot>
                        <tr>
                            <td className="tdright" colSpan="2">Subtotal:</td>
                            <td>$ {subTotalRenovaciones}
                            </td>
                        </tr>
                        </tfoot>
                    }
                </table>


                {haveTransferenciasTitular &&
                    <p style={{marginTop: "10px"}}>Transferir titular</p>
                }

                <table className="cart-list">
                    <tbody>
                    {haveTransferenciasTitular
                        && itemsTransferenciaTitular.map((item) => (
                            <tr key={item.nombre}>
                                <td className="domain">
                                    {/* eslint-disable-next-line */}
                                    <a id={item.id} name="link-delete-sidebar" className="close"
                                       onClick={removeTransferenciaTitularFromCarrito}></a>
                                    <span>{item.nombre}</span>
                                </td>
                                <td className="price">$ {item.precio }</td>
                            </tr>
                        ))}
                    </tbody>

                    {subTotalTransferenciasTitular > 0 &&
                        <tfoot>
                        <tr>
                            <td className="tdright" colSpan="2">Subtotal:</td>
                            <td>$ {subTotalTransferenciasTitular}
                            </td>
                        </tr>
                        </tfoot>
                    }
                </table>

                {/*No se debería mostrar si no hay items en el carrito, por el momento se deja por coherencia con DNS 1.0*/}
                <Link className="btn right" to={{pathname: "/carrito-compra-paso-uno"}}
                      onClick={props.handleOnClose}>Ir al carrito</Link>
            </div>

        </Modal>
    );
}

ModalCarritoNavTop.propTypes = {
    handleOnClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default ModalCarritoNavTop;