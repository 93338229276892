import React from 'react'

export const Header = () => {
    return <div id="header" className="cfx">
        <div className="left">
            <h1 className="logo">
                <a href="/"><img alt="Dominiosuy" src="/assets/logo.png"/></a>
            </h1>
        </div>
    </div>
}