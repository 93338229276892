import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import './ConsultaDisponibilidad.css';
import {ConsultaDisponibilidadSidebarResposnse} from './ConsultaDisponibilidadSidebarResposnse';
import RegistroDominioService from "../../services/RegistroDominioService";
import {TipoItemCarrito, TipoMensaje} from "../../store/AppProvider";
import AppContext from "../../store/AppContext";

export const ConsultaDisponibilidadContentResponse = (props) => {
    const appCtx = useContext(AppContext);
    const location = useLocation();
    const [preSeleccionDominios, setPreSeleccionDominios] = useState([]);
    const [agregandoAlCarrito, setAgregandoAlCarrito] = useState(false);
    let arrayPreSel = [];

    useEffect(() => {
        let promises = [];
        for (let i = 0; i < props.resultadoDominios.length; i++) {
            promises.push(obtenerTarifas(props.resultadoDominios[i].nombreDominio));
        }

        Promise.all(promises).then((response) => {
            for (let index = 0; index < response.length; index++) {
                if (response[index].codigo === 'OK') {
                    arrayPreSel.push({
                        seleccionado: props.resultadoDominios[index].disponible,
                        disponible: props.resultadoDominios[index].disponible,
                        reservado: props.resultadoDominios[index].reservado,
                        nombre: props.resultadoDominios[index].nombreDominio,
                        anios: 1, // Uno es el valor por defecto para mostrar
                        precio: +response[index].precioUnitario
                    })
                }
            }
            setPreSeleccionDominios(arrayPreSel);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resultadoDominios, location]);

    const handleClickCheck = (e) => {
        let nombreDominio = e.target.value;
        let editarPreSeleccion;

        editarPreSeleccion = modificarSeleccionados(nombreDominio, e.target.checked);
        setPreSeleccionDominios(editarPreSeleccion);
    }

    const modificarSeleccionados = (dominioNombre, chequeado) => {
        return preSeleccionDominios.map(dominio => {
            if (dominio.nombre === dominioNombre) {
                dominio.seleccionado = chequeado;
            }
            return dominio;
        });
    }

    const obtenerTarifas = (dominio) => {
        let extension = obtenerExtension(dominio);
        return RegistroDominioService.obtenerTarifas(extension);
    }

    const obtenerExtension = (dominio) => {
        let resultado = dominio.match(/[.].*/);
        return resultado[0];
    }

    const agregarAlCarrito = () => {
        // Si ya se está agregando al carrito, no permitimos mas clics
        if (agregandoAlCarrito) return;

        // Desactivar el boton para evitar clics múltiples
        setAgregandoAlCarrito(true);

        const registrosPendientes = appCtx.carrito.registrosPendientes;

        if (registrosPendientes == null || registrosPendientes.length < 10) {
            let registrarDominios = [];

            preSeleccionDominios.forEach((dominio) => {
                if (dominio.seleccionado && !registrosPendientes.some((elem) => elem.nombre === dominio.nombre)) {
                    registrarDominios.push({ "nombre": dominio.nombre, "anios": dominio.anios });
                }
            });

            if (registrarDominios.length > 0) {
                RegistroDominioService.agregarRegistroDominio(registrarDominios, appCtx.venta)
                    .then((response) => {
                        if (response.respuestaOK) {
                            response.dominiosOK.forEach((dominioNuevo) => {
                                const dominioActual = response.ventaDTO.items.find((item) => item.dominio.nombre === dominioNuevo.dominio.nombre);
                                appCtx.agregarItemCarrito({ id: dominioActual.dominio.id, nombre: dominioActual.dominio.nombre, precio: dominioActual.precioUnitario }, TipoItemCarrito.registro, null);
                            });
                            appCtx.setVenta({ idVenta: response.ventaDTO.ventaId, uuidVenta: response.ventaDTO.uuidVenta });
                        } else {
                            appCtx.mostrarMensaje(response.mensaje, TipoMensaje.error);
                        }
                    }).catch(() => {
                    console.log("Error agregando registro de dominio");
                }).finally(() => {
                    setAgregandoAlCarrito(false);
                });
            } else {
                setAgregandoAlCarrito(false);
            }
        } else {
            setAgregandoAlCarrito(false);
        }
    }

    return <>
        {/*contenido*/}
        <div id="content" className="fluid">
            <div className="inner-block">
                {/*caja  mensajes*/}
                <div className="box">

                    <div className="box-content domain-register cfx">
                        {props.dominioBuscadoDisponible ?
                            <h3 className="ok">Dominio disponible</h3> : <h3 className="no-ok">Dominio no disponible</h3>
                        }

                        <form id="id-form-register-domains">
                            <table className="register">
                                <tbody>
                                {  preSeleccionDominios && preSeleccionDominios.map((dominio, index) => (
                                    dominio.disponible?
                                    <tr key={index}>
                                        <th>
                                            <div>
                                                <input value={dominio.nombre} className={"checkbox-space"} type="checkbox"
                                                       checked={dominio.seleccionado} onChange={handleClickCheck}/>
                                                <span>{dominio.nombre}</span>
                                            </div>
                                        </th>

                                        <td>
                                            <select name="anios-dominio" id="id-anios-dominio">
                                                <option value="1">1 año</option>
                                            </select>
                                        </td>
                                        <td className="price">$ { dominio.precio }</td>
                                    </tr> :
                                    <tr className="no-free" key={index}>
                                        <td>
                                            <div>
                                                <input value={dominio.nombre} className={"checkbox-space"} type="checkbox" defaultChecked={dominio.seleccionado} disabled={true}/>
                                                <span>{dominio.nombre}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <Link to="/consulta-whois" state={{dominioDisponibilidad: dominio.nombre}}>Consultar Whois</Link>
                                        </td>
                                        { dominio.reservado ?
                                            <td>
                                                Reservado
                                            </td> :
                                            <></>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <hr/>

                    </form>
                    <div className="right">
                        <button id="btn-submit" className={"btn"} onClick={agregarAlCarrito}
                                disabled={agregandoAlCarrito}>
                            {agregandoAlCarrito ? 'Agregando...' : 'Agregar al carrito de compras'}
                        </button>

                    </div>
                    </div>

                    {/*<s:form id="id-form-register-domains-post" namespace="/" action="registrarDominiosConsultaDisponibilidad" theme="simple" cssStyle="display: none;">*/}
                {/*    <table id="id-tabla-form-post"><tbody></tbody></table>*/}
                {/*</s:form>*/}

                </div>
                {/*fin caja*/}
            </div>
        </div>

        <ConsultaDisponibilidadSidebarResposnse/>
    </>
}