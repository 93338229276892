import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {ModalModificarCuenta} from "../../../view/administrar/misDominios/acciones/ModalModificarCuenta";
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";
import {ModalEliminarFormaPago} from "./ModalEliminarFormaPago";
import CuentaService from "../../../services/CuentaService";

export const VerDominioFormaPago = ( { dominio, nombreDominio, permisos, setMensajeOk, setError, limpiarMensajes}) => {
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const [tieneContenido, setTieneContenido] = useState(dominio?.dominioCore?.cuentaActual ?? null);
    const [linkText, setLinkText] = useState("Ver historial de pagos");
    const [isOpenEditarCuenta, setIsOpenEditarCuenta] = useState(false);
    const [isOpenEliminarCuenta, setIsOpenEliminarCuenta] = useState(false);
    const [permisosDominio, setPermisosDominio] = useState(undefined);
    const [mapPermisosDominio, setMapPermisosDominio] = useState({});
    const [cuentaActual, setcuentaActual] = useState({});
    const [nombreFormaPago, setNombreFormaPago] = useState(dominio?.dominioCore?.cuentaActual?.cuenta?.formaPago?.nombre ?? '');
    const [cuentaCuenta, setCuentaCuenta] = useState(dominio?.dominioCore?.cuentaActual?.cuenta?.cuenta ?? '');
    const [cuentaNroDocumento, setCuentaNroDocumento] = useState(dominio?.dominioCore?.cuentaActual?.cuenta?.nroDocumento ?? '');
    const [historialPagos, setHistorialPagos] = useState([]);
    const [filtro, setFiltro] = useState({
        nombre: dominio.dominio.dominioBE.nombre,
        tipoRelacionUsuDom: 'TODOS',
        fechaVencimientoDesde: '',
        fechaVencimientoHasta: '',
        cuentaPago: '',
        cuentaPagoNombre: '',
        dominiosAlojados: null,
        modulo: 'FRONTEND',
        primerResultado: 0,
        cantResultados: 1
    })
    const respuestaAccionOk = (mensaje) => {
        setMensajeOk({
            mostrarMensaje: true,
            mensaje: mensaje
        });
    }

    useEffect(() => {
        obtenerDominiosUsuario(filtro, true);
        obtenerhistorialformapago();
        obtenerDominio(filtro);
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        obtenerDominiosUsuario(filtro, true);
    }

    const handleCambiar = () => {
        limpiarMensajes();
        setIsOpenEditarCuenta(true);
    };


    const handleEliminar = () => {
        limpiarMensajes();
        if (tieneContenido?.cuenta != null ) {
            setIsOpenEliminarCuenta(true);
        }
        else {
            setError({
                error: true,
                mensaje: "No se puede eliminar la cuenta de pago porque no existe."
            })
            window.scrollTo(0, 0);
        }

    }

    const obtenerDominiosUsuario = (filtro, esSubmit) => {
        let filtroTemp = filtro;
        filtroTemp.nombre = dominio.dominio.dominioBE.nombre;

        AdministracionUsuarioService.obtenerDominiosUsuario(filtroTemp)
            .then(response => {
                if (response.codigo === 'OK') {
                    setPermisosDominio(response.permisosDominio);
                    permisoPorDominio(response.permisosDominio);
                }
            })
            .catch(error => {
                setError({
                    error: true,
                    mensaje: "Error al obtener el dominio"
                })
            });

    }

    const permisoPorDominio = (permisosDominio) => {
        let mapPermisosDoms = new Map();

        Object.entries(permisosDominio).map(([clave, valor]) => {
            mapPermisosDoms.set(clave, {
                nombreDominio: clave,
                esAdmin: valor.esAdministrativo,
                esFinanciero: valor.esFinanciero,
                esTecnico: valor.esTecnico,
                esTitular: valor.esTitular
            })
        })
        setMapPermisosDominio(JSON.parse(JSON.stringify(Object.fromEntries(mapPermisosDoms))));
    }

    const togglePaymentHistory = (e) => {
        e.preventDefault();
        if (showPaymentHistory) {
            setShowPaymentHistory(false);
            setLinkText("Ver historial de pagos");
        } else {
            setShowPaymentHistory(true);
            setLinkText("Ocultar historial de pagos");
        }
    };

    const obtenerhistorialformapago = () => {
        AdministracionUsuarioService.obtenerHistorialFormaPago(nombreDominio)
            .then(response => {
                if(response != null && response.length > 0)
                {
                    setHistorialPagos(response);
                }

            })
            .catch(error => {
                setError({
                    error: true,
                    mensaje: 'Error al obtener el historial de pagos. Por favor, intente de nuevo más tarde.'
                });
            });
    }
    useEffect(() => {
        if (!isOpenEditarCuenta) {
            obtenerDominio(filtro);
        }
    }, [isOpenEditarCuenta]);

    useEffect(() => {
        if (!isOpenEliminarCuenta) {
            const timer = setTimeout(() => {
                obtenerDominio(filtro);
            }, 80);
            return () => clearTimeout(timer);
        }
    }, [isOpenEliminarCuenta]);


    const obtenerDominio= (filtro) => {
        let filtroTemp = filtro;

        AdministracionUsuarioService.obtenerDominiosUsuario(filtroTemp)
            .then(response => {
                if (response.codigo === 'OK') {
                    if(response.dominios[0].dominio.dominioCore.cuentaActual != null && response.dominios[0].dominio.dominioCore.cuentaActual.cuenta != null)
                    {
                        setCuentaCuenta(response.dominios[0].dominio.dominioCore.cuentaActual.cuenta.cuenta
                            ? (dominio.esTitular
                                ? response.dominios[0].dominio.dominioCore.cuentaActual.cuenta.cuenta
                                : response.dominios[0].dominio.dominioCore.cuentaActual.cuenta.cuenta.replace(/\d(?=\d{4})/g, '*'))
                            : null);
                        setCuentaNroDocumento(response.dominios[0]?.dominio?.dominioCore?.cuentaActual?.cuenta?.nroDocumento
                            ? (dominio.esTitular
                                ? response.dominios[0].dominio.dominioCore.cuentaActual.cuenta.nroDocumento
                                : response.dominios[0].dominio.dominioCore.cuentaActual.cuenta.nroDocumento.substring(0, response.dominios[0].dominio.dominioCore.cuentaActual.cuenta.nroDocumento.length - 2).replace(/\d/g, '*') + response.dominios[0].dominio.dominioCore.cuentaActual.cuenta.nroDocumento.substring(response.dominios[0].dominio.dominioCore.cuentaActual.cuenta.nroDocumento.length - 2))
                            : null);
                        setNombreFormaPago(response.dominios[0].dominio.dominioCore.cuentaActual.cuenta.formaPago.nombre ?? null);
                        setTieneContenido(response.dominios[0].dominio.dominioCore.cuentaActual ?? null);
                    }
                    else
                    {
                        setCuentaCuenta('');
                        setCuentaNroDocumento('');
                        setNombreFormaPago('');
                        setTieneContenido(null);
                    }

                }
            })
            .catch(error => {
            });

    }
    return (
        <>
            {isOpenEditarCuenta &&
                <ModalModificarCuenta
                    isOpen={isOpenEditarCuenta}
                    handleOnClose={() => setIsOpenEditarCuenta(false)}
                    dominiosSeleccionados={[dominio.dominio.dominioCore.nombre]}
                    mapPermisosDominio={mapPermisosDominio}
                    rolSeleccionado={filtro.tipoRelacionUsuDom}
                    respuestaAccionOk={respuestaAccionOk}
                />
            }
            {isOpenEliminarCuenta &&
                <ModalEliminarFormaPago
                    isOpen={isOpenEliminarCuenta}
                    handleOnClose={() => setIsOpenEliminarCuenta(false)}
                    dominiosSeleccionados={[dominio.dominio.dominioCore.nombre]}
                    respuestaAccionOk={respuestaAccionOk}
                />
            }
            <fieldset>
                <legend>Forma de pago</legend>
                <div className="cfx">
                    <table className="data-domain">
                        <tbody>
                        <tr>
                            <td>
                                Posee forma de pago para la renovación <strong><span
                                style={{marginRight: '40px'}}>{tieneContenido ? 'SÍ' : 'NO'}</span></strong>
                            </td>
                            <td>
                                <button className="link-style-button" onClick={handleCambiar}>{tieneContenido ? 'Cambiar' : 'Definir'}</button>
                            </td>
                            {tieneContenido &&
                            <td>
                                <button className="link-style-button" onClick={handleEliminar}>Eliminar</button>
                            </td>
                            }
                        </tr>
                        </tbody>
                    </table>
                </div>
                <hr/>

                {nombreFormaPago !== undefined && nombreFormaPago !== '' &&
                    (
                    <div>
                        <div className="cfx">
                            <table className="data-domain">
                                <tbody>
                                <tr>
                                    <td><strong>Forma de pago que se utilizará en la próxima renovación</strong></td>
                                </tr>
                                <tr>
                                    <td>{nombreFormaPago}:</td>
                                    <td>{cuentaCuenta}</td>
                                </tr>
                                <tr>
                                    <td>Documento:</td>
                                    <td>{cuentaNroDocumento}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr/>
                    </div>
                )}

                {historialPagos.length > 0 && (
                    <>
                        <p>
                            Usuario que realizó el último pago <strong>{
                            historialPagos.length > 0
                                ? historialPagos[historialPagos.length - 1].cuentaPago?.usuarioAsociacion?.usuario ?? "No disponible"
                                : "No disponible"
                        }</strong>
                        </p>
                        <p><Link to="#" onClick={togglePaymentHistory}>{linkText}</Link></p>
                        {showPaymentHistory && dominio.esTitular && (
                            <div id="id-tabla-historial-pagos">
                                <table className="data-domain tabla-pagos" style={{width: '100%'}}>
                                    <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Concepto de pago</th>
                                        <th>Nro. de cuenta Antel</th>
                                        <th>Documento</th>
                                        <th>Pago realizado por</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {historialPagos.map((item) => (
                                        <tr key={item.id ?? ''}>
                                            <td>{item.cuentaPago?.fechaAsociacionFormateada ?? ''}</td>
                                            <td>{item.tipoCreacion ?? ''}</td>
                                            <td>{item.cuentaPago?.cuenta?.cuenta ?? ''}</td>
                                            <td>{item.cuentaPago?.cuenta?.nroDocumento ?? ''}</td>
                                            <td>{item.cuentaPago?.usuarioAsociacion?.usuario ?? ''}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {showPaymentHistory && !dominio.esTitular && (
                            <div id="id-tabla-historial-pagos">
                                <table className="data-domain tabla-pagos" style={{width: '100%'}}>
                                    <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Concepto de pago</th>
                                        <th>Nro. de cuenta Antel</th>
                                        <th>Documento</th>
                                        <th>Pago realizado por</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {historialPagos.map((item) => (
                                        <tr key={item.id ?? ''}>
                                            <td>{item.cuentaPago?.fechaAsociacionFormateada ?? ''}</td>
                                            <td>{item.tipoCreacion ?? ''}</td>
                                            <td>{item.cuentaPago?.cuenta?.cuenta ? item.cuentaPago?.cuenta?.cuenta.replace(/\d(?=\d{4})/g, '*') : ''}</td>
                                            <td>{item.cuentaPago?.cuenta?.nroDocumento ?? ''}</td>
                                            <td>{item.cuentaPago?.usuarioAsociacion?.usuario ?? ''}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                    </>
                )}
            </fieldset>
        </>
    );
}