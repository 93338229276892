import React, {useContext, useState} from 'react'
import AppContext from "../../../../store/AppContext";
import styles from './FormularioContacto.module.css'; // CSS Module
import stylesHome from '../../HomeTabTAR.module.css';
import {TipoMensaje} from "../../../../store/AppProvider";
import PropTypes from "prop-types";
import {
    existeCodAuthValidado,
    guardarReqTransf,
    marcarCodAuthValidado,
    obtenerDominioValidado,
    obtenerReqTransf
} from "../SolTransLocalStorageUtils";
import transferenciaService from "../../../../services/TransferenciaRegistradorService";

const TIPO_CONTACTO_PERSONA = 'persona';
const TIPO_CONTACTO_EMPRESA = 'empresa';

const errorMapInit = new Map([
    ['codAuth', false],
    ['dominio', false],
    ['nombre', false],
    ['apellido', false],
    ['dir', false],
    ['tel', false],
    ['email', false]
]);

export const FormularioContacto = (props) => {
    const appCtx = useContext(AppContext);
    const dominio = obtenerDominioValidado();
    const [validacionCodAuth, setValidacionCodAuth] = useState(false);
    const [tipoContacto, setTipoContacto] = React.useState(TIPO_CONTACTO_PERSONA);

    // Al comienzo, ningún campo tiene error. Las entradas del map indicando: ['attr', tieneError?]
    const [errorMap, setErrorMap] = React.useState(new Map(errorMapInit));

    const [codAuth, setCodAuth] = React.useState('');
    const [nombre, setNombre] = React.useState('');
    const [nombreSeciu, setNombreSeciu] = React.useState('');
    const [apellido, setApellido] = React.useState('');
    const [dir, setDir] = React.useState('');
    const [tel, setTel] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailSeciu, setEmailSeciu] = React.useState('');

    React.useEffect(() => {
        const reqTransf = obtenerReqTransf();
        const existeCodAuth = existeCodAuthValidado();
        setValidacionCodAuth(existeCodAuth)
        if (reqTransf) {
            setCodAuth(reqTransf.codEpp);
            setNombre(reqTransf.nombre);
            setApellido(reqTransf.apellido);
            setDir(reqTransf.dir);
            setTel(reqTransf.tel);
            setEmail(reqTransf.email);
        }
    }, []);

    const handlerChangeTipoContacto = (event) => {
        setTipoContacto(event.target.value);
    }

    function validacionImportacion() {
        return transferenciaService.consultarSolicitudTransferencia(dominio, codAuth);
    }

    const handleOnChangeCodigoAutorizacion = (event) => {
        validacionImportacion()
            .then(response => {
                if (!response.ok) {
                    appCtx.mostrarMensaje(response.mensaje, TipoMensaje.error);
                    setValidacionCodAuth(false)

                } else {
                    setEmailSeciu(response.email)
                    setNombreSeciu(response.nombre)

                    if (nombreSeciu === null) {
                        setNombreSeciu('')
                    }

                    if (emailSeciu === null) {
                        setEmailSeciu('')
                    }

                    marcarCodAuthValidado()
                    setValidacionCodAuth(true)
                }
            }).catch(error => {
            appCtx.mostrarMensaje('Se produjo un error al consultar si el código de autorización es valido', TipoMensaje.error);
        })

    }

    const handleContinuar = (event) => {
        if (!isFormValid()) {
            return;
        }

        let nombreGuardar
        if (nombreSeciu !== '') {
            nombreGuardar = nombreSeciu
        } else {
            nombreGuardar = nombre
        }

        let emailGuardar
        if (emailSeciu !== '') {
            emailGuardar = emailSeciu
        } else {
            emailGuardar = email
        }

        console.log(emailGuardar)
        console.log(nombreGuardar)
        appCtx.ocultarMensaje();
        guardarReqTransf(codAuth, nombreGuardar, apellido, dir, tel, emailGuardar)
        props.handleContinuarOK();
    }

    const isFormValid = () => {
        const newMapError = new Map(errorMap);

        newMapError.set('dominio', dominio === '');
        newMapError.set('codAuth', codAuth.trim() === '');
        newMapError.set('nombre', nombreSeciu === '' && nombre.trim() === '');
        newMapError.set('apellido', tipoContacto === TIPO_CONTACTO_PERSONA && nombreSeciu === '' && apellido.trim() === '');
        newMapError.set('dir', dir.trim() === '');
        newMapError.set('tel', tel.trim() === '');
        newMapError.set('email', emailSeciu === '' && email.trim() === '');

        const formHasError = Array.from(newMapError.values()).some(value => value === true);
        if (existsFieldWithError(newMapError)) {
            setErrorMap(newMapError);
            appCtx.mostrarMensaje('Debe completar todos los datos del formulario', TipoMensaje.error);
        }

        return !formHasError;
    }

    const existsFieldWithError = (map) => {
        return Array.from(map.values()).some(value => value === true);
    }

    return <>

        <div id="step-content" className={stylesHome.stepsContainer}>
            <div id="step-2" className={stylesHome.stepContent}>
                <div id="id-form-datos-contacto">

                    {/*Dominio a importar*/}
                    <fieldset>
                        <legend>Dominio a importar</legend>
                        <div className="form-item">
                            <label htmlFor="id-dominio">Dominio</label>
                            <input type="text" id="id-dominio" placeholder="Nombre de dominio" value={dominio} readOnly
                                   className={stylesHome.inputReadOnly}/>
                        </div>

                        <div className="form-item">
                            <label htmlFor="id-cod-auth">Código EPP de Autorización *</label>
                            <div id="input-help" style={{position: "relative", display: "inline-block"}}>
                                <input type="text" id="id-cod-auth" placeholder="Código de autorización"
                                       value={codAuth}
                                       onChange={(e) => setCodAuth(e.target.value)}
                                       className={errorMap.get('codAuth') ? styles.formInputError : undefined}/>
                                <div className={`${styles.hoverText} help`}>
                                <span className={`${styles.tooltipText} ${styles.tooltipRight}`} id="right">
                                    El Código EPP de Autorización se debe solicitar desde el Agente Registrador actual del dominio
                                </span>
                                </div>
                            </div>
                            <p className={styles.note}>Código solicitado en el Registrar donde actualmente se encuentra
                                el dominio</p>
                        </div>
                        <div className="right">
                            <input type="button" value="Validar" id="id-submit-guardar-datos-contacto"
                                   className="btn edit"
                                   style={{padding: "7px 12px"}}
                                   onClick={handleOnChangeCodigoAutorizacion}/>
                        </div>
                    </fieldset>

                    {/*Datos del contacto*/}
                    {validacionCodAuth === true &&
                        <fieldset>
                            <legend>Contacto Titular</legend>

                            <p className="warning-icon" style={{fontSize: "0.85rem", marginBottom: "1rem"}}>
                                Los datos del contacto titular deben coincidir con los datos actuales del dominio a
                                transferir
                            </p>

                            <div className="form-item marginTop">
                                <label htmlFor="id-tipo-contacto">Tipo de contacto</label>
                                <select value={tipoContacto} id="id-tipo-contacto" onChange={handlerChangeTipoContacto}>
                                    <option value={TIPO_CONTACTO_PERSONA}>Persona</option>
                                    <option value={TIPO_CONTACTO_EMPRESA}>Empresa</option>
                                </select>
                            </div>

                            {nombreSeciu === '' &&
                                <div className="form-item">
                                    <label htmlFor="id-nombre">Nombre *</label>
                                    <input type="text" id="id-nombre"
                                           value={nombre}
                                           onChange={(e) => setNombre(e.target.value)}
                                           className={errorMap.get('nombre') ? styles.formInputError : undefined}/>
                                </div>
                            }

                            {nombreSeciu !== '' &&
                                <div className="form-item">
                                    <label htmlFor="id-nombre">Nombre *</label>
                                    <input type="text" id="id-nombre" placeholder="Nombre" value={nombreSeciu}
                                           readOnly
                                           className={stylesHome.inputReadOnly}/>
                                </div>
                            }

                            {tipoContacto === TIPO_CONTACTO_PERSONA && nombreSeciu === '' &&
                                <div className="form-item">
                                    <label htmlFor="id-apellido">Apellido *</label>
                                    <input type="text" id="id-apellido"
                                           value={apellido}
                                           onChange={(e) => setApellido(e.target.value)}
                                           className={errorMap.get('apellido') ? styles.formInputError : undefined}/>
                                </div>}

                            {emailSeciu === '' &&
                                <div className="form-item">
                                    <label htmlFor="id-mail-publico">Correo electrónico público *</label>
                                    <input
                                        type="text"
                                        id="id-mail-publico"
                                        value={email}
                                        onChange={(e) => {
                                            if (e.target.value.trim() || e.target.value === '') {
                                                setEmail(e.target.value);
                                            }
                                        }}
                                        className={errorMap.get('email') ? styles.formInputError : undefined}
                                    />
                                </div>
                            }

                            {emailSeciu !== '' &&
                                <div className="form-item">
                                    <label htmlFor="id-mail-publico">Correo electrónico público *</label>
                                    <input type="text" id="id-mail-publico" placeholder="Correo electrónico"
                                           value={emailSeciu}
                                           readOnly
                                           className={stylesHome.inputReadOnly}/>
                                </div>
                            }

                            <div className="form-item">
                                <label htmlFor="id-direccion">Domicilio constituido en Uruguay *</label>
                                <input type="text" id="id-direccion"
                                       value={dir}
                                       onChange={(e) => setDir(e.target.value)}
                                       className={errorMap.get('dir') ? styles.formInputError : undefined}/>
                                <p className={styles.note}>Los datos ingresados deben ser verdaderos, son tenidos en
                                    cuenta
                                    para cualquier gestión</p>
                            </div>

                            <div className="form-item">
                                <label htmlFor="id-telefono">Teléfono *</label>
                                <input type="text" maxLength="30" id="id-telefono"
                                       value={tel}
                                       onChange={(e) => setTel(e.target.value)}
                                       className={errorMap.get('tel') ? styles.formInputError : undefined}/>
                            </div>
                        </fieldset>
                    }
                </div>
            </div>
        </div>
        {
            validacionCodAuth === true &&
            <div className="right">
                <input type="button" value="Continuar" id="id-submit-guardar-datos-contacto"
                       className="btn edit"
                       style={{padding: "7px 12px"}}
                       onClick={handleContinuar}/>
            </div>
        }
    </>
}

FormularioContacto.propTypes = {
    handleContinuarOK: PropTypes.func.isRequired,
    handleContinuarError: PropTypes.func.isRequired,
    completedSteps: PropTypes.number,
};