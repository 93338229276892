import React, {useEffect, useState} from 'react'
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {MensajeError} from "../../../../view/MensajeError";

export const ModalAgregarNameserverDominio = ({ nombreDominio, nameservers, isCrear, nameserverEditar, index, cantMinimaNameservers, handleOnClose, isOpen, irVerDominio }) => {

    const [nameserver, setNameserver] = useState({});
    const tiposIP = ["IPV4", "IPV6"];

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    const [titulo, setTitulo] = useState("Editar");
    const [subdominio, setSubdominio] = useState(false);


    useEffect(() => {
        if (isCrear){
            setTitulo("Agregar nameserver")
            setNameserver({
                subdominio: false,
                nombre: "",
                tipoIP: "IPV4",
                ip: ""
            })
            setSubdominio(false);
        }else{
            setTitulo("Editar nameserver")
            setNameserver(nameserverEditar);
            setSubdominio(nameserverEditar.subdominio);

        }
    }, [nameserverEditar]);

    const handleClickCheck = (event) => {
        let nsChecked =  event.target.checked;
        let nsTipoIP = nsChecked ? "IPV4" : null;
        let nm = nsChecked ? nameserver.nombre.replace(/\.$/, "") : nameserver.nombre;
        let nsaux = {
            ...nameserver,
            subdominio : nsChecked,
            tipoIP : nsTipoIP,
            nombre : nm
        };
        setNameserver(nsaux);
        setSubdominio(nsChecked);
    }

    const handleOnChangeName = (event) => {
        let nsaux = {
            ...nameserver,
            nombre : event.target.value
        }
        setNameserver(nsaux);
    }

    const handleOnChangeIP = (event) => {
        let nsaux = {
            ...nameserver,
            ip : event.target.value
        }
        setNameserver(nsaux);
    }

    const handleOnChangeTipoIP = (event) => {
        let nsaux = {
            ...nameserver,
            tipoIP : event.target.value
        }
        setNameserver(nsaux);
    }

    const accionNameserver = (e) => {
        e.preventDefault();
        setError({error : false, mensaje: "Sin errores"})

        if (!nameserver.subdominio){
            nameserver.tipoIP = null;
            nameserver.ip = null;
        }

        let arraysNameservers = [];
        if (isCrear){
            arraysNameservers = [...nameservers, nameserver];
        }else{
            arraysNameservers = [...nameservers];
            arraysNameservers[index] = nameserver;
        }

        let delegarDominioDTO = {
            nombreDominio : nombreDominio,
            nameservers : arraysNameservers,
            cantMinimaNameservers : cantMinimaNameservers
        };
        if (validarNombresUnicos(arraysNameservers)) {
            AdministracionUsuarioService.delegarDominio(delegarDominioDTO)
                .then(response => {
                    if (!response.esRespOK) {
                        setError({
                            error: true,
                            mensaje: response.mensaje
                        })
                    } else {
                        let mensajeOk = {
                            mostrarMensaje: true,
                            mensaje: isCrear ? "El servidor de nombres se agregó correctamente." : "El servidor de nombres se actualizó correctamente."
                        }
                        irVerDominio(mensajeOk, nombreDominio)
                    }
                })
                .catch(() => {
                    setError({
                        error: true,
                        mensaje: "Ha ocurrido un error al agregar/editar el nameserver."
                    })
                })
        }
        else {
            setError({
                error: true,
                mensaje: "No se puede agregar un nameserver con nombres duplicados."
            });
        }
    }

    function validarNombresUnicos(nameservers) {
        const nombresVistos = new Set();
        for (const server of nameservers) {
            if (server.nombre && nombresVistos.has(server.nombre)) {
                return false;
            }
            if (server.nombre) {
                nombresVistos.add(server.nombre);
            }
        }
        return true;
    }

    return <>
        <Modal
            idDivModal="idModalEditarNameserverDominio"
            handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
            <fieldset id="id-fieldset-editar-nameserver">
                <legend>{titulo}</legend>

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <form id="id-form-editar-nameserver">
                    <div className="form-item">
                        <label id="id-chk-subdominio">Subdominio</label>
                        <input type="checkbox" id="id-chk-subdominio" className={"checkbox-space"}
                               checked={nameserver.subdominio}
                               onChange={ handleClickCheck }/>
                        <p className="note" style={{marginLeft: '0px'}}>Marcar esta opci&oacute;n si el nombre del
                            servidor de nombres es un subdominio de <strong>{nombreDominio}</strong></p>

                    </div>
                    <div className="form-item">
                        <label htmlFor="id-svrname">Nombre</label>
                        <input type="text" id="id-srvname" name="nombre"
                               value={nameserver.nombre} onChange={handleOnChangeName}/>
                        <p id={"id-svrname-nombre-dominio-" + index}
                           style={{display: "inline-block"}}>{nameserver.subdominio &&
                            <label><strong>.{nombreDominio}</strong></label>}</p>
                        <p className="note">Nombre de dominio del servidor que responder&aacute; por el dominio</p>
                    </div>

                    {nameserver.subdominio &&
                        <>
                            <div id="id-subdominio-show-tipo-ip" className="form-item">
                                <label htmlFor="id-subdominio-tipo-ip">Tipo IP</label>
                                <select id="id-subdominio-tipo-ip-select" value={nameserver.tipoIP} name={"tipoIP"} onChange={handleOnChangeTipoIP}>
                                    {tiposIP && tiposIP.map((tipoIP) => (
                                        <option value={tipoIP}
                                                key={tipoIP + index}
                                                id={tipoIP + index}
                                                cssErrorStyle="border-color:red;">{tipoIP}</option>
                                    ))}
                                </select>
                            </div>
                            <div id="id-subdominio-show-ip" className="form-item"
                                 hidden={!nameserver.subdominio}>
                                <label htmlFor="id-subdominio-ip">IP</label>
                                <input type="text" id="id-subdominio-ip" name="ip"
                                       value={nameserver.ip} onChange={ handleOnChangeIP }/>
                            </div>
                        </>
                    }

                    <div className="right">
                        <button className="btn" onClick={accionNameserver}>{titulo}</button>
                    </div>
                </form>
            </fieldset>
        </Modal>
    </>
}