import {
    buildDisponibilidad,
    buildDisponibilidadResponse,
    buildDominiosDisponibilidadResponse,
    buildMostrarCaptchaResponse,
} from "../dto/DisponibilidadDTOBuilder";
import http from '../support/HttpService';

const REACT_APP_CONSULTA_DISPONIBILIDAD = process.env.REACT_APP_CONSULTA_DISPONIBILIDAD;
const REACT_APP_DOMINIOS_HABILITADOS = process.env.REACT_APP_DOMINIOS_HABILITADOS;
const REACT_APP_MOSTRAR_CAPTCHA = process.env.REACT_APP_MOSTRAR_CAPTCHA;



const DisponibilidadService = {

    getDisponibilidad: async function(dominiosConsulta, captchaResponse) {
        try {
            const response = await http.post(REACT_APP_CONSULTA_DISPONIBILIDAD, buildDisponibilidad(dominiosConsulta, captchaResponse));
            return buildDisponibilidadResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    getDominiosHabilitados: async function() {
        try {
            const response = await http.get(REACT_APP_DOMINIOS_HABILITADOS);
            return buildDominiosDisponibilidadResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    mostrarCaptcha: async function() {
        try {
                const response = await http.get(REACT_APP_MOSTRAR_CAPTCHA);
            return buildMostrarCaptchaResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    }

}
export default DisponibilidadService;