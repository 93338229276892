import React, { useState, useEffect } from 'react';
import Modal from "../../Modal";
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";
import {MensajeError} from "../../../view/MensajeError";
import { useValidacionContacto } from '../../validaciones/validacionesContacto';

function VerEditarDatosContacto({ contacto, onClose, titular, tipo, dominio, setMensajeOk, setError, setcontactoActual}) {
    const [isVisible, setIsVisible] = useState(true);
    const [nombre, setNombre] = useState(contacto.nombre);
    const [apellido, setApellido] = useState(contacto.apellido);
    const [direccion, setDireccion] = useState(contacto.direccion);
    const [telefono, setTelefono] = useState(contacto.telefono);
    const [emailPublico, setEmailPublico] = useState(contacto.email || contacto.emailPublico);
    const [permisosDominio, setPermisosDominio] = useState(undefined);
    const [mapPermisosDominio, setMapPermisosDominio] = useState({});
    const [isProcesando, setIsProcesando] = useState(true);
    const [errores, setErrores] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const [camposError, setCamposError] = useState(undefined);

    const { validarDatosContacto } = useValidacionContacto();

    useEffect(() => {
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ''
        });
        setError({
            error: false,
            mensaje: ''
        });
        obtenerDominiosUsuario(filtro, true);
    }, [])
    const handleNombreChange = (e) => {
        setNombre(e.target.value);
    };

    const handleApellidoChange = (e) => {
        setApellido(e.target.value);
    };

    const handleDireccionChange = (e) => {
        setDireccion(e.target.value);
    };

    const handleTelefonoChange = (e) => {
        setTelefono(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmailPublico(e.target.value);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let contacto = {nombre, apellido, direccion, telefono, emailPublico};
        // contacto= getNombreApellido(contacto);
        let esValido="";
        try {
            setCamposError([])
            if (tipo === 'Titular') {
                esValido = validarDatosContacto(contacto, setErrores, false, false);
            }
            else
            {
                esValido = validarDatosContacto(contacto, setErrores, false, true);
            }
            if (esValido.error) {
                setErrores({
                    error: true,
                    mensaje: esValido.mensaje

                })

                if (esValido.camposError) {
                    procesarCamposError(esValido.camposError);
                }
                
                return;
            } else {
                setErrores({
                    error: false,
                    mensaje: ''

                })
                if (contacto.apellido === undefined || contacto.apellido === null || contacto.apellido === '')
                {
                    contacto.apellido = '__';
                }
                obtenerDominiosUsuario(filtro, true);
                let idUsuarioBE =(dominio.dominioBE.titular.idUsuarioBE);
                if (chequearTipoEnPermisos(mapPermisosDominio, normalizarTexto(tipo), idUsuarioBE)) {
                    const response = await AdministracionUsuarioService.editarContactoDominio(
                        [dominio.dominioBE.nombre],
                        normalizarTexto(tipo),
                        contacto
                    );
                    if (response.codigoRespuesta === 'OK') {
                        if(contacto.apellido === '__')
                        {
                            contacto.apellido = '';
                        }
                        setcontactoActual(contacto, tipo);
                        manejarRespuestaOk(response);
                    } else if (response.codigoRespuesta === 'ERROR') {
                        manejarRespuestaError('Error al actualizar el contacto.');
                    }
                } else {
                    manejarRespuestaError('No tiene permisos para realizar esta operación.');
                }

            }

        } catch (error) {
            manejarRespuestaError('Error al actualizar el contacto.');
        }
    };

    function procesarCamposError(camposError) {
        let camposConError = [];
        camposError.forEach(c => camposConError.push(c.codigo));
        setCamposError(camposConError);
    }
    
    const manejarRespuestaOk = (response) => {
        setError({
            error: false,
            mensaje: ''
        });
        setMensajeOk({
            mostrarMensaje: true,
            mensaje: response.mensajeRespuesta
        });
        setIsVisible(false);
        if (onClose) onClose();
        window.scrollTo(0, 0);
    };

    const manejarRespuestaError = (response) => {
        setError({
            error: true,
            mensaje: response
        });
        setIsVisible(false);
        if (onClose) onClose();
        window.scrollTo(0, 0);
    };


    function normalizarTexto(texto) {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
    }

    const [filtro, setFiltro] = useState({
        nombre: dominio.dominioBE.nombre,
        tipoRelacionUsuDom: 'TODOS',
        fechaVencimientoDesde: '',
        fechaVencimientoHasta: '',
        cuentaPago: '',
        cuentaPagoNombre: '',
        dominiosAlojados: null,
        modulo: 'FRONTEND',
        primerResultado: 0,
        cantResultados: 10
    })
    const obtenerDominiosUsuario = (filtro, esSubmit) => {
        let filtroTemp = filtro;
        filtroTemp.nombre = dominio.dominioBE.nombre;

        AdministracionUsuarioService.obtenerDominiosUsuario(filtroTemp)
            .then(response => {
                if (response.codigo === 'OK') {
                    setPermisosDominio(response.permisosDominio);
                    permisoPorDominio(response.permisosDominio);
                }
                setIsProcesando(false);
            })
            .catch(error => {
                setIsProcesando(false);
            });

    }
    const permisoPorDominio = (permisosDominio) => {
        let mapPermisosDoms = new Map();

        Object.entries(permisosDominio).map(([clave, valor]) => {
            mapPermisosDoms.set(clave, {
                nombreDominio: clave,
                esAdmin: valor.esAdministrativo,
                esFinanciero: valor.esFinanciero,
                esTecnico: valor.esTecnico,
                esTitular: valor.esTitular
            })
        })
        setMapPermisosDominio(JSON.parse(JSON.stringify(Object.fromEntries(mapPermisosDoms))));
    }

    function chequearTipoEnPermisos(mapPermisosDoms, tipo, idUsuarioBE) {
        const propiedadesTipos = ['esAdmin', 'esFinanciero', 'esTecnico', 'esTitular'];

        for (const dominio of Object.values(mapPermisosDoms)) {
            for (const propiedadTipo of propiedadesTipos) {
                if (dominio[propiedadTipo]) {
                    return true;
                }
            }
        }

        return false;
    }


    const  styleError = (campo) => {
        if (camposError !== undefined) {
            return camposError.includes(campo) ? 'red' : '';
        }
    }

    return (
        <>
            {isVisible && (
                <Modal
                    idDivModal="idDialogo"
                    handleClose={() => {
                        setIsVisible(false);
                        if (onClose) onClose();
                    }}
                    isOpen={isVisible}
                    closeOnClickOutside={false}
                >
                    <div className="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"></div>
                    <form id="id-form-editar-datos-contacto" onSubmit={handleSubmit}>
                        <fieldset>
                            <legend>Datos de Contacto Públicos {tipo}</legend>
                            {errores !== undefined && errores.error && <MensajeError mensajeError={errores.mensaje}/>}
                            {titular ? (
                                <div className="form-item">
                                    <label htmlFor="id-nombre-apellido">Nombre (y Apellido si es Persona)</label>
                                    <input
                                        type="text"
                                        name="nombreApellido"
                                        value={`${nombre} ${apellido}`}
                                        disabled
                                        id="id-nombre-apellido"
                                        className="edit"
                                        style={{borderColor: styleError('nombreApellido')}}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="form-item">
                                        <label htmlFor="id-nombre">Nombre</label>
                                        <input
                                            type="text"
                                            name="nombre"
                                            value={nombre}
                                            onChange={handleNombreChange}
                                            id="id-nombre"
                                            className="edit"
                                            style={{borderColor: styleError('nombre')}}
                                        />
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="id-apellido">Apellido</label>
                                        <input
                                            type="text"
                                            name="apellido"
                                            value={apellido}
                                            onChange={handleApellidoChange}
                                            id="id-apellido"
                                            className="edit"
                                            style={{borderColor: styleError('apellido')}}
                                        />
                                    </div>
                                </>
                            )}
                            <div className="form-item">
                                <label htmlFor="id-direccion">Dirección</label>
                                <input
                                    type="text"
                                    name="direccion"
                                    value={direccion}
                                    onChange={handleDireccionChange}
                                    id="id-direccion"
                                    className="edit"
                                    style={{borderColor: styleError('direccion')}}
                                />
                            </div>
                            <div className="form-item">
                                <label htmlFor="id-telefono">Teléfono</label>
                                <input
                                    type="text"
                                    name="telefono"
                                    maxLength="30"
                                    value={telefono}
                                    onChange={handleTelefonoChange}
                                    id="id-telefono"
                                    className="edit"
                                    style={{borderColor: styleError('telefono')}}
                                />
                            </div>
                            <div className="form-item">
                                <label htmlFor="id-mail-publico">Correo electrónico público</label>
                                <input
                                    type="text"
                                    name="emailPublico"
                                    value={emailPublico}
                                    onChange={handleEmailChange}
                                    id="id-mail-publico"
                                    className="edit"
                                    style={{borderColor: styleError('emailPublico')}}
                                />
                            </div>
                            <div className="right">
                                <input type="submit" value="Guardar datos" className="btn edit"/>
                            </div>
                        </fieldset>
                    </form>
                </Modal>
            )}
        </>
    );
}

export default VerEditarDatosContacto;
