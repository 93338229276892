import React, {useEffect, useState} from 'react'
import '../../consultaDisponibilidad/ConsultaDisponibilidad.css';
import {SidebarComponent} from "../../../components/sidebar/SidebarComponent";
import {getOidcState} from "../../../security/OidcManager";
import {ResumenDominiosImportantes} from "../../../components/administrar/resumen/ResumenDominiosImportantes";
import {ResumenMensajes} from "../../../components/administrar/resumen/ResumenMensajes";
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";
import {ValidacionAutenticado} from "../../../components/validaciones/ValidacionAutenticado";
import {Link} from "react-router-dom";
import FeedbackSpinner from "../../../components/feedback/FeedbackSpinner";

export const ResumenUsuario = () => {

    const resumenDefecto = {
        'cantDomRegistrados' : 0,
        'cantDomAlojados' : 0,
        'cantDomVencProximos' : 0,
        'cantFormasPago' : 0,
        'mensajes' : [],
        'mostrarMensajes': false,
        'mostrarDominiosImportantes' : false,
        'dominiosImportantes' : [],
        'mostrarSugerencias' : false,
        'itemsSuggested' : []
    };

    const [resumen, setResumen] = useState(resumenDefecto);
    const [mostrarResumen, setMostrarResumen] = useState(true);
    const currentDate = new Date();
    const targetDate = new Date(currentDate);
    targetDate.setDate(targetDate.getDate() + 20); // Añadir 20 días a la fecha actual
    const formattedTargetDate = targetDate.toISOString().split('T')[0];

    // const enlaceMisDominiosProximosVencimientos = `/v2/administrar/mis-dominios?fechaVencimientoHasta=${formattedTargetDate}`;
    const enlaceMisDominiosProximosVencimientos = `${formattedTargetDate}`;
    const [isProcesando, setIsProcesando] = useState(true);

    useEffect(() => {
        if (!getOidcState().is_authenticated){
            return;
        }
        obtenerResumenUsuario();
    }, []);

    function obtenerResumenUsuario() {
        setIsProcesando(true);
        AdministracionUsuarioService.obtenerResumenUsuario()
            .then((response) => {
                if (response !== undefined && response.codigo === 'OK') {
                    setResumen(response.resumen);
                    setMostrarResumen(true);
                } else {
                    setResumen(resumenDefecto);
                }
                setIsProcesando(false);
            })
            .catch(error => {
                setIsProcesando(false);
            });
    }

    function setMensajes(mensajes){
        if (resumen !== undefined){
            resumen.mensajes = mensajes;
        }
    }

    return <>
        <ValidacionAutenticado/>
        <h2>Administrar</h2>

        <div id="content" className="fluid">

            { isProcesando?
                <div className="inner-block">
                    <FeedbackSpinner open={isProcesando} message={'Cargando información...'}/>
                </div> : <></>
            }

            <div className="inner-block">

                <div className="box resumen left">
                    <div className="top">
                        <h4>Resumen</h4>
                    </div>

                    <div className="box-content">
                        <p><a href="/administrar/mis-dominios">Dominios registrados: <strong>{ resumen.cantDomRegistrados }</strong></a></p>
                        <p>
                            <Link to="/administrar/mis-dominios"
                                  state={{ alojado: 1 }}>
                                Dominios alojados: <strong>{resumen.cantDomAlojados}</strong>
                            </Link>
                        </p>
                        <p>
                            <Link to="/administrar/mis-dominios"
                                  state={{ fechaVencimientoHasta: enlaceMisDominiosProximosVencimientos }}>
                                Vencimiento/s próximo/s: <strong>{resumen.cantDomVencProximos}</strong>
                            </Link>
                        </p>
                        {/*<p>*/}
                        {/*    <a href={enlaceMisDominiosProximosVencimientos}>*/}
                        {/*        Vencimiento/s próximo/s: <strong>{resumen.cantDomVencProximos}</strong>*/}
                        {/*    </a>*/}
                        {/*</p>*/}
                        <p className="last">
                            <a href="/administrar/mis-datos#id-div-cuentas">
                                Forma/s de pago registrada/s: <strong>{resumen.cantFormasPago}</strong>
                            </a>
                        </p>
                    </div>
                </div>

                <ResumenDominiosImportantes mostrarDominiosImportantes={ resumen.mostrarDominiosImportantes }
                                            dominiosImportantes={ resumen.dominiosImportantes } />

                <br clear="all"/>

                <ResumenMensajes mostrarMensajes={resumen.mostrarMensajes}
                                 mensajes={resumen.mensajes}
                                 setMensajes={setMensajes}/>

            </div>

        </div>

        <SidebarComponent/>
        </>
}
