import React, {useContext} from "react";
import DominioRegistroService from "../../services/RegistroDominioService";
import AppContext from "../../store/AppContext";
import {TipoItemCarrito} from "../../store/AppProvider";
import {Link} from "react-router-dom";

export const ConsultaDisponibilidadSidebarResposnse = () => {
    const appCtx = useContext(AppContext);
    let carrito = appCtx.carrito.registrosPendientes;
    let uuidVenta = appCtx.venta.uuidVenta;

    const removeFromCarrito = (e)=> {
        e.preventDefault();
        const idDominio = e.target.id;
        DominioRegistroService.eliminarRegistroDominio(idDominio, uuidVenta)
            .then((response) => {
                if (response.respuestaOK) {
                    appCtx.eliminarItemCarrito(idDominio, TipoItemCarrito.registro);
                }
            }).catch(() => {
                console.log("Error eliminando registro de dominio");
        });
    }

    return <>
        <div id="sidebar-right" className="fixed-right">
            {/*caja  mensajes*/}
            <div id="id-box-cart" className="box cart">
                <div className="box-content cfx">
                    <h4>Carrito de compras</h4>
                    { carrito === null || carrito.length === 0 ?
                        <>
                            <p className="add">No hay dominio pendiente de registro</p>
                        </>
                            :
                        <>
                            <p className="add">Hay {carrito.length} dominio pendiente de registro</p>
                            { carrito.length === 10 ? <p>El carrito no admite más items.</p> : <></> }
                            <table className="cart-izq">
                                <tbody>
                                { carrito.map((dominio) => (
                                    <tr key={dominio.nombre}>
                                        <td>
                                            {/* eslint-disable-next-line */}
                                            <a aria-label="Borrar" id={dominio.id} name="link-delete-sidebar" className="close" onClick={ removeFromCarrito }></a>
                                            <span>{ dominio.nombre }</span>
                                        </td>
                                        <td className="tdright">$ { dominio.precio }</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <div className="right">
                                <Link className="btn" to={{ pathname: "/carrito-compra-paso-uno"}}>Continuar</Link>
                            </div>
                        </>
                    }
                </div>
            </div>
            {/*fin caja*/}
        </div>
    </>
}