import React from 'react';

export const ErrorServidor = () => {
    return (
        <div className="NotFound">
            <div className="lander">
                <h1>Error al conectarse con el servidor, intente nuevamente en unos minutos.</h1>
            </div>
        </div>
    )
}