import React, { createContext, useState } from 'react';


export const VentaContext = createContext();

export const VentaProvider = ({ children }) => {
    const [tiposVenta, setTiposVenta] = useState([]);

    return (
        <VentaContext.Provider value={{ tiposVenta, setTiposVenta }}>
            {children}
        </VentaContext.Provider>
    );
};
