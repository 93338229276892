export function buildObtenerVentaCarritoResponse(responseObtenerVenta) {
    return {
        ventaDTO : responseObtenerVenta.ventaDTO,
        respuestaOK: responseObtenerVenta.respuestaOK,
        codigo: responseObtenerVenta.codigoRespuesta,
        mensaje: responseObtenerVenta.mensajeRespuesta
    }
}

export function buildRefrescarVentaCarritoResponse(responseRefrescarCarrito) {
    return {
        registrosPendientes : responseRefrescarCarrito.registrosPendientes,
        renovacionesPendientes: responseRefrescarCarrito.renovacionesPendientes,
        transferenciasTitularesPendientes: responseRefrescarCarrito.transferenciasTitularesPendientes,
        respuestaOK: responseRefrescarCarrito.respuestaOK,
        codigo: responseRefrescarCarrito.codigoRespuesta,
        mensaje: responseRefrescarCarrito.mensajeRespuesta
    }
}

export function buildObtenerFormasDePagoResponse(response) {
    return {
        formasDePago : response.formasDePago,
        respuestaOK: response.respuestaOK,
        codigo: response.codigoRespuesta,
        mensaje: response.mensajeRespuesta
    }
}

export function buildObtenerFormaPagoGuardadaVentaResponse(response) {
    return {
        esRespOK: response.respuestaOK,
        cuentaPago: response.cuentaPago
    }
}

export function buildFinalizarCompraRequest(generaPago, generaFactura, medioPago) {
    return {
        generaPago : generaPago,
        generaFactura : generaFactura,
        medioPago : medioPago
    }
}

export function buildFinalizarCompraResponse(response) {
    return {
        respuestaOK: response.respuestaOK,
        codigo: response.codigoRespuesta,
        mensaje: response.mensajeRespuesta,
        wait: response.waitResponse,
        waitMilliseconds: response.waitMilliseconds
    }
}