import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {ModalEditarRegistroDominio} from "./modales/ModalEditarRegistroDominio";
import {ModalConfiguracionAvanzada} from "./modales/ModalConfiguracionAvanzada";
import {ModalAgregarRegistroDominio} from "./modales/ModalAgregarRegistroDominio";

export const VerDominioConfiguracion = ( { dominio, nombreDominio, infoDominio, setMensajeOk, actualizarInformacionRegistros, limpiarMensajes }) => {
    const [isOpenConfiguracionAvanzada, setIsOpenConfiguracionAvanzada] = useState(false);
    const [isOpenEditarRegistroDominio, setIsOpenEditarRegistroDominio] = useState(false);
    const [isOpenCrearRegistroDominio, setIsOpenCrearRegistroDominio] = useState(false);

    const [registroWeb, setRegistroWeb] = useState(infoDominio.registroWeb);
    const [registroMail, setRegistroMail] = useState(infoDominio.registroMail);

    const [registroEditar, setRegistroEditar] = useState(null);
    const [camposRegistroEditar, setCamposRegistroEditar] = useState(null);
    const [estructuraRegistro, setEstructuraRegistro] = useState(null);
    const [nuevoTipoRegistro, setNuevoTipoRegistro] = useState(null);

    const [mensajeConfiguracionAvanzadaOk, setMensajeConfiguracionAvanzadaOk] = useState({
        mostrarMensaje: false,
        mensaje: ''
    })

    useEffect(() => {
        if (infoDominio !== undefined && infoDominio !== null) {
            setRegistroWeb(infoDominio.registroWeb);
            setRegistroMail(infoDominio.registroMail)
        }
    }, [infoDominio, actualizarInformacionRegistros]);

    function valorCampoRegistro(registro, nombreCampo) {
        return registro.campos.filter((campo) => campo.nombre === nombreCampo)[0].valor;
    }

    const handleIrEditarRegistro = (registro) => {
        let estructuraRegistro = infoDominio.estructurasRegistros[registro.tipo];
        let camposEstructura = estructuraRegistro.estructuraCampos.map( ec => {
            return {
            etiqueta : ec.etiqueta,
            nombre : ec.nombre,
            descripcion : ec.descripcion,
            valor : valorCampoRegistro(registro, ec.nombre)
        }});
        setRegistroEditar(registro);
        setCamposRegistroEditar(camposEstructura);
        setEstructuraRegistro(estructuraRegistro);
        setIsOpenEditarRegistroDominio(true);
        limpiarMensajes();
    }

    const handleIrCrearRegistro = (tipoRegistroCrear) => {
        setIsOpenCrearRegistroDominio(true);
        setNuevoTipoRegistro(tipoRegistroCrear);
        limpiarMensajes();
    }

    const handleConfiguracionAvanzada = (event) => {
        event.preventDefault();
        setIsOpenConfiguracionAvanzada(true);
        limpiarMensajes();
        setMensajeConfiguracionAvanzadaOk({ mostrarMensaje: false, mensaje: '' })
    }

    const handleCerrarEditarRegistroOk = () => {
        setIsOpenEditarRegistroDominio(false);
        setIsOpenConfiguracionAvanzada(true);
    }

    const handleCerrarCrearRegistroOk = () => {
        setIsOpenCrearRegistroDominio(false);
        setIsOpenConfiguracionAvanzada(true);
    }

    function getEtiquetaCampo(campo, estructuraCampos) {
        return estructuraCampos.find(e => e.nombre === campo.nombre).etiqueta;
    }

    return <>

        { isOpenEditarRegistroDominio && <ModalEditarRegistroDominio isOpen={isOpenEditarRegistroDominio} handleOnClose={()=>{setIsOpenEditarRegistroDominio(false)}}
                                nombreDominio={nombreDominio}
                                dominio={dominio}
                                registro={registroEditar}
                                campos={camposRegistroEditar}
                                registros={infoDominio.registros.registros}
                                actualizarInformacionRegistros={actualizarInformacionRegistros}
                                setMensajeOk={setMensajeConfiguracionAvanzadaOk}
                                handleOnCloseOk={handleCerrarEditarRegistroOk}
                                                                     estructuraRegistro={estructuraRegistro}
        /> }

        { isOpenCrearRegistroDominio && <ModalAgregarRegistroDominio isOpen={isOpenCrearRegistroDominio} handleOnClose={()=>{setIsOpenCrearRegistroDominio(false)}}
                                 nombreDominio={nombreDominio}
                                 tipoRegistroCrear={nuevoTipoRegistro}
                                 dominio={dominio}
                                 estructurasRegistros={infoDominio.estructurasRegistros}
                                 tiposRegistro={infoDominio.tiposRegistro}
                                 actualizarInformacionRegistros={actualizarInformacionRegistros}
                                 setMensajeOk={setMensajeConfiguracionAvanzadaOk}
                                 handleOnCloseOk={handleCerrarCrearRegistroOk}
                                 registros={infoDominio.registros.registros}
        /> }

        {isOpenConfiguracionAvanzada &&
            <ModalConfiguracionAvanzada
                isOpen={isOpenConfiguracionAvanzada} handleOnClose={()=>{setIsOpenConfiguracionAvanzada(false)}}
                dominio={dominio}
                nombreDominio={nombreDominio}
                registros={infoDominio.registros.registros}
                tiposRegistro={infoDominio.tiposRegistro}
                estructurasRegistros={infoDominio.estructurasRegistros}
                actualizarInformacionRegistros={actualizarInformacionRegistros}
                mensaje={mensajeConfiguracionAvanzadaOk}
            /> }

        <div>
            <fieldset>
                <legend>Configuración básica</legend>

                {
                    (registroWeb !== undefined && registroWeb !== null) &&
                        <table className="data-domain">
                            <tbody>
                            <tr>
                                <td colSpan="2"><strong>Web</strong></td>
                                <td rowSpan={registroWeb.campos.length + 1}>
                                    <a className="link"
                                       onClick={() => handleIrEditarRegistro(registroWeb, setMensajeOk)}
                                       targets="idDialogEditarRegistro">Editar</a>
                                </td>
                            </tr>

                            {registroWeb.campos.map((campo, index) => (
                                <tr>
                                    <td>
                                        {getEtiquetaCampo(campo, registroWeb.estructura.estructuraCampos)}
                                    </td>
                                    <td>
                                        {campo.valor}
                                    </td>
                                </tr>
                            ))
                            }
                            </tbody>
                        </table>
                }

                {
                    (registroWeb === undefined || registroWeb === null )
                    &&
                    <table className="data-domain">
                        <tbody>
                        <tr>
                            <td colSpan="3"><strong>Web</strong></td>
                        </tr>
                        <tr>
                            <td colSpan="2">No existe ningún registro Web</td>
                            <td>
                                <a className="link"
                                   onClick={() => handleIrCrearRegistro("A", setMensajeOk)}
                                   targets="idDialogCrearRegistroA">Crear registro</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                }

                {
                    (registroMail !== undefined && registroMail !== null) &&
                    <table className="data-domain">
                        <tbody>
                        <tr>
                            <td colSpan="2"><strong>Mail</strong></td>
                            <td rowSpan={registroMail.campos.length + 1}>
                                <a className="link"
                                   onClick={() => handleIrEditarRegistro(registroMail, setMensajeOk)}
                                   targets="idDialogEditarRegistro">Editar</a>
                            </td>
                        </tr>

                        {registroMail.campos.map((campo, index) => (
                            <tr id={index}>
                                <td>
                                    {getEtiquetaCampo(campo, registroMail.estructura.estructuraCampos)}
                                </td>
                                <td>
                                    {campo.valor}
                                </td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                }

                {
                    (registroMail === undefined || registroMail === null )
                    &&
                    <table className="data-domain">
                        <tbody>
                        <tr>
                            <td colSpan="3"><strong>Mail</strong></td>
                        </tr>
                        <tr>
                            <td colSpan="2">No existe ningún registro de mail</td>
                            <td>
                                <a className="link"
                                   onClick={() => handleIrCrearRegistro("MX", setMensajeOk)}
                                   targets="idDialogCrearRegistroA">Crear registro</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                }

                <div className="cfx"></div>
                <p>
                    <Link to="#" onClick={handleConfiguracionAvanzada}>Configuración avanzada</Link>
                </p>
            </fieldset>

        </div>

    </>
};