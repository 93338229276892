import React, {useContext, useEffect, useState} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../../store/AppContext";
import {TipoItemCarrito} from "../../../store/AppProvider";
import DominioRegistroService from "../../../services/RegistroDominioService";
import ModalIngresarDatosContacto from "./ModalIngresarDatosContacto";
import CuentaService from "../../../services/CuentaService";
import cuentaService from "../../../services/CuentaService";

export const ListaRegistroDominiosPasoDos = (props) => {
    const location = useLocation();
    const appCtx = useContext(AppContext);
    const carrito = appCtx.carrito.registrosPendientes;
    let uuidVenta = appCtx.venta.uuidVenta;
    const history = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [dominioObjetivo, setDominioObjetivo] = useState({
        id: '',
        nombre: ''
    });
    const [datosIniciales, setDatosIniciales] = useState({});
    const [mapDomDatosContacto, setMapDomDatosContacto] = useState(new Map());
    const [errorValidacionBE, setErrorValidacionBE] = useState({})
    let contactoDominios = new Map();

    useEffect(() => {
        if (carrito) {
            let cont = 0;
            carrito.forEach(regDom => {
                obtenerContactoDominio(regDom.id)
                    .then(response => {
                        cont++;
                        let contactoTemp;
                        if (response.respCodigo === 'OK') {
                            contactoTemp = {
                                id: response.contactoDTO.id,
                                nombreApellido: response.contactoDTO.nombreApellido,
                                direccion: response.contactoDTO.direccion,
                                telefono: response.contactoDTO.telefono,
                                emailPublico: response.contactoDTO.emailPublico,
                                email: response.contactoDTO.email,
                                mailConfirmacion: response.contactoDTO.email,
                                guardado: true
                            };
                        } else {
                            contactoTemp = {
                                id: '',
                                nombreApellido: '',
                                direccion: '',
                                telefono: '',
                                emailPublico: '',
                                email: '',
                                mailConfirmacion: '',
                                guardado: false
                            };
                        }
                        contactoDominios.set(
                            regDom.id.toString(),
                            contactoTemp
                        )
                        if (cont === carrito.length) {
                            setMapDomDatosContacto(contactoDominios);
                            props.agregarContactoMap(contactoDominios);
                        }
                    });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, isOpen]);

    const removeFromCarrito = (e)=> {
        e.preventDefault();
        const dominioId = e.target.id;
        DominioRegistroService.eliminarRegistroDominio(dominioId, uuidVenta)
            .then((response) => {
                if (response.respuestaOK) {
                    contactoDominios = mapDomDatosContacto;
                    contactoDominios.delete(dominioId);
                    props.eliminarContactoMap(contactoDominios)
                    appCtx.eliminarItemCarrito(dominioId, TipoItemCarrito.registro);
                    if (appCtx.carrito.registrosPendientes.length === 1) {
                        history("/consulta-disponibilidad");
                    }
                }
            }).catch(() => {
            // Ignore
        });
    }

    const handleIngresarDatos = (event) => {
        event.preventDefault();
        const dominioId = event.target.id;
        setIsOpen(true);
        setDominioObjetivo({
            id: dominioId,
            nombre: carrito.find(item => item.id === +dominioId).nombre
        });
        setDatosIniciales(mapDomDatosContacto.get(dominioId));
    }

    const handleIngresarDatosTodos = (e) => {
        e.preventDefault();
        setDominioObjetivo({
            id: 'todos los dominios',
            nombre: 'todos los dominios'
        })
        setDatosIniciales({
            id: '',
            nombreApellido: '',
            direccion: '',
            telefono: '',
            emailPublico: '',
            email: '',
            mailConfirmacion: '',
            guardado: false
        });
        setIsOpen(true)
    }

    const handleGuardarContacto = (event, dominio, nuevosDatosContacto) => {
        event.preventDefault();
        let dominiosIds = [];
        contactoDominios = mapDomDatosContacto;
        if (dominio.id === 'todos los dominios') {
            mapDomDatosContacto.forEach((value, key) => {
                contactoDominios.set(key, nuevosDatosContacto);
                setMapDomDatosContacto(contactoDominios);
                dominiosIds.push(key);
            })
            crearContactoService(dominiosIds, nuevosDatosContacto);
        } else {
            contactoDominios.set(dominio.id, nuevosDatosContacto);
            setMapDomDatosContacto(contactoDominios);
            dominiosIds.push(dominio.id);
            crearContactoService(dominiosIds, nuevosDatosContacto);
        }
        event.target.blur();
    }

    const crearContactoService = (dominiosIds, datosContacto) => {
        datosContacto.separarNombreApellido=true;
        const { nombre, apellido } = separarNombreApellido(datosContacto.nombreApellido);
        datosContacto.nombre = nombre;
        datosContacto.apellido = apellido;

        let requestDTO = {
            dominiosIds : dominiosIds,
            contactoDTO: datosContacto,
            ignorarApellido : true
        }

        CuentaService.crearContactoDominio(dominiosIds, requestDTO)
            .then(response => {
                // debugger;
                if (response.esRespOK) {
                    setIsOpen(false);
                    let errorEnCarrito = {
                        error: false,
                        mensaje: "Sin errores"
                    }
                    props.errorEnCarrito(errorEnCarrito);
                    setErrorValidacionBE(errorEnCarrito);
                } else {
                    if (response.respCodigo === 'ERROR') {
                        let errorValidacion = {
                            error: true,
                            mensaje: response.respMensaje
                        }
                        setErrorValidacionBE(errorValidacion);
                    }
                }
            })
    }
    const separarNombreApellido = (nombreApellido) => {
        // Buscar el primer espacio en el input
        const espacioIndex = nombreApellido.trim().indexOf(' ');
        let nombre = '';
        let apellido = '';
        if (espacioIndex !== -1) {
            // Si hay un espacio, asignar el nombre y apellido
            nombre = nombreApellido.substring(0, espacioIndex);
            apellido = nombreApellido.substring(espacioIndex + 1).trim();
        } else {
            // Si no hay espacio, asignar solo el nombre
            nombre = nombreApellido.trim();
        }
        return { nombre, apellido };
    };

    const obtenerContactoDominio = (dominioId) => {
        return cuentaService.getUsuarioDominio(dominioId);
    }

    return (
        <>
            {carrito == null || carrito.length === 0 ?
                <></> :
                <>
                    <ModalIngresarDatosContacto handleOnClose={()=>{setIsOpen(false)}} isOpen={isOpen}
                                        dominioObjetivo={dominioObjetivo}
                                        datosIniciales={datosIniciales}
                                        onChangeDatosContacto={handleGuardarContacto}
                                        errorValidacionBE={errorValidacionBE}
                                        setErrorValidacionBE={setErrorValidacionBE}
                    />


                    <table id="id-tabla-reg-dom" className="cart">
                        <caption>Registrar dominio</caption>
                        <thead>
                            <tr>
                                <th>Artículo</th>
                                <th>Duración</th>
                                <th>Titular <NavLink to="/carrito-compra-paso-dos" onClick={handleIngresarDatosTodos}>Ingresar para todos</NavLink></th>
                                <th className="tdright">Precio</th>
                            </tr>
                        </thead>
                        <tbody id="register_domains_tbody">
                            { carrito.map((dominio) => (
                                <tr className="last" key={dominio.nombre}>
                                    <td>
                                        {/* eslint-disable-next-line */}
                                        <a className="close" id={dominio.id} onClick={ removeFromCarrito }></a>
                                        { dominio.nombre }
                                    </td>
                                    <td>1&nbsp;Año</td>
                                    <td>
                                        {(mapDomDatosContacto.get(dominio.id.toString()) && mapDomDatosContacto.get(dominio.id.toString()).emailPublico.length > 0) ?
                                            <>
                                                {mapDomDatosContacto.get(dominio.id.toString()).emailPublico}
                                                <NavLink to="/carrito-compra-paso-dos" id={dominio.id} onClick={handleIngresarDatos}> Editar</NavLink>
                                            </> :
                                            <NavLink to="/carrito-compra-paso-dos" id={dominio.id} onClick={handleIngresarDatos}>Ingresar datos</NavLink>
                                        }
                                    </td>
                                    <td className="tdright">$ { dominio.precio }</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td className="tdright">Subtotal:</td>

                                <td className="price" id="id_shopping_cart_subtotal">$ {props.subTotal}</td>
                            </tr>
                        </tfoot>
                    </table>
                </>
            }
        </>
    );
}