import React, {useContext, useState, useEffect} from 'react';
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import { useValidacionContacto } from "../../../validaciones/validacionesContacto";
import { MensajeError } from "../../../../view/MensajeError";
import CarritoService from "../../../../services/CarritoService";
import AppContext from "../../../../store/AppContext";
import {useNavigate} from "react-router-dom";

const ModalTransferirTitular = ({ nombreDominio, idDominioCore, mensajeTransferencia, handleOnClose, isOpen, setMensajeOk, setError }) => {
    const [step, setStep] = useState(1);
    const appCtx = useContext(AppContext);
    const navigate = useNavigate();
    const [mensajeDisclaimer] = useState("Al transferir el titular de un dominio, el nuevo titular asumirá la responsabilidad de la administración del dominio y de los pagos asociados.");
    const [formData, setFormData] = useState({
        nombreDominio: nombreDominio,
        chkEliminarCuenta: true,
        chkTransferirAdmin: false,
        contacto: {
            nombre: '',
            apellido: '',
            direccion: '',
            telefono: '',
            emailPublico: '',
            email: '',
            mailConfirmacion: ''
        }
    });
    useEffect(() => {
        isTransferenciaTitularEnabled();
    });
    const [procesandoTransferencia, setProcesandoTransferencia] = useState(false);
    const initialState = {
        registrosPendientes: [],
        renovacionesPendientes: [],
        transferenciasTitularesPendientes: []
    };


    const { validarDatosContacto } = useValidacionContacto();
    const [errores, setErrores] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const [transferenciaTitularEnabled, setTransferenciaTitularEnabled] = useState(false);

    const isTransferenciaTitularEnabled = () =>
    {
        AdministracionUsuarioService.isTransferenciaTitularEnabled().then(response => {
            setTransferenciaTitularEnabled(response.code === 'true');
        }).catch(ignoreError => {
        });
    }
    const yaEnCarrito = (nombreDominio) => {
        return appCtx.carrito.transferenciasTitularPendientes &&
            appCtx.carrito.transferenciasTitularPendientes.some(
                (transferencia) => transferencia.nombre === nombreDominio
            );
    };
    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                contacto: {
                    ...prevState.contacto,
                    [name]: value
                }
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleBack = () => {
        setStep(prevStep => prevStep - 1);
    };

    const validarContacto = () => {
        let esValido="";
        esValido = validarDatosContacto(formData.contacto, setErrores, true, true);
        if (esValido.error) {
            return;

        }
        setStep(prevStep => prevStep + 1);
    }
    const manejarRespuestaOk = (response) => {
        setErrores({
            error: false,
            mensaje: ''
        });
        setMensajeOk({
            mostrarMensaje: true,
            mensaje: response.mensajeRespuesta
        });
        window.scrollTo(0, 0);
    };

    const procesarTransferencia = async () => {
        if (procesandoTransferencia) return;
        // Verifico si el dominio ya esta en el carrito
        if (yaEnCarrito(formData.nombreDominio)) {
            manejarRespuestaError(`El dominio ${formData.nombreDominio} ya está en el carrito como transferencia de titular.`);
            handleOnClose(true);
            window.scrollTo(0, 0);
            return;
        }
        setProcesandoTransferencia(true);
        try {
            const transferirTitularDominioDTO = {
                nombreDominio: formData.nombreDominio,
                idDominioCore: idDominioCore,
                eliminarCuentaPago: formData.chkEliminarCuenta,
                modificarAdministrador: formData.chkTransferirAdmin,
                contactoDTO: formData.contacto,
                templateCuentaPagoDTO: null,
                datosFacturacionDTO: null,
                pagoVentaDTO: null,
                ingresaDatosFacturacion: false,
                desdeControl: false
            };
            if (!transferenciaTitularEnabled) {
                manejarRespuestaError('La transferencia de titular no está habilitada.');
                return;
            }
            const response = await AdministracionUsuarioService.transferirTitularDominio(transferirTitularDominioDTO);
            if (response.codigoRespuesta === 'OK') {
                manejarRespuestaOk(response);
                let uuidVenta = response.uuidVenta;
                appCtx.setVenta({ uuidVenta });
                setTimeout(() => {
                    refreshCarrito(uuidVenta);
                }, 2000);
            } else {
                manejarRespuestaError('Error al transferir el titular del dominio.');
            }
            handleOnClose(true);
        } catch (error) {
            manejarRespuestaError('Error inesperado al procesar la transferencia.');
        } finally {
            setProcesandoTransferencia(false);
        }
    };

    const refreshCarrito = (uuidVenta) => {
        CarritoService.refrescarVentaCarrito(uuidVenta).then(response => {
            if (response.respuestaOK) {
                appCtx.refrescarCarrito(response.registrosPendientes, response.renovacionesPendientes, response.transferenciasTitularesPendientes);
            }
        })
    }
    const manejarRespuestaError = (response) => {
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ''
        });
        setError({
            error: true,
            mensaje: response
        });
        window.scrollTo(0, 0);
    };

    const renderStep1 = () => (
        <fieldset id="id-fieldset-transferencia-1">
            <legend>Datos de Contacto</legend>
            {mensajeTransferencia && (
                <div className="message-box" id="register-ok">
                    <h3>{mensajeTransferencia}</h3>
                </div>
            )}
            <fieldset>
                <legend>Datos del nuevo titular</legend>
                <div className="form-item">
                    <label htmlFor="id-chk-eliminar-cuenta">Eliminar cuenta de pago</label>
                    <input
                        type="checkbox"
                        id="id-chk-eliminar-cuenta"
                        name="chkEliminarCuenta"
                        checked={formData.chkEliminarCuenta}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-item">
                    <label htmlFor="id-chk-modificar-admin">Modificar Administrador</label>
                    <input
                        type="checkbox"
                        id="id-chk-modificar-admin"
                        name="chkTransferirAdmin"
                        checked={formData.chkTransferirAdmin}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-item">
                    <label htmlFor="id-nombre">Nombre *</label>
                    <input
                        type="text"
                        id="id-nombre"
                        name="nombre"
                        value={formData.contacto.nombre}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-item">
                    <label htmlFor="id-apellido">Apellido *</label>
                    <input
                        type="text"
                        id="id-apellido"
                        name="apellido"
                        value={formData.contacto.apellido}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-item">
                    <label htmlFor="id-direccion">Dirección *</label>
                    <input
                        type="text"
                        id="id-direccion"
                        name="direccion"
                        value={formData.contacto.direccion}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-item">
                    <label htmlFor="id-telefono">Teléfono *</label>
                    <input
                        type="text"
                        id="id-telefono"
                        name="telefono"
                        value={formData.contacto.telefono}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-item">
                    <label htmlFor="id-mail">Correo electrónico público *</label>
                    <input
                        type="email"
                        id="id-mail-publico"
                        name="emailPublico"
                        value={formData.contacto.emailPublico}
                        onChange={handleChange}
                    />
                </div>
            </fieldset>
            <fieldset>
                <legend>Correo Electrónico para Notificaciones</legend>
                <div className="message-box" id="register-warning-2">
                    <h5>Se le enviarán notificaciones importantes relacionadas con sus dominios al siguiente correo electrónico.</h5>
                </div>
                <div className="form-item">
                    <label htmlFor="id-mail-notif">Correo electrónico *</label>
                    <input
                        type="email"
                        id="id-mail"
                        name="email"
                        value={formData.contacto.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-item">
                    <label htmlFor="id-mail-confirmacion">Confirmar correo electrónico *</label>
                    <input
                        type="email"
                        id="id-mail-confirmacion"
                        name="mailConfirmacion"
                        value={formData.contacto.mailConfirmacion}
                        onChange={handleChange}
                    />
                </div>
            </fieldset>
            <div className="right">
                <button type="button" className="btn avanzar-transferencia" onClick={validarContacto}>Continuar</button>
            </div>
        </fieldset>
    );

    const renderStep2 = () => (
        <fieldset id="id-fieldset-transferencia-2">
            <legend>Confirmar datos del nuevo titular</legend>
            <div className="form-item">
                <label>Eliminar cuenta de pago</label>
                <span id="id-span-elimina-cuenta-pago">{formData.chkEliminarCuenta ? 'Sí' : 'No'}</span>
            </div>
            <div className="form-item">
                <label>Modificar Administrador</label>
                <span id="id-span-modif-admin">{formData.chkTransferirAdmin ? 'Sí' : 'No'}</span>
            </div>
            <div className="form-item">
                <label>Nombre</label>
                <span id="id-span-nombre">{formData.contacto.nombre}</span>
            </div>
            <div className="form-item">
                <label>Apellido</label>
                <span id="id-span-apellido">{formData.contacto.apellido}</span>
            </div>
            <div className="form-item">
                <label>Dirección</label>
                <span id="id-span-dir">{formData.contacto.direccion}</span>
            </div>
            <div className="form-item">
                <label>Teléfono</label>
                <span id="id-span-tel">{formData.contacto.telefono}</span>
            </div>
            <div className="form-item">
                <label>Correo electrónico</label>
                <span id="id-span-mail">{formData.contacto.email}</span>
            </div>
            <div className="right">
                <button type="button" className="btn retroceder-transferencia" onClick={handleBack}>Ir al paso anterior</button>
                <button type="button" className="btn finalizar-transferencia" onClick={() => setStep(prevStep => prevStep + 1)}>Continuar</button>
            </div>
        </fieldset>
    );

    const renderStep3 = () => (
        <fieldset id="id-fieldset-transferencia-3">
            <legend>Transferir titular</legend>
            <div className="form-item">
                <span>{mensajeDisclaimer}</span>
            </div>
            <div className="right">
                <button type="button" className="btn retroceder-transferencia" onClick={handleBack}>Ir al paso anterior</button>
                <button
                    type="button"
                    className="btn finalizar-transferencia" onClick={procesarTransferencia} disabled={procesandoTransferencia}>{procesandoTransferencia ? 'Procesando...' : 'Agregar la transferencia al carrito de compras'}
                </button>

            </div>
        </fieldset>
    );

    return (
        <Modal
            idDivModal="idModalTransferirTitular"
            handleClose={handleOnClose}
            isOpen={isOpen}
            closeOnClickOutside={false}
        >
            {errores.error && <MensajeError mensajeError={errores.mensaje} />}
            <form id="id-form-transferir-titular" onSubmit={handleSubmit}>
                {step === 1 && renderStep1()}
                {step === 2 && renderStep2()}
                {step === 3 && renderStep3()}
            </form>
        </Modal>
    );
};

export default ModalTransferirTitular;
