import React, {useState} from "react";
import styles from './FeedbackSpinner.module.css';

const FeedbackSpinner = ({open, message}) => {
    const [showContent, setShowContent] = useState(false);
    const dialogRef = React.useRef();

    React.useEffect(() => {
        if (open) {
            dialogRef.current.showModal();
        } else {
            dialogRef.current.close();
        }
    }, [open]);

    /*useEffect(() => {
        if (procesando) {
            const timer = setTimeout(() => {
                setShowContent(true);
            }, Constants.FEEDBACK_SPINNER_DELAY);

            return () => clearTimeout(timer);
        } else {
            setShowContent(false);
        }
    }, [procesando]);*/

    return (
        <div>
            {message && <h1>{message}</h1>}
            <dialog ref={dialogRef}>
                <div className={styles.Spinner}>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </dialog>
        </div>
    );
}

export default FeedbackSpinner;

