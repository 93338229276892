import React, { useEffect, useState } from 'react'
import {getOidcState} from "../../../security/OidcManager";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {VerDominioInformacion} from "../../../components/administrar/misDominios/verDominioInformacion";
import {VerDominioConfiguracion} from "../../../components/administrar/misDominios/verDominioConfiguracion";
import {VerDominioContactos} from "../../../components/administrar/misDominios/verDominioContactos";
import {VerDominioFormaPago} from "../../../components/administrar/misDominios/verDominioFormaPago";
import {
    VerDominioTransferenciaRegistrador
} from "../../../components/administrar/misDominios/verDominioTransferenciaRegistrador";
import {MensajeOk} from "../../MensajeOk";
import FeedbackSpinner from "../../../components/feedback/FeedbackSpinner";
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";
import {MensajeError} from "../../MensajeError";
import {
    VerDominioConfiguracionDelegado
} from "../../../components/administrar/misDominios/verDominioConfiguracionDelegado";
import "../../../components/administrar/misDominios/VerDominio.css";
import {ValidacionAutenticado} from "../../../components/validaciones/ValidacionAutenticado";
import transferenciaRegistradorService from "../../../services/TransferenciaRegistradorService";

export const VerDominio = () => {
    const location = useLocation();
    const [dominio, setDominio] = useState(undefined)
    const [mensajeOk, setMensajeOk] = useState(undefined)
    const [showTransferDomain, setshowTransferDomain] = useState(false);

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const [permisos, setPermisos] = useState([]);
    const [isProcesando, setIsProcesando] = useState(true);
    const [isErrorObteniendoInformacionDominio, setIsErrorObteniendoInformacionDominio] = useState(true);

    const [infoDominio, setInfoDominio] = useState({})

    const history = useNavigate();


    useEffect(() => {
        isTAREnabled();
        if (!getOidcState().is_authenticated){
            setError({
                error: true,
                mensaje: "Información no disponible"
            })
            return;
        }

        if (location.state !==null && location.state.dominio !== null) {
            setDominio(location.state.dominio);
            obtenerInformacionDominio(location.state.dominio);
        } else {
            setIsErrorObteniendoInformacionDominio(true);
            setError({
                error: true,
                mensaje: "Información no disponible"
            })
        }

        setIsProcesando(false);

        if (location.state !== null && location.state.mensajeOk !== null) {
            setMensajeOk(location.state.mensajeOk)
        }else{
            setMensajeOk({
                mostrarMensaje: false,
                mensaje: ''
            })
        }
    }, []);

    const obtenerInformacionDominio = (dom) => {
        let requestInfoDominioDTO = {
            idDominioCore : dom.dominio.dominioCore.id,
            nombreDominio : dom.dominio.dominioBE.nombre
        };
        actualizarInformacionDominio(requestInfoDominioDTO);
    }

    function actualizarInformacionDominio(requestInfoDominioDTO) {
        setIsProcesando(true);
        setIsErrorObteniendoInformacionDominio(false);

        AdministracionUsuarioService.obtenerInformacionDominio(requestInfoDominioDTO)
            .then(response => {
                if (response.codigoRespuesta === 'OK') {
                    setInfoDominio(response);
                    setPermisos(response.permisos);
                }else{
                    setIsErrorObteniendoInformacionDominio(true);
                    setError({
                        error: true,
                        mensaje: "Información no disponible"
                    })
                }
            }).catch(() => {
                setIsErrorObteniendoInformacionDominio(true);
                setError({
                    error: true,
                    mensaje: "Información no disponible"
                })
            })

        setIsProcesando(false);

    }

    function actualizarInformacionRegistros(dom){
        setIsProcesando(true);
        let requestInfoDominioDTO = {
            idDominioCore : dom.dominioCore.id,
            nombreDominio : dom.dominioBE.nombre
        };

        AdministracionUsuarioService.obtenerInformacionRegistrosDominio(requestInfoDominioDTO)
            .then(response => {
                if (response.codigoRespuesta === 'OK') {
                    const infoAux = { ...infoDominio,
                        registros: response.registros,
                        registroWeb : response.registroWeb,
                        registroMail : response.registroMail};
                    setInfoDominio(infoAux);
                }
                setIsProcesando(false);
            }).finally(() => {
            setIsProcesando(false);

        })

        setIsProcesando(false);
    }

    const mostrarTransferencia = () => {
        return dominio?.dominio.dominioCore.tld === '.uy';
    };

    const limpiarMensajes = () => {
        setMensajeOk({mostrarMensaje: false,mensaje: ''});
        setError({error: false,mensaje: ''});
    }

    function irVerDominio(mensajeOk, nombreDominio) {

        let filtro = {
            nombre : nombreDominio,
            modulo: 'FRONTEND',
        };

        AdministracionUsuarioService.obtenerDominiosUsuario(filtro)
            .then(response => {
                if (response.codigo === 'OK' && response.totalDominiosFiltro === 1) {
                    let dominio = response.dominios[0];
                    history("/administrar/ver-dominio", {state : { dominio: dominio, mensajeOk: mensajeOk}});
                }
            })
            .catch(error => {
            });
        history("/administrar/mis-dominios");
    }
    const isTAREnabled = () =>
    {
        transferenciaRegistradorService.isTAREnabled().then(response => {
            setshowTransferDomain(response.code === 'true');
        }).catch(ignoreError => { // Por el momento no hacemos nada con esto
        });
    }

    return <>

        <ValidacionAutenticado/>

        { isErrorObteniendoInformacionDominio && error.error && <MensajeError mensajeError={error.mensaje}></MensajeError> }

        { dominio && isProcesando?
            <div className="inner-block">
                <FeedbackSpinner open={isProcesando} message={'Cargando información...'}/>
            </div> : <></>
        }

        { !isErrorObteniendoInformacionDominio &&
            <div>
                <h2>{ dominio.dominio.dominioBE.nombre }</h2>

                <div className="right">
                    <Link to={{ pathname: "/administrar/mis-dominios" }}>Volver a Mis dominios</Link>
                </div>
                <br/>
                { mensajeOk !== undefined && mensajeOk.mostrarMensaje && <MensajeOk mensajeOk={mensajeOk.mensaje}/>}
                { error.error && <MensajeError mensajeError={error.mensaje}></MensajeError>}

                {/*{error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}*/}

                <VerDominioInformacion dominio={dominio} nombreDominio={ dominio.dominio.dominioBE.nombre }
                                       infoDominio={infoDominio} permisos={permisos}
                                       setMensajeOk={setMensajeOk}
                                       setError={setError}
                                       limpiarMensajes={limpiarMensajes}
                                       irVerDominio={irVerDominio}/>

                {(dominio.esAdministrativo || dominio.esTecnico || dominio.esTitular)
                    && dominio.dominio.dominioBE.alojado
                    && <VerDominioConfiguracion dominio={dominio.dominio}
                                                nombreDominio={ dominio.dominio.dominioBE.nombre }
                                                infoDominio={infoDominio}
                                                setMensajeOk={setMensajeOk}
                                                actualizarInformacionRegistros={actualizarInformacionRegistros}
                                                limpiarMensajes={limpiarMensajes}/> }
                {(dominio.esAdministrativo || dominio.esTecnico || dominio.esTitular)
                    && !dominio.dominio.dominioBE.alojado
                    && <VerDominioConfiguracionDelegado dominio={dominio.dominio}
                                                nombreDominio={ dominio.dominio.dominioBE.nombre }
                                                infoDominio={infoDominio}
                                                setError={setError}
                                                limpiarMensajes={limpiarMensajes}
                                                irVerDominio={irVerDominio}/> }

                <VerDominioContactos  dominio={dominio.dominio}
                                      nombreDominio={ dominio.dominio.dominioBE }
                                      cuentasContactos={infoDominio.cuentasContactos}
                                      permisos={permisos}
                                      setMensajeOk={setMensajeOk}
                                      setError={setError}/>
                {(dominio.esAdministrativo  || dominio.esFinanciero || dominio.esTitular) &&
                    <VerDominioFormaPago dominio={dominio}
                    nombreDominio={dominio.dominio.dominioBE.nombre}
                    permisos={permisos}
                    setMensajeOk={setMensajeOk}
                    setError={setError}
                    limpiarMensajes={limpiarMensajes}/>}

                {(dominio.esAdministrativo  || dominio.esTecnico || dominio.esTitular) && (mostrarTransferencia() && showTransferDomain) &&
                    <VerDominioTransferenciaRegistrador
                    nombreDominio={ dominio.dominio.dominioBE.nombre }
                    dominio={dominio.dominio}
                    setError={setError}
                    setMensajeOk={setMensajeOk}
                /> }

            </div>
        }
    </>
}
