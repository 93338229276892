export function buildDisponibilidad(dominiosConsulta, captchaResponse) {
    return {
        dominios: dominiosConsulta,
        captchaResponse: captchaResponse
    }
}

export function buildDisponibilidadResponse(responseDisponibilidad) {
    return {
        dominioDisponibilidadDTO: responseDisponibilidad.dominioDisponibilidadDTO,
        respuestaOK: responseDisponibilidad.respuestaOK,
        codigo: responseDisponibilidad.codigo,
        mensaje: responseDisponibilidad.mensaje
    };
}

export function buildDominiosDisponibilidadResponse(responseDisponibilidad) {
    return {
        dominiosHabilitados: responseDisponibilidad.dominiosHabilitados,
        respuestaOK: responseDisponibilidad.respuestaOK,
        codigo: responseDisponibilidad.codigo,
        mensaje: responseDisponibilidad.mensaje
    };
}

export function buildMostrarCaptchaResponse(responseMostrarCaptcha) {
    return {
        resultado: responseMostrarCaptcha.resultado,
        codigo: responseMostrarCaptcha.codigoRespuesta,
        mensaje: responseMostrarCaptcha.mensajeRespuesta
    };
}