export function buildConsultaWhois(nombreDominio, captchaResponse) {
    return {
        dominio: {
            nombre: nombreDominio
        },
        captchaResponse: captchaResponse
    }
}

export function buildConsultaWhoisResponse(nombreDominio, responseConsultaWhois) {
    return {
        dominio: nombreDominio,
        esRespOK: responseConsultaWhois.respuestaOK,
        respCodigo: responseConsultaWhois.codigo,
        respMensaje: responseConsultaWhois.mensaje,
        titular: responseConsultaWhois.titular,
        administrador: responseConsultaWhois.administrador,
        tecnico: responseConsultaWhois.tecnico,
        financiero: responseConsultaWhois.financiero,
        nameServers: responseConsultaWhois.nameServers,
        datosDOminio: responseConsultaWhois.datosDOminio,
        estado: responseConsultaWhois.estado,
        fechaAlta: responseConsultaWhois.fechaAlta
    }
}