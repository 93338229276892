import React, {useEffect} from 'react';
import "./Modal.css";
import ReactPortal from "./ReactPortal";
import PropTypes from 'prop-types';

function Modal({ children, isOpen, handleClose, idDivModal, className = "modal", modalContentClassName = "modal-content",
                   closeClassName = "close-modal", wrapperId = "wrapper", modalRelativeTo = null, closeOnClickOutside = false}) {

    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);

    if (!isOpen) return null;

    return (
        <ReactPortal wrapperId={wrapperId} onClose={handleClose} positionRelativeTo={modalRelativeTo} closeOnClickOutside={closeOnClickOutside}>
            <div id={idDivModal} className={className}>
                <div className={modalContentClassName}>
                    <div className={closeClassName} onClick={handleClose}></div>
                    {children}
                </div>

            </div>
        </ReactPortal>
    );
}

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    idDivModal: PropTypes.string,
    className: PropTypes.string,
    modalContentClassName: PropTypes.string,
    closeClassName: PropTypes.string,
    wrapperId: PropTypes.string,
    modalRelativeTo: PropTypes.string,
    closeOnClickOutside: PropTypes.bool,
};

Modal.defaultProps = {
    idDivModal: "",
    className: "modal",
    modalContentClassName: "modal-content",
    closeClassName: "close-modal",
    wrapperId: "wrapper",
    modalRelativeTo: null,
    closeOnClickOutside: false,
};

export default Modal;