import Modal from "../../../Modal";
import React from "react";
import TransferenciaRegistradorService from "../../../../services/TransferenciaRegistradorService";

export const ModalDialogoRechazarTransferencia = ({ nombreDominio, handleOnClose, isOpen, transferdata, setError, setMensajeOK, setReloadTrigger }) => {

    const handleOnAceptar = () => {
        const result = TransferenciaRegistradorService.rechazarSolicitudTransferencia(nombreDominio, transferdata.authorizationCode)
            .then(response => {
                if (!response.error) {
                    setMensajeOK({
                        mostrarMensaje: true,
                        mensaje: "La transferencia ha sido rechazada exitosamente."
                    });
                    setError({
                        error: false,
                        mensaje: ""
                    });
                    window.scroll(0, 0);
                } else {
                    setError({
                        error: true,
                        mensaje: "Ocurrió un error al rechazar la transferencia. Por favor, inténtelo nuevamente."
                    });
                    window.scroll(0, 0);
                }
            })
            .catch(error => {
                setError({
                    error: true,
                    mensaje: "Ocurrió un error al rechazar la transferencia. Por favor, inténtelo nuevamente."
                });
                window.scroll(0, 0);
            });
        handleOnClose();
        setTimeout(() => {
            setReloadTrigger(prev => !prev);
        }, 1000);
    };

    const handleOnCancelar = () => {
        handleOnClose();
    };

    return (
        <Modal
            idDivModal="idModalDialogoRechazarTransferencia"
            handleClose={handleOnClose}
            isOpen={isOpen}
            closeOnClickOutside={false}
        >
            <div className="ui-dialog-content ui-widget-content">
                <fieldset>
                    <legend>Rechazar Transferencia</legend>
                    <p>{"¿Desea rechazar la Transferencia de Agente Registrador del dominio " + nombreDominio + "?"}</p>
                    <div className="right">
                        <input type="button" value="Aceptar" className="btn" style={{ marginRight: '10px' }} onClick={handleOnAceptar} />
                        <input type="button" value="Cancelar" className="btn" onClick={handleOnCancelar} />
                    </div>
                </fieldset>
            </div>
        </Modal>
    );
};

export default ModalDialogoRechazarTransferencia;
