import React, { useState, useEffect } from 'react';
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {MensajeError} from "../../../../view/MensajeError";

function ModalActualizarContacto({ contacto, onClose, tipo, dominio, setError, setMensajeOk}) {
    const [isVisible, setIsVisible] = useState(true);
    const [errores, setErrores] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        var correo= e.target.email.value;
        var confirmarCorreo= e.target.emailConfirmacion.value;
        //console.log(dominio.dominioBE.nombre)
        correo = correo.trim();
        confirmarCorreo = confirmarCorreo.trim();
        if(correo === '' || confirmarCorreo === ''){
            setErrores({
                error: true,
                mensaje: 'Los correos no pueden estar vacíos.'
            });
            return;
        }
        else if (correo !== confirmarCorreo){
            setErrores({
                error: true,
                mensaje: 'Los correos no coinciden. Por favor, verifique que los correos ingresados sean iguales.'
            });
            return;
        }
        else {
            setErrores({
                error: false,
                mensaje: ''
            });

        }
        if (correo==confirmarCorreo && validarEmail(correo) && validarEmail(confirmarCorreo)){
            AdministracionUsuarioService.invitacionCambiarContacto(dominio.dominioBE.nombre, correo, normalizarTexto(tipo)) .then(response => {
                if (response.codigoRespuesta === "OK"){
                    setError({
                        error: false,
                        mensaje: ""
                    });
                    setMensajeOk({
                        mostrarMensaje: true,
                        mensaje: response.mensajeRespuesta
                    });
                    onClose(true)
                    window.scrollTo(0, 0)
                } else {
                    setMensajeOk({
                        mostrarMensaje: false,
                        mensaje: ""
                    });
                    setError({
                        error: true,
                        mensaje: response.mensajeRespuesta
                    })
                    onClose(true);
                    window.scrollTo(0, 0)
                }
            })
                .catch(() => {
                    setMensajeOk
                    ({
                        mostrarMensaje: false,
                        mensaje: ""
                    });
                    setError({
                        error: true,
                        mensaje: "Ha ocurrido un error al cambiar contacto."
                    })
                    onClose(true);
                    window.scrollTo(0, 0)
                })
        }
        else
        {
            if (!validarEmail(correo) || !validarEmail(confirmarCorreo))
            {
                setErrores({
                    error: true,
                    mensaje: 'El correo ingresado no es válido.'
                });
            }
            else
            setErrores({
                error: true,
                mensaje: 'Los correos no coinciden. Por favor, verifique que los correos ingresados sean iguales.'
            });
        }

    };
    function normalizarTexto(texto) {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
    }
    const validarEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const generarMensajeInicial = (tipo) => {
        return (
            <>
            <span className="texto-azul">
                Para cambiar el contacto {tipo.toUpperCase()} se le enviará una invitación al correo electrónico que se ingrese a
            </span>
                <br />
                <span className="texto-azul">
                continuación. El cambio será realizado sólo si el nuevo contacto lo confirma siguiendo los pasos indicados en la
            </span>
                <br />
                <span className="texto-azul">
                invitación.
            </span>
            </>
        );
    };


    const mostrarMensajePago = tipo.toLowerCase() !== 'técnico';

    return (
        <>
            {isVisible && (
                <Modal
                    idDivModal="idDialogo"
                    handleClose={() => {
                        setIsVisible(false);
                        if (onClose) onClose();
                    }}
                    isOpen={isVisible}
                    closeOnClickOutside={false}
                >

                    <fieldset>
                        <legend>Cambiar Contacto {tipo}</legend>
                        {errores !== undefined && errores.error && <MensajeError mensajeError={errores.mensaje}/>}
                        <form id="id-form-invitar-contacto" action="/frontend/private/misDominiosInvitarContacto.action" method="post" onSubmit={handleSubmit}>

                            <div className="message-box" id="register-warning">
                                <h3>{generarMensajeInicial(tipo)}</h3>
                            </div>
                            {mostrarMensajePago && (
                                <div className="message-box" id="register-warning">
                                    <h3 className="texto-azul">Una vez confirmado el cambio de contacto deberá ingresar una nueva forma de pago para la renovación.</h3>
                                </div>
                            )}
                            {mostrarMensajePago && (
                            <div className="form-item">
                                <label htmlFor="id-chk-eliminar-cuenta">Eliminar cuenta de pago</label>
                                <input type="checkbox" name="chkEliminarCuenta" checked disabled />
                            </div>
                            )}
                            <div className="form-item">
                                <label htmlFor="id-mail">Email</label>
                                <input type="text" name="email" id="id-mail" />
                            </div>
                            <div className="form-item">
                                <label htmlFor="id-conf-mail">Confirmar Email</label>
                                <input type="text" name="emailConfirmacion" id="id-conf-mail" />
                            </div>
                            <div className="right">
                                <input type="submit" value="Enviar invitación" id="id-submit-editar-datos-contacto" className="btn" />
                            </div>
                        </form>
                        <span className="close-modal" onClick={() => {
                            setIsVisible(false);
                            if (onClose) onClose();
                        }}></span>
                    </fieldset>
                </Modal>
            )}
        </>
    );
}

export default ModalActualizarContacto;
