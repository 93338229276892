import React, {useEffect, useState} from 'react'
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {CarritoComprasSeleccionarCuenta} from "../../../carrito/paso_03/CarritoComprasSeleccionarCuenta";
import {MensajeError} from "../../../MensajeError";
export const ModalModificarCuenta = ({handleOnClose, isOpen, dominiosSeleccionados, mapPermisosDominio, rolSeleccionado, respuestaAccionOk}) => {
    const [dominiosAplicaAccion, setDominiosAplicaAccion] = useState([]);
    const [dominiosNoAplicaAccion, setDominiosNoAplicaAccion] = useState([]);
    const [respuestaObtenida, setRespuestaObtenida] = useState(false);
    const [templateCuentaPago, setTemplateCuentaPago] = useState(undefined)
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores",
    });
    const [camposError, setCamposError] = useState(undefined);

    useEffect(() => {
        obtenerPermisosDominios();
    }, [])

    const obtenerPermisosDominios = () => {
        AdministracionUsuarioService.obtenerPermisosDominio(mapPermisosDominio, dominiosSeleccionados, rolSeleccionado, 'editarCuentaPago')
            .then(response => {
                if (response.codigo === 'OK') {
                    setDominiosAplicaAccion(response.dominiosAplicaAccion);
                    setDominiosNoAplicaAccion(response.dominiosNoAplicaAccion);
                }
            }).finally(() => {
            setRespuestaObtenida(true)
        })
    }

    const handleOnChangeTemplateCuentaPago = (templateCuenta) => {
        setTemplateCuentaPago(templateCuenta);
        // setCuentaNoSeleccionadaError(false);
    }

    const guardarDatos = (event) => {
        event.preventDefault();

        if (templateCuentaPago === undefined || templateCuentaPago.id === 0) {
            setError({
                error: true,
                mensaje: "Debe seleccionar una cuenta o crear una nueva"
            })
            return;
        }

        const validacion = validarDatosCuentaPago(templateCuentaPago);
        if (validacion.error) {
            setError(validacion);
        } else {
            AdministracionUsuarioService.editarCuentaDominio(dominiosSeleccionados, rolSeleccionado, templateCuentaPago)
                .then(response => {
                    let camposConError = [];
                    let mensajeError = "";
                    if (response.codigoRespuesta === "ERROR") {
                        Object.entries(response.mapErrores).map(([key, value]) => {
                            camposConError.push(key);
                            mensajeError = mensajeError + value;

                        })
                        setCamposError(camposConError);
                        setError({
                            error: true,
                            mensaje: mensajeError
                        })
                    } else {
                        respuestaAccionOk("Cuenta de pago modificada con éxito.");
                        handleOnClose(false);
                        window.scrollTo(0, 0);
                    }
                })
        }
    }

    function validarDatosCuentaPago(templateCuentaPago) {
        let camposConError = [];
        let errorValidacion = {
            error: false,
            mensaje: "Sin errores"
        }
        if (templateCuentaPago.id === undefined || templateCuentaPago.id === -1) {
            if (templateCuentaPago.nombre.trim().length === 1) {
                errorValidacion = {
                    error: true,
                    mensaje: "El nombre ingresado no es v\u00E1lido"
                };
            }

            if (templateCuentaPago.cuenta.idPais === undefined) {
                errorValidacion = {
                    error: true,
                    mensaje: "El pa\u00EDs seleccionado no es v\u00E1lido"
                };
            }

            if (templateCuentaPago.cuenta.idTipoDocumento === undefined) {
                errorValidacion = {
                    error: true,
                    mensaje: "El tipo de documento seleccionado no es v\u00E1lido"
                };
            }

            if (chequearVacio(templateCuentaPago.nombre.trim())) {
                errorValidacion = {
                    error: true,
                    mensaje: "Debe completar todos los campos obligatorios"
                };
                camposConError.push("nombre");
            }

            if (chequearVacio(templateCuentaPago.cuenta.cuenta.trim())) {
                errorValidacion = {
                    error: true,
                    mensaje: "Debe completar todos los campos obligatorios"
                };
                camposConError.push("cuenta");
            }

            if (chequearVacio(templateCuentaPago.cuenta.nroDocumento.trim())) {
                errorValidacion = {
                    error: true,
                    mensaje: "Debe completar todos los campos obligatorios"
                };
                camposConError.push("nroDocumento");
            }
        }
        setCamposError(camposConError);
        return errorValidacion;
    }

    const chequearVacio = (texto) => {
        return texto.length === 0;
    }

    return(<>
    { respuestaObtenida && <Modal handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
        { error.error && <MensajeError mensajeError={error.mensaje}></MensajeError>}
        {dominiosAplicaAccion && dominiosAplicaAccion.length > 0 ?
            <>
                { dominiosNoAplicaAccion && dominiosNoAplicaAccion.length > 0 &&
                    <div className="message-box" id="register-error">
                        <p>Los siguientes dominios no tendrán cambios ya que no tiene los permisos suficientes para realizar
                            la acción seleccionada</p>
                        <ul>
                            { dominiosNoAplicaAccion.map(dominio => (
                                <li>
                                    <span>{dominio}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                }

                <fieldset>
                    <legend>Modificar forma de pago para los dominios seleccionados</legend>
                    <legend>Seleccionar cuenta de telefonía fija o datos</legend>
                    <CarritoComprasSeleccionarCuenta ocultarMsjCuentaPago={true} onChangeTemplateCuentaPago={handleOnChangeTemplateCuentaPago}
                                                     camposConError={camposError}
                                                     setError={setError}
                    />
                    <div className="right">
                        <button type={"submit"} className="btn" onClick={guardarDatos}>Guardar Datos</button>
                    </div>
                </fieldset>
            </>
            :
            <>
                <div className="message-box" id="register-error">
                    <h3>No tiene permisos para definir la cuenta de pago de ninguno de los dominios seleccionados.</h3>
                </div>
            </>
        }
    </Modal>
    }
    </>)
}

ModalModificarCuenta.propTypes = {
    handleOnClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};