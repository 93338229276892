import React, {useEffect, useState} from 'react'
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {CarritoComprasSeleccionarCuenta} from "../../../../view/carrito/paso_03/CarritoComprasSeleccionarCuenta";
import {MensajeError} from "../../../../view/MensajeError";

export const ModalRenovacionDominio = ({ idDominioCore, nombreDominio, handleOnClose, isOpen, respuestaAccionOk, setIsProcesando, setMensajeProcesando }) => {
    const [camposError, setCamposError] = useState(undefined);
    const [warningCompraInmediata, setWarningCompraInmediata] = useState("Le informamos que si continúa con la compra inmediata, se eliminarán todos los elementos que tenga en el carrito de la compra actual");
    const [templateCuentaPago, setTemplateCuentaPago] = useState(undefined);
    const [estaRenovando, setEstaRenovando] = useState(false);
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    useEffect(() => {
        obtenerPermisosDominios();
    },[])

    const obtenerPermisosDominios = () => {
        // AdministracionUsuarioService.obtenerPermisosDominio(mapPermisosDominio, dominiosSeleccionados, rolSeleccionado, 'editarDatosContacto')
        //     .then(response => {
        //         if (response.codigo === 'OK') {
        //         }
        //     }).finally(() => {
        // })
    }

    const handleOnChangeTemplateCuentaPago = (templateCuenta) => {
        setTemplateCuentaPago(templateCuenta);
    }

    const renovar = (e) => {
        e.preventDefault();
        // Verifica si ya está en proceso de renovacion
        if (localStorage.getItem('estaRenovando') === 'true') {
            setError({ error: true, mensaje: "Ya se encuentra en proceso de renovación." });
            return;
        }
        localStorage.setItem('estaRenovando', 'true');

        if (templateCuentaPago === undefined || templateCuentaPago.id === 0) {
            setError({ error: true, mensaje: "Debe seleccionar una cuenta de pago" });
            // Restablece estado de renovación
            localStorage.setItem('estaRenovando', 'false');
            return;
        }

        let renovarDominioDTO = {
            idDominioCore: idDominioCore,
            nombreDominio : nombreDominio,
            compraInmediata: true,
            asociarTemplateCuenta: true,
            cantAnios: 1,
            pagoVentaDTO : {
                "medioPago" : "ff",
                "generaPago" : true,
                "generaFactura" : true
            },
            templateCuentaPagoDTO: templateCuentaPago
        };

        setError({ error: false, mensaje: "Sin errores" });
        const validacion = validarDatosCuentaPago(templateCuentaPago);
        if (validacion.error) {
            setError(validacion);
            localStorage.setItem('estaRenovando', 'false');
        } else {
            setMensajeProcesando('Procesando renovación')
            setIsProcesando(true);
            AdministracionUsuarioService.renovarDominio(renovarDominioDTO)
                .then(response => {
                    if (!response.esRespOK) {
                        setError({
                            error: true,
                            mensaje: response.mensaje
                        });
                    } else {
                        respuestaAccionOk(response.mensaje);
                        handleOnClose();
                    }
                })
                .catch(() => {
                    setError({
                        error: true,
                        mensaje: "Ha ocurrido un error al renovar dominio."
                    });
                })
                .finally(() => {
                    localStorage.setItem('estaRenovando', 'false');
                    setIsProcesando(false);

                });
        }
    };

    function validarDatosCuentaPago(templateCuentaPago) {
        let camposConError = [];
        let errorValidacion = {
            error: false,
            mensaje: "Sin errores"
        }
        if (templateCuentaPago.id === undefined || templateCuentaPago.id === -1) {
            if (templateCuentaPago.nombre.trim().length === 1) {
                errorValidacion = {
                    error: true,
                    mensaje: "El nombre ingresado no es v\u00E1lido"
                };
            }

            if (templateCuentaPago.cuenta.idPais === undefined) {
                errorValidacion = {
                    error: true,
                    mensaje: "El pa\u00EDs seleccionado no es v\u00E1lido"
                };
            }

            if (templateCuentaPago.cuenta.idTipoDocumento === undefined) {
                errorValidacion = {
                    error: true,
                    mensaje: "El tipo de documento seleccionado no es v\u00E1lido"
                };
            }

            if (chequearVacio(templateCuentaPago.nombre.trim())) {
                errorValidacion = {
                    error: true,
                    mensaje: "Debe completar todos los campos obligatorios"
                };
                camposConError.push("nombre");
            }

            if (chequearVacio(templateCuentaPago.cuenta.cuenta.trim())) {
                errorValidacion = {
                    error: true,
                    mensaje: "Debe completar todos los campos obligatorios"
                };
                camposConError.push("cuenta");
            }

            if (chequearVacio(templateCuentaPago.cuenta.nroDocumento.trim())) {
                errorValidacion = {
                    error: true,
                    mensaje: "Debe completar todos los campos obligatorios"
                };
                camposConError.push("nroDocumento");
            }
        }
        setCamposError(camposConError);
        return errorValidacion;
    }

    const chequearVacio = (texto) => {
        return texto.length === 0;
    }

    return <>

        <Modal
            idDivModal="idModalRenovar"
            handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>

            <fieldset style={{width: '600px'}}>

                <legend>Renovaci&oacute;n inmediata de dominio</legend>

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <form id="id-form-renovar-dominio" autoComplete="off">
                    <p>
                        <label>{ "Seleccionar cuenta de telefonía fija o datos" }</label>
                    </p>

                    <div className="message-box" id="register-warning">
                        <h3> { warningCompraInmediata } </h3>
                    </div>

                    <fieldset>
                        <legend>Datos de la cuenta de Antel de telefonía fija o datos</legend>
                        <div id="div-fieldset-nueva-cuenta">
                            <CarritoComprasSeleccionarCuenta ocultarMsjCuentaPago={true} onChangeTemplateCuentaPago={handleOnChangeTemplateCuentaPago}
                                 camposConError={camposError}
                                 setError={setError}
                            />
                        </div>
                    </fieldset>

                    <div className="right">
                        <button className={"btn"} onClick={renovar}>Renovar</button>
                    </div>

                </form>

            </fieldset>

        </Modal>
    </>
}
