import React, {useEffect, useState} from "react";
import CarritoService from "../../../services/CarritoService";

export const CarritoComprasNuevaCuentaForm = (props) => {
    const paises = props.paises;
    const [pais, setPais] = useState({idPais: "1", nombrePais: "URUGUAY"});
    const [documentosPorPais, setDocumentosPorPais] = useState(undefined);
    const [documento, setDocumento] = useState({idTipoDocumento: "", nombreTipoDocumento: ""});
    const [nroDocumento, setNroDocumento] = useState("");
    const [cuenta, setCuenta] = useState("");
    const [camposConError, setCamposConError] = useState(undefined);

    useEffect(() => {
        obtenerDocumentoPorPaisService(pais.idPais);
        actualizarInfoCuenta();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pais])

    useEffect(() => {
        actualizarInfoCuenta();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documento, cuenta, nroDocumento])

    useEffect(() => {
        setCamposConError(props.camposConError);
    }, [props.camposConError])

    const elegirPaisHandler = (event) => {
        const idPais = event.target.value;
        const nombrePais = event.target.options[event.target.selectedIndex].text;
        setPais({idPais: idPais, nombrePais: nombrePais});
    }

    const elegirDocumentoHandler = (event) => {
        const idTipoDocumentoActual = event.target.value;
        const nombreTipoDocumentoActual = event.target.options[event.target.selectedIndex].text;
        setDocumento({idTipoDocumento: idTipoDocumentoActual, nombreTipoDocumento: nombreTipoDocumentoActual});
    }

    const obtenerDocumentoPorPaisService = (idPais) => {
        CarritoService.obtenerDocumentoPorPais(idPais)
            .then(response => {
                if (response.esRespOK) {
                    setDocumentosPorPais(response.tipoDocumentoPaisDTOS);
                    setDocumento({
                        idTipoDocumento: response.tipoDocumentoPaisDTOS[0].tipoDocumentoDTO.id
                        , nombreTipoDocumento: response.tipoDocumentoPaisDTOS[0].tipoDocumentoDTO.nombre
                    })
                }
            })
    }

    const onChangeData = (e) => {
        actualizarInfoTemplate(e.target.name, e.target.value);
    }

    const actualizarInfoTemplate = (propiedad, valor) => {
        props.actualizarInfoCuenta(propiedad, valor);
    }

    const onChangeCuentaData = (e) => {
        setCuenta(e.target.value);
    }

    const onChangeNroDocumentoData = (e) => {
        setNroDocumento(e.target.value);
    }

    const actualizarInfoCuenta = () => {
        props.actualizarInfoCuenta("cuenta",{
            "idPais" : pais.idPais,
            "nombrePais": pais.nombrePais,
            "idTipoDocumento" : documento.idTipoDocumento,
            "nombreTipoDocumento": documento.nombreTipoDocumento,
            "nroDocumento" : nroDocumento,
            "cuenta" : cuenta.trim()});
    }

    const  styleError = (campo) => {
        if (camposConError !== undefined) {
            return camposConError.includes(campo) ? 'red' : '';
        }
    }

    return (
        <>
            <div className="form-item">
                <label htmlFor="id-nuevacuenta-nombre">Nombre de la cuenta *</label>
                <input type={"text"} id="id-nuevacuenta-nombre" name="nombre" onChange={onChangeData} style={{borderColor: styleError('nombre')}}/>
                <p className="note">Elegir Nombre para hacer referencia a la cuenta, ejemplo: casa</p>
            </div>

            <div className="form-item">
                <label htmlFor="id-Pais-Select-Nueva-Cuenta">Pa&iacute;s *</label>
                <select id="id-Pais-Select-Nueva-Cuenta" onChange={elegirPaisHandler} >
                    { paises !== undefined && paises.map((pais) => (
                        <option value={pais.id} key={pais.id}>{pais.nombre}</option>
                    ))}
                </select>
            </div>

            <div className="form-item" id="id-nueva-cuenta-tipo-documento-div">
                <label htmlFor="id-nueva-cuenta-tipo-documento-select">Tipo de documento *</label>
                <select id="id-nueva-cuenta-tipo-documento-select" onChange={elegirDocumentoHandler}>
                    {documentosPorPais !== undefined && documentosPorPais.map((documento)=>(
                        <option value={documento.tipoDocumentoDTO.id} key={documento.tipoDocumentoDTO.id}>{documento.tipoDocumentoDTO.nombre}</option>
                    ))}
                </select>
            </div>

            <div className="form-item">
                <label htmlFor="id-nuevacuenta-doc">N&uacute;mero de documento *</label>
                <input type={"text"} id="id-nuevacuenta-doc" name="nroDocumento" maxLength="12" onChange={onChangeNroDocumentoData} style={{borderColor: styleError('nroDocumento')}}/>
                <p className="note">Ingresar el n&uacute;mero de documento sin puntos ni guiones</p>
            </div>

            <div className="form-item">
                <label htmlFor="id-nuevacuenta-cuenta">Nro. de cuenta de Antel *</label>
                <input type={"text"} id="id-nuevacuenta-cuenta" name="cuenta" onChange={onChangeCuentaData} style={{borderColor: styleError('cuenta')}}/>
            </div>
        </>
    );
};