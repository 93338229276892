import React, {useState} from 'react';
import './MisDatos.css';
import {MensajeError} from "../../MensajeError";
import {useValidacionContacto} from "../../../components/validaciones/validacionesContacto";

export const MisDatosContacto = ({contactoPredeterminado, actualizarDatosContacto, titulo, guardarDatosContacto, editarDatosContacto, setEditarDatosContacto}) => {

    const { validarDatosContacto } = useValidacionContacto();
    const [camposError, setCamposError] = useState(undefined);

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores",
        campo: ""
    });

    const onChangeEditarDatosContacto = (e) => {
        e.preventDefault();
        setEditarDatosContacto(false);
    }

    const onGuardarDatosContacto = (e) => {
        e.preventDefault();
        setCamposError([])
        let validacion = validarDatosContacto(contactoPredeterminado, setError, true, true);
        if (!validacion.error) {
            setEditarDatosContacto(true);
            let requestDTO = {
                contacto : contactoPredeterminado,
                ignorarApellido : true
            }
            guardarDatosContacto(requestDTO);
        } else {
            setError({
                error: true,
                mensaje: validacion.mensaje,
                campo: validacion.campo
            });
            if (validacion.camposError) {
                procesarCamposError(validacion.camposError);
            }
        }
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        let newVar = {
            ...contactoPredeterminado,
            [e.target.name]: e.target.value
        };
        actualizarDatosContacto(newVar);
    }

    function procesarCamposError(camposError) {
        let camposConError = [];
        camposError.forEach(c => camposConError.push(c.codigo));
        setCamposError(camposConError);
    }

    const  styleError = (campo, campo2) => {
        if (camposError !== undefined) {
            if (camposError.includes(campo)) {
                return 'red'
            }
            if (campo2 !== undefined && camposError.includes(campo2)) {
                return 'red'

            }
        }
        return '';
    }

    return <>

        <form id="id-form-datos-contacto">
            <fieldset>
                <legend>{titulo}</legend>

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <input style={{display: 'none'}} type="text" name="invCheck" value={contactoPredeterminado.id} id="id-check-datos-contacto" disabled={true}/>

                <fieldset>
                    <legend>Datos Públicos</legend>

                    <div className="message-box" id="register-warning">
                        <h3>Los siguientes datos serán utilizados como datos de contacto por defecto para los contactos Administrativo, Técnico y Financiero de los dominios que usted registre. Serán datos públicos y podrán ser consultados a través del whois.</h3>
                    </div>

                    <div className="form-item">
                        <label htmlFor="id-nombre">Nombre *</label>
                        <input type={"text"} id="id-nombre" name="nombre"
                               style={{borderColor: styleError('nombre')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.nombre} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-apellido">Apellido *</label>
                        <input type={"text"} id="id-apellido" name="apellido"
                               style={{borderColor: styleError('apellido')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.apellido} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-direccion">Direcci&oacute;n *</label>
                        <input type={"text"} id="id-direccion" name="direccion"
                               style={{borderColor: styleError('direccion')}}
                               className="edit" disabled={editarDatosContacto} value={contactoPredeterminado.direccion} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-telefono">Tel&eacute;fono *</label>
                        <input type={"text"} id="id-telefono" name="telefono"
                               style={{borderColor: styleError('telefono')}}
                               className="edit" maxLength="30" disabled={editarDatosContacto} value={contactoPredeterminado.telefono} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-mail-publico">Correo electr&oacute;nico p&uacute;blico *</label>
                        <input type={"text"} id="id-mail-publico" name="emailPublico"
                               style={{borderColor: styleError('emailPublico')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.emailPublico} onChange={handleOnChange}/>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Correo Electrónico para Notificaciones</legend>

                    <div className="message-box" id="register-warning">
                        <h3>Se le enviarán notificaciones importantes relacionadas con sus dominios al siguiente correo electrónico.</h3>
                    </div>

                    <div className="form-item">
                        <label htmlFor="id-mail">Correo electr&oacute;nico *</label>
                        <input type={"text"} id="id-mail" name="email"
                               style={{borderColor: styleError('emailPublico', 'emailMismatch')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.email} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-mail-confirmacion">Confirmar correo electr&oacute;nico *</label>
                        <input type={"text"} id="id-mail-confirmacion" name="mailConfirmacion"
                               style={{borderColor: styleError('mailConfirmacion', 'emailMismatch')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.mailConfirmacion} onChange={handleOnChange}/>
                    </div>
                </fieldset>

                <div className="right">
                    { editarDatosContacto ?
                        <button id="id-submit-guardar-datos-contacto" className="btn edit" onClick={onChangeEditarDatosContacto}>Editar datos</button>
                        :
                        <button id="id-submit-editar-datos-contacto" className="btn" onClick={onGuardarDatosContacto}>Guardar datos</button>
                    }
                </div>
            </fieldset>
        </form>

    </>
}