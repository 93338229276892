import React, {useEffect} from 'react';
import {getOidcState, oidc_authorize} from "../../security/OidcManager";
import * as HttpService from "../../support/HttpService";

export const ValidacionAutenticado = () => {

    useEffect(() => {
        if (!getOidcState().is_authenticated) {
            oidc_authorize(HttpService.history.location.pathname);
        }
    }, []);

    return <></>
}