import {
    buildMensajesFiltroRequest,
    buildMensajesGlobalesResponse,
    buildMensajesUsuarioResponse, buildMensajeUsuarioResponse
} from "../dto/MensajesDTOBuilder";
import http from '../support/HttpService';

const REACT_APP_MENSAJE = process.env.REACT_APP_MENSAJES;
const REACT_APP_MENSAJES_GLOBALES = REACT_APP_MENSAJE + "/globales";
const REACT_APP_MENSAJES_USUARIO = REACT_APP_MENSAJE + "/usuario";

const MensajeService = {


    obtenerMensajesGlobales: async function(page, size) {
        try {
            const response = await http.post(REACT_APP_MENSAJES_GLOBALES, buildMensajesFiltroRequest(page, size));
            return buildMensajesGlobalesResponse(response.data);
        } catch (error) {
            console.log("Error al obtener mensajes globales");
        }
    },

    obtenerMensajesUsuario: async function(page, size) {
        try {
            const response = await http.post(REACT_APP_MENSAJES_USUARIO, buildMensajesFiltroRequest(page, size));
            return buildMensajesUsuarioResponse(response.data);
        } catch (error) {
            console.log("Error al obtener mensajes del usuario");
        }
    },

    marcarMensajeLeido: async function(idMensaje) {
        try {
            const response = await http.put(REACT_APP_MENSAJE + "/usuario/" + idMensaje + "/leido");
            return buildMensajeUsuarioResponse(response.data);
        } catch (error) {
            console.log("Error al marcar mensaje como leido");
        }
    },

    eliminarMensajeLeido: async function(idMensaje) {
        try{
            const response = await http.put(REACT_APP_MENSAJE + "/usuario/" + idMensaje + "/eliminar");
            return buildMensajeUsuarioResponse(response.data);
        } catch (error) {
            console.log("Error al eliminar mensaje");
        }
    },

    obtenerCantidadMensajesSinLeer: async function() {
        try {
            return await http.get(REACT_APP_MENSAJES_USUARIO + "/cantidadSinLeer")
                .then((response) => {
                    if (response.data.codigoRespuesta === 'OK') {
                       return response.data.cantidad;
                    }
                    return 0;
                }).catch(() => {
                    console.log("Error obteniendo la cantidad de mensajes sin leer");
                    return 0;
            });
        } catch (error) {
            console.log("Error al obtener la cantidad de mensajes sin leer del usuario");
        }
        return 0;
    }
}
export default MensajeService;