export function buildActualizarConatctoUsuarioRequest(contactoDTO) {
    return {
        contactoDTO: contactoDTO
    }
}

export function buildActualizarContactoUsuarioResponse(actualizarContactoUsuarioResponse) {
    return {
        contactoDTO: actualizarContactoUsuarioResponse.contactoDTO,
        esRespOK: actualizarContactoUsuarioResponse.respuestaOK,
        respCodigo: actualizarContactoUsuarioResponse.codigoRespuesta,
        respMensaje: actualizarContactoUsuarioResponse.mensajeRespuesta,
    }
}

export function buildObtenerCantidadContactosActualizablesResponse(cantContactosActualizablesResponse) {
    return {
        cantidad: cantContactosActualizablesResponse.cantidadContactosActualizables,
        esRespOK: cantContactosActualizablesResponse.respuestaOK,
        respCodigo: cantContactosActualizablesResponse.codigoRespuesta,
        respMensaje: cantContactosActualizablesResponse.mensajeRespuesta,
    }
}

export function buildDebeValidarDatosContactoResponse(debeValidarDatosContactoResponse) {
    return {
        ok: debeValidarDatosContactoResponse.ok,
        esRespOK: debeValidarDatosContactoResponse.respuestaOK,
        respCodigo: debeValidarDatosContactoResponse.codigoRespuesta,
    }
}

export function buildValidarDatosContactoRequest(codigo) {
    return {
        codigo: codigo
    }
}

export function buildValidarDatosContactoResponse(debeValidarDatosContactoResponse) {
    return {
        esRespOK: debeValidarDatosContactoResponse.respuestaOK,
        respCodigo: debeValidarDatosContactoResponse.codigoRespuesta,
        respMensaje: debeValidarDatosContactoResponse.mensajeRespuesta
    }
}

export function buildSolicitarNuevoCodigoResponse(solicitarNuevoCodigoResponse) {
    return {
        respCodigo: solicitarNuevoCodigoResponse.codigoRespuesta,
        respMensaje: solicitarNuevoCodigoResponse.mensajeRespuesta
    }
}