import React from 'react'
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";
import {useNavigate} from "react-router-dom";


export const ResumenDominiosImportantes = ( { mostrarDominiosImportantes, dominiosImportantes }) => {

    const history = useNavigate();

    function irVerDominio(obj) {

        let filtro = {
            nombre : obj.nombre,
            modulo: 'FRONTEND',
        };

        AdministracionUsuarioService.obtenerDominiosUsuario(filtro)
            .then(response => {
                if (response.codigo === 'OK' && response.totalDominiosFiltro === 1) {
                    let dominio = response.dominios[0];
                    console.log(dominio.dominio)
                    history("/administrar/ver-dominio", {state : { dominio: dominio}});
                }
            })
            .catch(error => {
                console.error(error)
            });
        history("/administrar/mis-dominios");
    }

    return <>
        { mostrarDominiosImportantes &&
            <div className="box dominios right">
                <div className="top">
                    <h4>Dominios</h4>
                    <div className="right">
                        <a href="/administrar/mis-dominios">Ver todos</a>
                    </div>
                </div>

                <div className="box-content">
                        { Object.entries(dominiosImportantes).map(([key, listaDominios]) => (
                            <React.Fragment key={key}>
                            { listaDominios.map((obj, index) => (
                                <p key={index} className={key}>
                                    <a className="link" onClick={() => irVerDominio(obj)}>{ obj.nombre }</a></p>
                            ))}

                            </React.Fragment>
                        ))}
                </div>

            </div>
        }
    </>
}