import { buildObtenerUsuarioResponse} from "../dto/ObtenerUsuarioDTOBuilder";
import http from '../support/HttpService';
import {
    buildActualizarConatctoUsuarioRequest,
    buildActualizarContactoUsuarioResponse,
    buildDebeValidarDatosContactoResponse,
    buildObtenerCantidadContactosActualizablesResponse, buildSolicitarNuevoCodigoResponse,
    buildValidarDatosContactoRequest,
    buildValidarDatosContactoResponse
} from "../dto/ActualizarContactoUsuario";
import {buildObtenerResumenUsuarioResponse} from "../dto/AdministracionUsuarioDTOBuilder";

const REACT_APP_OBTENER_USUARIO = process.env.REACT_APP_OBTENER_USUARIO;
const REACT_APP_ACTUALIZAR_CONTACTO_PREDETERMINADO_USUARIO = process.env.REACT_APP_ACTUALIZAR_CONTACTO_PREDETERMINADO_USUARIO;
const REACT_APP_CANT_CONTACTOS_ACTUALIZABLES = process.env.REACT_APP_CANT_CONTACTOS_ACTUALIZABLES;
const REACT_APP_ACTUALIZAR_CONTACTOS_BE_USUARIO = process.env.REACT_APP_ACTUALIZAR_CONTACTOS_BE_USUARIO;
const REACT_APP_DEBE_VALIDAR_DATOS_CONTACTO = process.env.REACT_APP_DEBE_VALIDAR_DATOS_CONTACTO;
const REACT_APP_SOLICITAR_NUEVO_CODIGO_VALIDACION = process.env.REACT_APP_SOLICITAR_NUEVO_CODIGO_VALIDACION;
const REACT_APP_VALIDAR_CODIGO_DATOS_CONTACTO = process.env.REACT_APP_VALIDAR_CODIGO_DATOS_CONTACTO;
const REACT_APP_USUARIO_ADMINISTRACION = process.env.REACT_APP_USUARIO_ADMINISTRACION;
const REACT_APP_VALIDAR_USUARIO_LOGEADO = process.env.REACT_APP_VALIDAR_USUARIO_LOGEADO;

const UsuarioService = {

    getUsuario: async function () {
        try {
            const response = await http.get(REACT_APP_OBTENER_USUARIO);
            return buildObtenerUsuarioResponse(response.data);
        } catch (error){
            console.error("Error procesando pedido");
            console.error(JSON.stringify(error));
        }
    },

    updateContactoPredeterminadoUsuario: async function(contactoDto) {
        try {
            const response = await http.put(REACT_APP_ACTUALIZAR_CONTACTO_PREDETERMINADO_USUARIO, buildActualizarConatctoUsuarioRequest(contactoDto));
            return buildActualizarContactoUsuarioResponse(response.data);
        } catch (error){
            console.error(JSON.stringify(error));
        }
    },

    obtenerCantidadContactosActualizables : async function () {
        try {
            const response = await http.get(REACT_APP_CANT_CONTACTOS_ACTUALIZABLES);
            return buildObtenerCantidadContactosActualizablesResponse(response.data);
        } catch (error){
            console.error(JSON.stringify(error));
        }
    },

    actualizarContactoBEUsuario: async function(datosContactoDTO) {
        try {
            const response = await http.post(REACT_APP_ACTUALIZAR_CONTACTOS_BE_USUARIO, datosContactoDTO);
            return buildActualizarContactoUsuarioResponse(response.data);
        } catch (error){
            console.error(JSON.stringify(error));
            throw error;
        }
    },

    solicitarNuevoCodigoValidacion: async function() {
        try {
            const response = await http.get(REACT_APP_SOLICITAR_NUEVO_CODIGO_VALIDACION);
            return buildSolicitarNuevoCodigoResponse(response.data);
        } catch (error){
            console.error(JSON.stringify(error));
            throw error;
        }
    },


    debeValidarDatosContacto: async function() {
        try {
            const response = await http.get(REACT_APP_DEBE_VALIDAR_DATOS_CONTACTO);
            return buildDebeValidarDatosContactoResponse(response.data);
        } catch (error){
            console.error(JSON.stringify(error));
            throw error;
        }
    },

    validarCodigoDatosContacto: async function(codigo) {
        try {
            const response = await http.post(REACT_APP_VALIDAR_CODIGO_DATOS_CONTACTO, buildValidarDatosContactoRequest(codigo));
            return buildValidarDatosContactoResponse(response.data);
        } catch (error){
            console.error(JSON.stringify(error));
            throw error;
        }
    },

    obtenerResumenUsuario: async function() {
        try {
            const response = await http.get(REACT_APP_USUARIO_ADMINISTRACION + "/resumen");
            return buildObtenerResumenUsuarioResponse(response.data);
        } catch (error) {
            console.log("Error al obtener el resumen del usuario");
        }
    },

    validarUsuarioLogeado: async function() {
        try {
            const response = await http.get(REACT_APP_VALIDAR_USUARIO_LOGEADO);
            return response.data;
        } catch (error) {
            console.log("Error al obtener el resumen del usuario");
        }
    }
}
export default UsuarioService;