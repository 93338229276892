import React, {useEffect, useState} from "react";
import Modal from "../../../components/Modal";
import PropTypes from 'prop-types';
import CuentaService from "../../../services/CuentaService";
import './ModalIngresarDatosContacto.css';
import {MensajeError} from "../../MensajeError";
import { useValidacionContacto } from '../../../components/validaciones/validacionesContacto';

const ModalIngresarDatosContacto = (props) => {
    const [usarMisDatos, setUsarMisDatos] = useState(true);
    const [contactoGuardado, setContactoGuardado] = useState({});
    const caracteresEspeciales = /[^A-Za-z0-9ñÑáéíóúÁÉÍÓÚ\s@-]/g;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const [contactoNuevo, setContactoNuevo] = useState({
            id: '',
            nombreApellido: '',
            direccion: '',
            telefono: '',
            emailPublico: '',
            email: '',
            mailConfirmacion: '',
            guardado: false
    });
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const [camposError, setCamposError] = useState(undefined);
    const [camposErrorNuevo, setCamposErrorNuevo] = useState(undefined);

    const { validarDatosContacto } = useValidacionContacto();
    const dominioObjetivo = props.dominioObjetivo;

    useEffect(() => {
        obtenerContactoPredeterminadoService();
        if (props.datosIniciales) {
            if (props.datosIniciales.guardado) {
                if (props.datosIniciales && props.datosIniciales.id !== undefined && contactoGuardado.id !== undefined) {
                    if (esPredeterminado()) {
                        setUsarMisDatos(true);
                        setContactoNuevo({
                            id: '',
                            nombreApellido: '',
                            direccion: '',
                            telefono: '',
                            emailPublico: '',
                            email: '',
                            mailConfirmacion: '',
                            guardado: false
                        });
                    } else if (props.datosIniciales.id !== '') {
                        setUsarMisDatos(false);
                        setContactoNuevo(props.datosIniciales);
                    }
                }
            } else {
                setUsarMisDatos(true);
                setContactoNuevo({
                    id: '',
                    nombreApellido: '',
                    direccion: '',
                    telefono: '',
                    emailPublico: '',
                    email: '',
                    mailConfirmacion: '',
                    guardado: false
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.datosIniciales, props.isOpen]);

    useEffect(() => {
        if (error && error.error) {
            setError({
                error: false,
                mensaje: ''
            })
            props.setErrorValidacionBE({
                error: false,
                mensaje: ''
            })
        }
        setCamposError([])
        setCamposErrorNuevo([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.isOpen]);

    useEffect(() => {
        if (props.errorValidacionBE && props.errorValidacionBE.error) {
            setError(props.errorValidacionBE);
        }
    },[props.isOpen, props.errorValidacionBE]);


    function handleOnChange(e) {

        if (usarMisDatos) {
            const datosContactoAux = {...contactoGuardado};
            datosContactoAux[e.target.name] = e.target.value;
            setContactoGuardado(datosContactoAux);
        } else {
            const datosContactoAux = {...contactoNuevo};
            datosContactoAux[e.target.name] = e.target.value;
            setContactoNuevo(datosContactoAux);
        }

    }

    const handleChecksUsarDatosContacto = (usarMisDatosCheckValue) => {
        setUsarMisDatos(usarMisDatosCheckValue);
    }

    const obtenerContactoPredeterminadoService = () => {
        CuentaService.getContactoPredeterminadoUsuario()
            .then((response) => {
                if (response.respCodigo === 'OK') {
                    setContactoGuardado({
                        id: response.contactoDTO.id,
                        nombreApellido: response.contactoDTO.nombreApellido,
                        direccion: response.contactoDTO.direccion,
                        telefono: response.contactoDTO.telefono,
                        emailPublico: response.contactoDTO.emailPublico,
                        email: response.contactoDTO.email,
                        mailConfirmacion: response.contactoDTO.email,
                        guardado: false
                    });
                }
            })
    }

    const onChangeDatosContacto = (event) => {
        setCamposError([])
        setCamposErrorNuevo([])
        let validacion;
        if (usarMisDatos) {
            validacion = validarDatosContacto(contactoGuardado, setError, true, true);
            if (validacion.error) {
                setError({
                    error: true,
                    mensaje: validacion.mensaje

                })
                if (validacion.camposError) {
                    procesarCamposError(validacion.camposError);
                }
                event.preventDefault();
            } else {
                let contactoAux = {...contactoGuardado,
                    guardado: true
                };
                props.onChangeDatosContacto(event, dominioObjetivo, contactoAux);
            }
        } else {
            validacion = validarDatosContacto(contactoNuevo,setError, true, true);
            if (validacion.error) {
                setError({
                    error: true,
                    mensaje: validacion.mensaje

                })
                if (validacion.camposError) {
                    procesarCamposErrorNuevo(validacion.camposError);
                }
                event.preventDefault();
            } else {

                let contactoAux = {...contactoNuevo,
                    guardado: true
                };
                props.onChangeDatosContacto(event, dominioObjetivo, contactoAux);
            }
        }
    }

    function procesarCamposError(camposError) {
        let camposConError = [];
        camposError.forEach(c => camposConError.push(c.codigo));
        setCamposError(camposConError);
    }

    function procesarCamposErrorNuevo(camposError) {
        let camposConError = [];
        camposError.forEach(c => camposConError.push(c.codigo));
        setCamposErrorNuevo(camposConError);
    }

    const  styleError = (campo, campo2) => {
        if (camposError !== undefined) {
            if (camposError.includes(campo)) {
                return 'red'
            }
            if (campo2 !== undefined && camposError.includes(campo2)) {
                return 'red'
            }
        }
        return '';
    }

    const  styleErrorNuevo = (campo, campo2) => {
        if (camposErrorNuevo !== undefined) {
            if (camposErrorNuevo.includes(campo)) {
                return 'red'
            }
            if (campo2 !== undefined && camposErrorNuevo.includes(campo2)) {
                return 'red'
            }
        }
        return '';
    }


    const esPredeterminado = () => {
        return (props.datosIniciales.nombreApellido === contactoGuardado.nombreApellido &&
            props.datosIniciales.direccion === contactoGuardado.direccion &&
            props.datosIniciales.telefono === contactoGuardado.telefono &&
            props.datosIniciales.emailPublico === contactoGuardado.emailPublico &&
            props.datosIniciales.email === contactoGuardado.email)
    }

    const CheckUsarDatosContacto = (props) => {
        return <>
            {
                props.show?
                    <>
                        { props.showBR && <br/> } {/*En algunos casos se necesta un espacio extra*/}
                        <label>
                            <input type={"radio"} name={"contacto"} checked={props.checked}
                                   onChange={props.onChange}/>{props.label}
                        </label>
                    </>
                    : null
            }
        </>
    }

    return (
        <Modal handleClose={props.handleOnClose} isOpen={props.isOpen} closeOnClickOutside={false}>
            <form id="idFormGuardarDatosTitular">

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <div className="cfx">
                    <p className="msjRequerido right">Los campos marcados con * son requeridos.</p>
                </div>

                <fieldset className="select-titular">
                    <legend>Datos del titular de {dominioObjetivo.nombre}</legend>
                    <div className="message-box" id="register-warning">
                        <h3>El titular es la Persona, Empresa u Organización que posee los derechos legales sobre el nombre del dominio.</h3>
                    </div>
                    <div className="form-item">
                        {/* LABEL y CHECK Usar Mis Datos de Contacto */}
                        <CheckUsarDatosContacto checked={usarMisDatos} defaultChecked={!usarMisDatos}
                                                label="Usar mis datos de contacto"
                                                onChange={() => handleChecksUsarDatosContacto(true)}
                                                show={true}/>
                        <br/>

                        {/* LABEL y CHECK Ingresar Datos de Contacto (Para mostrarse por arriba del formulario) */}
                        <CheckUsarDatosContacto checked={!usarMisDatos} defaultChecked={!usarMisDatos}
                                                label="Ingresar datos de contacto"
                                                onChange={() => handleChecksUsarDatosContacto(false)}
                                                show={!usarMisDatos} showBR={true}/>

                        {usarMisDatos &&
                            <fieldset>
                                <fieldset>
                                    <legend>Datos Públicos</legend>

                                    <div className="form-item">
                                        <label htmlFor="id-nombre">Nombre (y Apellido si es Persona) *</label>
                                        <input type="text" id="id-nombre" name="nombreApellido" value={contactoGuardado.nombreApellido} onChange={handleOnChange}
                                               style={{borderColor: styleError('nombreApellido')}}/>
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="id-direccion">Domicilio constituido en Uruguay *</label>
                                        <input type="text" id="id-direccion" name="direccion" value={contactoGuardado.direccion} onChange={handleOnChange}
                                               style={{borderColor: styleError('direccion')}}/>
                                        <p>Los datos ingresados deben ser verdaderos, son tenidos en cuenta para cualquier gestión</p>
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="id-telefono">Teléfono *</label>
                                        <input type="text" id="id-telefono" name="telefono" maxLength="30" value={contactoGuardado.telefono} onChange={handleOnChange}
                                               style={{borderColor: styleError('telefono')}}/>
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="id-mail-publico">Correo electr&oacute;nico p&uacute;blico *</label>
                                        <input type="text" id="id-mail-publico" name="emailPublico" value={contactoGuardado.emailPublico} onChange={handleOnChange}
                                               style={{borderColor: styleError('emailPublico')}}/>
                                    </div>
                                </fieldset>

                                <fieldset>
                                    <legend>Correo Electrónico para Notificaciones</legend>

                                    <div className="message-box" id="register-warning">
                                        <h3>El siguiente correo electrónico le permitirá al titular recibir notificaciones y realizar gestiones administrativas.</h3>
                                    </div>

                                    <div className="form-item">
                                        <label htmlFor="id-mail">Correo electrónico *</label>
                                        <input type="text" id="id-mail" name="email" value={contactoGuardado.email} onChange={handleOnChange}
                                               style={{borderColor: styleError('email', 'emailMismatch')}}/>
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="id-mail-confirmacion">Confirmar correo electrónico *</label>
                                        <input type="text" id="id-mail-confirmacion" name="mailConfirmacion" value={contactoGuardado.mailConfirmacion} onChange={handleOnChange}
                                               style={{borderColor: styleError('mailConfirmacion', 'emailMismatch')}}/>
                                    </div>
                                </fieldset>
                            </fieldset> }

                        {!usarMisDatos &&
                        <fieldset>
                            <fieldset>
                                <legend>Datos Públicos</legend>

                                <div className="form-item">
                                    <label htmlFor="id-nombre">Nombre (y Apellido si es Persona) *</label>
                                    <input type="text" id="id-nombre" name="nombreApellido" value={contactoNuevo.nombreApellido} onChange={handleOnChange}
                                           style={{borderColor: styleErrorNuevo('nombreApellido')}}
                                    />
                                </div>
                                <div className="form-item">
                                    <label htmlFor="id-direccion">Domicilio constituido en Uruguay *</label>
                                    <input type="text" id="id-direccion" name="direccion" value={contactoNuevo.direccion} onChange={handleOnChange}
                                           style={{borderColor: styleErrorNuevo('direccion')}}
                                    />
                                    <p>Los datos ingresados deben ser verdaderos, son tenidos en cuenta para cualquier gestión</p>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="id-telefono">Teléfono *</label>
                                    <input type="text" id="id-telefono" name="telefono" maxLength="30" value={contactoNuevo.telefono} onChange={handleOnChange}
                                           style={{borderColor: styleErrorNuevo('telefono')}}
                                    />
                                </div>
                                <div className="form-item">
                                    <label htmlFor="id-mail-publico">Correo electr&oacute;nico p&uacute;blico *</label>
                                    <input type="text" id="id-mail-publico" name="emailPublico" value={contactoNuevo.emailPublico} onChange={handleOnChange}
                                           style={{borderColor: styleErrorNuevo('emailPublico')}}
                                    />
                                </div>
                            </fieldset>

                            <fieldset>
                                <legend>Correo Electrónico para Notificaciones</legend>

                                <div className="message-box" id="register-warning">
                                    <h3>El siguiente correo electrónico le permitirá al titular recibir notificaciones y realizar gestiones administrativas.</h3>
                                </div>

                                <div className="form-item">
                                    <label htmlFor="id-mail">Correo electrónico *</label>
                                    <input type="text" id="id-mail" name="email" value={contactoNuevo.email} onChange={handleOnChange}
                                           style={{borderColor: styleErrorNuevo('email', 'emailMismatch')}}/>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="id-mail-confirmacion">Confirmar correo electrónico *</label>
                                    <input type="text" id="id-mail-confirmacion" name="mailConfirmacion" value={contactoNuevo.mailConfirmacion} onChange={handleOnChange}
                                           style={{borderColor: styleErrorNuevo('mailConfirmacion', 'emailMismatch')}}/>
                                </div>
                            </fieldset>
                        </fieldset> }

                        {/* LABEL y CHECK Ingresar Datos de Contacto (Para mostrarse por debajo del formulario) */}
                        <CheckUsarDatosContacto checked={!usarMisDatos} defaultChecked={!usarMisDatos}
                                                label="Ingresar datos de contacto"
                                                onChange={() => handleChecksUsarDatosContacto(false)}
                                                show={usarMisDatos}/>
                    </div>
                    <div className="right">
                        <button className="btn" onClick={onChangeDatosContacto}>Guardar datos</button>
                    </div>
                </fieldset>
            </form>
        </Modal>
    );
}

ModalIngresarDatosContacto.propTypes = {
    handleOnClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default ModalIngresarDatosContacto;