import ModalCancelacionTransferencia from "./ModalCancelacionTransferencia";
import transferenciaService from "../../../services/TransferenciaRegistradorService";
import React, {useContext, useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import MisSolicitudesTransferenciaRow from "./MisSolicitudesTransferenciaRow";
import AppContext from "../../../store/AppContext";
import {TipoMensaje} from "../../../store/AppProvider";
import ModalEliminarTransferencia from "./ModalEliminarTransferencia";

const MisSolicitudesTransferencia = (props) => {
    const appCtx = useContext(AppContext);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEliminar, setIsOpenEliminar] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [codAuth, setCodAuth] = useState('');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const request = transferenciaService.obtenerSolicitudesTransferenciaXUsuario(
            ).then(response => {
                setData(response);
            }).catch(() => {
                console.log("Falla al cargar lista")
            }).finally(() => {
                setIsLoading(false)
            });
        }

        if (selectedDomain === null) {
            fetchData();
        }
    }, [selectedDomain]);

    const handleSubmit = (event) => {
        transferenciaService.cancelarTransferencia(selectedDomain, codAuth).then(response => {
            if (response.isOk) {
                appCtx.mostrarMensaje('La solicitud de transferencia fue cancelada correctamente', TipoMensaje.ok);
            } else {
                appCtx.mostrarMensaje('Se produjo un error al cancelar la solicitud de transferencia', TipoMensaje.error);
            }
        }).catch(error => {
            appCtx.mostrarMensaje('Se produjo un error al cancelar la solicitud de transferencia', TipoMensaje.error);
        }).finally(() => {
                setSelectedDomain(null);
                setCodAuth(codAuth);
                setIsOpen(false);
            }
        )
    }

    const handleSubmitRemove = (event) => {
        transferenciaService.eliminarTransferencia(selectedDomain, codAuth).then(response => {
            if (response.isOk) {
                appCtx.mostrarMensaje('La solicitud de transferencia fue eliminada correctamente', TipoMensaje.ok);
            } else {
                appCtx.mostrarMensaje('Se produjo un error al eliminar la solicitud de transferencia', TipoMensaje.error);
            }
        }).catch(error => {
            appCtx.mostrarMensaje('Se produjo un error al eliminar la solicitud de transferencia', TipoMensaje.error);
        }).finally(() => {
                setSelectedDomain(null);
                setCodAuth(codAuth);
                setIsOpenEliminar(false);
            }
        )
    }

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    const handleCancelarTransferencia = (domain, codAuth) => {
        setSelectedDomain(domain)
        setCodAuth(codAuth)
        setIsOpen(true);
    }

    const handleEliminarTransferencia = (domain, codAuth) => {
        setSelectedDomain(domain)
        setCodAuth(codAuth)
        setIsOpenEliminar(true);
    }

    const handleVerTransferencia = (domain, codAuth) => {
        navigate('/detalle-transferencia?dominio=' + domain +  '&codAuth=' + codAuth);
    }

    return (
        <>
            {isOpen &&
                <ModalCancelacionTransferencia
                    isOpen={isOpen}
                    submit={handleSubmit}
                    domain={selectedDomain}
                    handleOnClose={() => {
                        setIsOpen(false)
                    }}/>
            }

            {isOpenEliminar &&
                <ModalEliminarTransferencia
                    isOpen={isOpenEliminar}
                    submit={handleSubmitRemove}
                    domain={selectedDomain}
                    handleOnClose={() => {
                        setIsOpenEliminar(false)
                    }}/>
            }

            <div>
                <h1>Solicitudes de transferencia</h1>
                {data.length > 0 ? (
                <table className="data" id="idTablaDominios">
                    <thead>
                    <tr>
                        <th>Dominio</th>
                        <th>Estado</th>
                        <th colSpan={3}></th>
                    </tr>
                    </thead>
                    <tbody>
                        {data.map((item) =>
                            <MisSolicitudesTransferenciaRow key={item.dominio + item.codigoAutorizacion}
                                                            item={item}
                                                            handleVer={(e) => {
                                                                e.preventDefault();
                                                                handleVerTransferencia(item.dominio, item.codigoAutorizacion);
                                                            }}
                                                            handleCancelar={(e) => {
                                                                e.preventDefault();
                                                                handleCancelarTransferencia(item.dominio, item.codigoAutorizacion);
                                                            }}
                                                            handleEliminar={(e) => {
                                                                e.preventDefault();
                                                                handleEliminarTransferencia(item.dominio, item.codigoAutorizacion);
                                                            }}
                            />)
                        }
                    </tbody>
                </table>
                ) : (
                    <p>En este momento no se han encontrado solicitudes de transferencia.</p>
                )}
            </div>
        </>
    );
}

export default MisSolicitudesTransferencia;