export function buildMensajesFiltroRequest(page, size) {
    return {
        paginaActual: page,
        cantXPagina: size
    }
}

export function buildMensajesGlobalesResponse(response) {
    return {
        mensajes: response.mensajes,
        totalCount: response.totalCount,
        codigo: response.codigoRespuesta,
        mensaje: response.mensajeRespuesta
    }
}

export function buildMensajesUsuarioResponse(response) {
    return {
        mensajes: response.mensajes,
        totalCount: response.totalCount,
        codigo: response.codigoRespuesta,
        mensaje: response.mensajeRespuesta
    }
}

export function buildMensajeUsuarioResponse(response) {
    return {
        mensajeDTO: response.mensaje,
        codigo: response.codigoRespuesta,
        mensaje: response.mensajeRespuesta
    }
}
