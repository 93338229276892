import React from 'react'

const MisSolicitudesTransferenciaRow = ({item, handleVer, handleCancelar, handleEliminar}) => {
    const {color, mensaje} = convertirEstado(item.estado);

    return <tr>
        <td>{item.dominio}</td>
        <td>
            <div className={color}>{mensaje}</div>
        </td>
        <td>
            <a href="#" onClick={handleVer}>Ver</a>
        </td>
        <td>
            {item.estado === 'PENDING' && item.estado !== '' &&
                <a href="#" onClick={handleCancelar}>Cancelar</a>
            }
        </td>
        <td>
            {item.estado !== 'PENDING' && item.estado !== '' &&
                <a href="#" onClick={handleEliminar}>Eliminar</a>
            }
        </td>
    </tr>;
}

const convertirEstado = (estado) => {
    if (estado === 'PENDING') {
        return {color: 'label-admin label-info label-outlined', mensaje: 'Pendiente'};
    } else if (estado === 'CLIENT_REJECTED' || estado === 'SERVER_REJECTED') {
        return {color: 'label-admin label-danger label-outlined', mensaje: 'Rechazado'};
    } else if (estado === 'CLIENT_CANCELLED' || estado === 'SERVER_CANCELLED') {
        return {color: 'label-admin label-danger label-outlined', mensaje: 'Cancelado'};
    } else if (estado === 'CLIENT_APPROVED' || estado === 'SERVER_APPROVED') {
        return {color: 'label-admin label-success label-outlined', mensaje: 'Aprobado'};
    } else if (estado === 'PENDING_NO_PAYMENT') {
        return {color: 'label-admin label-danger label-outlined', mensaje: 'Cancelado por problemas al procesar el pago'};
    } else if (estado === 'EXPIRED') {
        return {color: 'label-admin label-danger label-outlined', mensaje: 'Transferencia vencida'};
    } else {
        return {color: 'label-admin label-danger label-outlined', mensaje: 'Error'};
    }
};

export default MisSolicitudesTransferenciaRow;