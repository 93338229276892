import React from "react";
import PropTypes from 'prop-types';
import Modal from "../../../components/Modal";


const ModalEliminarTransferencia = (props) => {
    const {isOpen, setIsOpen, submit, domain} = props;

    const handleEliminar = () => {
        submit();
    }

    return (
        <Modal handleClose={props.handleOnClose} isOpen={props.isOpen} closeOnClickOutside={false}
               className="modal" modalContentClassName="modal-content" closeClassName="close-modal">
            <p>¿Está seguro de que desea eliminar el proceso de transferencia de registrador de su dominio {domain}?</p>
            <hr style={{borderTop: '1px solid #AAAAAA'}}></hr>
            <div className="right">
                <button className={'btn'} onClick={props.handleOnClose}>Cancelar</button>
                <button className={'btn'} onClick={handleEliminar}>Aceptar</button>
            </div>
        </Modal>
    )
}

ModalEliminarTransferencia.propTypes = {
    handleOnClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default ModalEliminarTransferencia;