import React, {useEffect} from 'react';
import {getOidcState, oidc_authorize} from "../../security/OidcManager";
import * as HttpService from "../../support/HttpService";

export const GestionAutenticacion = () => {

    useEffect(() => {
        if (!getOidcState().is_authenticated) {
            oidc_authorize(window.location.pathname + window.location.search);
        }
    }, []);

    return <></>
}