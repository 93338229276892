import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";
import { MensajeError } from "../../MensajeError";
import { ModalRechazarInvitacion } from "./ModalRechazarInvitacion";
import { MensajeOk } from "../../MensajeOk";
import {GestionAutenticacion} from "../../../components/validaciones/GestionAutenticacion";
import { getOidcState } from "../../../security/OidcManager";
import CuentaService from "../../../services/CuentaService";

const VerPaginaInvitacion = () => {
    const [invitacion, setInvitacion] = useState(null);
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const location = useLocation();
    const navigate = useNavigate();
    const [mensajeOk, setMensajeOk] = useState(undefined);

    const [mostrarModal, setMostrarModal] = useState(false);
    const [isOpenRechazarInvitacion, setIsOpenRechazarInvitacion] = useState(false);
    const query = new URLSearchParams(location.search);
    const codigo = query.get('codigo');
    const [contactoPredeterminado, setContactoPredeterminado] = useState({
        id: '',
        nombre: '',
        apellido: '',
        direccion: '',
        telefono: '',
        emailPublico: '',
        email: '',
        mailConfirmacion: '',
    });


    const handleSubmit = (event) => {
        event.preventDefault();
    };
    const cerrarModal = () => {
        setMostrarModal(false);
    };

    useEffect(() => {
        if (usuarioAutenticado()) {
            verificarInvitacion();
        }
    }, [codigo]);

    const usuarioAutenticado = () => {
        return getOidcState().is_authenticated;
    };

    useEffect(() => {
        obtenerContactoPredeterminadoService();
    }, []);

    const obtenerContactoPredeterminadoService = () => {
        CuentaService.getContactoPredeterminadoUsuario()
            .then((response) => {
                if (response.respCodigo === 'OK') {
                    setContactoPredeterminado({
                        id: response.contactoDTO.id,
                        nombre: response.contactoDTO.nombre,
                        apellido: response.contactoDTO.apellido,
                        direccion: response.contactoDTO.direccion,
                        telefono: response.contactoDTO.telefono,
                        emailPublico: response.contactoDTO.emailPublico,
                        email: response.contactoDTO.email,
                        mailConfirmacion: response.contactoDTO.email,
                    });
                }
            })
    }

    const verificarInvitacion = () => {
        if (codigo) {
            AdministracionUsuarioService.obtenerInvitacionPendiente(codigo)
                .then(response => {
                    if (response.codigoRespuesta === "OK") {
                        setInvitacion(response);
                    } else {
                        setError({
                            error: true,
                            mensaje: "La invitación ya no es válida."
                        });
                    }
                })
                .catch(() => {
                    setError({
                        error: true,
                        mensaje: "La invitación ya no es válida."
                    });
                });
        }
    };

    const handleAceptar = () => {
        if (invitacion?.tipoContacto !== "TITULAR") {
            const { nombre, apellido, direccion, telefono, emailPublico, email } = contactoPredeterminado;
            if (!nombre || !apellido || !direccion || !telefono || !emailPublico || !email) {
                setError({
                    error: true,
                    mensaje: "Antes de continuar, por favor complete sus datos de contacto predeterminados. " +
                        "Diríjase a 'Mis datos' para verificar y actualizar la información necesaria."
                });
                return;
            }
        }
        AdministracionUsuarioService.aceptarInvitacionPendiente(invitacion.codigoInvitacion)
            .then(response => {
                if (response.data.codigoRespuesta === 'OK') {
                    setError({
                        error: false,
                        mensaje: ""
                    });
                    navigate('/administrar/mis-dominios', { state: { mensajeOk: { mostrarMensaje: true, mensaje: 'Invitación aceptada con éxito.' } } });
                } else {
                    setError({
                        error: true,
                        mensaje: "Error al aceptar la invitación. " + response.data.mensajeRespuesta
                    });
                }
            })
            .catch((error) => {
                setError({
                    error: true,
                    mensaje: "Error al aceptar la invitación."
                });
            });
    };

    const handleCancelar = () => {
        setIsOpenRechazarInvitacion(true);
    };

    if (error.error) {
        return <MensajeError mensajeError={error.mensaje} />;
    }

    if (!invitacion) {
        return <div><GestionAutenticacion /><strong>Cargando datos de la invitación...</strong></div>;
    }

    return (
        <>
            <div>
                <h2>Datos de invitación</h2>
                {mensajeOk !== undefined && mensajeOk.mostrarMensaje && <MensajeOk mensajeOk={mensajeOk.mensaje} />}

                <fieldset>
                    <legend>Datos de la invitación</legend>
                    <div
                        style={{ display: 'flex', marginBottom: '10px', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <label style={{ width: '250px', marginRight: '10px' }}>Dominio:</label>
                        <span>{invitacion?.nombreDominio}</span>
                    </div>
                    <div
                        style={{ display: 'flex', marginBottom: '10px', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <label style={{ width: '250px', marginRight: '10px' }}>Tipo Contacto:</label>
                        <span>{invitacion?.tipoContacto}</span>
                    </div>
                </fieldset>

                <ModalRechazarInvitacion
                    isOpen={isOpenRechazarInvitacion}
                    invitacion={invitacion}
                    setError={setError}
                    setMensajeOK={setMensajeOk}
                    handleOnClose={() => setIsOpenRechazarInvitacion(false)}
                />

                {invitacion?.tipoContacto !== "TITULAR" && (
                    <>
                        <fieldset>
                            <legend>Datos Públicos</legend>
                            <div>
                                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                                    <label style={{width: '250px'}}>Nombre: </label>
                                    <input
                                        type="text"
                                        value={contactoPredeterminado.nombre || ''}
                                        disabled={true}
                                        id="id-nombre"
                                        style={{width: '350px'}}
                                    />
                                </div>
                                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                                    <label style={{width: '250px'}}>Apellido: </label>
                                    <input
                                        type="text"
                                        value={contactoPredeterminado.apellido || ''}
                                        disabled={true}
                                        id="id-apellido"
                                        style={{width: '350px'}}
                                    />
                                </div>
                                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                                    <label style={{width: '250px'}}>Domicilio constituido en Uruguay: </label>
                                    <input
                                        type="text"
                                        value={contactoPredeterminado.direccion || ''}
                                        disabled={true}
                                        id="id-direccion"
                                        style={{width: '350px'}}
                                    />
                                </div>
                                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                                    <label style={{width: '250px'}}>Teléfono: </label>
                                    <input
                                        type="text"
                                        value={contactoPredeterminado.telefono || ''}
                                        disabled={true}
                                        id="id-telefono"
                                        style={{width: '350px'}}
                                    />
                                </div>
                                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                                    <label style={{width: '250px'}}>Correo electrónico público: </label>
                                    <input
                                        type="text"
                                        value={contactoPredeterminado.emailPublico || ''}
                                        disabled={true}
                                        id="id-mail-publico"
                                        style={{width: '350px'}}
                                    />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>Correo Electrónico para Notificaciones</legend>
                            <div>
                                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                                    <label style={{width: '250px'}}>Correo electrónico: </label>
                                    <input
                                        type="text"
                                        value={contactoPredeterminado.email || ''}
                                        disabled={true}
                                        id="id-mail"
                                        style={{width: '350px'}}
                                    />
                                </div>
                                <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                                    <label style={{width: '250px'}}>Confirmar correo electrónico: </label>
                                    <input
                                        type="text"
                                        value={contactoPredeterminado.email || ''}
                                        disabled={true}
                                        id="id-mail-confirmacion"
                                        style={{width: '350px'}}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </>
                )}

                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px'
                    }}
                >
                    <input
                        type="submit"
                        value="Aceptar Invitación"
                        id="aceptarInvitacion_0"
                        className="btn"
                        onClick={handleAceptar}
                    />
                    <input
                        type="submit"
                        value="Rechazar Invitación"
                        id="rechazarInvitacion_0"
                        className="btn"
                        onClick={handleCancelar}
                    />
                </form>
            </div>
        </>
    );
};

export default VerPaginaInvitacion;