import React from "react";

export const CarritoComprasPasos = (props) => {
    const pasoActual = props.paso;
    let pasos = ['cxf','cxf','cxf','cxf','cxf'];
    const tituloCarrito = props.tituloCarrito;
    const parrafoCarrito = props.parrafoCarrito;

    for (let index = 0; index < pasoActual; index++) {
        if (index + 1 === pasoActual) {
            if (pasoActual === 5) {
                pasos[index] = "cfx selected last-child-selected";
            } else {
                pasos[index] = "cfx selected";
            }
        } else if (index + 2 === pasoActual) {
            pasos[index] = "cfx previously-selected next-step-is-selected";
        } else if (index + 2 < pasoActual) {
            pasos[index] = "cfx previously-selected";
        }
    }

    return (
        <>
            <h2>{tituloCarrito}</h2>
            <div id="stepper">
                <ul className="cfx">
                    <li className={pasos[0]}>
                        <div className="step-box">
                            <div className="title">Mi carrito</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                    <li className={pasos[1]}>
                        <div className="step-box"> <div className="title">Registrar titular</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                    <li className={pasos[2]}>
                        <div className="step-box">
                            <div className="title">Forma de pago</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                    <li className={pasos[3]}>
                        <div className="step-box">
                            <div className="title">Resumen de la compra</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                    <li className={pasos[4] + " last-child"}>
                        <div className="step-box">
                            <div className="title">Confirmación de la compra</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                </ul>
            </div>
            <p>{parrafoCarrito}</p>
            <hr/>
        </>
    );
};