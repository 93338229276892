import usuarioService from "../services/UsuarioService";

import jwt_decode from "jwt-decode";
import terminosYCondicionesService from "../services/TerminosYCondicionesService";

const CARRITO_PASO_DOS = "/carrito-compra-paso-dos"; // Este va sin el /v2 pues se la url_post_login ya lo tiene y sino queda duplicado al volver de LUA
const CARRITO_PASO_UNO = "/carrito-compra-paso-uno";
const ERROR_AUTHORIZATION_PAGE = "/errorAuthorization";

// Implementamos el flujo de autenticacion haciendo que se devuelva un access_token directamente al cliente

var oidc_settings = {
    url_authority: process.env.REACT_APP_URL_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    url_post_login: process.env.REACT_APP_URL_POST_LOGIN,
    url_post_logout: process.env.REACT_APP_URL_POST_LOGOUT,
    url_first_login: process.env.REACT_APP_URL_FIRST_LOGIN,
    url_aceptar_tyc: '/aceptar-terminos-condiciones',
    response_type: 'id_token token',
    scope: 'openid profile email api'
}

let notLoggedIn = true;
let notValidated = true;

export function oidc_check_authentication() {
    const oidcState = getOidcState();

    if (!oidcState.is_authenticated || isTokenExpired(oidcState.expires_in)) {
        oidc_authorize();
    }
}

export function oidc_get_username() {
    if (getOidcState().is_authenticated && getOidcState().id_token != null) {
        return getOidcState().id_token["username"];
    }
    return "";
}

export function oidc_authorize(path_login_origin) {
    if (!path_login_origin || path_login_origin.includes(ERROR_AUTHORIZATION_PAGE)) {
        redirectToDefaultPage();
        return; // Aseguramos que no se ejecute nada mas
    }

    if (path_login_origin.startsWith("/")) {
        path_login_origin = path_login_origin.replace("/", "");
    }

    if (path_login_origin === CARRITO_PASO_UNO) {
        redirectToCarritoPaso2();
    } else {
        redirectToPage(path_login_origin);
    }
}

const redirectToCarritoPaso2 = () => {
    window.location.href = oidc_settings.url_authority + "/oidc/authorize?" +
        "client_id=" + oidc_settings.client_id +
        "&redirect_uri=" + oidc_settings.url_post_login + CARRITO_PASO_DOS +
        "&response_type=" + oidc_settings.response_type +
        "&scope=" + oidc_settings.scope;
}

const redirectToDefaultPage = () => {
    window.location.href = oidc_settings.url_authority + "/oidc/authorize?" +
        "client_id=" + oidc_settings.client_id +
        "&redirect_uri=" + oidc_settings.url_post_login +
        "&response_type=" + oidc_settings.response_type +
        "&scope=" + oidc_settings.scope;
}

const redirectToPage = (page) => {
    window.location.href = oidc_settings.url_authority + "/oidc/authorize?" +
        "client_id=" + oidc_settings.client_id +
        "&redirect_uri=" + oidc_settings.url_post_login + page +
        "&response_type=" + oidc_settings.response_type +
        "&scope=" + oidc_settings.scope;
}

export function oidc_authorize_callback(params_string) {
    const oidcState = getOidcState();

    if (oidcState.is_authenticated && !isTokenExpired(oidcState.expires_in)) {
        return;
    }

    let url_params = new URLSearchParams(params_string)

    if (url_params.has("#access_token")) {

        loadOidcStateFromParams(oidcState, url_params);
        cleanUrlParams(url_params);

    }
}

function isTokenExpired(exp) {
    const now = Math.floor(Date.now() / 1000);

    return now > exp;
}

export function oidc_logout() {

    cleanOidcState();

    let url_logout = oidc_settings.url_authority + "/logout?" +
        "service=" + oidc_settings.url_post_logout;

    window.location.href = url_logout;
}

export function setOidcState(oidcState){
    sessionStorage.setItem("oidc_login", JSON.stringify(oidcState));
}

export function getOidcState(){
    let oidc_state = null;
    if(sessionStorage.getItem("oidc_login") != null){
        oidc_state = JSON.parse(sessionStorage.getItem("oidc_login") );
    }

    if (oidc_state !== null && oidc_state.is_authenticated && notLoggedIn) {
        notLoggedIn = false;
        cargarUsuario();
    }

    if(oidc_state == null){
        oidc_state = {
            access_token: null,
            token_type: null,
            expires_in: null,
            id_token: null,
            is_authenticated: false
        };
        setOidcState(oidc_state);
    }

    return oidc_state;
}

export function cargarUsuario() {

    usuarioService.validarUsuarioLogeado().then(resultado => {
    });

    usuarioService.getUsuario()
        .then(async (response) => {
            if (response) {
                let debeCompletar = debeCompletarDatosContacto(response.usuarioDTO)
                let debeAceptar = await debeAceptarTerminosYCondiciones();

                notLoggedIn = debeAceptar || debeCompletar;

                if (debeCompletar && !enPath('/mis-datos-primer-login')) {
                    //cleanOidcState();
                    goToMisDatosPrimerLogin();
                } else {
                    debeAceptar = await debeAceptarTerminosYCondiciones();
                    if (debeAceptar && !enPath('/aceptar-terminos-condiciones') && !enPath('/mis-datos-primer-login')) {
                        goToAceptarTerminosYCondiciones();
                    }
                }
            }
        });
}

function enPath(path) {
    return window.location.pathname === path;
}

function debeCompletarDatosContacto(usuarioDTO) {
    //return false;
    return usuarioDTO == null || usuarioDTO.contactoPredeterminado == null;
}

function debeAceptarTerminosYCondiciones() {
    return terminosYCondicionesService.usuarioDebeAceptarTerminosYcondiciones() .then((response) => {
        if (response && response.respuestaOK){
            return !response.acepto;
        }
    }).catch(() => {
        return false;
    });
}

function goToMisDatosPrimerLogin(){
    window.location.assign("/mis-datos-primer-login?redirectTo="+window.location.pathname);
}

function goToAceptarTerminosYCondiciones(){
    window.location.assign("/aceptar-terminos-condiciones?redirectTo="+window.location.pathname);
}

function loadOidcStateFromParams(oidcState, url_params) {
    let oidc_state = oidcState;

    oidc_state.access_token = url_params.get("#access_token");
    oidc_state.token_type = url_params.get("token_type");
    oidc_state.expires_in = url_params.get("expires_in");

    let id_token = jwt_decode(url_params.get("id_token"));

    oidc_state.id_token = id_token;
    oidc_state.is_authenticated = true;

    setOidcState(oidc_state);
}

function cleanOidcState() {
    let oidc_state = getOidcState();
    oidc_state.access_token = null;
    oidc_state.token_type = null;
    oidc_state.expires_in = null;
    oidc_state.id_token = null;
    oidc_state.is_authenticated = false;

    setOidcState(oidc_state);
}

function cleanUrlParams(url_params) {
    url_params.delete("token_type")
    url_params.delete("expires_in")
    url_params.delete("id_token")
    url_params.delete("#access_token")

    window.location.hash=url_params.toString()
}
