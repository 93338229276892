import React, {useEffect, useState} from 'react';
import "./css/App.css";

import {oidc_authorize_callback} from "./security/OidcManager";

import AppProvider from "./store/AppProvider";
import {Navigate, useLocation, useNavigate, useRoutes} from "react-router-dom";
import * as HttpService from "./support/HttpService";
import TheLayout from "./containers/TheLayout";
import {ConsultaWhois} from "./view/consultaWhois/ConsultaWhois";
import {TransferirTitular} from "./view/transferirTitular/TransferirTitular";
import {ConsultaDisponibilidadContent} from "./view/consultaDisponibilidad/ConsultaDisponibilidadContent";
import {NotFound} from "./view/NotFound";
import UnAuthorized from "./view/UnAuthorized";
import {
    DetalleTransferencia
} from "./view/transferenciaRegistrador/administrarSolicitudesTransferencia/DetalleTransferencia";
import {ConfirmarTransferencia} from "./view/transferenciaRegistrador/confirmarTransferencia";
import {Contacto} from "./view/contacto/Contacto";
import {CarritoComprasPasoUno} from "./view/carrito/paso_01/CarritoComprasPasoUno";
import {CarritoComprasPasoDos} from "./view/carrito/paso_02/CarritoComprasPasoDos";
import {CarritoComprasPasoTres} from "./view/carrito/paso_03/CarritoComprasPasoTres";
import {CarritoComprasPasoCuatro} from "./view/carrito/paso_04/CarritoComprasPasoCuatro";
import {CarritoCompraPasoCinco} from "./view/carrito/paso_05/CarritoComprasPasoCinco";
import {ErrorServidor} from "./support/ErrorServidor";
import {MisDominios} from "./view/administrar/misDominios/MisDominios";
import {MisMensajes} from "./view/administrar/misMensajes/MisMensajes";
import {MisDatos} from "./view/administrar/misDatos/MisDatos";
import {VerTerminosCondiciones} from "./view/terminosCondiciones/VerTerminosCondiciones";
import HomeTabTAR from "./view/transferenciaRegistrador/HomeTabTAR";
import Confirmacion from "./view/transferenciaRegistrador/solicitarTransferencia/paso5/Confirmacion";
import transferenciaRegistradorService from "./services/TransferenciaRegistradorService";
import {VerDominio} from "./view/administrar/misDominios/VerDominio";
import {ResumenUsuario} from "./view/administrar/resumen/ResumenUsuario";
import VerPaginaInvitacion from './view/administrar/invitacion/verPaginaInvitacion';
import {Ayuda} from "./view/ayuda/Ayuda";
import {IrVerDominioTar} from "./view/administrar/misDominios/IrVerDominioTar";
import {AceptarTerminosCondiciones} from "./view/terminosCondiciones/AceptarTerminosCondiciones";
import {MisDatosPrimerLogin} from "./view/primerlogin/MisDatosPrimerLogin";
import {GenerarTicket} from "./view/tickets/GenerarTicket";

import { VentaProvider } from "./view/carrito/VentaContext";

const App = () => {
    const [tarEnabled, setTarEnabled] = useState(false);

    HttpService.history.navigate = useNavigate();
    HttpService.history.location = useLocation();

    // Siempre intentamos procesar los parametros de la URL, para ver si son de LUA
    oidc_authorize_callback(window.location.hash);

    useEffect(() => {
        isTAREnabled();
    }, []);

    const isTAREnabled = () =>
    {
        transferenciaRegistradorService.isTAREnabled().then(response => {
            setTarEnabled(response.code === 'true');
        }).catch(ignoreError => { // Por el momento no hacemos nada con esto
        });
    }

    const Router = () => {
        const routes =[
            {path: '/', element: <Navigate replace to="/index" />},
            {path: '/index', element: <Navigate replace to="/consulta-disponibilidad"/>},

            // Consulta disponibilidad y whois
            {path: '/consulta-whois', element: <TheLayout><ConsultaWhois/></TheLayout>},
            {path: '/consulta-disponibilidad', element: <TheLayout><ConsultaDisponibilidadContent/></TheLayout>},

            // Transferencia Titular
            {path: '/transferir-titular', element: <TheLayout><TransferirTitular/></TheLayout>},

            // Carrito Compra
            {path: '/carrito-compra-paso-uno', element: <TheLayout><CarritoComprasPasoUno/></TheLayout>},
            {path: '/carrito-compra-paso-dos', element: <TheLayout><CarritoComprasPasoDos/></TheLayout>},
            {path: '/carrito-compra-paso-tres', element: <TheLayout><CarritoComprasPasoTres/></TheLayout>},
            {path: '/carrito-compra-paso-cuatro', element: <TheLayout><CarritoComprasPasoCuatro/></TheLayout>},
            {path: '/carrito-compra-paso-cinco', element: <TheLayout><CarritoCompraPasoCinco/></TheLayout>},

            // Administracion
            {path: '/administrar/resumen', element: <TheLayout><ResumenUsuario/></TheLayout>},
            {path: '/administrar/mis-dominios', element: <TheLayout><MisDominios/></TheLayout>},
            {path: '/administrar/ver-dominio', element: <TheLayout><VerDominio/></TheLayout>},
            {path: '/administrar/tar-ver-dominio', element: <TheLayout><IrVerDominioTar/></TheLayout>},
            {path: '/administrar/accederPaginaInvitacion', element: <TheLayout><VerPaginaInvitacion/></TheLayout>},
            {path: '/administrar/mensajes', element: <TheLayout><MisMensajes/></TheLayout>},
            {path: '/administrar/mis-datos', element: <TheLayout><MisDatos/></TheLayout>},

            {path: '/mis-datos-primer-login', element: <TheLayout><MisDatosPrimerLogin/></TheLayout>},
            {path: '/aceptar-terminos-condiciones', element: <TheLayout><AceptarTerminosCondiciones/></TheLayout>},

            // Navegacion Publica
            {path: '/terminos-condiciones', element: <TheLayout><VerTerminosCondiciones/></TheLayout>},
            {path: '/ayuda', element: <TheLayout><Ayuda/></TheLayout>},
            {path: '/contacto', element: <TheLayout><Contacto/></TheLayout>},
            {path: '/generar-ticket', element: <TheLayout><GenerarTicket/></TheLayout>},

            // Error y defaults
            {path: '/errorServidor', element: <TheLayout><ErrorServidor/></TheLayout>},
            // "/v2/errorAuthorization" se utiliza en OidcManager.js. Si se cambia el valor del path, debe actualizarse también ahí
            {path: '/errorAuthorization', element: <TheLayout><UnAuthorized/></TheLayout>},
            {path: '*', element: <TheLayout><NotFound/></TheLayout>}
        ];

        if (tarEnabled) {
            // TAR
            routes.push({path: '/transferir-dominio', element: <TheLayout><HomeTabTAR/></TheLayout>});
            routes.push({path: '/transferir-dominio-confirmacion', element: <TheLayout><Confirmacion/></TheLayout>});
            routes.push({path: '/confirmarTransferencia/:nombreDominio', element: <TheLayout><ConfirmarTransferencia/></TheLayout>});
            routes.push({path: '/transferir-dominio/lista-solicitudes', element: <TheLayout><HomeTabTAR/></TheLayout>});
            routes.push({path: '/detalle-transferencia', element:<TheLayout><DetalleTransferencia/></TheLayout>});
        }

        return useRoutes(routes);
    };

    return (
        <VentaProvider>
            <AppProvider>
                <div className="App container" translate={"no"}>
                    <Router />
                </div>
            </AppProvider>
        </VentaProvider>
    );
}

export default App;
