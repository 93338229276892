import React, { useEffect, useState } from 'react'
import MensajesService from "../../../services/MensajesService";
import {getOidcState} from "../../../security/OidcManager";
import {SidebarComponent} from "../../../components/sidebar/SidebarComponent";
import {ListadoMensajes} from "../../../components/mensajes/ListadoMensajes";
import {ValidacionAutenticado} from "../../../components/validaciones/ValidacionAutenticado";
import FeedbackSpinner from "../../../components/feedback/FeedbackSpinner";

export const MisMensajes = () => {

    const [mensajes, setMensajes] = useState([]);
    const [mensajesTotal, setMensajesTotal] = useState(0);
    const [mostrarMensajes, setMostrarMensajes] = useState(true);
    const [mensajeRespuesta, setMensajeRespuesta] = useState('');

    const defaultPage = 0;
    const defaultSizePage = 10;

    const [isProcesando, setIsProcesando] = useState(true);

    function buscarMensajes(nrPage, size) {
        setIsProcesando(true);
        MensajesService.obtenerMensajesUsuario(nrPage, size)
            .then((response) => {
                if (response.codigo === 'OK' && response.mensajes.length > 0) {
                    setMensajes(response.mensajes);
                    setMensajesTotal(response.totalCount);
                    setMostrarMensajes(true);
                } else {
                    setMensajeRespuesta(response.mensaje);
                    setMostrarMensajes(false);
                    setMensajes([]);
                    setMensajesTotal(0);
                }
            })
            .catch(error => {
            });
        setIsProcesando(false);

    }

    useEffect(() => {
        if (!getOidcState().is_authenticated){
            setMostrarMensajes(false);
            return;
        }
        buscarMensajes(defaultPage, defaultSizePage);
    }, []);

    function handleChangePage(nroPage, size){
        buscarMensajes(nroPage, size);
    }

    return <>
        <ValidacionAutenticado/>
        <h2>Mis mensajes</h2>

        { isProcesando?
            <div>
                <FeedbackSpinner open={isProcesando} message={'Buscando mensajes...'}/>
            </div> : <></>
        }

        <div id="idMisMensajes" className="fluid">
            <div className="inner-block">
                { mostrarMensajes &&
                    mensajes !== undefined &&
                    <div className="box">
                    <ListadoMensajes
                        mensajes={mensajes}
                        setMensajes={setMensajes}
                        mensajesTotal={mensajesTotal}
                        handleChangePage={handleChangePage}
                        paginador={true}
                        defaultPage={defaultPage}
                        defaultSizePage={defaultSizePage}/>
                    </div>
                }
                { !mostrarMensajes &&
                    mensajeRespuesta !== '' &&
                    <div className="box-content">
                        <p>{ mensajeRespuesta }</p>
                    </div>
                }
            </div>
        </div>
        <SidebarComponent/>
    </>
}
