import http from '../support/HttpService';
import {
    buildAccionDominioResponse,
    buildEditarContactoDominioRequest,
    buildEditarCuentaDominioRequest,
    buildEliminarCuentaDominioRequest,
    buildObtenerDominiosUsuarioResponse,
    buildObtenerPermisosDominiosRequest,
    buildObtenerPermisosDominiosResponse,
    buildObtenerResumenUsuarioResponse,
    buildEInvitacionCambiarContactoRequest,
    buildObtenerInvitacionPendienteRequest,
    buildobtenerInvitacionPendienteResponse,
    buildAccionRenovarDominioResponse,
    buildHistorialDominioFormaPagoRequest,
    buildHistorialDominioFormaPagoResponse
} from "../dto/AdministracionUsuarioDTOBuilder";

const REACT_APP_USUARIO_ADMINISTRACION = process.env.REACT_APP_USUARIO_ADMINISTRACION;
const REACT_APP_DOMINIOS_USUARIO_ADMINISTRACION = process.env.REACT_APP_DOMINIOS_USUARIO_ADMINISTRACION;
const REACT_APP_PERMISOS_DOMINIO_ADMINISTRACION = process.env.REACT_APP_PERMISOS_DOMINIO_ADMINISTRACION;
const REACT_APP_EDITAR_CONTACTO_DOMINIO_ADMINISTRACION = process.env.REACT_APP_EDITAR_CONTACTO_DOMINIO_ADMINISTRACION;
const REACT_APP_EDITAR_CUENTA_DOMINIO_ADMINISTRACION = process.env.REACT_APP_EDITAR_CUENTA_DOMINIO_ADMINISTRACION;
const REACT_APP_ELIMINAR_CUENTA_DOMINIO_ADMINISTRACION = process.env.REACT_APP_ELIMINAR_CUENTA_DOMINIO_ADMINISTRACION;
const REACT_APP_OBTENER_MENSAJES_ACCIONES_DOMINIO = process.env.REACT_APP_OBTENER_MENSAJES_ACCIONES_DOMINIO;
const REACT_APP_OBTENER_INFORMACION_DOMINIO = process.env.REACT_APP_OBTENER_INFORMACION_DOMINIO;
const REACT_APP_OBTENER_INFORMACION_REGISTROS_DOMINIO = process.env.REACT_APP_OBTENER_INFORMACION_REGISTROS_DOMINIO;
const REACT_APP_MODIFICAR_REGISTRO_DOMINIO = process.env.REACT_APP_MODIFICAR_REGISTRO_DOMINIO;
const REACT_APP_INVITAR_CONTACTO_DOMINIO = process.env.REACT_APP_INVITAR_CONTACTO_DOMINIO;
const REACT_APP_OBTENER_INVITACION_PENDIENTE = process.env.REACT_APP_OBTENER_INVITACION_PENDIENTE;
const REACT_APP_CANCELAR_INVITACION_PENDIENTE = process.env.REACT_APP_CANCELAR_INVITACION_PENDIENTE;
const REACT_APP_ACEPTAR_INVITACION_PENDIENTE = process.env.REACT_APP_ACEPTAR_INVITACION_PENDIENTE;
const REACT_APP_ELIMINAR_REGISTRO_DOMINIO = process.env.REACT_APP_ELIMINAR_REG_DOMINIO;
const REACT_APP_HISTORIAL_PAGOS_DOMINIO = process.env.REACT_APP_HISTORIAL_PAGOS_DOMINIO;
const REACT_APP_TRANSFERIR_TITULAR = process.env.REACT_APP_TRANSFERIR_TITULAR;


const AdministracionUsuarioService = {

    obtenerResumenUsuario: async function() {
        try {
            const response = await http.get(REACT_APP_USUARIO_ADMINISTRACION + "/resumen");
            return buildObtenerResumenUsuarioResponse(response.data);
        } catch (error) {
        }
    },

    alojarDominio: async function(alojarDominioDTO) {
        try {
            const response = await http.post(REACT_APP_USUARIO_ADMINISTRACION + "/dominio/alojar", alojarDominioDTO);
            return buildAccionDominioResponse(response.data);
        } catch (error) {
            console.log("Error al alojar el dominio");
        }
    },

    delegarDominio: async function(delegarDominioDTO) {
        try {
            const response = await http.post(REACT_APP_USUARIO_ADMINISTRACION + "/dominio/delegar", delegarDominioDTO);
            return buildAccionDominioResponse(response.data);
        } catch (error) {
            console.log("Error al delegar el dominio");
        }
    },

    liberarDominio: async function(accionDominioDTO) {
        try {
            const response = await http.post(REACT_APP_USUARIO_ADMINISTRACION + "/dominio/liberar", accionDominioDTO);
            return buildAccionDominioResponse(response.data);
        } catch (error) {
            console.log("Error al liberar el dominio");
        }
    },

    eliminarDominio: async function(accionDominioDTO) {
        try {
            const response = await http.post(REACT_APP_USUARIO_ADMINISTRACION + "/dominio/eliminar", accionDominioDTO);
            return buildAccionDominioResponse(response.data);
        } catch (error) {
            console.log("Error al eliminar el dominio");
        }
    },

    recuperarDominio: async function(accionDominioDTO) {
        try {
            const response = await http.post(REACT_APP_USUARIO_ADMINISTRACION + "/dominio/recuperar", accionDominioDTO);
            return buildAccionDominioResponse(response.data);
        } catch (error) {
            console.log("Error al recuperar el dominio");
        }
    },

    renovarDominio: async function(accionDominioDTO) {
        try {
            const response = await http.post(REACT_APP_USUARIO_ADMINISTRACION + "/dominio/renovar", accionDominioDTO);
            return buildAccionRenovarDominioResponse(response.data);
        } catch (error) {
            console.log("Error al renovar el dominio");
        }
    },


    obtenerDominiosUsuario: async function(filtroDominioDTO) {
        try {
            const response = await http.post(REACT_APP_DOMINIOS_USUARIO_ADMINISTRACION, filtroDominioDTO);
            return buildObtenerDominiosUsuarioResponse(response.data);
        } catch (error) {
            console.log("Error al obtener dominios del usuario");
        }
    },

    obtenerPermisosDominio: async function(permisosDominio, dominiosSeleccionados, rolSeleccionado, operacion) {
        try {
            const response = await http.post(REACT_APP_PERMISOS_DOMINIO_ADMINISTRACION, buildObtenerPermisosDominiosRequest(permisosDominio, dominiosSeleccionados, rolSeleccionado, operacion));
            return buildObtenerPermisosDominiosResponse(response.data);
        } catch (error) {
            console.log("Error al obtener los permisos del dominio del usuario");
        }
    },

    editarContactoDominio: async function(nombresDominio, tipoContacto, datosContactoDTO) {
        try {
            const response = await http.post(REACT_APP_EDITAR_CONTACTO_DOMINIO_ADMINISTRACION, buildEditarContactoDominioRequest(nombresDominio, tipoContacto, true, datosContactoDTO));
            return response.data;
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    transferirTitularDominio: async function(transferirTitularDominioDTO) {
        try {
            const response = await http.post(REACT_APP_TRANSFERIR_TITULAR, transferirTitularDominioDTO);
            return response.data;
        } catch (error) {
            console.error("Error procesando pedido transferirTitularDominio", error);
            throw error;
        }
    },

    invitacionCambiarContacto: async function(nombreDominio, correo, tipoContacto) {
        try {
            const response = await http.post(REACT_APP_INVITAR_CONTACTO_DOMINIO, buildEInvitacionCambiarContactoRequest(nombreDominio, correo, tipoContacto));
            return response.data;
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    obtenerInvitacionPendiente: async function(codigo) {
        try {
            const response = await http.post(REACT_APP_OBTENER_INVITACION_PENDIENTE, buildObtenerInvitacionPendienteRequest(codigo));
            return buildobtenerInvitacionPendienteResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    cancelarInvitacionPendiente: async function(codigo) {
        try {
            const response = await http.post(REACT_APP_CANCELAR_INVITACION_PENDIENTE, buildObtenerInvitacionPendienteRequest(codigo));
            return response;
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    aceptarInvitacionPendiente: async function(codigo) {
        try {
            const response = await http.post(REACT_APP_ACEPTAR_INVITACION_PENDIENTE, buildObtenerInvitacionPendienteRequest(codigo));
            return response;
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    editarCuentaDominio: async function(nombresDominio, tipoContacto, TemplateCuentaPago) {
        try {
            const response = await http.post(REACT_APP_EDITAR_CUENTA_DOMINIO_ADMINISTRACION, buildEditarCuentaDominioRequest(nombresDominio, tipoContacto, TemplateCuentaPago));
            return response.data;
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    eliminarFormaPago: async function(nombresDominio) {
        try {
            const response = await http.post(REACT_APP_ELIMINAR_CUENTA_DOMINIO_ADMINISTRACION, buildEliminarCuentaDominioRequest(nombresDominio));
            return response.data;
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    obtenerHistorialFormaPago: async function(nombresDominio) {
        try {
            const response = await http.post(REACT_APP_HISTORIAL_PAGOS_DOMINIO, buildHistorialDominioFormaPagoRequest(nombresDominio));
            return buildHistorialDominioFormaPagoResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    obtenerMensajesAccionesAdministracion: async function() {
        try {
            const response = await http.get(REACT_APP_OBTENER_MENSAJES_ACCIONES_DOMINIO);
            return response.data;
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    obtenerInformacionDominio: async function(request) {
        try {
            const response = await http.post(REACT_APP_OBTENER_INFORMACION_DOMINIO, request);
            return response.data;
        } catch (error) {
            console.error("Error obteniendo informacion del dominio");
        }
    },

    obtenerInformacionRegistrosDominio: async function(request) {
        try {
            const response = await http.post(REACT_APP_OBTENER_INFORMACION_REGISTROS_DOMINIO, request);
            return response.data;
        } catch (error) {
            console.error("Error obteniendo informacion de los registros del dominio");
        }
    },

    modificarRegistroDominio: async function(request) {
        try {
            const response = await http.post(REACT_APP_MODIFICAR_REGISTRO_DOMINIO, request);
            return response.data;
        } catch (error) {
            console.error("Error modificando registro del dominio");
        }
    },

    eliminarRegistroDominio: async function(request) {
        try {
            const response = await http.post(REACT_APP_ELIMINAR_REGISTRO_DOMINIO, request);
            return response.data;
        } catch (error) {
            console.error("Error modificando registro del dominio");
        }
    },

    isTransferenciaTitularEnabled: async function () {
        try {
            const response = await http.get(REACT_APP_USUARIO_ADMINISTRACION + "/is-transferencia-titular-enabled");
            return response.data;
        } catch (error) {
            console.error(JSON.stringify(error));
            throw error;
        }
    }

}
export default AdministracionUsuarioService;
