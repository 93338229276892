import React from "react";
import Modal from "../../../Modal"; // Asumiendo que tienes un componente Modal común

export const ModalConfirmarEliminarRegistro = ({ open, handleClose, handleEliminarRegistro }) => {
    return (
        <Modal isOpen={open} handleClose={handleClose} closeOnClickOutside={false}>
            <fieldset>
                <legend>Eliminar registro</legend>
                <p>¿Confirma que desea eliminar el registro?</p>
            <br></br>
                <div className="form-item">
                    <button className="btn" onClick={handleEliminarRegistro}>Eliminar</button>
                    <button className="btn" onClick={handleClose}>Cancelar</button>
                </div>
            </fieldset>
        </Modal>
    );
}
