export function buildAgregarRegistroDominio(registrarDominios, ventaDto) {
    let ventaUuid = null;
    if (ventaDto.uuidVenta !== undefined && ventaDto.uuidVenta !== ''){
        ventaUuid = ventaDto.uuidVenta;
    }
    return {
        dominios: registrarDominios,
        uuidVenta : ventaUuid
    }
}

export function buildAgregarRegistroDominioResponse(responseAgregarRegistroDominio) {
    return {
        ventaDTO : responseAgregarRegistroDominio.ventaDTO,
        dominiosOK : responseAgregarRegistroDominio.dominiosOK,
        dominiosError : responseAgregarRegistroDominio.dominiosError,
        respuestaOK: responseAgregarRegistroDominio.respuestaOK,
        codigo: responseAgregarRegistroDominio.codigoRespuesta,
        mensaje: responseAgregarRegistroDominio.mensajeRespuesta
    }
}