import React from 'react';
import {BrowserRouter, createBrowserRouter, Navigate} from "react-router-dom"
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createRoot} from "react-dom/client"
import "./css/style.css";
import TheLayout from "./containers/TheLayout";
import {ConsultaDisponibilidadContent} from "./view/consultaDisponibilidad/ConsultaDisponibilidadContent";
import {ConsultaWhois} from "./view/consultaWhois/ConsultaWhois";

// Inicializamos la interfaz
const container = document.getElementById('root');
const root = createRoot(container);

const router = createBrowserRouter([
    {path: '/', element: <Navigate replace to="/index" />},
    {path: '/index', element: <Navigate replace to="/consulta-disponibilidad"/>},
    {path: '/consulta-disponibilidad', element: <TheLayout><ConsultaDisponibilidadContent/></TheLayout>},
    {path: '/consulta-whois', element: <TheLayout><ConsultaWhois/></TheLayout>},
]);

root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>

    /*<RouterProvider router={router}>
        <App/>
    </RouterProvider>*/
    /*<App/>*/
);

serviceWorker.unregister();
