import React, {useEffect, useState} from 'react'
import Modal from "../../../Modal";
import {MensajeError} from "../../../../view/MensajeError";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";

export const ModalDelegarDominio = ({ nombreDominio, infoDominio, handleOnClose, isOpen, irVerDominio }) => {

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    const tiposIP = ["IPV4", "IPV6"];
    const [nameservers, setNameservers] = useState([]);

    function cargarNameservers() {
        let servers = [];
        for (let i = 1; i <= infoDominio.cantMinimaNameservers; i++){
            let nsNuevo = {
                index: i,
                subdominio: false,
                nombre: "",
                tipoIP: "IPV4",
                ip: ""
            };
            nsNuevo.index = i;
            servers.push(nsNuevo);
        }
        return servers;
    }

    useEffect(() => {
        limpiarDatos();
    }, [infoDominio]);

    function limpiarDatos() {
        setError({error : false, mensaje: "Sin errores"})
        let servers = cargarNameservers();
        setNameservers(servers)
    }

    function limpiarCerrar() {
        limpiarDatos();
        handleOnClose();
    }

    const delegarDominio = (e) => {
        e.preventDefault();
        const nombres = new Set();
        const tieneDuplicados = nameservers.some(ns => {
            const nombre = document.getElementById(`id-subdominio-nombre-${ns.index}`).value;
            if (nombres.has(nombre)) {
                return true;
            }
            nombres.add(nombre);
            return false;
        });

        if (tieneDuplicados) {
            setError({
                error: true,
                mensaje: "No se puede delegar un dominio con nombres duplicados."
            });
            return;
        }
        let delegarDominioDTO = {
            nombreDominio : nombreDominio,
            nameservers : nameservers,
            cantMinimaNameservers : infoDominio.cantMinimaNameservers
        };
        setError({error : false, mensaje: "Sin errores"})
        AdministracionUsuarioService.delegarDominio(delegarDominioDTO)
            .then(response => {
                if (!response.esRespOK) {
                    setError({
                        error: true,
                        mensaje: response.mensaje
                    })
                } else {
                    let mensajeOk = {
                        mostrarMensaje: true,
                        mensaje : response.mensaje
                    }
                    irVerDominio(mensajeOk, nombreDominio)
                    limpiarCerrar();
                }
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al delegar el dominio."
                })
            })
    }

    function isChecked(ns) {
        return ns.subdominio;
    }

    const handleClickCheck = (event) => {
        let idNs = event.target.id;
        let nsaux = nameservers.map(ns => {
            if ("id-chk-subdominio" + ns.index === idNs){
                ns.subdominio = event.target.checked;
            }
            return ns;
        });
        setNameservers(nsaux);
    }

    const handleOnChangeName = (event) => {
        let idNs = event.target.id;
        let nsaux = nameservers.map(ns => {
            if ("id-subdominio-nombre-" + ns.index === idNs){
                ns.nombre = event.target.value;
            }
            return ns;
        });
        setNameservers(nsaux);
    }

    const handleOnChangeIP = (event) => {
        let idNs = event.target.id;
        let nsaux = nameservers.map(ns => {
            if ("id-subdominio-ip-" + ns.index === idNs){
                ns.ip = event.target.value;
            }
            return ns;
        });
        setNameservers(nsaux);
    }

    const handleOnChangeTipoIP = (event) => {
        let idNs = event.target.id;
        let nsaux = nameservers.map(ns => {
            if ("id-subdominio-tipo-ip-" + ns.index === idNs){
                ns.tipoIP = event.target.value;
            }
            return ns;
        });
        setNameservers(nsaux);
    }

    return <>
        <Modal
            idDivModal="idModalDelegar"
            handleClose={limpiarCerrar} isOpen={isOpen} closeOnClickOutside={false}>
            <fieldset id="id-fieldset-delegar">
                <legend>Delegar dominio</legend>

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <div id="div-fieldset-delegar-dominio">
                    <form id="id-form-delegar-dominio">
                        {
                            nameservers !== undefined && nameservers.map((ns, index) => (

                                <fieldset key={'id-ns-'+index}>

                                    <legend> {"Servidor de nombres " + ns.index}</legend>

                                    <div className="form-item">
                                        <label htmlFor={"id-chk-subdominio" + ns.index}>Subdominio</label>
                                        <input id={"id-chk-subdominio" + ns.index} className={"checkbox-space"}
                                               type="checkbox" onChange={handleClickCheck}/>
                                        <p className="note" style={{marginLeft: "0px"}}>Marcar esta opci&oacute;n si el
                                            nombre del servidor de nombres es un subdominio
                                            de <strong>.{nombreDominio}</strong></p>
                                    </div>

                                    <div className="form-item">
                                        <label htmlFor={"id-svrname-" + ns.index}>Nombre</label>
                                        <input type={"text"} id={"id-subdominio-nombre-" + ns.index}
                                               onChange={handleOnChangeName}/>
                                        <p id={"id-subdominio-show-nombre-dominio-" + index}
                                           style={{display: "inline-block"}}>{isChecked(ns) &&
                                            <label><strong>.{nombreDominio}</strong></label>}</p>
                                        <p className="note">Nombre de dominio del servidor que responder&aacute; por el
                                            dominio</p>
                                    </div>

                                    {ns.subdominio &&
                                        <>
                                            <div key={index} id={"id-subdominio-show-tipo-ip-" + ns.index}
                                                 className="form-item">
                                                <label htmlFor={"id-subdominio-tipo-ip-" + ns.index}>Tipo IP</label>
                                                <select onChange={handleOnChangeTipoIP}
                                                        id={"id-subdominio-tipo-ip-" + ns.index}>
                                                    {tiposIP.map(tipoIP =>
                                                        <option key={tipoIP + ns.index} id={tipoIP + ns.index}
                                                                value={tipoIP}>{tipoIP} </option>
                                                    )}
                                                </select>

                                            </div>

                                            <div id={"id-subdominio-show-ip-" + ns.index} className="form-item">
                                                <label htmlFor={"id-subdominio-ip-" + ns.index}>IP</label>
                                                <input type={"text"} id={"id-subdominio-ip-" + ns.index} value={ns.ip}
                                                       name="ip" onChange={handleOnChangeIP}/>
                                            </div>
                                        </>
                                    }

                                </fieldset>
                            ))

                        }

                        <div className="right">
                            <button className="btn" onClick={delegarDominio}>Delegar dominio</button>
                        </div>

                    </form>
                </div>
                <br/>
                <div id="id-message" style={{
                    margin: "20px 0",
                    padding: "10px",
                    backgroundColor: "#d1ecf1", color: "#0c5460",
                    border: "1px solid #bee5eb", borderRadius: "4px",
                    width: "600px"
                }}>
                    <strong>Información:</strong> Para los registros CNAME, MX y NS, añade un punto al final para indicar
                    un nombre de dominio completo (FQDN). Sin este punto, se añadirá el nombre de tu dominio al valor de
                    tu registro.
                    Por favor, verifica el resultado de la configuración antes de finalizar la edición del registro.
                </div>
            </fieldset>
        </Modal>
    </>
}