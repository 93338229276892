import React, {useEffect, useState} from 'react'
import MensajesService from "../../services/MensajesService";
import {Link} from "react-router-dom";
import {ModalMensaje} from "./ModalMensaje";
import Paginador from "../tabla/Paginador";

export const MensajesGlobales = (props) => {

    const [mensajes, setMensajes] = useState([]);
    const [mensajesTotal, setMensajesTotal] = useState(0);
    const [mostrarMensajes, setMostrarMensajes] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [mensaje, setMensaje] = useState([]);
    const defaultPage = 0;
    const defaultSizePage = 10;

    function buscarMensajes(nrPage, size) {
        MensajesService.obtenerMensajesGlobales(nrPage, size)
            .then((response) => {
                if (response.codigo === 'OK' && response.mensajes.length > 0) {
                    setMensajes(response.mensajes);
                    setMensajesTotal(response.totalCount);
                    setMostrarMensajes(true);
                } else {
                    setMostrarMensajes(false);
                    setMensajesTotal(0);
                    setMensaje(response.mensaje);
                }
            }).catch((error) => {
            setMostrarMensajes(false);
            console.log("Error obteniendo los mensajes globales");
        });
    }

    useEffect( () => {
        buscarMensajes(defaultPage, defaultSizePage);
    }, []);

    function handleChangePage(nroPage, size){
        buscarMensajes(nroPage, size);
    }

    const handleVerMensaje = (event) => {
        event.preventDefault();
        const idMensaje = event.target.id;
        setIsOpen(true);
        setMensaje(mensajes.find(msg => msg.id === +idMensaje));
    }

    return <>

        { mostrarMensajes &&
            mensajes !== undefined &&
            <div id="idMensajesGlobales" className="box">

                <ModalMensaje handleOnClose={()=>{setIsOpen(false)}} isOpen={isOpen} mensaje={mensaje}/>

                <div className="top">
                    <h4>Mensajes</h4>
                </div>

                <div className="box-content cfx" id="id-div-mensajes">
                    <table id="id-tabla-mensajes-globales">
                        <tbody id="id-tbody-mensajes">
                            { mensajes.map((mensaje, index) => (
                                <tr className={"msg cfx " + (mensaje.prioridad === "ALTA" ? "red" : mensaje.prioridad === "MEDIA" ? "yellow" : "green")}
                                    key={mensaje.id}>

                                    <td className="left"><Link to={{ pathname: "/mensajes-globales",
                                                                    state: { "idMensaje" : mensaje.id}
                                                                }}
                                                               id={mensaje.id}
                                                               onClick={handleVerMensaje}>{ mensaje.titulo}</Link></td>
                                    <td className="right">{ mensaje.fechaDesdeString }</td>
                                </tr>

                            ))}
                        </tbody>
                    </table>

                </div>

                <Paginador
                    handleChangePage={handleChangePage}
                    page2={defaultPage}
                    sizePage2={defaultSizePage}
                    totalRecords={mensajesTotal}
                    mostrarCantElemXPag={false}
                    cantElemXPagMap={[5,10,25]}
                    />

            </div>


        }
    </>
}