import React, {useEffect, useState} from 'react'
import Modal from "../../../Modal";
import {MensajeError} from "../../../../view/MensajeError";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {MensajeOk } from "../../../../view/MensajeOk";
import {ModalEditarRegistroDominio } from "./ModalEditarRegistroDominio";
import {ModalAgregarRegistroDominio } from "./ModalAgregarRegistroDominio";
import { ModalConfirmarEliminarRegistro } from "./ModalConfirmarEliminarRegistro";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const ModalConfiguracionAvanzada = ({ dominio, nombreDominio, registros, tiposRegistro, estructurasRegistros, handleOnClose, isOpen, actualizarInformacionRegistros, mensaje }) => {

    const [isOpenEditarRegistroDominio, setIsOpenEditarRegistroDominio] = useState(false);
    const [isOpenCrearRegistroDominio, setIsOpenCrearRegistroDominio] = useState(false);
    const [isOpenConfirmarEliminarRegistro, setIsOpenConfirmarEliminarRegistro] = useState(false);

    const [registrosPorTipo, setRegistrosPorTipo] = useState({});
    const [registroEditar, setRegistroEditar] = useState(null);
    const [registroEliminar, setRegistroEliminar] = useState(null);
    const [camposRegistroEditar, setCamposRegistroEditar] = useState(null);
    const [estructuraRegistro, setEstructuraRegistro] = useState(null);
    const [nuevoTipoRegistro, setNuevoTipoRegistro] = useState(null);
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [storedRegistros, setStoredRegistros] = useState([]);

    const handleCopy = (index) => {
        setCopiedIndex(index); // Set the copied index to the current row
        setTimeout(() => setCopiedIndex(null), 1500);
    };

    const [mensajeOk, setMensajeOk] = useState({
        mostrarMensaje: false,
        mensaje: ''
    });

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    useEffect(() => {
        if (registros && Array.isArray(registros)) {
            agruparRegistros();
        }
    }, [registros]);

    useEffect(() => {
        if (registros && Array.isArray(registros)) {
            setStoredRegistros(registros);
        }
    }, [registros]);

    useEffect(() => {
        setMensajeOk(mensaje)
    }, [mensaje]);

    const handleIrEditarRegistro = (registro) => {
        let estructuraRegistro = estructurasRegistros[registro.tipo];
        let camposEstructura = estructuraRegistro.estructuraCampos.map( ec => {
            return {
                etiqueta : ec.etiqueta,
                nombre : ec.nombre,
                descripcion : ec.descripcion,
                valor : valorCampoRegistro(registro, ec.nombre)
            }});
        setRegistroEditar(registro);
        setCamposRegistroEditar(camposEstructura);
        setEstructuraRegistro(estructuraRegistro);
        setIsOpenEditarRegistroDominio(true);
        setMensajeOk({ mostrarMensaje: false, mensaje: '' });
    }

    function valorCampoRegistro(registro, nombreCampo) {
        const campo = registro.campos.find((campo) => campo.nombre === nombreCampo);
        return campo ? campo.valor : '';
    }

    const handleIrCrearRegistro = () => {
        setIsOpenCrearRegistroDominio(true);
        setNuevoTipoRegistro("A");
        setMensajeOk({ mostrarMensaje: false, mensaje: '' });
    }

    function agruparRegistros() {
        if (registros && Array.isArray(registros)) {
            setRegistrosPorTipo(registros.reduce((acc, registro) => {
                if (!acc[registro.tipo]) {
                    acc[registro.tipo] = [];
                }
                acc[registro.tipo].push(registro);
                return acc;
            }, {}));
        }
    }

    const handleCerrarEditarRegistroOk = () => {
        setIsOpenEditarRegistroDominio(false);
    }

    const handleCerrarCrearRegistroOk = (mensaje) => {
        setIsOpenCrearRegistroDominio(false);
        setMensajeOk(mensaje);
    }

    const handleAbrirConfirmarEliminarRegistro = (registro) => {
        setRegistroEliminar(registro);
        setIsOpenConfirmarEliminarRegistro(true);
    };

    const handleCerrarConfirmarEliminarRegistro = () => {
        setIsOpenConfirmarEliminarRegistro(false);
        setRegistroEliminar(null);
    };

    const handleEliminarRegistro = () => {
        if (!registroEliminar) return;
        setMensajeOk({ mostrarMensaje: false, mensaje: "" });
        setError({ error: false, mensaje: "Sin errores" });
        let requestEliminar = {
            nombreDominio: nombreDominio,
            registro: registroEliminar,
        };
        AdministracionUsuarioService.eliminarRegistroDominio(requestEliminar)
            .then(response => {
                if (!response.respuestaOK) {
                    setError({
                        error: true,
                        mensaje: response.mensajeRespuesta
                    })
                } else {
                    actualizarInformacionRegistros(dominio);
                    setMensajeOk({ mostrarMensaje: true, mensaje: "El registro se eliminó correctamente" });
                    agruparRegistros(storedRegistros);
                }
                handleCerrarConfirmarEliminarRegistro();
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al modificar los registros del dominio."
                });
                handleCerrarConfirmarEliminarRegistro();
            });
    }

    const convertirSegundos = (segundos) => {
        if (segundos >= 3600) {
            const horas = segundos / 3600;
            return `${horas} hrs`;
        } else if (segundos >= 60) {
            const minutos = segundos / 60;
            return `${minutos} min`;
        } else {
            return `${segundos} sec`;
        }
    };

    const handleCopyClick = (index) => {
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(''), 2000);
    };

    function esCampoCopiable(campo) {
        const camposCopiables = ['text','ip','ipv6','cannonical_name','mail_server','target'];
        return camposCopiables.includes(campo.nombre);
    }

    return (
        <>
            <Modal
                idDivModal="idModalConfiguracionAvanzada"
                handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
                <fieldset id="id-fieldset-configuracion-avanzada" style={{ width: '900px' }}>
                    <legend>Configuración avanzada</legend>

                    {mensajeOk !== undefined && mensajeOk.mostrarMensaje && <MensajeOk mensajeOk={mensajeOk.mensaje}/>}
                    {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                    {isOpenEditarRegistroDominio &&
                        <ModalEditarRegistroDominio
                            isOpen={isOpenEditarRegistroDominio}
                            handleOnClose={() => setIsOpenEditarRegistroDominio(false)}
                            nombreDominio={nombreDominio}
                            dominio={dominio}
                            registro={registroEditar}
                            campos={camposRegistroEditar}
                            registros={storedRegistros}
                            actualizarInformacionRegistros={actualizarInformacionRegistros}
                            setMensajeOk={setMensajeOk}
                            handleOnCloseOk={handleCerrarEditarRegistroOk}
                            estructuraRegistro={estructuraRegistro}
                        />
                    }

                    {isOpenCrearRegistroDominio &&
                        <ModalAgregarRegistroDominio
                            isOpen={isOpenCrearRegistroDominio}
                            handleOnClose={() => setIsOpenCrearRegistroDominio(false)}
                            nombreDominio={nombreDominio}
                            tipoRegistroCrear={nuevoTipoRegistro}
                            dominio={dominio}
                            estructurasRegistros={estructurasRegistros}
                            tiposRegistro={tiposRegistro}
                            actualizarInformacionRegistros={actualizarInformacionRegistros}
                            setMensajeOk={setMensajeOk}
                            handleOnCloseOk={handleCerrarCrearRegistroOk}
                            registros={storedRegistros}
                        />
                    }

                    {isOpenConfirmarEliminarRegistro &&
                        <ModalConfirmarEliminarRegistro
                            open={isOpenConfirmarEliminarRegistro}
                            handleClose={handleCerrarConfirmarEliminarRegistro}
                            registro={registroEliminar}
                            handleEliminarRegistro={handleEliminarRegistro}
                        />
                    }

                    <form id="id-form-agregar-registro">
                        <div id="id-estructura">
                            {Object.keys(registrosPorTipo).map((tipo, index) => (
                                <table className="data" key={tipo}>
                                    <caption><b>Registros de tipo {tipo}</b></caption>
                                    <thead>
                                    <tr>
                                        {estructurasRegistros[tipo].estructuraCampos.map((campo, index) => (
                                            <th key={index}>{campo.etiqueta}</th>
                                        ))}
                                        <th>TTL</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {registrosPorTipo[tipo].map((registro, index2) => (
                                        <tr key={index2}>
                                            {estructurasRegistros[tipo].estructuraCampos.map((ec, indexC) => {
                                                    let c = registro.campos.find(c => c.nombre === ec.nombre)
                                                    const valor = c.valor
                                                    return <td key={indexC}>
                                                        <div className="td-flex">
                                                            <span title={valor.length > 30 ? valor : ''}>
                                                                {valor.length > 30 ? `${valor.substring(0, 30)}...` : valor}
                                                            </span>
                                                            {(esCampoCopiable(c)) && (
                                                                <CopyToClipboard text={valor}
                                                                                 onCopy={() => handleCopyClick(index2 + '-' + indexC)}>
                                                                    <i title={"Copiar"}
                                                                       className={`fa-light fa-clone ${copiedIndex === (index2 + '-' + indexC) ? 'fa-check' : ''}`}
                                                                       style={{
                                                                           cursor: 'pointer',
                                                                           visibility: 'visible'
                                                                       }}
                                                                    />
                                                                </CopyToClipboard>
                                                            )}
                                                        </div>
                                                    </td>
                                            })}
                                            <td key="ttl">
                                                <div className="td-flex">
                                                    <span>
                                                        {convertirSegundos(registro.ttl)}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <a id={"id-editar-registro-" + index2}
                                                   onClick={() => handleIrEditarRegistro(registro)}
                                                   title={"Editar registro"}>
                                                    <i className="fa-light fa-pen-to-square"></i>
                                                </a>
                                                <a id={"id-eliminar-registro-" + index2}
                                                   onClick={() => handleAbrirConfirmarEliminarRegistro(registro)}
                                                   title="Eliminar registro">
                                                    <i className="fa-light fa-trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ))}
                        </div>

                        <div className="right">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={"btn"} onClick={handleIrCrearRegistro}>Agregar registro</a>
                        </div>
                    </form>
                </fieldset>
            </Modal>
        </>
    );
}
