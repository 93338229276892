import React, {useEffect, useState} from 'react';
import {oidc_authorize} from "../security/OidcManager";
import {useLocation, useNavigate} from "react-router-dom";

const UnAuthorized = () => {
    const SECOND_TO_REDIRECT = 5;

    const [countdown, setCountdown] = useState(SECOND_TO_REDIRECT);
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from || '';

    const showPositiveCountdown = () => {
        return countdown === 0 ? 1 : countdown;
    }

    useEffect(() => {
        if (countdown === 0) {
            oidc_authorize(from);
        } else {
            // Decrease countdown each second
            const timerId = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timerId); // Cleanup the timeout
        }
    }, [countdown, from, navigate]);

    return (
        <div className="NotFound">
            <div className="lander">
                <h3 style={{fontSize: "x-large", fontWeight: "normal"}}>
                    Su sesión ha finalizado o no se encuentra activa. Será redirigido automáticamente para iniciar una nueva sesión en {showPositiveCountdown()}
                </h3>
            </div>
        </div>
    );
};

export default UnAuthorized;