import moment from 'moment';

export function buildObtenerResumenUsuarioResponse(resumenUsuarioResponse) {
    return {
        resumen: resumenUsuarioResponse,
        esRespOK: resumenUsuarioResponse.respuestaOK,
        codigo: resumenUsuarioResponse.codigoRespuesta,
        mensaje: resumenUsuarioResponse.mensajeRespuesta,
    }
}

export function buildAccionDominioResponse(response) {
    return {
        esRespOK: response.respuestaOK,
        codigo: response.codigoRespuesta,
        mensaje: response.mensajeRespuesta,
    }
}

export function buildAccionRenovarDominioResponse(response) {
    return {
        esRespOK: response.respuestaOK,
        codigo: response.codigoRespuesta,
        mensaje: response.mensajeRespuesta,
        uuidVenta: response.uuidVenta
    }
}

export function buildObtenerDominiosUsuarioResponse(dominiosUsuarioResponse) {
    return {
        dominios: dominiosUsuarioResponse.dominioWrapperDTOList,
        permisosDominio: dominiosUsuarioResponse.permisoDominioDTOMap,
        totalDominiosFiltro: dominiosUsuarioResponse.totalDominiosFiltro,
        codigo: dominiosUsuarioResponse.codigoRespuesta,
        mensaje: dominiosUsuarioResponse.mensajeRespuesta,
    }
}

export function buildObtenerPermisosDominiosRequest(permisosDominio, dominiosSeleccionados, rolSeleccionado, operacion) {
    return {
        permisosDominio: permisosDominio,
        dominiosSeleccionados: dominiosSeleccionados,
        rolSeleccionado: rolSeleccionado,
        operacion: operacion
    }
}

export function buildObtenerPermisosDominiosResponse(permisosDominioResponse) {
    return {
        dominiosAplicaAccion: permisosDominioResponse.dominiosAplicaAccion,
        dominiosNoAplicaAccion: permisosDominioResponse.dominiosNoAplicaAccion,
        codigo: permisosDominioResponse.codigoRespuesta,
        mensaje: permisosDominioResponse.mensajeRespuesta,
    }
}
export function buildobtenerInvitacionPendienteResponse(invitacionPendienteResponse) {
    return {
        nombreDominio: invitacionPendienteResponse.nombreDominio,
        tipoContacto: invitacionPendienteResponse.tipoContacto,
        codigoInvitacion: invitacionPendienteResponse.codigo,
        codigoRespuesta: invitacionPendienteResponse.codigoRespuesta,
        mensaje: invitacionPendienteResponse.mensajeRespuesta
    }
}

export function buildEditarContactoDominioRequest(nombresDominio, tipoContacto, soloPublicos, datosContactoDTO) {
    return {
        nombresDominio: nombresDominio,
        tipoContacto: tipoContacto,
        soloPublicos: soloPublicos,
        contactoDTO: datosContactoDTO
    }
}
export function buildEInvitacionCambiarContactoRequest(nombreDominio, correo, tipoContacto) {
    return {
        nombreDominio: nombreDominio,
        correo: correo,
        tipoContacto: tipoContacto
    }
}

    export function buildObtenerInvitacionPendienteRequest(codigo) {
    return {
        codigo: codigo
    }
}

export function buildEditarCuentaDominioRequest(nombresDominio, tipoContacto, templateCuentaPago) {
    return {
        nombresDominio : nombresDominio,
        tipoContacto: tipoContacto,
        templateCuentaPagoDTO: {
            id: templateCuentaPago.id,
            nombre: templateCuentaPago.nombre,
            cuenta:{
                id: templateCuentaPago.cuenta.id,
                cuenta: templateCuentaPago.cuenta.cuenta,
                pais:{
                    id: templateCuentaPago.cuenta.idPais,
                    nombre: templateCuentaPago.cuenta.nombrePais
                },
                tipoDocumento:{
                    id: templateCuentaPago.cuenta.idTipoDocumento,
                    nombre: templateCuentaPago.cuenta.nombreTipoDocumento
                },
                nroDocumento: templateCuentaPago.cuenta.nroDocumento,
            }
        }
    }
}
export function buildEliminarCuentaDominioRequest(nombresDominio) {
    return nombresDominio;
}
export function buildHistorialDominioFormaPagoRequest(nombreDominio) {
    const requestPayload = {
        nombre: nombreDominio,
        id: null,
        version: null,
        tld: null,
        fechaVencimiento: null,
        fechaAlta: null,
        fechaBaja: null
    };
    return requestPayload;
}

export function buildHistorialDominioFormaPagoResponse(data) {
    return data.map(item => {
        const formattedDate = moment(item.cuentaPago.fechaAsociacion).format('DD/MM/YYYY HH:mm');
        item.cuentaPago.fechaAsociacionFormateada = formattedDate;
        return item;
    });
}
