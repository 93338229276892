import React from 'react'
import Modal from "../Modal";

export const ModalMensaje = (props) => {

    return (
        <Modal
            idDivModal="idModalMensaje"
            handleClose={props.handleOnClose} isOpen={props.isOpen} closeOnClickOutside={false}>
            <p dangerouslySetInnerHTML={{ __html: props.mensaje.mensaje}}></p>
        </Modal>
    )
}