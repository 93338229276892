import React, {useEffect, useState} from 'react'
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {useNavigate} from "react-router-dom";

export const ModalLiberarDominio = ({ nombreDominio, handleOnClose, isOpen, mensaje, respuestaAccionOk, setError, redirectToPage, setIsProcesando, setMensajeProcesando }) => {
    const [mensajeLiberacionDominio, setMensajeLiberacionDominio] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        obtenerMensajeLiberarDomino();
    }, [mensaje])

    const obtenerMensajeLiberarDomino = () => {
        if (mensaje) {
            let mensajeLiberacion = mensaje.replace("PLACEHOLDER_NOMBRE_DOMINIO", nombreDominio);
            setMensajeLiberacionDominio(mensajeLiberacion);
        } else {
            setMensajeLiberacionDominio(`Se liberará el dominio <b>${nombreDominio}</b>. ¿Está seguro que desea continuar?`);
        }
    }

    const liberarDominio = (e) => {
        e.preventDefault();

        // Verifica si ya está en proceso de liberación
        const estaLiberandoLocalStorage = localStorage.getItem('estaLiberando') === 'true';
        if (estaLiberandoLocalStorage) {
            setError({
                error: true,
                mensaje: "El proceso de liberación ya está en curso."
            });
            return;
        }
        localStorage.setItem('estaLiberando', 'true');
        setError({ error: false, mensaje: "Sin errores" });

        let accionDominioDTO = {
            nombreDominio : nombreDominio
        };

        if (setIsProcesando !== undefined){
            setMensajeProcesando('Procesando liberación')
            setIsProcesando(true);
        }
        AdministracionUsuarioService.liberarDominio(accionDominioDTO)
            .then(response => {
                if (!response.esRespOK) {
                    setError({
                        error: true,
                        mensaje: response.mensaje
                    });
                } else {
                    if (respuestaAccionOk !== undefined) {
                        respuestaAccionOk(response.mensaje);
                    }else if (redirectToPage){
                        navigate('/administrar/mis-dominios', { state: { mensajeOk: { mostrarMensaje: true, mensaje: response.mensaje } } });
                    }
                }
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al liberar el dominio."
                });
            })
            .finally(() => {
                if (setIsProcesando !== undefined){
                    setIsProcesando(false);
                }
                localStorage.removeItem('estaLiberando');
            });
        handleOnClose();

    };


    return <>
        <Modal
            idDivModal="idModalLiberar"
            handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
            <p>
                <span dangerouslySetInnerHTML={{ __html: mensajeLiberacionDominio }}></span>
            </p>
            <hr className={"linea"}></hr>
            <div className="right">
                <button className={'btn'} onClick={liberarDominio}>Confirmar</button>
                <button className={'btn'} onClick={handleOnClose}>Cancelar</button>
            </div>
        </Modal>
    </>
}