import React, {useContext, useEffect, useState} from 'react'
import transferenciaService from "../../services/TransferenciaRegistradorService";
import AppContext from "../../store/AppContext";
import {TipoMensaje} from "../../store/AppProvider";
import {useParams} from "react-router-dom";

export const ConfirmarTransferencia = () => {
    const appCtx = useContext(AppContext);
    const [dominio, setDominio] = useState(undefined);
    const {nombreDominio} = useParams();

    useEffect(() => {
        if (nombreDominio) {
            setDominio(nombreDominio);
            //appCtx.mostrarMensaje('Existe un dominio', TipoMensaje.warning);
        }
    }, [nombreDominio]);

    const solicitarCodigoAutorizacion = () => {
        transferenciaService.getNotificacionSolicitudConfirmacion(1)
            .then(response => {
                appCtx.mostrarMensaje('El código de autorización se procesó correctamente: ' + response.codigo, TipoMensaje.ok);
            }).catch(() => {
                appCtx.mostrarMensaje('Consulta con error', TipoMensaje.error);
            });
    }

    return <>
        <fieldset>
            <legend>Confirmar Transferencia de Registrador</legend>

            <div className="form-item">
                <label>Dominio</label>{dominio}
            </div>
        </fieldset>

        <div className="right">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="btn btn-right-separator" onClick={() => solicitarCodigoAutorizacion()}>Aprobar Transferencia</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="btn" onClick={() => solicitarCodigoAutorizacion()}>Rechazar Transferencia</a>
        </div>
    </>
}