import React, {useContext} from "react";
import AppContext from "../../../store/AppContext";
import {TipoItemCarrito} from "../../../store/AppProvider";

export const ListaTransferenciasTitularPasoUno = (props) => {
    const appCtx = useContext(AppContext);
    const items = appCtx.carrito.transferenciasTitularPendientes;

    const removeFromCarrito = (e)=> {
        const dominioId = e.target.id;
        props.handleEliminarItemCarrito(dominioId, TipoItemCarrito.transferenciaTitular);
    }

    return (
        <>
            {items == null || items.length === 0 ?
                <></> :
                <>
                    <table id="id-tabla-ren-dom" className="cart">
                        <caption>Transferir titular</caption>
                        <thead>
                        <tr>
                            <th>Artículo</th>
                            <th>Titular anterior</th>
                            <th>Titular nuevo</th>
                            <th className="tdright">Precio</th>
                        </tr>
                        </thead>
                        <tbody id="register_domains_tbody">
                            { items.map((dominio, index) => (

                                <tr className="last" key={dominio.nombre}>
                                    <td>
                                        <a className="close" id={dominio.id} onClick={removeFromCarrito}></a>
                                        {dominio.nombre}
                                    </td>
                                    <td>{dominio.titularActual}</td>
                                    <td>{dominio.nuevoTitular}</td>
                                    <td className="tdright">$ {dominio.precio}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td className="tdright">Subtotal:</td>
                            <td className="price" id="id_subtotal_transferencias">$ {props.subTotal}</td>
                        </tr>
                        </tfoot>
                    </table>
                </>
            }
        </>
    );
}