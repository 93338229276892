import Modal from "../../../Modal";
import React from "react";
import TransferenciaRegistradorService from "../../../../services/TransferenciaRegistradorService";
import { useNavigate } from 'react-router-dom';

export const ModalDialogoAprobarTransferencia = ({ nombreDominio, handleOnClose, isOpen, transferdata, setError, setMensajeOK }) => {
    const navigate = useNavigate();

    const handleOnAceptar = async () => {
        try {
            const response = await TransferenciaRegistradorService.aprobarTransferencia(nombreDominio, transferdata.authorizationCode);
            if (response.code !== 'OK') {
                setError({
                    error: true,
                    mensaje: "Ocurrió un error al aprobar la transferencia. Por favor, inténtelo nuevamente."
                });
                window.scroll(0, 0);
                handleOnClose();
            }
            setTimeout(() => {
                navigate('/administrar/mis-dominios', {
                    state: {
                        mensajeOk: {
                            mostrarMensaje: true,
                            mensaje: 'Se aprobó la Transferencia del dominio ' + nombreDominio
                        }
                    }
                });
                handleOnClose();
            }, 1000);
        } catch (error) {
            setError({
                error: true,
                mensaje: "Ocurrió un error al aprobar la transferencia. Por favor, inténtelo nuevamente."
            });
            window.scroll(0, 0);
            handleOnClose();
        }
    };

    return (
        <>
            <Modal
                idDivModal="idModalDialogoAprobarTransferencia"
                handleClose={handleOnClose}
                isOpen={isOpen}
                closeOnClickOutside={false}
            >
                <div className="ui-dialog-content ui-widget-content">
                    <fieldset>
                        <legend>Aprobar Transferencia</legend>
                        <p>
                            <label>
                                {"¿Desea aprobar la Transferencia de Agente Registrador del dominio " + nombreDominio + "?"}
                            </label>
                        </p>
                        <div className="right">
                            <input type="submit" value="Aceptar" id="id-btn-aprobar-submit" className="btn" style={{ marginRight: '10px' }} onClick={handleOnAceptar} />
                            <input type="button" className="btn" value="Cancelar" onClick={handleOnClose} />
                        </div>
                    </fieldset>
                </div>
            </Modal>
        </>
    );
};