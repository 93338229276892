import React, { useEffect, useState } from 'react'
import {getOidcState} from "../../../security/OidcManager";
import {useNavigate} from "react-router-dom";
import {MensajeError} from "../../MensajeError";
import "../../../components/administrar/misDominios/VerDominio.css";
import {ValidacionAutenticado} from "../../../components/validaciones/ValidacionAutenticado";
import transferenciaRegistradorService from "../../../services/TransferenciaRegistradorService";
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";

export const IrVerDominioTar = () => {

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (!isTAREnabled()){
            navigate('/administrar/mis-dominios');
        }
        if (!getOidcState().is_authenticated){
            setError({
                error: true,
                mensaje: "Información no disponible"
            })
            return;
        }
        const params = new URLSearchParams(window.location.search);
        const dominioParam = params.get('dominio');

        if (dominioParam) {
            irVerDominio(dominioParam);
        } else {
            setError({
                error: true,
                mensaje: "Información no disponible"
            })
            return;

        }

    }, []);

    function irVerDominio(nombreDominio) {

        let filtro = {
            nombre : nombreDominio,
            modulo: 'FRONTEND',
        };

        AdministracionUsuarioService.obtenerDominiosUsuario(filtro)
            .then(response => {
                if (response.codigo === 'OK' && response.totalDominiosFiltro === 1) {
                    let dominio = response.dominios[0];
                    navigate("/administrar/ver-dominio", {state : { dominio: dominio }});
                }
            })
            .catch(error => {
                console.error(error)
            });
        navigate("/administrar/mis-dominios");
    }

    const isTAREnabled = () =>
    {
        return true;
        return transferenciaRegistradorService.isTAREnabled().then(response => {
            return true;
        }).catch(ignoreError => { // Por el momento no hacemos nada con esto
            return false;
        });
        return false;
    }

    return <>

        <ValidacionAutenticado/>

        { error.error && <MensajeError mensajeError={error.mensaje}></MensajeError> }

    </>
}
