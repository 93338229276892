import React, {useContext, useEffect, useState} from "react";
import {CarritoComprasPasos} from "../CarritoComprasPasos";
import AppContext from "../../../store/AppContext";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {CarritoComprasSeleccionarCuenta} from "./CarritoComprasSeleccionarCuenta";
import CuentaService from "../../../services/CuentaService";
import CarritoService from "../../../services/CarritoService";
import {ValidacionAutenticado} from "../../../components/validaciones/ValidacionAutenticado";
import {ValidacionesCarrito} from "../../../components/validaciones/ValidacionesCarrito";
import {MensajeError} from "../../MensajeError";

export const CarritoComprasPasoTres = () => {
    const appCtx = useContext(AppContext);
    let carrito = appCtx.carrito.registrosPendientes;
    let venta = appCtx.venta;
    const history = useNavigate();
    const location = useLocation();
    const [templateCuentaPago, setTemplateCuentaPago] = useState(undefined);
    const [templateCuentaPagoGuardada, setTemplateCuentaPagoGuardada] = useState(undefined);
    const [formasDePago, setFormasDePago] = useState([]);
    const [formaDePagoActual, setFormaDePagoActual] = useState(undefined);
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    useEffect(() => {
        if (location.state && location.state.errorValidacionCarrito.error) {
            setError(location.state.errorValidacionCarrito);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        obtenerFormasDePago();
    }, []);

    useEffect(() => {
        if (formasDePago.length > 0 ) {
            obtenerFormaPagoGuradadaVenta(venta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formasDePago]);

    function validarDatosCuentaPago(templateCuentaPago) {
        let errorValidacion = {
            error: false,
            mensaje: "Sin errores"
        }
        if (templateCuentaPago.id === undefined || templateCuentaPago.id === -1) {
            switch (true) {
                case templateCuentaPago.nombre.trim().length === 1:
                    errorValidacion = {
                        error: true,
                        mensaje: "El nombre ingresado no es v\u00E1lido"
                    };
                    break;
                case templateCuentaPago.cuenta.idPais === undefined:
                    errorValidacion = {
                        error: true,
                        mensaje: "El pa\u00EDs seleccionado no es v\u00E1lido"
                    };
                    break;
                case templateCuentaPago.cuenta.idTipoDocumento === undefined:
                    errorValidacion = {
                        error: true,
                        mensaje: "El tipo de documento seleccionado no es v\u00E1lido"
                    };
                    break;
                case chequearVacio(templateCuentaPago.nombre.trim())
                    || chequearVacio(templateCuentaPago.cuenta.cuenta.trim())
                    || chequearVacio(templateCuentaPago.cuenta.nroDocumento.trim()):
                        errorValidacion = {
                            error: true,
                            mensaje: "Debe completar todos los campos obligatorios"
                        };
                    break;
                default:
                    break;
            }
        }
        return errorValidacion;
    }

    const chequearVacio = (texto) => {
        return texto.length === 0;
    }

    const guardarFormaPago = (event) => {
        event.preventDefault();
        if (templateCuentaPago === undefined || templateCuentaPago.id === 0) {
            setError({
                error: true,
                mensaje: "Debe seleccionar una cuenta o crear una nueva"
            })
            return;
        }

        if (appCtx.venta.idVenta !== "") {
            let validacion = validarDatosCuentaPago(templateCuentaPago);
            if (validacion.error) {
                event.preventDefault();
                setError(validacion);
            } else {

                CuentaService.crearCuentaPago(appCtx.venta.uuidVenta, templateCuentaPago, formaDePagoActual)
                    .then((response) => {
                        if (response.respCodigo !== 'OK') {
                            setError({
                                error: true,
                                mensaje: response.respMensaje
                            })
                            setTemplateCuentaPagoGuardada(undefined);
                        } else {
                            setTemplateCuentaPago(response.templateCuentaPago);
                            history("/carrito-compra-paso-cuatro", {templateCuentaPago : response.templateCuentaPago});
                        }
                    }).catch(() => {
                    setError({
                        error: true,
                        mensaje: "Error al crear la cuenta de pago"
                    })
                });
            }
        }
    }



    const obtenerFormasDePago = () => {
        CarritoService.obtenerFormasDePago().then(response => {
            if (response !== undefined && response.respuestaOK) {
                setFormasDePago(response.formasDePago);
                setFormaDePagoActual(response.formasDePago[0])
            }
        })
    }

    const obtenerFormaPagoGuradadaVenta = (venta) => {
        CarritoService.obtenerFormaPagoGuardadaVenta(venta.uuidVenta)
            .then(response => {
                if (response.esRespOK) {
                    if (response.cuentaPago !== null && response.cuentaPago !== undefined &&
                        response.cuentaPago.cuenta.id !== null && response.cuentaPago.cuenta.id !== undefined){
                        setFormaDePagoActual(formasDePago.find(formaDePago => formaDePago.id === response.cuentaPago.cuenta.formaPago.id));
                        setTemplateCuentaPagoGuardada(response.cuentaPago.id);
                    }
                }
            })
    }

    const handleOnChangeTemplateCuentaPago = (templateCuenta) => {
        setTemplateCuentaPago(templateCuenta);
    }

    const handleOnChangeComboFormaPago = (event) => {
        setFormaDePagoActual(formasDePago.find(formaDePago => formaDePago.id === +event.target.value));
    }

    return (
      <>
          {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

          <ValidacionAutenticado/>

          <ValidacionesCarrito paso={3} setError={setError}/>

          <CarritoComprasPasos paso={3}
                               tituloCarrito={'Carrito de compras'}
                               parrafoCarrito={'Seleccionar la cuenta de Antel de telefonía fija o datos que se utilizará para realizar la compra. De ser necesario se puede personalizar los datos de la factura de la compra.'}
          />


          <form autoComplete={"off"}>
              <div className="cfx">
                  <p className="msjRequerido right">Los campos marcados con * son requeridos.</p>
              </div>

              <fieldset id="id-fieldset-form-pago">
                  <legend>Forma de pago</legend>

                  <div className="form-item">
                      <label htmlFor="id-select-forma-pago">Forma de Pago *</label>
                      {formaDePagoActual !== undefined &&
                          <select onChange={handleOnChangeComboFormaPago} value={formaDePagoActual.id}>
                              {formasDePago.map(formaPago =>
                                  <option key={formaPago.id} value={formaPago.id} >{formaPago.nombre}</option>
                              )}
                          </select>}
                      <p className="note">Seleccione la forma de pago</p>

                  </div>

                  {formaDePagoActual !== undefined && formaDePagoActual.id === 1 &&
                      <div id="id-div-nueva-cuenta">
                          <div id="div-fieldset-nueva-cuenta">
                              <CarritoComprasSeleccionarCuenta
                                  onChangeTemplateCuentaPago={handleOnChangeTemplateCuentaPago}
                                  templateCuentaGuardada={templateCuentaPagoGuardada}
                                  setError={setError}
                              />
                          </div>
                      </div>
                  }

                  <div className="right">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a className={"btn"} onClick={guardarFormaPago}>Continuar</a>
                  </div>
                      { carrito != null && carrito.length > 0 ?
                          <Link to={{ pathname: "/carrito-compra-paso-dos"}}>Volver al paso anterior</Link>
                          :
                          <Link to={{ pathname: "/carrito-compra-paso-uno"}}>Volver al paso anterior</Link>
                      }
              </fieldset>
          </form>
      </>
    );
};