import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Modal from "../../../components/Modal";
import CarritoService from "../../../services/CarritoService";
import {MensajeError} from "../../MensajeError";
import CuentaService from "../../../services/CuentaService";

const ModalEditarCuenta = (props) => {
    const {isOpen, setIsOpen} = props;
    const [cuenta, setCuenta] = useState({
        nombre: "",
        paisId: "",
        nombrePais: "",
        tipoDocumentoId: "",
        nombreTipoDocumento: "",
        nroDocumento: "",
        nroCuenta: ""
    });
    const [pais, setPais] = useState({idPais: "1", nombrePais: "URUGUAY"});
    const [documentosPorPais, setDocumentosPorPais] = useState(undefined);
    const [documento, setDocumento] = useState({idTipoDocumento: "", nombreTipoDocumento: ""});
    const [formasDePago, setFormasDePago] = useState([]);
    const [paises, setPaises] = useState(undefined);
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const isCuentaNueva = props.crearCuenta;

    useEffect(() => {
        obtenerFormasDePago();
        obtenerPaisesService();
        let cuentaTemp = {};
        if (!isCuentaNueva) {
            cuentaTemp = {
                nombre: props.cuenta.cuentaAEditar.nombre,
                paisId: props.cuenta.cuentaAEditar.cuenta.pais.id,
                tipoDocumentoId: props.cuenta.cuentaAEditar.cuenta.tipoDocumento.id,
                nroDocumento: props.cuenta.cuentaAEditar.cuenta.nroDocumento,
                nroCuenta: props.cuenta.cuentaAEditar.cuenta.cuenta
            }
            setCuenta(cuentaTemp);
        }

    }, []);

    useEffect(() => {
        obtenerDocumentoPorPaisService(isCuentaNueva ? pais.idPais : props.cuenta.cuentaAEditar.cuenta.pais.id);
    }, [pais]);

    const obtenerPaisesService = () => {
        CarritoService.obtenerPaises()
            .then(response => {
                if (response.esRespOK) {
                    setPaises(response.paisesDTO);
                }
            })
    }

    const obtenerDocumentoPorPaisService = (idPais) => {
        CarritoService.obtenerDocumentoPorPais(idPais)
            .then(response => {
                if (response.esRespOK) {
                    setDocumentosPorPais(response.tipoDocumentoPaisDTOS);
                    setDocumento({
                        idTipoDocumento: response.tipoDocumentoPaisDTOS[0].tipoDocumentoDTO.id
                        , nombreTipoDocumento: response.tipoDocumentoPaisDTOS[0].tipoDocumentoDTO.nombre
                    })
                }
            })
    }

    const onChangeData = (e) => {
        setCuenta({ ...cuenta, [e.target.name]: e.target.value });
    }

    const elegirPaisHandler = (e) => {
        const idPais = e.target.value;
        const nombrePais = e.target.options[e.target.selectedIndex].text;
        setPais({idPais: idPais, nombrePais: nombrePais});
    }

    const elegirDocumentoHandler = (e) => {
        const idTipoDocumentoActual = e.target.value;
        const nombreTipoDocumentoActual = e.target.options[e.target.selectedIndex].text;
        setDocumento({idTipoDocumento: idTipoDocumentoActual, nombreTipoDocumento: nombreTipoDocumentoActual});
    }

    const guardarDatosCuenta = (e) => {
        e.preventDefault();
        if (isCuentaNueva) {
            let nuevaCuenta = cuenta;
            nuevaCuenta.paisId = pais.idPais;
            nuevaCuenta.nombrePais = pais.nombrePais;
            nuevaCuenta.tipoDocumentoId = documento.idTipoDocumento;
            nuevaCuenta.nombreTipoDocumento = documento.nombreTipoDocumento;
            onGuardarDatosCuenta(nuevaCuenta, isCuentaNueva);
        } else {
            let cuentaAEditar = props.cuenta.cuentaAEditar;
            cuentaAEditar.nombre = cuenta.nombre;
            onGuardarDatosCuenta(cuentaAEditar, isCuentaNueva);
        }
    }

    const onGuardarDatosCuenta = (cuentaAGuardar, isCuentaNueva) => {
        if (isCuentaNueva) {
            const templateCuentaPago = {
                id: -1,
                nombre: cuentaAGuardar.nombre,
                cuenta: {
                    id: undefined,
                    cuenta: cuentaAGuardar.nroCuenta.trim(),
                    idPais: cuentaAGuardar.paisId,
                    idTipoDocumento: cuentaAGuardar.tipoDocumentoId,
                    nombrePais: cuentaAGuardar.nombrePais,
                    nombreTipoDocumento: cuentaAGuardar.nombreTipoDocumento,
                    nroDocumento: cuentaAGuardar.nroDocumento
                }
            }
            guardarNuevaCuentaPago(templateCuentaPago);
        } else {
            actualizarCuentaPago(cuentaAGuardar.id, cuentaAGuardar.nombre);
        }
    }

    const guardarNuevaCuentaPago = (templateCuentaPago) => {
        CuentaService.crearCuentaPago(undefined, templateCuentaPago, formasDePago[0])
            .then((response) => {
                console.log(response);
                if (response.esRespOK) {
                    setIsOpen(false);
                } else {
                    setError({
                        error: true,
                        mensaje: response.respMensaje
                    })
                }
            })
    }

    const actualizarCuentaPago = (templateCuentaPagoId, templateCuentaPagoNombre) => {
        CuentaService.actualizarTemplateCuentaPago(templateCuentaPagoId, templateCuentaPagoNombre)
            .then((response) => {
                console.log(response);
                if (response.esRespOK) {
                    setIsOpen(false);
                } else {
                    setError({
                        error: true,
                        mensaje: response.respCodigo
                    })
                }
            })
    }

    const obtenerFormasDePago = () => {
        CarritoService.obtenerFormasDePago().then(response => {
            if (response !== undefined && response.respuestaOK) {
                setFormasDePago(response.formasDePago);
            }
        })
    }

    return (
        <Modal handleClose={props.handleOnClose} isOpen={props.isOpen} closeOnClickOutside={false}
               className="modal" modalContentClassName="modal-content" closeClassName="close-modal">
            {/*        <s:if test="hasActionErrors()">*/}
            {/*            <div class="message-box" id="register-error">*/}
            {/*                <h3><s:actionerror theme="simple"/></h3>*/}
            {/*            </div>*/}
            {/*        </s:if>*/}

            {/*        <s:set var="urlAction">*/}
            {/*            <s:if test="%{templateCuenta.id != null}">adminMisDatosEditarCuenta</s:if>*/}
            {/*            <s:else>adminMisDatosCrearCuenta</s:else>*/}
            {/*        </s:set>*/}

            <fieldset id="id-div-cuenta-nueva" style={{display: (("templateCuentas.size() == 0 || (templateCuenta != null && templateCuenta.id == -1)") || true) ? 'block' : 'none'}}>
                <legend>Datos de la cuenta de Antel de telefonía fija o datos</legend>

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <div id="div-fieldset-nueva-cuenta">
                    <form>
                        {/*<s:if test="%{templateCuenta.id != null}">*/}
                        {/*    <s:hidden name="templateCuenta.id" value="%{idCuenta}"/>*/}
                        {/*</s:if>*/}

                        <div id="div-fieldset">
                            <div className="form-item">
                                <label htmlFor="id-nuevacuenta-nombre">Nombre de la cuenta *</label>
                                <input type={"text"} id="id-nuevacuenta-nombre" value={cuenta.nombre} onChange={onChangeData} name="nombre" cssErrorStyle="border-color:red;"/>
                                <p className="note">Elegir Nombre para hacer referencia a la cuenta, ejemplo: casa</p>
                            </div>

                            <div className="form-item">
                                <label htmlFor="idPaisSelectNuevaCuenta">Pa&iacute;s *</label>
                                <select value={isCuentaNueva ? pais.idPais : cuenta.paisId} onChange={elegirPaisHandler} name={"paisId"}>
                                    { paises && paises.map((pais) => (
                                        <option value={pais.id}
                                                key={pais.id} id="idPaisSelectNuevaCuenta"
                                                disabled={!isCuentaNueva}
                                                name="templateCuenta.cuenta.pais.id" cssErrorStyle="border-color:red;">{pais.nombre}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-item" id="id-div-nueva-cuenta-tipo-documento">
                                <label htmlFor="id-facturacion-tipo-documento-nueva-cuenta">Tipo de documento *</label>
                                <select value={isCuentaNueva ? documento.idTipoDocumento : cuenta.tipoDocumentoId} onChange={elegirDocumentoHandler} name={"tipoDocumentoId"}>
                                    {documentosPorPais !== undefined && documentosPorPais.map((documento)=>(
                                        <option value={documento.tipoDocumentoDTO.id}
                                                key={documento.tipoDocumentoDTO.id}
                                                disabled={!isCuentaNueva} id="id-facturacion-tipo-documento-nueva-cuenta" name="templateCuenta.cuenta.tipoDocumento.id" cssErrorStyle="border-color:red;">{documento.tipoDocumentoDTO.nombre}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-item">
                                <label htmlFor="id-nuevacuenta-doc">Número de documento *</label>
                                <input type={"text"} id="id-nuevacuenta-doc" value={cuenta.nroDocumento} readOnly={!isCuentaNueva} onChange={onChangeData} name="nroDocumento" cssErrorStyle="border-color:red;" maxLength="12"/>
                                <p className="note">Ingresar el numero de documento sin puntos ni guiones</p>
                            </div>

                            <div className="form-item">
                                <label htmlFor="id-nuevacuenta-cuenta">Nro. de cuenta *</label>
                                <input type={"text"} id="id-nuevacuenta-cuenta" value={cuenta.nroCuenta} readOnly={!isCuentaNueva} onChange={onChangeData} name="nroCuenta" cssErrorStyle="border-color:red;"/>
                            </div>

                            <div className="right">
                                <button className="btn" onClick={guardarDatosCuenta}>Guardar datos</button>
                            </div>
                        </div>
                    </form>
                </div>
            </fieldset>
        </Modal>
    )
}

ModalEditarCuenta.propTypes = {
    handleOnClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default ModalEditarCuenta;