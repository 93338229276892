import React, {useEffect, useState} from 'react'
import styles from './AdministrarTransferencia.module.css'; // CSS Module
import transferenciaService from "../../../services/TransferenciaRegistradorService";
import ModalCancelacionTransferencia from "./ModalCancelacionTransferencia";
import {useLocation, useNavigate} from "react-router-dom";
import TablaRegistros from "../componentes/TablaRegistros";

export const DetalleTransferencia = (props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [codAuth, setCodAuth] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState('');
    const [fechaSolicitud, setFechaSolicitud] = useState('');
    const [fechaExpiracion, setFechaExpiracion] = useState('');
    const [registros, setRegistros] = useState([]);

    const dominio = queryParams.get("dominio");
    const codAuthParam = queryParams.get("codAuth");

    useEffect(() => {
        async function fetchData() {
            const request = transferenciaService.obtenerDatosTransferencia(
                dominio, codAuthParam
            ).then(response => {
                setState(response.estado);
                setCodAuth(response.codigoAutorizacion);
                setFechaSolicitud(response.fechaSolicitud);
                setFechaExpiracion(response.fechaVencSol);
                setRegistros(response.registros? JSON.parse(response.registros) : [])
            }).catch(() => {
                this.props.history.push('/errorAutorizacion');
            }).finally(() => {
                setIsLoading(false)
            });
        }

        fetchData();
    }, []);

    useEffect(() => {
    }, [isOpen])

    const handleSubmit = (event) => {
        const request = transferenciaService.cancelarTransferencia(
            dominio,
            codAuth
        );
        window.location.reload();
    }

    const estadosMensajes = {
        PENDING: {
            mensaje: 'Pendiente',
            color: 'label-admin label-info label-outlined',
        },
        CLIENT_REJECTED: {
            mensaje: 'Rechazado',
            color: 'label-admin label-danger label-outlined',
        },
        SERVER_REJECTED: {
            mensaje: 'Rechazado',
            color: 'label-admin label-danger label-outlined',
        },
        CLIENT_CANCELLED: {
            mensaje: 'Cancelado',
            color: 'label-admin label-danger label-outlined',
        },
        SERVER_CANCELLED: {
            mensaje: 'Cancelado',
            color: 'label-admin label-danger label-outlined',
        },
        CLIENT_APPROVED: {
            mensaje: 'Aprobado',
            color: 'label-admin label-success label-outlined',
        },
        SERVER_APPROVED: {
            mensaje: 'Aprobado',
            color: 'label-admin label-success label-outlined',
        },
        PENDING_NO_PAYMENT: {
            mensaje: 'Cancelado por problemas al procesar el pago',
            color: 'label-admin label-danger label-outlined',
        },
        EXPIRED: {
            mensaje: 'Transferencia vencida',
            color: 'label-admin label-danger label-outlined',
        },
    };

    const handleCancelarTransferencia = () => {
        setIsOpen(true);
    }

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    if (!state) {
        navigate('/errorAutorizacion');
        return null;
    }

    const mensaje = estadosMensajes[state]?.mensaje;
    const color = estadosMensajes[state]?.color;

    const handleVolverTransferencia = () => {
        navigate('/transferir-dominio/lista-solicitudes');
    }

    return <>

        {isOpen &&
            <ModalCancelacionTransferencia
                isOpen={isOpen}
                submit={handleSubmit}
                handleOnClose={() => {
                    setIsOpen(false)
                }}/>
        }

            <fieldset>
                <legend>Detalle dominio transferencia de registrador</legend>
                <table style={{width: '40%'}}>
                    <tbody>
                    <tr>
                        <td>Dominio</td>
                        <td>{dominio}</td>
                    </tr>
                    {state !== 'CLIENT_REJECTED' && state !== '' &&
                        <tr>
                            <td>Código de Autorización</td>
                            <td>{codAuth}</td>
                        </tr>
                    }
                    <tr>
                        <td>Fecha de solicitud</td>
                        <td>{fechaSolicitud}</td>
                    </tr>
                    <tr>
                        <td>Fecha de expiración</td>
                        <td>{fechaExpiracion}</td>
                    </tr>
                    <tr>
                        <td>Estado</td>
                        <td>
                            <div className={color}>{mensaje}</div>
                        </td>
                    </tr>
                    {state === 'PENDING_NO_PAYMENT' ?
                        <tr>
                            <td colSpan={2}>
                                <p className="note" style={{marginLeft: "0px"}}><strong>Importante: </strong>Para
                                    finalizar
                                    la solicitud de transferencia debe iniciar el proceso nuevamente</p>
                            </td>
                        </tr> : <></>
                    }
                    </tbody>
                </table>
                <fieldset>
                    <legend>Registros a importar</legend>
                    { registros && registros.length > 0?
                        <TablaRegistros registros={registros} customStyles={{width: '40%', marginTop: "0"}}/> :
                        <p>No se encontraron registros asociados al dominio</p>
                    }
                </fieldset>
                <div className={styles.topSeparator}/>
                <div className="left button-container">
                    <a className="btn" onClick={handleVolverTransferencia}>Volver</a>

                    {state === 'PENDING' && state !== '' &&

                        <a className="btn" onClick={handleCancelarTransferencia}>Cancelar Transferencia</a>

                    }
                </div>
            </fieldset>

    </>
}