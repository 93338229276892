import React, {useContext, useState} from 'react'

import {getOidcState, oidc_authorize, oidc_get_username, oidc_logout} from "../security/OidcManager";
import AppContext from "../store/AppContext";
import ModalCarritoNavTop from "../view/carrito/navtop/ModalCarritoNavTop";
import * as HttpService from "../support/HttpService";
import MensajesService from "../services/MensajesService";

export const NavTop = (props) => {
    const appCtx = useContext(AppContext);
    const [isCarritoOpen, setIsCarritoOpen] = useState(false);

    //invoke when the component is mounted

    React.useEffect(() => {
        mensajesUsuario();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = () => {
        appCtx.clean();
        oidc_logout();
    }

    const login = () => {
        oidc_authorize(HttpService.history.location.pathname);
    }

    const mensajesUsuario = () => {
        if (getOidcState().is_authenticated && getOidcState().id_token != null) {
            MensajesService.obtenerCantidadMensajesSinLeer(getOidcState().id_token.sub)
                .then((response) => {
                    appCtx.setCantidadMensajesSinLeer(response);
                });
            return appCtx.usuario.cantidadMensajesSinLeer;
        }
        return 0;
    };

    return <div id="id_nav_top_wrapper">
        <div id="id-float-carrito" style={{position: "absolute"}}/>
        <div id="nav-top" className="cfx">
            <div className="right">
                <ul>
                    {getOidcState().is_authenticated ?
                        <>
                            <li className="top-usu"><a href="/administrar/mis-datos">{oidc_get_username()}</a></li>
                            <li className="top-msg"><a href="/administrar/mensajes">({appCtx.usuario.cantidadMensajesSinLeer})</a></li>
                        </>
                        :
                        <></>
                    }
                    <li id="id-nav-top-cart-link" className="top-cart">
                        {/* eslint-disable-next-line */}
                        <a id="id-link-carrito" className="link" onClick={() => setIsCarritoOpen(true)}>
                            <strong>Carrito</strong> ({appCtx.carrito.registrosPendientes.length + appCtx.carrito.renovacionesPendientes.length + appCtx.carrito.transferenciasTitularPendientes.length})
                        </a>
                        <ModalCarritoNavTop handleOnClose={()=>{setIsCarritoOpen(false)}} isOpen={isCarritoOpen} wrapperId="id-float-carrito"/>
                    </li>

                    {getOidcState().is_authenticated ?
                        <li>
                            {/* eslint-disable-next-line */}
                            <a className="link" onClick={logout}>Cerrar Sesión</a>
                        </li>
                        :
                        <li>
                            {/* eslint-disable-next-line */}
                            <a className="link" onClick={login}>Iniciar Sesión</a>
                        </li>
                    }
                </ul>
            </div>
        </div>
    </div>
}