import React, {useEffect, useState} from 'react';
import './MisDatos.css';
import CuentaService from "../../../services/CuentaService";
import ModalEliminarCuenta from "./ModalEliminarCuenta";
import ModalEditarCuenta from "./ModalEditarCuenta";
import {MensajeError} from "../../MensajeError";
import ModalEditarContactoBE from "./ModalEditarContactoBE";
import UsuarioService from "../../../services/UsuarioService";
import {MensajeOk} from "../../MensajeOk";
import {ValidacionAutenticado} from "../../../components/validaciones/ValidacionAutenticado";
import { Link } from 'react-router-dom';
import FeedbackSpinner from "../../../components/feedback/FeedbackSpinner";
import { useValidacionContacto } from '../../../components/validaciones/validacionesContacto';

export const MisDatos = () => {
    const [editarDatosContacto, setEditarDatosContacto] = useState(true);
    const [cuentasPagoUsuario, setCuentasPagoUsuario] = useState([]);
    const [isOpenEliminarCuenta, setIsOpenEliminarCuenta] = useState(false);
    const [isOpenEditarCuenta, setIsOpenEditarCuenta] = useState(false);
    const [isOpenEditarCuentaBE, setIsOpenEditarCuentaBE] = useState(false);
    const [cuentaAEliminar, setCuentaAEliminar] = useState();
    const [cuentaAEditar, setCuentaAEditar] = useState();
    const [crearCuenta, setCrearCuenta] = useState();
    const [cantidadActualizables, setCantidadActualizables] = useState(0);
    const [debeValidarDC, setDebeValidarDC] = useState(false);
    const [codigo, setCodigo] = useState("");
    const [mensajeOk, setMensajeOk] = useState("");
    const [contactoPredeterminado, setContactoPredeterminado] = useState({
        id: '',
        nombre: '',
        apellido: '',
        direccion: '',
        telefono: '',
        emailPublico: '',
        email: '',
        mailConfirmacion: '',
    });
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores",
        campo: ""
    });
    const [errorCodigo, setErrorCodigo] = useState({
        error: false,
        mensaje: "Sin errores",
    });

    const [isHovered, setIsHovered] = useState(false);

    const [isProcesando, setIsProcesando] = useState(true);
    const { validarDatosContacto } = useValidacionContacto();

    const [camposError, setCamposError] = useState(undefined);

    useEffect(() => {
        setIsProcesando(true);
        debeValidarDatosContacto();
        obtenerContactoPredeterminadoService();
        obtenerCuentasPagoUsuarioService();
        setIsProcesando(false);

    }, [isOpenEliminarCuenta, isOpenEditarCuenta])

    useEffect(() => {
    }, [cuentasPagoUsuario])

    const obtenerContactoPredeterminadoService = () => {
        CuentaService.getContactoPredeterminadoUsuario()
            .then((response) => {
                if (response.respCodigo === 'OK') {
                    setContactoPredeterminado({
                        id: response.contactoDTO.id,
                        nombre: response.contactoDTO.nombre,
                        apellido: response.contactoDTO.apellido,
                        direccion: response.contactoDTO.direccion,
                        telefono: response.contactoDTO.telefono,
                        emailPublico: response.contactoDTO.emailPublico,
                        email: response.contactoDTO.email,
                        mailConfirmacion: response.contactoDTO.email,
                    });
                }
            })
    }

    const obtenerCuentasPagoUsuarioService = () => {
        CuentaService.getCuentasPagoUsuario()
            .then(response => {
                let cuentasPago = [];
                if (response.respCodigo === 'OK') {
                    for (let index = 0; index < response.cuentas.length; index++) {
                        cuentasPago.push(response.cuentas[index]);
                    }
                }
                setCuentasPagoUsuario(cuentasPago);
            })
    }

    const solicitarNuevoCodigoValidacion = (event) => {
        event.preventDefault();
        setErrorCodigo({
            error: false,
            mensaje: ""
        })

        UsuarioService.solicitarNuevoCodigoValidacion()
            .then(response => {
                if (response.respCodigo === "OK") {
                    setMensajeOk(response.respMensaje)
                } else {
                    setErrorCodigo({
                        error: true,
                        mensaje: response.respMensaje
                    })
                }
            })
    }

    const debeValidarDatosContacto = () => {
        UsuarioService.debeValidarDatosContacto()
            .then(response => {
                if (response.esRespOK) {
                    setDebeValidarDC(response.ok);
                }
            })
    }

    const validarCodigoDatosContacto = (event) => {
        event.preventDefault();
        setMensajeOk("");

        UsuarioService.validarCodigoDatosContacto(codigo)
            .then(response => {
                if (response.esRespOK) {
                    if (response.respCodigo === "OK") {
                        setMensajeOk(response.respMensaje);
                        setDebeValidarDC(false);
                    } else {
                        setErrorCodigo({
                            error: true,
                            mensaje: response.respMensaje,
                        })
                    }
                }
            })
    }

    const onChangeEditarDatosContacto = (e) => {
        e.preventDefault();
        setEditarDatosContacto(false);
    }

    const onGuardarDatosContacto = (e) => {
        e.preventDefault();
        setCamposError([])
        let validacion = validarDatosContacto(contactoPredeterminado, setError, true, true);
        if (validacion.error) {
            setError({
                error: true,
                mensaje: validacion.mensaje,
                campo: validacion.campo
            });
            if (validacion.camposError) {
                procesarCamposError(validacion.camposError);
            }
        }
        if (!validacion.error) {
            setEditarDatosContacto(true);
            let requestDTO = {
                contacto : contactoPredeterminado,
                ignorarApellido : true
            }
            CuentaService.actualizarContactoPredeterminadoUsuario(requestDTO)
                .then(response => {
                    if (response.respCodigo === 'OK') {
                        obtenerCantidadContactosActualizables();
                    } else {
                        setError({
                            error: true,
                            mensaje: response.respMensaje,
                            campo: ""
                        })
                    }
                });
        }
    }

    const obtenerCantidadContactosActualizables = () => {
        UsuarioService.obtenerCantidadContactosActualizables()
            .then(response => {
                if (response.esRespOK) {
                    setCantidadActualizables(response.cantidad);
                    setIsOpenEditarCuentaBE(true);
                }
            })
    }

    const eliminarCuentaPago = (templateCuentaPagoId) => {
        CuentaService.eliminarCuentaPagoUsuario(templateCuentaPagoId)
            .then((response) => {
                if (response !== undefined && response.respMensaje === 'OK') {
                    setMensajeOk('La cuenta se eliminó correctamente.');

                    setIsOpenEliminarCuenta(false);
                }
            });
    };

    const handleOnChangeCodigo = (e) => {
        e.preventDefault();
        setCodigo(e.target.value);
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        setContactoPredeterminado({
            ...contactoPredeterminado,
            [e.target.name]: e.target.value
        });
    }

    const handleEliminarCuenta = (cuenta) => {
        setCuentaAEliminar(cuenta);
        setIsOpenEliminarCuenta(true)
    }

    const handleEditarCuenta = (cuenta, isNuevaCuenta) => {
        setCuentaAEditar(cuenta);
        setCrearCuenta(isNuevaCuenta);
        setIsOpenEditarCuenta(true)
    }

    const renderCuentasTabla = () => {
        return cuentasPagoUsuario.map(cuenta => {

            return (
                <tr id={cuenta.id} key={cuenta.id}>
                    <td><a onMouseOver={handleMouseOver}
                           onMouseOut={handleMouseOut}
                           style={{ cursor: isHovered ? 'pointer' : 'default' }}
                           onClick={() => handleEditarCuenta(cuenta, false)}
                           targets="idDialogCrearCuenta">{cuenta.nombre}</a></td>
                    <td>{cuenta.cuenta.formaPago.nombre}</td>
                    <td>
                        <Link to="/administrar/mis-dominios"
                              state={{
                                    habilitarInput: true,
                                    cuentaId: cuenta.cuenta,
                                    opcionFiltroPago: 1
                                }}
                              id={cuenta.id}>Ver</Link>
                    </td>
                    <td><a onMouseOver={handleMouseOver}
                           onMouseOut={handleMouseOut}
                           style={{ cursor: isHovered ? 'pointer' : 'default' }}
                           onClick={() => handleEliminarCuenta(cuenta)}>Eliminar</a></td>
                </tr>
            );
        });
    };

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    function procesarCamposError(camposError) {
        let camposConError = [];
        camposError.forEach(c => camposConError.push(c.codigo));
        setCamposError(camposConError);
    }

    const  styleError = (campo, campo2) => {
        if (camposError !== undefined) {
            if (camposError.includes(campo)) {
                return 'red'
            }
            if (campo2 !== undefined && camposError.includes(campo2)) {
                return 'red'
            }
        }
        return '';
    }


    return <>
        <ValidacionAutenticado/>
        { isOpenEliminarCuenta &&
            <ModalEliminarCuenta handleOnClose={()=>{setIsOpenEliminarCuenta(false)}}
                                 isOpen={isOpenEliminarCuenta}
                                 cuenta={{cuentaAEliminar}}
                                 eliminarCuentaPago={eliminarCuentaPago}/>
        }

        { isOpenEditarCuenta &&
            <ModalEditarCuenta handleOnClose={()=>{setIsOpenEditarCuenta(false)}}
                               isOpen={isOpenEditarCuenta}
                               setIsOpen={setIsOpenEditarCuenta}
                               cuenta={{cuentaAEditar}}
                               crearCuenta={crearCuenta}/>
        }

        { isOpenEditarCuentaBE &&
            <ModalEditarContactoBE handleOnClose={()=>{setIsOpenEditarCuentaBE(false)}}
                                   isOpen={isOpenEditarCuentaBE}
                                   setIsOpen={setIsOpenEditarCuentaBE}
                                   cantidadActualizables={cantidadActualizables}
                                   contactoActualizar={contactoPredeterminado}
            />
        }

        {mensajeOk !== undefined && mensajeOk !== "" && <MensajeOk mensajeOk={mensajeOk}/>}

        {errorCodigo !== undefined && errorCodigo.error && <MensajeError mensajeError={errorCodigo.mensaje}/>}

        <h2><strong>Mis Datos</strong></h2>

        { isProcesando?
            <div>
                <FeedbackSpinner open={isProcesando} message={'Buscando mensajes...'}/>
            </div> : <></>
        }

        <div className="cfx">
            <p className="msjRequerido right">Los campos marcados con * son requeridos.</p>
        </div>

        { debeValidarDC &&
            <fieldset id="id-validar-codigo-fieldset">
                <legend>Validar Correo Electrónico para Notificaciones</legend>

                <form id="id-validar-codigo">
                    <div className="form-item">
                        <label htmlFor="codigo">Código</label>
                        <input id="codigo" type="text" name="codigo" onChange={handleOnChangeCodigo} placeholder="Pegar código recibido por correo"/>
                    </div>

                    <div className="right">
                        <button id="id-submit-validar-codigo" className="btn edit" onClick={validarCodigoDatosContacto}>Validar correo</button>
                        {/*<s:url var="urlGenerarNuevoCodigo" namespace="/private/mis-datos" action="enviarNuevoCodigo"/>*/}
                        {/*<url var="urlGenerarNuevoCodigo" namespace="/private/mis-datos" action="enviarNuevoCodigo"/>*/}

                        {/*<s:a href="%{#urlGenerarNuevoCodigo}" id="id-submit-pedir-nuevo-codigo" cssClass="btn edit">Solicitar nuevo código de validación</s:a>*/}
                        <button id="id-submit-pedir-nuevo-codigo" className="btn edit" onClick={solicitarNuevoCodigoValidacion}>Solicitar nuevo código de validación</button>
                    </div>
                </form>
            </fieldset>
        }

        <form id="id-form-datos-contacto">
            <fieldset>
                <legend>Datos de Contacto Públicos</legend>

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <input style={{display: 'none'}} type="text" name="invCheck" value={contactoPredeterminado.id} id="id-check-datos-contacto" disabled={true}/>

                <fieldset>
                    <legend>Datos Públicos</legend>

                    <div className="message-box" id="register-warning">
                        <h3>Los siguientes datos serán utilizados como datos de contacto por defecto para los contactos Administrativo, Técnico y Financiero de los dominios que usted registre. Serán datos públicos y podrán ser consultados a través del whois.</h3>
                    </div>

                    <div className="form-item">
                        <label htmlFor="id-nombre">Nombre *</label>
                        <input type={"text"} id="id-nombre" name="nombre"
                               style={{borderColor: styleError('nombre')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.nombre} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-apellido">Apellido *</label>
                        <input type={"text"} id="id-apellido" name="apellido"
                               style={{borderColor: styleError('apellido')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.apellido} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-direccion">Direcci&oacute;n *</label>
                        <input type={"text"} id="id-direccion" name="direccion"
                               style={{borderColor: styleError('direccion')}}
                               className="edit" disabled={editarDatosContacto} value={contactoPredeterminado.direccion} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-telefono">Tel&eacute;fono *</label>
                        <input type={"text"} id="id-telefono" name="telefono"
                               style={{borderColor: styleError('telefono')}}
                               className="edit" maxLength="30" disabled={editarDatosContacto} value={contactoPredeterminado.telefono} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-mail-publico">Correo electr&oacute;nico p&uacute;blico *</label>
                        <input type={"text"} id="id-mail-publico" name="emailPublico"
                               style={{borderColor: styleError('emailPublico')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.emailPublico} onChange={handleOnChange}/>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Correo Electrónico para Notificaciones</legend>

                    <div className="message-box" id="register-warning">
                        <h3>Se le enviarán notificaciones importantes relacionadas con sus dominios al siguiente correo electrónico.</h3>
                    </div>

                    <div className="form-item">
                        <label htmlFor="id-mail">Correo electr&oacute;nico *</label>
                        <input type={"text"} id="id-mail" name="email"
                               style={{borderColor: styleError('email', 'emailMismatch')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.email} onChange={handleOnChange}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="id-mail-confirmacion">Confirmar correo electr&oacute;nico *</label>
                        <input type={"text"} id="id-mail-confirmacion" name="mailConfirmacion"
                               style={{borderColor: styleError('mailConfirmacion', 'emailMismatch')}}
                               disabled={editarDatosContacto} value={contactoPredeterminado.mailConfirmacion} onChange={handleOnChange}/>
                    </div>
                </fieldset>

                <div className="right">
                    { editarDatosContacto ?
                        <button id="id-submit-guardar-datos-contacto" className="btn edit" onClick={onChangeEditarDatosContacto}>Editar datos</button>
                        :
                        <button id="id-submit-editar-datos-contacto" className="btn" onClick={onGuardarDatosContacto}>Guardar datos</button>
                    }
                </div>
            </fieldset>
        </form>

        <div className="box" id="id-div-cuentas">

            <div className="top">
                <h4>Formas de pago</h4>
            </div>

            <div className="box-content cfx">

                <table className="recommend">
                    <thead>
                    <tr>
                        <th>Nros de cuenta de Antel</th>
                        <th>Tipo</th>
                        <th>Dominios asociados</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        {cuentasPagoUsuario.length > 0 ? renderCuentasTabla() : <tr><td colSpan="4">No posee ninguna cuenta aún.</td></tr>}
                    </tbody>
                </table>
                <div className="right">
                    <button style={{display: 'block'}} className="btn" onClick={() => {handleEditarCuenta(undefined, true)}}>Agregar forma de pago</button>
                </div>
            </div>
        </div>

        {/*fin contenido*/}
    </>
}
