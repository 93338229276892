import React, {useContext, useEffect, useState} from 'react'
import AppContext from "../../../../store/AppContext";
import {CarritoComprasSeleccionarCuenta} from "../../../carrito/paso_03/CarritoComprasSeleccionarCuenta";
import CarritoService from "../../../../services/CarritoService";
import stylesHome from "../../HomeTabTAR.module.css";
import PropTypes from "prop-types";
import {buildCrearCuentaPagoRequest} from "../../../../dto/CuentaDTOBuilder";
import {guardarMediopago, obtenerMediopago} from "../SolTransLocalStorageUtils";
import {TipoMensaje} from "../../../../store/AppProvider";
import cuentaService from "../../../../services/CuentaService";

const FormularioCuentaPago = (props) => {
    const appCtx = useContext(AppContext);

    const [templateCuentaPago, setTemplateCuentaPago] = useState(undefined);
    const [templateCuentaPagoGuardada, setTemplateCuentaPagoGuardada] = useState(undefined);
    const [formasDePago, setFormasDePago] = useState([]);
    const [formaDePagoActual, setFormaDePagoActual] = useState(undefined);

    const [cuentaNoSeleccionadaError, setCuentaNoSeleccionadaError] = useState(false);

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });


    useEffect(() => {
        obtenerFormasDePago();

        const medioPagoGuardado = obtenerMediopago();
        if (medioPagoGuardado) {
            setTemplateCuentaPagoGuardada(medioPagoGuardado.templateCuentaPagoDTO.id);
        }
    }, []);

    const handleOnChangeTemplateCuentaPago = (templateCuenta) => {
        setTemplateCuentaPago(templateCuenta);
        setCuentaNoSeleccionadaError(false);
    }

    const handleOnChangeComboFormaPago = (event) => {
        setFormaDePagoActual(formasDePago.find(formaDePago => formaDePago.id === +event.target.value));
        setCuentaNoSeleccionadaError(false);
    }

    const obtenerFormasDePago = () => {
        CarritoService.obtenerFormasDePago().then(response => {
            if (response !== undefined && response.respuestaOK) {
                setFormasDePago(response.formasDePago);
                setFormaDePagoActual(response.formasDePago[0])
            }
        })
    }

    const handleContinuar = () => {
        if (!templateCuentaPago || templateCuentaPago.id === 0) {
            setCuentaNoSeleccionadaError(true);
            appCtx.mostrarMensaje("Debe seleccionar una Cuenta de Pago para continuar", TipoMensaje.error);
            return;
        }

        if (templateCuentaPago.id !== -1) {
            const nuevaCuenta = buildCrearCuentaPagoRequest(null, templateCuentaPago, formaDePagoActual);
            guardarMediopago(nuevaCuenta);
            props.handleContinuarOK();
            appCtx.clean();
        } else {
            cuentaService.crearCuentaPago(null, templateCuentaPago, formaDePagoActual)
                .then((response) => {
                    if (response.respCodigo !== 'OK') {
                        appCtx.mostrarMensaje(response.respMensaje, TipoMensaje.error);
                    } else {
                        guardarMediopago(
                            {
                                uuidVenta: null,
                                templateCuentaPagoDTO: response.templateCuentaPago
                            }
                        );
                        props.handleContinuarOK();
                        appCtx.clean();
                    }
                }).catch((error) => {
                appCtx.mostrarMensaje('Se produjo un error al crear la cuenta de pago', TipoMensaje.error);
            });
        }
    };

    return <>
        <div id="step-content" className={stylesHome.stepsContainer}>
            <div id="step-3" className={stylesHome.stepContent}>

                <p>Seleccionar la cuenta de Antel de telefonía fija o datos que se utilizará para realizar la
                    compra.</p>
                <hr/>
                <div id="id-form-cuenta-pago">
                    <fieldset style={{marginBottom: "0px"}} id="idFielsetFormaPago">
                        <legend>Forma de Pago</legend>
                        <div className="form-item">
                            {formaDePagoActual !== undefined &&
                                <select onChange={handleOnChangeComboFormaPago} value={formaDePagoActual.id}>
                                    {formasDePago.map(formaPago =>
                                        <option key={formaPago.id} value={formaPago.id}>{formaPago.nombre}</option>
                                    )}
                                </select>}
                            <p className="note" style={{marginLeft: "0px"}}>Seleccione la forma de pago</p>
                        </div>
                    </fieldset>

                    {formaDePagoActual !== undefined && formaDePagoActual.id === 1 &&
                        <div id="id-div-nueva-cuenta">
                            <div id="div-fieldset-nueva-cuenta">
                                <CarritoComprasSeleccionarCuenta
                                    style={cuentaNoSeleccionadaError ? {borderColor: "red"} : {}}
                                    ocultarMsjCuentaPago={true}
                                    onChangeTemplateCuentaPago={handleOnChangeTemplateCuentaPago}
                                    templateCuentaGuardada={templateCuentaPagoGuardada}
                                    setError={setError}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

        <div className="right">
            <input type="button" value="Continuar" id="id-submit-datos-cuenta" className="btn edit"
                   style={{padding: "7px 12px"}} onClick={handleContinuar}/>
        </div>
    </>
}
export default FormularioCuentaPago;

FormularioCuentaPago.propTypes = {
    handleContinuarOK: PropTypes.func.isRequired,
    handleContinuarError: PropTypes.func.isRequired,
    completedSteps: PropTypes.number,
};