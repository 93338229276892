import React, {useState} from 'react'
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {MensajeError} from "../../../../view/MensajeError";

export const ModalAlojarDominio = ({ nombreDominio, handleOnClose, isOpen, irVerDominio }) => {

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    const alojarDominio = (e) => {
        e.preventDefault();
        let alojarDominioDTO = {
            nombreDominio : nombreDominio
        };
        setError({error : false, mensaje: "Sin errores"})
        AdministracionUsuarioService.alojarDominio(alojarDominioDTO)
            .then(response => {
                if (!response.esRespOK) {
                    setError({
                        error: true,
                        mensaje: response.mensaje
                    })
                } else {
                    let mensajeOk = {
                        mostrarMensaje: true,
                        mensaje : response.mensaje
                    }
                    irVerDominio(mensajeOk)
                    handleOnClose();
                }
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al alojar dominio."
                })
            })
    }

    return <>
        <Modal
            idDivModal="idModalAlojar"
            handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
            <fieldset id="id-fieldset-alojar">
                <legend>Alojar dominio</legend>

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <form id="id-form-alojar-dominio">
                    <div className="form-item">
                        <p>{ "¿Confirma que desea alojar el dominio " + nombreDominio + "?"}</p>
                    </div>
                    <div className="form-item">
                        <button id="id-submit-alojar-dominio" className="btn" onClick={alojarDominio}>Alojar dominio</button>
                    </div>
                </form>
            </fieldset>
        </Modal>
    </>
}