import React, {useEffect, useState} from 'react'
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {MensajeError} from "../../../../view/MensajeError";
import {useValidacionesRegistro} from "../../../validaciones/ValidacionesRegistro";

export const ModalEditarRegistroDominio = ({ dominio, nombreDominio, registro, campos, registros,
                                               actualizarInformacionRegistros, setMensajeOk,
                                               handleOnClose, isOpen, handleOnCloseOk, estructuraRegistro }) => {

    const [camposEditar, setCamposEditar] = useState(campos);
    const [mostrarInfoSubdominio, setMostrarInfoSubdominio] = useState(false);
    const [infoSubdominio, setInfoSubdominio] = useState(<></>);
    const [ttl, setTtl] = useState(null);

    useEffect(() => {
        if (registro && registro.ttl) {
            setTtl(convertirSegundosATtl(registro.ttl));
        }
    }, [registro]);

    const handleTtlChange = (e) => {
        setTtl(e.target.value);
    };

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    const [camposError, setCamposError] = useState(undefined);

    const validacionesRegistro = useValidacionesRegistro();


    useEffect(() => {
        setMostrarInfoSubdominio(isMostrarSubdominioTipoRegistro());
        actualizarInfoSubdominio();
    }, []);

    function isMostrarSubdominioTipoRegistro() {
        let tipoRegistro = estructuraRegistro.tipo;
        if (tipoRegistro === 'CNAME' || tipoRegistro === 'MX' || tipoRegistro === 'NS'){
            return true;
        }
        return false;
    }

    function getValorCampo(nombreCampo) {
        let campo = camposEditar.find((campo) => campo.nombre === nombreCampo);
        if (campo !== undefined){
            return campo.valor;
        }
        return '';
    }

    function actualizarInfoSubdominio() {
        switch (registro.estructura.tipo){
            case 'CNAME':
                let cname = getValorCampo('cname');
                let cannonical_name = getValorCampo('cannonical_name');
                let infoNombre = cname.slice(-1) !== "." ? "Alias resultante: " + cname + "." + nombreDominio + "." : "Alias resultante: " + cname;
                let infoServidor = cannonical_name.slice(-1) !== "." ? "Nombre resultante: " + cannonical_name + "." + nombreDominio + "." : "Nombre resultante: " + cannonical_name;
                setInfoSubdominio(<>
                    <div><text>{infoNombre}</text></div>
                    {/*<br/>*/}
                    <div><text>{infoServidor}</text></div>
                </>)
                break
            case 'MX':
                let name = getValorCampo('name');
                let mail_server = getValorCampo('mail_server');
                let infoNombreMX = name.slice(-1) !== "." ? "Nombre resultante: " + name + "." + nombreDominio + "." : "Nombre resultante: " + name;
                let infoServidorMX = mail_server.slice(-1) !== "." ? "Servidor resultante: " + mail_server + "." + nombreDominio + "." : "Servidor resultante: " + mail_server;
                setInfoSubdominio(<>
                    <div><text>{infoNombreMX}</text></div>
                    {/*<br/>*/}
                    <div><text>{infoServidorMX}</text></div>
                </>)
                break
            case 'NS':
                let nameNS = getValorCampo('name');
                let target = getValorCampo('target');
                let infoNombreNS = nameNS.slice(-1) !== "." ? "Nombre resultante: " + nameNS + "." + nombreDominio + "." : "Nombre resultante: " + nameNS;
                let infoServidorNS = target.slice(-1) !== "." ? "Servidor resultante: " + target + "." + nombreDominio + "." : "Servidor resultante: " + target;
                setInfoSubdominio(<>
                    <div><text>{infoNombreNS}</text></div>
                    {/*<br/>*/}
                    <div><text>{infoServidorNS}</text></div>
                </>)
                break
            default:
                setInfoSubdominio(<></>)
        }
    }

    function procesarCamposError(camposError) {
        let camposConError = [];
        camposError.forEach(c => camposConError.push(c.nombre));
        setCamposError(camposConError);
        console.log(camposConError)
    }

    const modificarRegistro = (e) => {
        e.preventDefault();
        setMensajeOk({mostrarMensaje : false, mensaje: ""})
        setError({error : false, mensaje: "Sin errores"})
        let tipoRegistroActual = registro.estructura.tipo;

        if (validarCamposRegistro()) {
            setError({
                error: true,
                mensaje: "Debe completar todos los campos obligatorios."
            })
        } else {
            const ttlEnSegundos = convertirTtlASegundos(ttl);

            let valorNombreCampo = validacionesRegistro.getValorNombreRegistro(campos);

            if (!validacionesRegistro.validarNombreDuplicado(tipoRegistroActual, valorNombreCampo, registros, setError, true, registro.idInterno)){
                return;
            }

            if (!validacionesRegistro.validarValorIpRegistro(tipoRegistroActual, campos, setError, setCamposError)){
                return;
            }
            
            let editarRegistroDTO = {
                nombreDominio: nombreDominio,
                registro: { ...registro, campos: camposEditar, ttl: ttlEnSegundos },
                registros: registros,
                estructuraRegistro: estructuraRegistro
            };

            AdministracionUsuarioService.modificarRegistroDominio(editarRegistroDTO)
                .then(response => {
                    if (!response.respuestaOK) {
                        setError({
                            error: true,
                            mensaje: response.mensajeRespuesta
                        })
                        if (response.camposConError && response.camposError){
                            procesarCamposError(response.camposError);
                        }
                    } else {
                        actualizarInformacionRegistros(dominio);
                        setMensajeOk({mostrarMensaje: true, mensaje : "La edición del registro se realizó correctamente."});
                        handleOnCloseOk();
                    }
                })
                .catch(() => {
                    setError({
                        error: true,
                        mensaje: "Ha ocurrido un error al modificar los registros del dominio."
                    })
                })
        }
    };

    const convertirTtlASegundos = (ttl) => {
        switch (ttl) {
            case '10min':
                return 600;
            case '30min':
                return 1800;
            case '60min':
                return 3600;
            case '6hrs':
                return 21600;
            case '12hrs':
                return 43200;
            case '24hrs':
            default:
                return 86400;
        }
    };


    const handleOnChangeValorCampo = (e) => {
        let camposNuevo = camposEditar.map((campo) => {
            if (campo.nombre === e.target.name){
                campo.valor = e.target.value;
            }
            return campo;
        })

        if (isMostrarSubdominioTipoRegistro()) {
            actualizarInfoSubdominio();
        }

        setCamposEditar(camposNuevo);
    }

    const  styleError = (campo) => {
        if (camposError !== undefined) {
            return camposError.includes(campo) ? 'red' : '';
        }
    }

    const validarCamposRegistro = () => {
        let camposConError = [];
        switch (registro.estructura.tipo){
            case 'CNAME':
                checkErrorVacio('cname', getValorCampo('cname'), camposConError);
                checkErrorVacio('cannonical_name', getValorCampo('cannonical_name'), camposConError);
                break
            case 'MX':
                checkErrorVacio('name', getValorCampo('name'), camposConError);
                checkErrorVacio('pri', getValorCampo('pri'), camposConError);
                checkErrorVacio('mail_server', getValorCampo('mail_server'), camposConError);
                break
            case 'NS':
                checkErrorVacio('name', getValorCampo('name'), camposConError);
                checkErrorVacio('target', getValorCampo('target'), camposConError);
                break
            case 'A':
                checkErrorVacio('name', getValorCampo('name'), camposConError);
                checkErrorVacio('ip', getValorCampo('ip'), camposConError);
                break
            case 'AAAA':
                checkErrorVacio('name', getValorCampo('name'), camposConError);
                checkErrorVacio('ipv6', getValorCampo('ipv6'), camposConError);
                break
            case 'TXT':
                checkErrorVacio('txt_name', getValorCampo('txt_name'), camposConError);
                checkErrorVacio('text', getValorCampo('text'), camposConError);
                break
            default:
        }

        setCamposError(camposConError);
        return camposConError.length > 0;
    };

    const checkErrorVacio = (campoAValidar, valor, listaErrores) => {
        if (chequearVacio(valor.trim())) {
            listaErrores.push(campoAValidar);
        } else {
            listaErrores.filter((campo) => campo !== campoAValidar);
        }
        return listaErrores;
    }

    const chequearVacio = (texto) => {
        return texto.length === 0;
    }

    const convertirSegundosATtl = (segundos) => {
        switch (segundos) {
            case 600:
                return '10min';
            case 1800:
                return '30min';
            case 3600:
                return '60min';
            case 21600:
                return '6hrs';
            case 43200:
                return '12hrs';
            case 86400:
            default:
                return '24hrs';
        }
    };

    return <>
        <Modal
            idDivModal="idModalEditarRegistroDominio"
            handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
            <fieldset id="id-fieldset-editar-registro">
                <legend>Editar registro</legend>
                <p id="descripcion-registro"><strong>
                    {registro.estructura.descripcion}
                </strong></p>

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <form id="id-form-agregar-registro">
                    <div className="form-item">
                        <label>Tipo de registro</label>
                        <label>
                            {registro.estructura.tipo}
                        </label>
                    </div>

                    <div className="form-item">
                        <label htmlFor="idSelectTtl">TTL</label>
                        <select id="idSelectTtl" value={ttl} name={"ttl"} onChange={handleTtlChange}>
                            <option value="10min">10min</option>
                            <option value="30min">30min</option>
                            <option value="60min">60min</option>
                            <option value="6hrs">6hrs</option>
                            <option value="12hrs">12hrs</option>
                            <option value="24hrs">24hrs</option>
                        </select>
                    </div>

                    <div id="id-estructura">
                        {camposEditar.map((campo, index) => (
                            <div className="form-item" key={index}>
                                <label htmlFor={"id-" + index}>
                                    {campo.etiqueta}
                                </label>
                                <input type={"text"} id={"id-" + index} name={campo.nombre}
                                       value={campo.valor}
                                       onChange={handleOnChangeValorCampo}
                                       className="edit"
                                       style={{borderColor: styleError(campo.nombre)}}/>

                                {campo.descripcion != null &&
                                    <p className="note">
                                        {campo.descripcion}
                                    </p>
                                }
                            </div>
                        ))}
                    </div>

                </form>
                <div id="id-message" style={{
                    margin: "20px 0",
                    padding: "10px",
                    backgroundColor: "#d1ecf1", color: "#0c5460",
                    border: "1px solid #bee5eb", borderRadius: "4px",
                    width: "600px"
                }}>
                    <strong>Información:</strong> Para los registros CNAME, MX y NS, añade un punto al final para
                    indicar
                    un nombre de dominio completo (FQDN). Sin este punto, se añadirá el nombre de tu dominio al valor de
                    tu registro.
                    Por favor, verifica el resultado de la configuración antes de finalizar la edición del registro.
                    {mostrarInfoSubdominio && <br/>}
                    {mostrarInfoSubdominio && <br/>}
                    {mostrarInfoSubdominio && <strong>Resultado:</strong>}
                    {mostrarInfoSubdominio && <br/>}
                    {mostrarInfoSubdominio && infoSubdominio}
                </div>
                <div className="right">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <button id="id-submit-guardar-datos-contacto" className={"btn"}
                            onClick={modificarRegistro}>Editar registro
                    </button>
                </div>
            </fieldset>
        </Modal>
    </>
}