import React, {useEffect, useState} from 'react';
import '../administrar/misDatos/MisDatos.css';
import CuentaService from "../../services/CuentaService";
import {MensajeError} from "../MensajeError";
import {MensajeOk} from "../MensajeOk";
import {ValidacionAutenticado} from "../../components/validaciones/ValidacionAutenticado";
import FeedbackSpinner from "../../components/feedback/FeedbackSpinner";
import {MisDatosContacto} from "../administrar/misDatos/MisDatosContacto";
import {useLocation, useNavigate} from "react-router-dom";

export const MisDatosPrimerLogin = () => {

    const [contactoPredeterminado, setContactoPredeterminado] = useState({
        id: '',
        nombre: '',
        apellido: '',
        direccion: '',
        telefono: '',
        emailPublico: '',
        email: '',
        mailConfirmacion: '',
    });
    const [redirectTO, setRedirectTo] = useState(null);
    const [isProcesando, setIsProcesando] = useState(false);

    const [mensajeOk, setMensajeOk] = useState("");
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores",
        campo: ""
    });

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setIsProcesando(false);
        if (location.state && location.state.redirectTO) {
            setRedirectTo(location.state.redirectTO);
        }
        let url_params = new URLSearchParams(location.hash)
        setRedirectTo(url_params.get('redirectTo'));
    }, [location])

    const guardarDatosContacto = (requestDTO) => {
        setIsProcesando(true);
        CuentaService.crearContactoPredeterminadoUsuario(requestDTO)
            .then(response => {
                setIsProcesando(false);

                if (response.respCodigo === 'OK') {
                    navigate(redirectTO ? redirectTO : "/");

                } else {
                    setError({
                        error: true,
                        mensaje: response.respMensaje,
                        campo: ""
                    })
                }
            }).finally( () => {
                setIsProcesando(false);
        } );

    }

    function actualizarDatosContacto (contactoPredeterminado) {
        setContactoPredeterminado(contactoPredeterminado);
    }

    return <>
        <ValidacionAutenticado/>

        {mensajeOk !== undefined && mensajeOk !== "" && <MensajeOk mensajeOk={mensajeOk}/>}
        {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

        <h2><strong>Mis Datos</strong></h2>

        { isProcesando?
            <div>
                <FeedbackSpinner open={isProcesando} message={'Guardando datos...'}/>
            </div> : <></>
        }

        <div className="cfx">
            <p className="msjRequerido right">Los campos marcados con * son requeridos.</p>
        </div>

        <MisDatosContacto
            contactoPredeterminado={contactoPredeterminado}
            actualizarDatosContacto={actualizarDatosContacto}
            titulo={"Datos de Contacto Públicos"}
            editarDatosContacto={false}
            setEditarDatosContacto={() => {}}
            guardarDatosContacto={guardarDatosContacto}
            setMensajeOk={setMensajeOk}>
        </MisDatosContacto>

    </>
}