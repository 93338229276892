import React from "react";
import PropTypes from "prop-types";


const TablaRegistros = props => {
    return <table className="data" style={props.customStyles}>
        <thead>
            <tr>
                <th>Nombre</th>
                <th>TTL</th>
                <th>Tipo</th>
                <th>Valor</th>
            </tr>
        </thead>
        <tbody>
            {props.registros.map((item) => (
                <tr>
                    <td>{item.nombre}</td>
                    <td>{item.ttl}</td>
                    <td>{item.tipo}</td>
                    <td>{item.valor}</td>
                </tr>
            ))}
        </tbody>
    </table>
}

TablaRegistros.propTypes = {
    registros: PropTypes.array.isRequired,
    customStyles: PropTypes.object
};

export default TablaRegistros;