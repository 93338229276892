import React from 'react'
import { Link } from "react-router-dom";

const h3Style = {
    marginBottom: '-15px',
};

const divBlockSeparator = {
    marginBottom: '10px', // Pasar a CSS
};



export const ConsultaWhoisPanelResponse = (props) => {

    return <>

        <div id="content" className="cont-whois">
            <div className="box">
                <div className="box-content cfx">
                    <h3 style={h3Style}>Resultado para <span className={"bold"}>{props.resultadoConsultaWhois.dominio}</span></h3>

                    {props.resultadoConsultaWhois ?
                        <div className="result">
                            {(props.resultadoConsultaWhois.esRespOK && props.resultadoConsultaWhois.titular) ?
                                <>
                                    <div style={divBlockSeparator}>
                                        <p><strong>Nombre del dominio</strong>&nbsp;{props.resultadoConsultaWhois.dominio}</p>

                                        {props.resultadoConsultaWhois.estado ?
                                            <>
                                                <p>Estado: {props.resultadoConsultaWhois.estado === "REGISTRADO" ? "ACTIVO" : props.resultadoConsultaWhois.estado}</p>
                                                {(props.resultadoConsultaWhois.estado === "REGISTRADO" || props.resultadoConsultaWhois.estado === "PREREGISTRO") && (
                                                    <p>
                                                        Fecha Alta: {new Date(props.resultadoConsultaWhois.fechaAlta).toLocaleDateString('es-ES', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric'
                                                    })}
                                                    </p>
                                                )}
                                            </>
                                            : <></>
                                        }
                                    </div>

                                    {props.resultadoConsultaWhois.titular ?
                                        <div style={divBlockSeparator}>
                                            <p><strong>Contacto Titular</strong></p>

                                            <p>Nombre: {props.resultadoConsultaWhois.titular.nombre} {props.resultadoConsultaWhois.titular.apellido}</p>
                                            <p>Nic-Handle: {props.resultadoConsultaWhois.titular.nicHandler}</p>
                                            <p>Correo Electrónico: {props.resultadoConsultaWhois.titular.email}</p>
                                            <p>Dirección: {props.resultadoConsultaWhois.titular.direccion}</p>
                                            <p>Teléfono: {props.resultadoConsultaWhois.titular.telefono}</p>
                                        </div> : <></>
                                    }

                                    {props.resultadoConsultaWhois.administrador ?
                                        <div style={divBlockSeparator}>
                                            <p><strong>Contacto Administrador</strong></p>

                                            <p>Nombre: {props.resultadoConsultaWhois.administrador.nombre} {props.resultadoConsultaWhois.administrador.apellido}</p>
                                            <p>Nic-Handle: {props.resultadoConsultaWhois.administrador.nicHandler}</p>
                                            <p>Correo
                                                Electrónico: {props.resultadoConsultaWhois.administrador.email}</p>
                                            <p>Dirección: {props.resultadoConsultaWhois.administrador.direccion}</p>
                                            <p>Teléfono: {props.resultadoConsultaWhois.administrador.telefono}</p>
                                        </div> : <></>
                                    }

                                    {props.resultadoConsultaWhois.tecnico ?
                                        <div style={divBlockSeparator}>
                                            <p><strong>Contacto Técnico</strong></p>

                                            <p>Nombre: {props.resultadoConsultaWhois.tecnico.nombre} {props.resultadoConsultaWhois.tecnico.apellido}</p>
                                            <p>Nic-Handle: {props.resultadoConsultaWhois.tecnico.nicHandler}</p>
                                            <p>Correo Electrónico: {props.resultadoConsultaWhois.tecnico.email}</p>
                                            <p>Dirección: {props.resultadoConsultaWhois.tecnico.direccion}</p>
                                            <p>Teléfono: {props.resultadoConsultaWhois.tecnico.telefono}</p>
                                        </div> : <></>
                                    }

                                    {props.resultadoConsultaWhois.financiero ?
                                        <div style={divBlockSeparator}>
                                            <p><strong>Contacto Financiero</strong></p>

                                            <p>Nombre: {props.resultadoConsultaWhois.financiero.nombre} {props.resultadoConsultaWhois.financiero.apellido}</p>
                                            <p>Nic-Handle: {props.resultadoConsultaWhois.financiero.nicHandler}</p>
                                            <p>Correo Electrónico: {props.resultadoConsultaWhois.financiero.email}</p>
                                            <p>Dirección: {props.resultadoConsultaWhois.financiero.direccion}</p>
                                            <p>{props.resultadoConsultaWhois.financiero.telefono}</p>
                                        </div> : <></>
                                    }

                                    {props.resultadoConsultaWhois.nameServers ?
                                        <div style={divBlockSeparator}>
                                            <p><strong>Servidores de Nombres</strong></p>
                                            {props.resultadoConsultaWhois.nameServers.map((nameServer, index) => (
                                                <p key={index}>{nameServer}</p>
                                            ))}
                                        </div> : <></>
                                    }
                                </> :
                                <>
                                    El dominio <span className={"bold"}>{props.resultadoConsultaWhois.dominio}</span> no está registrado. Puede
                                    acceder al registro del mismo mediante el&nbsp;
                                    <Link to="/consulta-disponibilidad" state={{ dominioDisponibilidadWhoIs: props.resultadoConsultaWhois.dominio }}>siguiente enlace</Link>
                                </>
                            }
                        </div> :
                        <></>
                    }
                </div>
            </div>
        </div>
    </>
}