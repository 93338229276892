import React, {useEffect, useState} from 'react'
import {MensajeError} from "../MensajeError";
import TerminosYCondicionesService from "../../services/TerminosYCondicionesService";

export const VerTerminosCondiciones = () => {

    const [termYCond, setTermYCond] = useState({});

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    useEffect(() => {
        cargarTerminosYCondiciones();
    }, [])

    function cargarTerminosYCondiciones() {
        TerminosYCondicionesService.obtenerTerminosYCondicionesActual()
            .then(response => {
                if (response.respuestaOK) {
                    setTermYCond(response.terminosYCondiciones);
                } else {
                    setError({
                        error: true,
                        mensaje: response.mensajeRespuesta
                    })
                }
            })
            .catch((e) => {
                console.log(e)
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al obtener los términos y condiciones."
                })
            })
    }

    return <>

        <h2>T&eacute;rminos y condiciones</h2>

        <fieldset>
            <legend>Ver los t&eacute;rminos y condiciones</legend>

            {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

            <form>
                <div className="form-item">
                    <div className="form-item" style={{width: "1127px", height: "433px", overflowY: "auto"}}
                         dangerouslySetInnerHTML={{__html: termYCond.contenido}}>
                    </div>
                </div>

            </form>
        </fieldset>

    </>

}
