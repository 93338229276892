import React, {useEffect, useState} from 'react'
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";

export const ModalEliminarDominio = ({ idDominioCore, nombreDominio, handleOnClose, isOpen, mensaje, setError, respuestaAccionOk, setIsProcesando, setMensajeProcesando}) => {
    const [mensajeElinimacionDominio, setMensajeEliminacionDominio] = useState("");
    const [estaEliminando, setEstaEliminando] = useState(false);

    useEffect(() => {
        obtenerMensajeEliminarDomino();
    }, [])

    const obtenerMensajeEliminarDomino = () => {
        let mensajeEliminacion = mensaje;
        mensajeEliminacion = mensajeEliminacion.replace("PLACEHOLDER_NOMBRE_DOMINIO", nombreDominio);
        setMensajeEliminacionDominio(mensajeEliminacion);
    }

    const eliminarDominio = (e) => {
        e.preventDefault();
        // Verifica si ya está en proceso de eliminación
        if (estaEliminando) return;

        setEstaEliminando(true);
        localStorage.setItem('estaEliminando', 'true');

        let accionDominioDTO = {
            nombreDominio: nombreDominio,
            idDominioCore: idDominioCore,
        };

        setError({error: false, mensaje: "Sin errores"});
        if (setIsProcesando !== undefined){
            setMensajeProcesando('Procesando eliminación')
            setIsProcesando(true);
        }
        AdministracionUsuarioService.eliminarDominio(accionDominioDTO)
            .then(response => {
                console.log(response);
                if (!response.esRespOK) {
                    setError({
                        error: true,
                        mensaje: response.mensaje,
                    });
                } else {
                    respuestaAccionOk(response.mensaje);
                }
                handleOnClose();
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al eliminar el dominio.",
                });
            })
            .finally(() => {
                if (setIsProcesando !== undefined){
                    setIsProcesando(false);
                }
                localStorage.removeItem('estaEliminando');
                setEstaEliminando(false);
            });
    };

    return <>
        <Modal
            idDivModal="idModalLiberar"
            handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
            <p>
                <span dangerouslySetInnerHTML={{ __html: mensajeElinimacionDominio }}></span>
            </p>
            <hr className={"linea"}></hr>
            <div className="right">
                <button className={'btn'} onClick={eliminarDominio}>Confirmar</button>
                <button className={'btn'} onClick={handleOnClose}>Cancelar</button>
            </div>
        </Modal>
    </>
}