import React, {useContext} from "react";
import AppContext from "../../../store/AppContext";
import DominioRegistroService from "../../../services/RegistroDominioService";
import {TipoItemCarrito} from "../../../store/AppProvider";
import {useNavigate} from "react-router-dom";

export const ListaRegistroDominiosPasoUno = (props) => {
    const appCtx = useContext(AppContext);
    const carrito = appCtx.carrito.registrosPendientes;
    let uuidVenta = appCtx.venta.uuidVenta;
    const history = useNavigate();

    const removeFromCarrito = (e)=> {
        e.preventDefault();
        const dominioId = e.target.id;
        DominioRegistroService.eliminarRegistroDominio(dominioId, uuidVenta)
            .then((response) => {
                if (response.respuestaOK) {
                    appCtx.eliminarItemCarrito(dominioId, TipoItemCarrito.registro);
                    if (appCtx.carrito.registrosPendientes.length === 1) {
                        history("/consulta-disponibilidad");
                    }
                }
            }).catch(() => {
                // Ignore
            });
    }

    return (
        <>
            {carrito == null || carrito.length === 0 ?
                <></> :
                <>
                    <table id="id-tabla-reg-dom" className="cart">
                        <caption>Registrar dominio</caption>
                        <thead>
                            <tr>
                                <th>Artículo</th>
                                <th>Duración</th>
                                <th className="tdright">Precio</th>
                            </tr>
                        </thead>
                        <tbody id="register_domains_tbody">
                            { carrito.map((dominio) => (

                                <tr className="last" key={dominio.nombre}>
                                    <td>
                                    {/* eslint-disable-next-line */}
                                    <a className="close" id={dominio.id} onClick={ removeFromCarrito }></a>
                                    { dominio.nombre }
                                    </td>
                                    <td>
                                        <select name="anios-dominio" id="id-anios-dominio">
                                            <option value="1">1 año</option>
                                        </select>
                                    </td>
                                    <td className="tdright">$ { dominio.precio }</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td className="tdright">Subtotal:</td>
                                <td className="price" id="id_shopping_cart_subtotal">$ {props.subTotal}</td>
                            </tr>
                        </tfoot>
                    </table>
                </>
            }
        </>
    );
}