import React, {useContext, useEffect, useState} from "react";
import {FormularioContacto} from "./Paso2/FormularioContacto";
import FormularioCuentaPago from "./Paso3/FormularioCuentaPago";
import AppContext from "../../../store/AppContext";
import {FormularioValidacionDominio} from "./Paso1/FormularioValidacionDominio";
import Resumen from "./Paso4/Resumen";
import styles from "../HomeTabTAR.module.css";
import {obtenerDominioValidado} from "./SolTransLocalStorageUtils";
import {useNavigate} from "react-router-dom";


const LAST_STEP = 5;

const SolicitarTransferencia = (props) => {
    const appCtx = useContext(AppContext);
    const [currentStep, setCurrentStep] = React.useState(1);
    const [completedSteps, setCompletedSteps] = React.useState(0);
    const [textoBotonContinuar, setTextoBotonContinuar] = React.useState('Continuar');
    const [showTransferDomain, setshowTransferDomain] = useState(false);
    const history = useNavigate();

    // Se obtiene si existe algún dominio ya validado guardado en la sesión
    const dominio = obtenerDominioValidado();
    function irAPaso(paso, event = null) {
        if (event) {
            event.preventDefault();
        }

        if (paso < 1 || paso > LAST_STEP) {
            return;
        }

        setCurrentStep(paso);
    }

    const stepperClassNameBuilder = (stepToBuild) => {
        let className = 'cfx';

        if (stepToBuild === currentStep) {
            className += stepToBuild === LAST_STEP ? ' last-child-selected' : ' selected';
        }

        if (stepToBuild === LAST_STEP && currentStep !== LAST_STEP) {
            className += ' last-child';
        }

        if (stepToBuild < currentStep) {
            className += stepToBuild === currentStep -1?
                ' previously-selected next-step-is-selected' : ' previously-selected';
        }

        return className;
    }

    /*React.useEffect(() => {
        if (currentStep === 1) {
            setTextoBotonContinuar('Ingresar datos de Contacto');
        } else if (currentStep === 2) {
            setTextoBotonContinuar('Seleccionar forma de pago');
        } else if (currentStep === 3) {
            setTextoBotonContinuar('Solicitar Transferencia');
        }
    }, [currentStep]);*/

    React.useEffect(() => {
        if (currentStep === 4) {
            setTextoBotonContinuar('Solicitar Transferencia');
        }
    }, [currentStep]);

    useEffect(() => {
    }, []);

    const updateProperty = (showTransferDomainResult) => {
        if (!showTransferDomainResult) {
            history("/consulta-disponibilidad");
        }
    };

    return (
        <>
            <h2>Transferencia de Dominio</h2>
            <div className="contactenos_body">
                <p className={styles.info}>Una transferencia de dominio se refiere al proceso de cambiar el Agente Registrador de un nombre de dominio.</p>
                <p className={styles.info}>Una vez que se ha realizado la transferencia, el nuevo registrador mantendrá toda la información relevante sobre el dominio y el titular.
                    Los nombres de dominio solo pueden ser transferidos si han sido registrados con el registrador anterior durante 60 días o más y además se debe cumplir
                    que no hayan sido transferidos a otro registrador en los últimos 60 días calendario.</p>
            </div>

            <div id="stepper">
                <ul className="cfx">
                    <li className={stepperClassNameBuilder(1)}>
                        <div className="step-box">
                            <div className="title">Validar dominio para Transferencia</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                    <li className={stepperClassNameBuilder(2)}>
                        <div className="step-box">
                            <div className="title">Datos de Contacto</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                    <li className={stepperClassNameBuilder(3)}>
                        <div className="step-box">
                            <div className="title">Forma de pago</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                    <li className={stepperClassNameBuilder(4)}>
                        <div className="step-box">
                            <div className="title">Resumen</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                    <li className={stepperClassNameBuilder(5)}>
                        <div className="step-box">
                            <div className="title">Confirmación</div>
                        </div>
                        <div className="arrow"></div>
                    </li>
                </ul>
            </div>

            <div id="steps-container">
                {/*Paso 1 - Validar dominio para Transferencia*/}
                { currentStep === 1 &&
                    <FormularioValidacionDominio
                        handleContinuarBtnDisabled={completedSteps === 0 && (dominio === null || dominio === undefined)}
                        onValidacionOK={() => setCompletedSteps(1)}
                        onValidacionError={() => setCompletedSteps(0)}
                        handleContinuar={() => setCurrentStep(2)}
                        completedSteps={completedSteps}/> }

                {/*Paso 2 - Datos de contacto y código de autorización*/}
                { currentStep === 2 &&
                    <FormularioContacto
                        handleContinuarOK={() => {
                            setCompletedSteps(2)
                            setCurrentStep(3);
                        }}
                        handleContinuarError={() => {
                            setCompletedSteps(1)
                            setCurrentStep(2);
                        }}
                        completedSteps={completedSteps} /> }

                {/*Paso 3 - Datos de Pago*/}
                { currentStep === 3 &&
                    <FormularioCuentaPago
                        handleContinuarOK={() => {
                            setCompletedSteps(3)
                            setCurrentStep(4);
                        }}
                        handleContinuarError={() => {
                            setCompletedSteps(2)
                            setCurrentStep(3);
                        }}
                        completedSteps={completedSteps} />}

                {/*Paso 4 - Resumen*/}
                { currentStep === 4 && <Resumen/>}

                {/*Paso 5 - Confirmacion. El paso 5 es una página independiente y se redirige a ella al
                confirmar la solicitud dentro de Resumen */}
            </div>

            {currentStep > 1 && <p>
                <a href="#" onClick={(event) => irAPaso(currentStep-1, event)}>Volver al paso anterior</a>
            </p>}
        </>
    )
}

export default SolicitarTransferencia;