import React, { useEffect, useState } from 'react'
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import {MensajeError} from "../../../MensajeError";
import { useValidacionContacto } from '../../../../components/validaciones/validacionesContacto';

export const ModalModificarDatosContacto = ({handleOnClose, isOpen, setIsOpen, dominiosSeleccionados, mapPermisosDominio, rolSeleccionado, respuestaAccionOk}) => {
    const [dominiosAplicaAccion, setDominiosAplicaAccion] = useState([]);
    const [dominiosNoAplicaAccion, setDominiosNoAplicaAccion] = useState([]);
    const [respuestaObtenida, setRespuestaObtenida] = useState(false);
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const [camposError, setCamposError] = useState(undefined);
    const [contacto, setContacto] = useState({
        nombre: '',
        apellido: '',
        direccion: '',
        telefono: '',
        emailPublico: '',
    })
    const { validarDatosContacto } = useValidacionContacto();

    useEffect(() => {
        obtenerPermisosDominios();
    },[])


    const obtenerPermisosDominios = () => {
        AdministracionUsuarioService.obtenerPermisosDominio(mapPermisosDominio, dominiosSeleccionados, rolSeleccionado, 'editarDatosContacto')
            .then(response => {
                if (response.codigo === 'OK') {
                    setDominiosAplicaAccion(response.dominiosAplicaAccion);
                    setDominiosNoAplicaAccion(response.dominiosNoAplicaAccion);
                }
            }).finally(() => {
                setRespuestaObtenida(true)
        })
    }

    const editarContactoDominio = () => {
        setCamposError([]);
        const validacion = validarDatosContacto(contacto, setError, false, true);
        if (validacion.error) {
            setError({
                error: true,
                mensaje: validacion.mensaje
            });

            if (validacion.camposError) {
                procesarCamposError(validacion.camposError);
            }

            return;
        }
        AdministracionUsuarioService.editarContactoDominio(dominiosSeleccionados, rolSeleccionado, contacto)
            .then(response => {
                if (response.codigoRespuesta === 'OK') {
                    setCamposError([]);
                    setError({
                        error: false,
                        mensaje: ''
                    })
                    respuestaAccionOk(response.mensajeRespuesta);
                    setIsOpen(false);
                }

                if (response.codigoRespuesta === 'ERROR') {
                    if (response.mapErrores !== undefined && response.mapErrores !== null ){
                        erroresBE(response.mapErrores);
                    }else if (response.mensajeRespuesta !== undefined){
                        setError({error : true, mensaje: response.mensajeRespuesta});
                    }else{
                        setError({error : true, mensaje: 'Error al actualizar el contacto.'});
                    }
                }
            })
    }

    function procesarCamposError(camposError) {
        let camposConError = [];
        camposError.forEach(c => camposConError.push(c.codigo));
        setCamposError(camposConError);
    }

    const definirRol = (rol) => {
        let rolDefinido;
        switch (rolSeleccionado) {
            case 'TODOS':
                rolDefinido = 'Titular, Administrativo, Técnico y Financiero';
                break
            case 'TITULAR':
                rolDefinido = 'Titular';
                break
            case 'ADMINISTRADOR':
                rolDefinido = 'Administrativo';
                break
            case 'TECNICO':
                rolDefinido = 'Técnico';
                break
            case 'FINANCIERO':
                rolDefinido = 'Financiero';
                break
        }
        return rolDefinido;
    }

    const erroresBE = (errores) => {
        let camposConError = [];
        let errorValidacion = {
            error: false,
            mensaje: "Sin errores"
        }

        if (errores.general !== undefined) {
            errorValidacion = {
                error: true,
                mensaje: errores.general
            }

            Object.entries(errores).map(([clave, valor]) => {
                if (clave !== 'general') {
                    camposConError.push(clave);
                }
            })
        } else {
            let erroresMsj = '';
            Object.entries(errores).map(([clave, valor]) => {
                camposConError.push(clave);
                erroresMsj = erroresMsj + valor + '\n';
            })
            errorValidacion = {
                error: true,
                mensaje: erroresMsj
            }
        }
        setError(errorValidacion);
        setCamposError(camposConError);
    }

    const handleOnChange = (e) => {
        const datosContactoAux = {...contacto};
        datosContactoAux[e.target.name] = e.target.value;
        setContacto(datosContactoAux);
    }

    const realizarCambios = (e) => {
        e.preventDefault();
        editarContactoDominio();

    }

    const  styleError = (campo) => {
        if (camposError !== undefined) {
            return camposError.includes(campo) ? 'red' : '';
        }
    }

    return(<>
    { respuestaObtenida && <Modal handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
        { dominiosAplicaAccion && dominiosAplicaAccion.length > 0 ?
            <>
                { dominiosNoAplicaAccion && dominiosNoAplicaAccion.length > 0 &&
                    <div className="message-box" id="register-error">
                        <p>Los siguientes dominios no tendrán cambios ya que no tiene los permisos suficientes para realizar
                            la acción seleccionada</p>
                        <ul>
                            { dominiosNoAplicaAccion.map(dominio => (
                                <li>
                                    <span>{dominio}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                }

                <fieldset>
                    <legend>Modificar datos de contacto para los dominios seleccionados</legend>

                    <form autoComplete="off">
                        {/*<s:hidden name="currentPage" value="%{currentPage}"/>*/}
                        {/*<s:hidden name="elementsPerPage" value="%{elementsPerPage}"/>*/}
                        {/*<s:hidden name="tipoRelacionUsuDomBulk" value="%{tipoRelacionUsuDomBulk}"/>*/}

                        {/*<s:iterator value="%{dominiosSeleccionados}">*/}
                        {/*    <s:hidden name="dominiosSeleccionados" value="%{top}"/>*/}
                        {/*</s:iterator>*/}
                        {/*<s:iterator value="%{dominiosAplicaAccion}">*/}
                        {/*    <s:hidden name="dominiosAplicaAccion" value="%{top}"/>*/}
                        {/*</s:iterator>*/}
                        {/*<s:iterator value="%{dominiosNoAplicaAccion}">*/}
                        {/*    <s:hidden name="dominiosNoAplicaAccion" value="%{top}"/>*/}
                        {/*</s:iterator>*/}

                        {/*si el contacto es de tipo titular, colocamos como hidden estos valores para que viajen al servidor*/}
                        {/*<s:if test="%{tipoRelacionUsuDomBulk == 'Titular'}">*/}
                        {/*    <s:hidden name="contactoEdicion.nombre"/>*/}
                        {/*    <s:hidden name="contactoEdicion.apellido"/>*/}
                        {/*</s:if>*/}

                        <div className="message-box" id="register-warning">
                            <h3>
                                Se modificarán los datos de contacto en los casos en los que su cuenta Vera<br/>
                                figure como contacto {definirRol(rolSeleccionado)}.<br/>
                                En el caso de figurar como titular no se modificarán los campos Nombre y Apellido.
                            </h3>
                        </div>
                        {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}
                        <fieldset>
                            <legend>Datos de Contacto Públicos</legend>

                            <div className="form-item">
                                <label htmlFor="id-nombre">Nombre *</label>
                                {/*<s:textfield id="id-nombre" name="contactoEdicion.nombre" cssClass="edit"*/}
                                {/*             disabled="%{tipoRelacionUsuDomBulk == 'Titular'}"/>*/}
                                <input type={"text"} id="id-nombre" name="nombre" onChange={handleOnChange} className="edit" style={{borderColor: styleError('nombre')}}/>
                            </div>
                            <div className="form-item">
                                <label htmlFor="id-nombre">Apellido *</label>
                                {/*<s:textfield id="id-apellido" name="contactoEdicion.apellido" cssClass="edit"*/}
                                {/*             disabled="%{tipoRelacionUsuDomBulk == 'Titular'}"/>*/}
                                <input type={"text"} id="id-apellido" name="apellido" onChange={handleOnChange} className="edit" style={{borderColor: styleError('apellido')}}/>
                            </div>
                            <div className="form-item">
                                <label htmlFor="id-direccion">Direcci&oacute;n *</label>
                                {/*<s:textfield id="id-direccion" name="contactoEdicion.direccion" cssClass="edit"/>*/}
                                <input type={"text"} id="id-direccion" name="direccion" onChange={handleOnChange} className="edit" style={{borderColor: styleError('direccion')}}/>
                            </div>
                            <div className="form-item">
                                <label htmlFor="id-telefono">Tel&eacute;fono *</label>
                                {/*<s:textfield id="id-telefono" name="contactoEdicion.telefono" cssClass="edit"*/}
                                {/*             maxLength="30"/>*/}
                                <input type={"text"} id="id-telefono" name="telefono" onChange={handleOnChange} className="edit" maxLength="30" style={{borderColor: styleError('telefono')}}/>
                            </div>
                            <div className="form-item">
                                <label htmlFor="id-mail-publico">Correo electr&oacute;nico p&uacute;blico *</label>
                                {/*<s:textfield id="id-mail-publico" name="contactoEdicion.emailPublico" cssClass="edit"/>*/}
                                <input type={"text"} id="id-mail-publico" name="emailPublico" onChange={handleOnChange} className="edit" style={{borderColor: styleError('emailPublico')}}/>
                            </div>
                        </fieldset>

                        <div className="right">
                            <button className="btn" onClick={realizarCambios}>Realizar cambio</button>
                        </div>
                    </form>
                </fieldset>
            </>
            :
            <>
                <div className="message-box" id="register-error">
                    <h3>No tiene permisos para modificar los datos de contacto de ninguno de los dominios
                        seleccionados.</h3>
                </div>
            </>
        }
    </Modal>}
    </>)
}

ModalModificarDatosContacto.propTypes = {
    handleOnClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};
