import {
    buildFinalizarCompraRequest,
    buildFinalizarCompraResponse,
    buildObtenerFormaPagoGuardadaVentaResponse,
    buildObtenerFormasDePagoResponse,
    buildObtenerVentaCarritoResponse,
    buildRefrescarVentaCarritoResponse
} from "../dto/CarritoDTOBuilder";
import {buildObtenerPaisesResponse} from "../dto/ObtenerPaisesDTOBuilder";
import {buildObtenerDocumentosPorPaisResponse} from "../dto/ObtenerDocumentoPorPaisDTOBuilder";

import http from '../support/HttpService';

const REACT_APP_OBTENER_VENTA_CARRITO = process.env.REACT_APP_OBTENER_VENTA_CARRITO;
const REACT_APP_REFRESCAR_VENTA_CARRITO = process.env.REACT_APP_REFRESCAR_VENTA_CARRITO;
const REACT_APP_OBTENER_PAISES = process.env.REACT_APP_OBTENER_PAISES;
const REACT_APP_OBTENER_DOCUMENTO_POR_PAIS = process.env.REACT_APP_OBTENER_DOCUMENTO_POR_PAIS;
const REACT_APP_OBTENER_FORMAS_DE_PAGO = process.env.REACT_APP_OBTENER_FORMAS_DE_PAGO
const REACT_APP_OBTENER_FORMA_PAGO_VENTA = process.env.REACT_APP_OBTENER_FORMA_PAGO_VENTA;
const REACT_APP_FINALIZAR_COMPRA = process.env.REACT_APP_FINALIZAR_COMPRA;
const REACT_APP_PROPERTIES_CARRITO = process.env.REACT_APP_PROPERTIES_CARRITO;
const REACT_APP_VALIDAR_DOMINIOS_CON_CONTACTO = process.env.REACT_APP_VALIDAR_DOMINIOS_CON_CONTACTO;


const CarritoService = {

    obtenerVentaCarrito: async function(venta) {
        try {
            const response = await http.get(REACT_APP_OBTENER_VENTA_CARRITO + venta.uuidVenta);
            return buildObtenerVentaCarritoResponse(response.data)
        } catch (error) {
            console.log("Error al obtener venta");
        }
    },

    refrescarVentaCarrito: async function(uuidVenta) {
        try {
            const response = await http.get(REACT_APP_REFRESCAR_VENTA_CARRITO + uuidVenta);
            return buildRefrescarVentaCarritoResponse(response.data)
        } catch (error) {
            console.log("Error al obtener venta");
        }
    },

    obtenerPaises: async function() {
        try {
            const response = await http.get(REACT_APP_OBTENER_PAISES);
            return buildObtenerPaisesResponse(response.data);
        } catch (error) {
            console.log("Error cargando paises");
        }
    },

    obtenerDocumentoPorPais: async function(paisId) {
        try {
            const response = await http.get(REACT_APP_OBTENER_DOCUMENTO_POR_PAIS + paisId)
            return buildObtenerDocumentosPorPaisResponse(response.data);
        } catch (error) {
            console.log("Error obteniendo documentos");
        }
    },

    obtenerFormasDePago: async function() {
        try {
            const response = await http.get(REACT_APP_OBTENER_FORMAS_DE_PAGO);
            return buildObtenerFormasDePagoResponse(response.data);
        } catch (error) {
            console.log("Error obteniendo formas de pago");
        }
    },

    obtenerFormaPagoGuardadaVenta: async function(uuidVenta) {
        try {
            const response = await http.get(REACT_APP_OBTENER_FORMA_PAGO_VENTA + uuidVenta);
            return buildObtenerFormaPagoGuardadaVentaResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    finalizarCompra: async function(uuidVenta) {
        try {
            const response = await http.post(REACT_APP_FINALIZAR_COMPRA + uuidVenta, buildFinalizarCompraRequest(true, true, 'ff'));
            return buildFinalizarCompraResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    obtenerMensaje: async function( clave ) {
        try {
            const response = await http.get(REACT_APP_PROPERTIES_CARRITO + clave)
            return response.data;
        } catch (error) {
            console.log("Error obteniendo propiedad");
        }
    },

    validarDominiosConContacto: async function(uuidVenta) {
        try {
            const response = await http.get(REACT_APP_VALIDAR_DOMINIOS_CON_CONTACTO + uuidVenta)
            return response.data;
        } catch (error) {
            console.log("Error validando dominios con contacto");
        }
    }
}

export default CarritoService;