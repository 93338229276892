import React, {useEffect, useRef, useState} from 'react'
import {MensajeError} from "../MensajeError";
import TicketsService from "../../services/TicketsService";
import ReCAPTCHA from "react-google-recaptcha";
import {MensajeOk} from "../MensajeOk";
import {useValidacionContacto} from "../../components/validaciones/validacionesContacto";
const REACT_APP_SITE_KEY = process.env.REACT_APP_SITE_KEY;

export const GenerarTicket = () => {

    const recaptchaRef = useRef();
    const [ticket, setTicket] = useState({
        nombre : '',
        email : '',
        mailConfirmacion : '',
        telefono : '',
        dominio : '',
        asunto : '',
        mensaje : ''
    });
    const [captchaResponse, setCaptchaResponse] = useState('');

    const [mensajeOk, setMensajeOk] = useState(undefined)
    const [error, setError] = useState(undefined);
    const [camposError, setCamposError] = useState(undefined);

    const { validarDatosContactoTicket } = useValidacionContacto();

    useEffect(() => {
        limpiarMensajes();
        limpiarFormulario();
    }, []);

    const limpiarMensajes = () => {
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ''
        });
        setError({
            error: false,
            mensaje: "Sin errores"
        });
        setCamposError([]);
    }

    const limpiarFormulario = () => {
        setTicket({
            nombre : '',
            email : '',
            mailConfirmacion : '',
            telefono : '',
            dominio : '',
            asunto : '',
            mensaje : ''
        });
    }

    const generarTicket = (e) => {
        e.preventDefault();

        limpiarMensajes();
        let validacion = validarDatosContactoTicket(ticket, setError);
        if (!validacion.error) {
            TicketsService.generarTicket(ticket, "TICKET_AYUDA", captchaResponse)
                .then(response => {
                    if (!response.respuestaOK) {
                        setError({
                            error: true,
                            mensaje: response.mensajeRespuesta
                        })
                        if (response.camposError) {
                            procesarCamposError(response.camposError);
                        }
                    } else {
                        setMensajeOk({
                            mostrarMensaje: true,
                            mensaje: response.mensajeRespuesta
                        })
                        limpiarFormulario();
                    }
                })
                .catch(() => {
                    setError({
                        error: true,
                        mensaje: "Ha ocurrido un error al generar el ticket."
                    })
                }).finally(()=>{
                recaptchaRef.current.reset();
            })
        }else{
            if (validacion.camposError) {
                procesarCamposError(validacion.camposError);
            }
            recaptchaRef.current.reset();
        }
    }

    function procesarCamposError(camposError) {
        let camposConError = [];
        camposError.forEach(c => camposConError.push(c.codigo));
        setCamposError(camposConError);
    }

    const  styleError = (campo) => {
        if (camposError !== undefined) {
            return camposError.includes(campo) ? 'red' : '';
        }
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        let newVar = {
            ...ticket,
            [e.target.name]: e.target.value
        };
        setTicket(newVar);
    }

    function onChange(value) {
        setCaptchaResponse(value);
    }

    return <>

        { mensajeOk !== undefined && mensajeOk.mostrarMensaje && <MensajeOk mensajeOk={mensajeOk.mensaje} /> }
        { error !== undefined && error.error && <MensajeError mensajeError={error.mensaje} /> }

        <h2>Ticket</h2>

        <fieldset>
            <legend>Datos personales</legend>

            <form>
                <div className="form-item">
                    <label htmlFor="id-nombre">Nombre*</label>
                    <input type={"text"} id="id-nombre" name="nombre"
                           style={{borderColor: styleError("nombre")}}
                           value={ticket.nombre}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-mail">Correo electr&oacute;nico*</label>
                    <input type={"text"} id="id-mail" name="email"
                           style={{borderColor: styleError("email")}}
                           value={ticket.email}
                           onChange={handleOnChange}/>
                </div>
                <div className="form-item">
                    <label htmlFor="id-mail-confirmacion">Confirmar correo electrónico*</label>
                    <input type={"text"} id="id-mail-confirmacion" name="mailConfirmacion"
                           style={{borderColor: styleError("mailConfirmacion")}}
                           value={ticket.mailConfirmacion}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-telefono">Tel&eacute;fono</label>
                    <input type={"text"} id="id-telefono" name="telefono"
                           style={{borderColor: styleError("telefono")}} className="edit"
                           maxLength="30" value={ticket.telefono}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-nombre-dominio">Nombre de dominio</label>
                    <input type={"text"} id="id-dominio" name="dominio"
                           style={{borderColor: styleError("dominio")}} className="edit"
                           maxLength="30" value={ticket.dominio}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-asunto">Asunto*</label>
                    <input type={"text"} id="id-asunto" name="asunto"
                           style={{borderColor: styleError("asunto")}} className="edit"
                           value={ticket.asunto}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-mensaje">Mensaje*</label>
                    <textarea type={"text"} id="id-mensaje" name="mensaje"
                           style={{borderColor: styleError("mensaje")}} className="edit"
                           value={ticket.mensaje}
                           onChange={handleOnChange}
                           maxLength={2048}
                    />
                </div>

                <div className={"box-captcha cfx " }>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={REACT_APP_SITE_KEY}
                        onChange={onChange}
                    />
                </div>

                <div className="form-item">
                    <button className="btn right" onClick={generarTicket}>Guardar datos</button>
                </div>
            </form>
        </fieldset>


    </>

}
