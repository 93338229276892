import React, {useEffect, useState} from 'react'
import {ModalLiberarDominio} from "../misDominios/modales/ModalLiberarDominio";
import {ModalDelegarDominio} from "../misDominios/modales/ModalDelegarDominio";
import {ModalAlojarDominio} from "../misDominios/modales/ModalAlojarDominio";
import {ModalRecuperarDominio} from "../misDominios/modales/ModalRecuperarDominio";
import moment from "moment";
import {ModalItemRenovacionDominio} from "./modales/ModalItemRenovacionDominio";
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";

export const VerDominioInformacion = ( { dominio, nombreDominio, infoDominio, permisos, setMensajeOk, setError, limpiarMensajes, irVerDominio }) => {

    const [isOpenModalRenovacion, setIsOpenModalRenovacion] = useState(false);
    const [isOpenModalLiberar, setIsOpenModalLiberar] = useState(false);
    const [isOpenModalDelegar, setIsOpenModalDelegar] = useState(false);
    const [isOpenModalAlojar, setIsOpenModalAlojar] = useState(false);
    const [isOpenModalRecuperar, setIsOpenModalRecuperar] = useState(false);

    const [dominioEliminado, setDominioEliminado] = useState(undefined);
    const [habilitadoRenovar, setHabilitadoRenovar] = useState(undefined);
    const [mensajeRenovar, setMensajeRenovar] = useState(undefined);
    const [mensajeRecuperar, setMensajeRecuperar] = useState(undefined);
    const [mensajeConfirmarLiberar, setMensajeConfirmarLiberar] = useState("");

    useEffect(() => {
        cargarPropiedades();
    }, [infoDominio]);

    const cargarPropiedades = () => {
        if (infoDominio !== undefined){
            setDominioEliminado(infoDominio.dominioEliminado);
            setHabilitadoRenovar(permisos.puedeRenovar
                                    && infoDominio.renovacionManualHabilitada
                                    && dominio.dominio.dominioCore.estado.renovable);

            if (infoDominio.dominioEliminado){
                setMensajeRenovar("Renovar (Renovable por " + infoDominio.tiempoAntesEliminacion + ")");
            }else{
                setMensajeRenovar("Renovar");
            }
            if (infoDominio.dominioVencido !== undefined && infoDominio.dominioVencido){
                setMensajeRecuperar("Recuperar (Recuperable por " + infoDominio.tiempoAntesEliminacion + ")");
            }else{
                setMensajeRecuperar("Recuperar");
            }
            obtenerMensajesAccionesAdministracion();
        }
    }

    const obtenerMensajesAccionesAdministracion = () => {
        AdministracionUsuarioService.obtenerMensajesAccionesAdministracion()
            .then((response) => {
                if (response.codigoRespuesta === "OK") {
                    setMensajeConfirmarLiberar(response.mensajeConfirmarLiberar);
                }
            })
    }
    function handleIrRenovacion(event) {
        event.preventDefault();
        setIsOpenModalRenovacion(true);
        limpiarMensajes();
    }

    function handleOnCloseRenovacion(){
        setIsOpenModalRenovacion(false);
    }

    function handleIrLiberar(event) {
        event.preventDefault();
        setIsOpenModalLiberar(true);
        limpiarMensajes();
    }

    function handleOnCloseLiberar(){
        setIsOpenModalLiberar(false);
    }


    function handleIrDelegar(event) {
        event.preventDefault();
        setIsOpenModalDelegar(true);
        limpiarMensajes();
    }

    function handleOnCloseDelegar(){
        setIsOpenModalDelegar(false);
    }

    function handleIrAlojar(event) {
        event.preventDefault();
        setIsOpenModalAlojar(true);
        limpiarMensajes();
    }

    function handleOnCloseAlojar(){
        setIsOpenModalAlojar(false);
    }

    function handleIrRecuperar(event) {
        event.preventDefault();
        setIsOpenModalRecuperar(true);
        limpiarMensajes();
    }

    function handleOnCloseRecuperar(){
        setIsOpenModalRecuperar(false);
    }

    return <>

        <ModalItemRenovacionDominio handleOnClose={handleOnCloseRenovacion} isOpen={isOpenModalRenovacion} nombreDominio={nombreDominio}
                                    setMensajeOk={setMensajeOk} setError={setError} />
        <ModalLiberarDominio handleOnClose={handleOnCloseLiberar} isOpen={isOpenModalLiberar} nombreDominio={nombreDominio}
                             mensaje={mensajeConfirmarLiberar} setError={setError} redirectToPage={true}/>
        <ModalDelegarDominio handleOnClose={handleOnCloseDelegar} isOpen={isOpenModalDelegar}
                             nombreDominio={nombreDominio} infoDominio={infoDominio}
                             irVerDominio={irVerDominio} />
        <ModalAlojarDominio handleOnClose={handleOnCloseAlojar} isOpen={isOpenModalAlojar}
                            nombreDominio={nombreDominio}
                            setError={setError}
                            irVerDominio={irVerDominio} />
        <ModalRecuperarDominio handleOnClose={handleOnCloseRecuperar} isOpen={isOpenModalRecuperar}
                               nombreDominio={nombreDominio}
                               setError={setError}
                               irVerDominio={irVerDominio} />

        <fieldset>
            <legend>Informaci&oacute;n</legend>
            <table className="data-domain vertical-align">
                <tbody>
                <tr>
                    <td>Fecha alta</td>
                    <td><label>{ moment(dominio.dominio.dominioCore.fechaAlta).format('DD/MM/yyyy') }</label></td>
                </tr>
                <tr>
                    <td>Vencimiento</td>
                    <td><label>{ moment(dominio.dominio.dominioCore.fechaVencimiento).format('DD/MM/yyyy') }</label></td>
                    { habilitadoRenovar &&
                        <>
                            <td>
                                <a className="link"
                                   id={nombreDominio}
                                   onClick={handleIrRenovacion}>{ mensajeRenovar }</a>

                            </td>
                        </>
                    }
                </tr>
                <tr>
                    <td>Estado</td>
                    <td><label>{dominio.dominio.dominioCore.estado.nombreString }</label></td>
                    { dominioEliminado &&
                        <>
                            <td>
                                {(dominio.esAdministrativo || dominio.esTecnico || dominio.esTitular) && (
                                    <td>
                                        <a className="link" id={nombreDominio} onClick={handleIrLiberar}>{"Liberar"}</a>
                                    </td>
                                )}
                            </td>
                            {(dominio.esAdministrativo || dominio.esTecnico || dominio.esTitular) && (
                                <td>
                                    <a className="link" id={nombreDominio}
                                       onClick={handleIrRecuperar}>{mensajeRecuperar}</a>
                                </td>
                            )}


                        </>
                    }
                </tr>
                </tbody>

            </table>

            <table className="data-domain">
                <tbody>
                {dominio.dominio.dominioBE.alojado &&
                    <>
                    <tr>
                            <td>Este dominio se encuentra alojado</td>
                            { permisos.puedeDelegarAlojar &&
                                <td>
                                    <a className="link"
                                        id={nombreDominio}
                                        onClick={handleIrDelegar}>{ "Delegar" }</a>
                                </td>
                            }
                        </tr>
                        {/*Se muestran los servidores de nombre de Antel*/}
                        <tr>
                            <td colSpan="2"><strong>Servidores de nombre</strong></td>
                        </tr>
                        {
                            infoDominio.nameservers !== undefined
                            && infoDominio.nameservers !== null
                            && infoDominio.nameservers.map((servidor, index) => (
                                <tr key={index.toString()}>
                                    <td>{servidor.nombre}</td>
                                    <td>{servidor.ip}</td>
                                </tr>
                            ))
                        }
                    </>
                }
                {!dominio.dominio.dominioBE.alojado &&
                    <tr>
                        <td>Este dominio se encuentra delegado</td>
                        { permisos.puedeDelegarAlojar &&
                            <a className="link"
                                id={nombreDominio}
                                onClick={handleIrAlojar} >{ "Alojar" }</a>
                        }
                    </tr>
                }
                </tbody>
            </table>
        </fieldset>
    </>
}