import React, {useEffect, useState} from 'react'
import {MensajeError} from "../MensajeError";
import TerminosYCondicionesService from "../../services/TerminosYCondicionesService";
import {useLocation, useNavigate} from "react-router-dom";

export const AceptarTerminosCondiciones = () => {

    const [termYCond, setTermYCond] = useState({});
    const [aceptaTermYCond, setAceptaTermYCond] = useState(false);
    const [redirectTO, setRedirectTo] = useState(null);

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        cargarTerminosYCondiciones();
        if (location.state && location.state.redirectTO) {
            console.log("location.state.redirectTO > " + location.state.redirectTO);

            setRedirectTo(location.state.redirectTO);
        }
        console.log("window.location.hash > " + window.location.hash)
        let url_params = new URLSearchParams(location.hash)
        console.log("url_params > " + url_params)
        console.log("url_params.get('redirectTo') > " + url_params.get('redirectTo'))

        setRedirectTo(url_params.get('redirectTo'));
    }, [location])

    function cargarTerminosYCondiciones() {
        TerminosYCondicionesService.obtenerTerminosYCondicionesActual()
            .then(response => {
                if (response.respuestaOK) {
                    setTermYCond(response.terminosYCondiciones);
                    setAceptaTermYCond(false);
                } else {
                    setError({
                        error: true,
                        mensaje: response.mensajeRespuesta
                    })
                }
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al aceptar los términos y condiciones."
                })
            })
    }

    const aceptarTerminosYCondiciones = (e) => {
        e.preventDefault();
        setError({error : false, mensaje: "Sin errores"})

        if (!aceptaTermYCond){
            setError({error : true, mensaje: "Para continuar en el sitio debe aceptar los términos y condiciones."})
            return;
        }

        TerminosYCondicionesService.aceptarTerminosYCondiciones()
            .then(response => {
                if (!response.respuestaOK) {
                    setError({
                        error: true,
                        mensaje: response.mensajeRespuesta
                    })
                }else{
                    navigate(redirectTO ? redirectTO : "/");
                }
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al aceptar los términos y condiciones."
                })
            })
    }

    return <>

        {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

        <h2>T&eacute;rminos y condiciones</h2>

        <fieldset>
            <legend>Acepto los t&eacute;rminos y condiciones</legend>

            <form>
                <div className="form-item">
                    <div className="form-item" style={{width: "1127px", height: "433px", overflowY: "auto"}}
                         dangerouslySetInnerHTML={{__html: termYCond.contenido}}>
                    </div>
                </div>

                <div className="form-item">
                    <input type="checkbox" id="id-chk-aceptaTermYCond" name="aceptaTermYCond"
                           onChange={(e) => setAceptaTermYCond(e.target.value)}/>
                    <label className={""} id="id-chk-aceptaTermYCond" style={{width: "auto"}}>&nbsp;Acepto los términos y condiciones</label>

                </div>

                <div className="form-item">
                    <button className="btn right" onClick={aceptarTerminosYCondiciones}>Continuar</button>
                </div>
            </form>
        </fieldset>


    </>

}
