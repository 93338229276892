import axios from "axios";
import {getOidcState} from "../security/OidcManager";


axios.interceptors.request.use(function (config) {
        const token = getOidcState().access_token;
        config.headers.Authorization = "Bearer " + token;
        const id_token = getOidcState().id_token;

        if (getOidcState().is_authenticated){
            config.headers.OID = id_token.sub;
            config.headers.LOGIN = id_token.username;
        }
        return config;
    });

    // Response interceptor to handle errors
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                //history.navigate('/v2/errorAuthorization');
                history.navigate('/errorAuthorization', { state: { from: window.location.pathname } });
            } else if (error.response.status === 404) {
                history.navigate('/errorNotFound');
            } else if (error.response.status === 502) {
                history.navigate('/errorBackend');
            } else {
                return Promise.reject(error);
            }
        }
    );

    const http = {
        get: axios.get,
        post: axios.post,
        put: axios.put,
        delete: axios.delete,
        patch: axios.patch
    };
    export default http;

    export const history = {
        navigate: null,
        location: null
    };