import React from 'react';
import Modal from "../../components/Modal";
import PropTypes from 'prop-types';
import "./ModalLoginRequerido.css";
import {oidc_authorize} from "../../security/OidcManager";
import * as HttpService from "../../support/HttpService";

const ModalLoginRequerido = (props) => {

    const login = () => {
        if (props.preRedirectAction) {
            props.preRedirectAction();
        }
        oidc_authorize(HttpService.history.location.pathname);
    }

    return (
        <Modal handleClose={props.handleOnClose} isOpen={props.isOpen}
               className="modal" modalContentClassName="modal-content" closeClassName="close-modal">
            <div>
                <div className="modal-login-requerido-texto">
                    {props.modalMessage ?? 'Debe ingresar al sitio con una cuenta Vera para continuar con la compra'}
                </div>
                <div className="modal-login-requerido-botones">
                    <button type="button" className="btn" aria-disabled="false" onClick={login}>
                        <span>{props.okModalMessage ?? 'Continuar con la compra'}</span>
                    </button>
                    <button type="button" className="btn" aria-disabled="false" onClick={props.handleOnClose}>
                        <span>Cancelar</span>
                    </button>
                </div>
            </div>
        </Modal>
    );
}

ModalLoginRequerido.propTypes = {
    handleOnClose: PropTypes.func.isRequired,
    modalMessage: PropTypes.string,
    okModalMessage: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    preRedirectAction: PropTypes.func
};

export default ModalLoginRequerido;