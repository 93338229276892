import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../../store/AppContext";
import {CarritoComprasPasos} from "../CarritoComprasPasos";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {CarritoComprasTotal} from "../CarritoComprasTotal";
import {ListaRegistroDominiosPasoDos} from "./ListaRegistroDominiosPasoDos";
import {ListaRenovacionDominiosPasoDos} from "./ListaRenovacionDominiosPasoDos";
import {MensajeError} from "../../MensajeError";
import {getOidcState} from "../../../security/OidcManager";
import RegistroDominioService from "../../../services/RegistroDominioService";
import CarritoService from "../../../services/CarritoService";
import {ValidacionAutenticado} from "../../../components/validaciones/ValidacionAutenticado";
import {ValidacionesCarrito} from "../../../components/validaciones/ValidacionesCarrito";
import {ListaTransferenciasTitularPasoDos} from "./ListaTransferenciasTitularPasoDos";

export const CarritoComprasPasoDos = () => {
    const appCtx = useContext(AppContext);
    const history = useNavigate();
    const location = useLocation();
    let carritoRegistros = appCtx.carrito.registrosPendientes;
    let carritoRenovaciones = appCtx.carrito.renovacionesPendientes;
    let carritoTransferenciasTitular = appCtx.carrito.transferenciasTitularPendientes;
    const[mapContactosDoms, setMapContactosDoms] = useState(new Map());
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    let subTotal = 0;
    let subTotalRegistros = 0;
    let subTotalRenovaciones = 0;
    let subTotalTransferenciasTitular = 0;

    let descuento = 0;

    function calcularSubtotal(items) {
        let subTotalAux = 0;
        if (items.length > 0) {
            items.forEach((dominio) => {
                if (dominio.anios === undefined ){
                    subTotalAux = subTotalAux + (dominio.precio);
                }else{
                    subTotalAux = subTotalAux + (dominio.precio * dominio.anios);
                }
            })
        }
        return subTotalAux;
    }

    subTotalRegistros = calcularSubtotal(carritoRegistros);
    subTotalRenovaciones = calcularSubtotal(carritoRenovaciones);
    subTotalTransferenciasTitular = calcularSubtotal(carritoTransferenciasTitular);

    subTotal = subTotalRegistros + subTotalRenovaciones + subTotalTransferenciasTitular;

    useEffect(() =>{
        if (appCtx.venta !== undefined && appCtx.venta.uuidVenta !== undefined && appCtx.venta.uuidVenta !== "" && getOidcState().is_authenticated) {
            RegistroDominioService.asociarUsuarioAVenta(appCtx.venta.uuidVenta)
                .then();
        }
    }, [appCtx])

    useEffect(() => {
        if (location.state && location.state.errorValidacionCarrito.error) {
            setError(location.state.errorValidacionCarrito);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const avanzarAPasoTres = (e) => {
        e.preventDefault()

        if (!validarContactoNoVacio()) {
            return;
        }
        CarritoService.validarDominiosConContacto(appCtx.venta.uuidVenta)
            .then((response) => {
                if (!response.respuestaOK) {
                    setError({
                        error: true,
                        mensaje: "No se ha ingresado un titular para todos los dominios"
                    })
                } else {
                    history("/carrito-compra-paso-tres");
                }
            })
        e.target.blur();
    }

    const validarContactoNoVacio = () => {
        let contactosOk = true;
        mapContactosDoms.forEach((value) => {
            if (value.id === '' && carritoRegistros.length >= 1) {
                contactosOk = false;
                setError({
                    error: true,
                    mensaje: "No se ha ingresado un titular para todos los dominios"
                })
            }
        })
        return contactosOk;
    }

    const agregarContactoMap = (mapContactos) => {
        setMapContactosDoms(mapContactos)
    }

    const eliminarContactoMap = (mapContactos) => {
        setMapContactosDoms(mapContactos)
    }

    return (
        <>
            {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

            <ValidacionAutenticado/>
            <ValidacionesCarrito paso={2} setError={setError}/>

            <CarritoComprasPasos paso={2}
                                 tituloCarrito={'Carrito de compras'}
                                 parrafoCarrito={'El titular es la Persona, Empresa u Organización que posee los derechos ' +
                                     'legales sobre el nombre del dominio. Para registrar un dominio se deben ingresar ' +
                                     'los datos del titular correspondiente.'}/>

            <form>
                <ListaRegistroDominiosPasoDos subTotal={subTotalRegistros} agregarContactoMap={agregarContactoMap} eliminarContactoMap={eliminarContactoMap} errorEnCarrito={setError}/>
                <ListaRenovacionDominiosPasoDos subTotal={subTotalRenovaciones} errorEnCarrito={setError}/>
                <ListaTransferenciasTitularPasoDos subTotal={subTotalTransferenciasTitular} errorEnCarrito={setError}/>

                <CarritoComprasTotal subTotal={subTotal} descuento={descuento}/>

                <br clear="all" />
                <div className="right">
                    <button className="btn" onClick={avanzarAPasoTres}>Seleccionar forma de pago</button>
                </div>
            </form>

            <Link to={{ pathname: "/carrito-compra-paso-uno"}}>Volver al paso anterior</Link>
        </>
    );
};