import React from 'react';

export const buildImportarDominioBaseDTO = (dominio, registros) => {
    return {
        dominio: dominio,
        registros: registros
    };
}

export const addCodigoEpp = (importarDominioDTO, dominio) => {
    return {
        ...importarDominioDTO,
        dominio: dominio
    };
}