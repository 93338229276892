import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";

export const Ayuda = () => {

    const [preguntasFrecuentes, setPreguntasFrecuentes] = useState([]);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setPreguntasFrecuentes([

            {
                "id": 1,
                "isHidden": true,
                "pregunta": "¿En qué consiste el servicio dominiosuy?",
                "respuesta": "Es un servicio de gestión web de alojamiento y delegación de dominios. Para utilizar el servicio es necesario tener una cuenta vera."
            },
            {
                "id": 2,
                "isHidden": true,
                "pregunta": "¿Por cuánto tiempo es válido mi registro de dominio?",
                "respuesta": "El registro de un Nombre de Dominio y su correspondiente delegación se realiza por plazo de un año a contar desde la fecha de solicitud del mismo. Vencido este plazo el servicio se renovará automáticamente por igual término. Si no desea renovar el servicio deberá darlo de baja en el sistema antes de la fecha de renovación."
            },
            {
                "id": 3,
                "isHidden": true,
                "pregunta": "Si no lo quiero renovar, ¿tengo que avisar antes?",
                "respuesta": "Si no desea renovar el servicio deberá darlo de baja en el sistema antes de la fecha de renovación."
            },
            {
                "id": 4,
                "isHidden": true,
                "pregunta": "¿Cuándo me llega la factura del dominio que registré?",
                "respuesta": "La factura llega en el período siguiente de la cuenta con la que se registró el domino."
            },
            {
                "id": 5,
                "isHidden": true,
                "pregunta": "No tengo número de cuenta en Antel, ¿puedo registrar un dominio?",
                "respuesta": "No, debe tener una cuenta activa y sin deuda de un servicio de datos o telefonía fija de Antel."
            },
            {
                "id": 6,
                "isHidden": true,
                "pregunta": "¿Cuáles son los diferentes Contactos de un dominio?",
                "respuesta": "Los contactos de un dominio son: Titular, Administrativo, Técnico y Financiero. Contacto Titular: es quien posee los derechos legales sobre el nombre del dominio, puede administrar toda la gestión del dominio, y cambiar cualquier contacto del mismo (a excepción del propio Titular) Contacto Administrativo: puede administrar toda la gestión del dominio, excepto la baja del mismo. Contacto Técnico: puede realizar acciones técnicas vinculadas a la configuración de registros. Contacto Financiero: puede realizar pagos, recibir facturas, y demás actividades relacionadas con el pago de los cargos generados por los servicios contratados"
            },
            {
                "id": 7,
                "isHidden": true,
                "pregunta": "Cuando delego un dominio, ¿los registros configurados se pueden modificar desde Antel?",
                "respuesta": "Si delega un dominio, todas las configuraciones de registros estarán en el servidor que Ud. contrate, por lo que deberá contactarse con el proveedor correspondiente."
            },
            {
                "id": 8,
                "isHidden": true,
                "pregunta": "Cuando delego un dominio, ¿puedo ingresar un único servidor de nombres?",
                "respuesta": "Cuando delega un dominio, como mínimo tiene que tener 2 sevidores de nombre."
            },
            {
                "id": 9,
                "isHidden": true,
                "pregunta": "Hice una modificación y todavía no puedo ver el cambio, ¿por qué?",
                "respuesta": "La actualización podría demorar hasta 24 hs debido a los TTLs."
            },
            {
                "id": 10,
                "isHidden": true,
                "pregunta": "Quiero realizar un registro SRV y no se cómo",
                "respuesta": "No se pueden ingresar registros SRV. Los registros que permite el sistema en el panel de administración actualmente son A, AAAA, NS, MX, CNAME y TXT."
            },
            {
                "id": 11,
                "isHidden": true,
                "pregunta": "Quiero modificar los TTL y no se cómo.",
                "respuesta": "Los TTL no pueden ser modificados por la web de administración. Los cambios en la configuración que realice un usuario tienen un tiempo máximo de demora de 86400 segundos, equivalente a 24hrs"
            },
            {
                "id": 12,
                "isHidden": true,
                "pregunta": "¿Quiero agregar un registro pero es muy largo y el sistema no me lo permite?",
                "respuesta": "<p>Si estás intentando agregar un registro pero encuentras que es demasiado largo y el sistema no te lo permite, es importante tener en cuenta que cada tipo de registro de DNS tiene un límite específico de longitud para su contenido, establecido por las normativas estándar de DNS.<p/>" +
                    "<br/>" +
                    "<p>Para asegurar el cumplimiento con estas normativas y garantizar la funcionalidad adecuada de tu dominio, aquí te proporcionamos el largo máximo permitido para algunos tipos de registros comunes:<p/>" +
                    "<br/>" +
                    "<p><b>A</b> (Dirección IPv4): El largo máximo permitido es de 255 caracteres.<p/>" +
                    "<p><b>AAAA</b> (Dirección IPv6): El largo máximo permitido es de 255 caracteres.<p/>" +
                    "<p><b>CNAME</b> (Alias de Nombre Canónico): El largo máximo permitido es de 255 caracteres.<p/>" +
                    "<p><b>MX</b> (Registro de Intercambio de Correo): El largo máximo permitido es de 255 caracteres.<p/>" +
                    "<p><b>TXT</b> (Registro de Texto): El largo máximo permitido es de 500 caracteres.\n" +
                    "<p><b>SRV</b> (Registro de Recursos de Servicio): El largo máximo permitido es de 255 caracteres.<p/>" +
                    "<p><b>NS</b> (Servidores de Nombres): El largo máximo permitido es de 255 caracteres.<p/>" +
                    "<br/>" +
                    "<p>Si el contenido de tu registro excede estos límites, es posible que debas revisarlo y ajustarlo para cumplir con las normativas de DNS. Considera:<p/>" +
                    "<p style='text-indent: 15px'><b>&bull;&nbsp;reducir la longitud del contenido, </b><p/>" +
                    "<p style='text-indent: 15px'><b>&bull;&nbsp;utilizar abreviaturas cuando sea posible.</b><p/>" +
                    "<p/>"
            },
            {
                "id": 13,
                "isHidden": true,
                "pregunta": "¿Cuándo debo y no debo usar un punto (.) al configurar registros NS, CNAME y MX en mi zona de DNS?",
                "respuesta": "<p>Para asegurar una configuración precisa y evitar errores al configurar registros NS, CNAME y MX en su zona de DNS, aquí están algunas pautas claras:</p>" +
                    "<br/>" +
                    "<p><b>Usar un Punto (.) al Final del FQDN (Fully Qualified Domain Name):</b></p>" +
                    "<br/>" +
                    "<p style='text-indent: 15px'><b>&bull;&nbsp;Zona Raíz del DNS</b>: Cuando esté configurando registros NS y SOA en la zona raíz del DNS, asegúrese de que el FQDN de los dominios termine con un punto (.). </p>" +
                    "<p style='text-indent: 15px'><b>&bull;&nbsp;Referencias Absolutas en Zonas de DNS</b>: Al referirse a un nombre de dominio de forma absoluta dentro de una zona de DNS, como al configurar registros MX, CNAME u otros, siempre use un punto (.) al final del FQDN del dominio al que se está haciendo referencia. </p>" +
                    "<br/>" +
                    "<p>Para estos casos, el punto indica una referencia completa y absoluta al dominio y evita confusiones en la resolución del DNS.</p>" +
                    "<br/>" +
                    "<b>Cuando No Usar un Punto (.) al Final del FQDN:</p></b>" +
                    "<br/>" +
                    "<p style='text-indent: 15px'><b>&bull;&nbsp;Referencias Relativas en Zonas de DNS</b>: Cuando se hace referencia a un nombre de dominio de forma relativa dentro de una zona de DNS, como al configurar registros CNAME para apuntar a subdominios dentro de su propio dominio, no se debe usar un punto al final del FQDN. Esto permite que la <b>referencia sea relativa al dominio actual</b> y no a un dominio absoluto.</p>" +
                    "<br/>" +
                    "<p>Recuerde, al utilizar un punto al final del FQDN en la configuración de registros NS, CNAME y MX, está asegurando una referencia precisa y absoluta al dominio deseado, lo que es fundamental para el correcto funcionamiento del sistema de nombres de dominio (DNS). Por otro lado, evite usar un punto cuando desee referenciar dominios de manera relativa dentro de su propia zona de DNS.</p>"
            }
        ])
    }, []);

    const [indicesActivos, setInidicesActivos] = useState([]);

    const togglePregunta = (index) => {
        if (indicesActivos.includes(index)) {
            setInidicesActivos(indicesActivos.filter((i) => i !== index));
        } else {
            setInidicesActivos([...indicesActivos, index]);
        }
    };

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    function getRespuesta(pregunta) {
        return {__html: pregunta.respuesta + '<p>&nbsp;</p>'};
    }

    return <>
        <h2>Ayuda</h2>

        <div id="content">

            {preguntasFrecuentes.map((pregunta, index) => (
                <>
                    <dt><a onMouseOver={handleMouseOver}
                           onMouseOut={handleMouseOut}
                           style={{cursor: isHovered ? 'pointer' : 'default'}}
                           onClick={() => togglePregunta(index)}
                    >{pregunta.id}. {pregunta.pregunta}</a></dt>
                    <p>&nbsp;</p>
                    {indicesActivos.includes(index) && (
                        <div dangerouslySetInnerHTML={getRespuesta(pregunta)}/>
                    )}
                </>

            ))}
            <Link className="btn" to={{pathname: "/generar-ticket"}}>Contacto</Link>
        </div>

    </>
}