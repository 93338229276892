import React from "react";

export const MensajeError = (props) => {
    return (
        <div className={"message-box"} id={"register-error"}>
            <h3>
                <pre style={{ fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit' }}>
                          {props.mensajeError}
                </pre>
            </h3>
        </div>
    )
}