import React, {useEffect, useState} from "react";
import {CarritoComprasNuevaCuentaForm} from "./CarritoComprasNuevaCuentaForm";
import CarritoService from "../../../services/CarritoService";
import CuentaService from "../../../services/CuentaService";

export const CarritoComprasSeleccionarCuenta = (props) => {
    const [cuentaSeleccionada, setCuentaSeleccionada] = useState(undefined);
    const [cuentasPagoUsuario, setCuentasPagoUsuario] = useState([]);
    const [paises, setPaises] = useState(undefined);
    const [camposConError, setCamposConError] = useState(undefined);

    useEffect(() => {
        setCamposConError(props.camposConError);
    }, [props.camposConError]);

    useEffect(() => {
        obtenerCuentasPagoUsuarioService();
        obtenerPaisesService();
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (props.templateCuentaGuardada !== undefined && cuentasPagoUsuario.length > 0) {
            elegirCuentaActual(props.templateCuentaGuardada);
        } else {
            setCuentaSeleccionada(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.templateCuentaGuardada, cuentasPagoUsuario]);

    const obtenerPaisesService = () => {
        CarritoService.obtenerPaises()
            .then(response => {
                if (response.esRespOK) {
                    setPaises(response.paisesDTO);
                }
            })
    }

    const comboCuentaHandle = (event) => {
        const cuentaId = event.target.value;
        setCamposConError(undefined);
        props.setError({
            error: false,
            mensaje: "Sin errores",
        })
        elegirCuentaActual(cuentaId);

    }

    const elegirCuentaActual = (cuentaId) => {
        let cuentaActual;
        let datosCuenta = cuentasPagoUsuario.find(cuenta => cuenta.id === +cuentaId);
        if (+datosCuenta.id !== -1 && +datosCuenta.id !== 0) {
            cuentaActual = {
                id: datosCuenta.id,
                nombre: datosCuenta.nombre,
                cuenta: {
                    id: datosCuenta.cuenta.id,
                    cuenta: datosCuenta.cuenta.cuenta,
                    nroDocumento: datosCuenta.cuenta.nroDocumento,
                    idPais: datosCuenta.cuenta.pais.id,
                    nombrePais: datosCuenta.cuenta.pais.nombre,
                    idTipoDocumento: datosCuenta.cuenta.tipoDocumento.id,
                    nombreTipoDocumento: datosCuenta.cuenta.tipoDocumento.nombre
                },
            };
        } else {
            cuentaActual = {
                id: datosCuenta.id,
                nombre: ''
            }
        }
        setCuentaSeleccionada(cuentaActual);
        props.onChangeTemplateCuentaPago(cuentaActual)
    }

    const obtenerCuentasPagoUsuarioService = () => {
        CuentaService.getCuentasPagoUsuario()
            .then(response => {
                let cuentasPago = [];
                cuentasPago.push({
                    id: 0,
                    nombre: '-- Seleccionar --'
                });
                if (response.respCodigo === 'OK') {
                    for (let index = 0; index < response.cuentas.length; index++) {
                        cuentasPago.push(response.cuentas[index]);
                    }
                }
                cuentasPago.push({
                    id: -1,
                    nombre: '-- Ingresar nueva cuenta --'
                });
                setCuentasPagoUsuario(cuentasPago);
            })
    }

    const actualizarInfoCuenta = (prop, valor) => {
        let cuentaModificar = cuentaSeleccionada;
        cuentaModificar = {...cuentaModificar, [prop]: valor};
        setCuentaSeleccionada(cuentaModificar);
        props.onChangeTemplateCuentaPago(cuentaModificar);
    }

    return (
        <>
            {props.ocultarMsjCuentaPago === undefined && <div className="cfx">
                <p>Para debitar de la factura de telefonía fija o datos se debe indicar el documento de la persona
                    titular del servicio telefónico y el número de cuenta que figura en la factura.</p>
            </div>}
            <br clear="all"/>

                <div className="form-item">
                    <label htmlFor="id-select-cuentas">Cuenta de Antel *</label>
                    {cuentaSeleccionada !== undefined ?
                        <select onChange={comboCuentaHandle} value={cuentaSeleccionada.id} style={props.style}>
                            {cuentasPagoUsuario.map(cuenta =>
                                <option key={cuenta.id} value={cuenta.id}>{cuenta.nombre}</option>
                            )}
                        </select> :
                        <select onChange={comboCuentaHandle} style={props.style}> /*Se agrega el props.style para
                            mostrar errores*/
                            {cuentasPagoUsuario.map(cuenta =>
                                <option key={cuenta.id} value={cuenta.id}>{cuenta.nombre}</option>
                            )}
                        </select>
                    }
                    <p className="note" style={{marginLeft: 0}}>Seleccione la cuenta de Antel que se utilizará para el
                        pago.</p>
                </div>

                {(cuentaSeleccionada !== undefined && cuentaSeleccionada.id > 0) &&
                    <fieldset id="idFieldsetCuenta">
                        <legend>Datos de la cuenta {cuentaSeleccionada.nombre}</legend>

                        <div className="form-item">
                            <label htmlFor="">Pa&iacute;s *</label>
                            <span>{cuentaSeleccionada.cuenta.nombrePais}</span>
                            {/*<s:property value="cuenta.pais.nombre"/>*/}
                        </div>

                        <div className="form-item" id="id-div-nueva-cuenta-tipo-documento">
                            <label htmlFor="">Tipo de documento *</label>
                            <span>{cuentaSeleccionada.cuenta.nombreTipoDocumento}</span>
                        </div>

                        <div className="form-item">
                            <label htmlFor="">Número de documento *</label>
                            <span>{cuentaSeleccionada.cuenta.nroDocumento}</span>
                        </div>

                        <div className="form-item">
                            <label htmlFor="">Nro. de cuenta *</label>
                            <span>{cuentaSeleccionada.cuenta.cuenta}</span>
                        </div>
                    </fieldset>
                }

            {cuentaSeleccionada !== undefined && cuentaSeleccionada.id === -1 &&
                <fieldset id="id-div-cuenta-nueva"
                          style={{display: (("templateCuentas.size() == 0 || (templateCuenta != null && templateCuenta.id == -1)") || true) ? 'block' : 'none'}}>
                    <legend>Datos de la cuenta de Antel de telefonía fija o datos</legend>
                    <div id="div-fieldset-nueva-cuenta">
                        <CarritoComprasNuevaCuentaForm templateCuenta={cuentaSeleccionada} paises={paises} actualizarInfoCuenta={actualizarInfoCuenta}
                                                       camposConError={camposConError}
                        />
                    </div>
                </fieldset>
            }

        </>
    );
};
