import React, {useContext, useEffect, useState} from 'react'
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {CarritoComprasSeleccionarCuenta} from "../../../../view/carrito/paso_03/CarritoComprasSeleccionarCuenta";
import {MensajeError} from "../../../../view/MensajeError";
import CarritoService from "../../../../services/CarritoService";
import AppContext from "../../../../store/AppContext";

export const ModalItemRenovacionDominio = ({ nombreDominio, handleOnClose, isOpen, setMensajeOk, setError }) => {

    const appCtx = useContext(AppContext);

    const aniosRenovacion= [1];
    const [cantAnios, setCantAnios] = useState(1);

    useEffect(() => {
        setError({error : false, mensaje: "Sin errores"});
    },[])

    const agregarItemCarrito = (e) => {
        e.preventDefault();
        setError({error : false, mensaje: "Sin errores"});

        let renovarDominioDTO = {
            nombreDominio : nombreDominio,
            cantAnios: cantAnios
        };
        AdministracionUsuarioService.renovarDominio(renovarDominioDTO)
            .then(response => {
                console.log(response);
                if (!response.esRespOK) {
                    setError({
                        error: true,
                        mensaje: response.mensaje
                    })
                } else {

                    let uuidVenta = response.uuidVenta;
                    appCtx.setVenta( { uuidVenta : uuidVenta});
                    refreshCarrito(uuidVenta);

                    setMensajeOk({
                        mostrarMensaje: true,
                        mensaje : "Se agrego la renovación al carrito de compras."
                    })
                    handleOnClose();
                }
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al renovar dominio."
                })
            })


        handleOnClose();
    }

    const refreshCarrito = (uuidVenta) => {
        CarritoService.refrescarVentaCarrito(uuidVenta).then(response => {
            if (response.respuestaOK) {
                appCtx.refrescarCarrito(response.registrosPendientes, response.renovacionesPendientes, response.transferenciasTitularesPendientes);
            }
        })
    }

    const elegirAnios = (event) => {
        const anios = event.target.value;
        setCantAnios(anios);
    }

    return <>
        <Modal
            idDivModal="idModalRenovarCarrito"
            handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>

            <fieldset>

                <legend>Datos de renovaci&oacute;n</legend>

                <form id="form-item">

                    <div className="form-item">
                        <label htmlFor="id-anios">Años renovaci&oacute;n</label>
                        <select id="id-anios" onChange={elegirAnios}>
                            {aniosRenovacion.map((anio, index) => (
                                <option value={anio} key={index}>{anio}</option>
                            ))}
                        </select>
                    </div>

                    <div className="right">
                        <button className="btn" onClick={agregarItemCarrito}>Agregar al carrito de compras</button>
                    </div>

                </form>

            </fieldset>

        </Modal>
    </>
}