import React, { useContext, useEffect, useState } from 'react';
import AppContext from "../../../store/AppContext";
import { getOidcState } from "../../../security/OidcManager";
import './CarritoComprasPasoUno.css';
import { CarritoComprasPasos } from "../CarritoComprasPasos";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CarritoComprasTotal } from "../CarritoComprasTotal";
import ModalLoginRequerido from "../../consultaDisponibilidad/ModalLoginRequerido";
import { ListaRegistroDominiosPasoUno } from "./ListaRegistroDominiosPasoUno";
import { ValidacionesCarrito } from "../../../components/validaciones/ValidacionesCarrito";
import { MensajeError } from "../../MensajeError";
import CarritoService from "../../../services/CarritoService";
import { ListaRenovacionDominiosPasoUno } from "./ListaRenovacionDominiosPasoUno";
import DominioRegistroService from "../../../services/RegistroDominioService";
import { TipoItemCarrito } from "../../../store/AppProvider";
import { ListaTransferenciasTitularPasoUno } from "./ListaTransferenciasTitularPasoUno";
import { VentaContext } from "../VentaContext";

export const CarritoComprasPasoUno = () => {
    const appCtx = useContext(AppContext);
    const { setTiposVenta } = useContext(VentaContext);
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    let carritoRegistros = appCtx.carrito.registrosPendientes;
    let carritoRenovaciones = appCtx.carrito.renovacionesPendientes;
    let carritoTransferenciasTitular = appCtx.carrito.transferenciasTitularPendientes;

    let subTotal = 0;
    let subTotalRegistros = 0;
    let subTotalRenovaciones = 0;
    let subTotalTransferenciasTitular = 0;

    let descuento = 0;

    let uuidVenta = appCtx.venta.uuidVenta;
    const history = useNavigate();

    useEffect(() => {
        if (location.state && location.state.errorValidacionCarrito.error) {
            setError(location.state.errorValidacionCarrito);
        }

        if (appCtx.venta !== undefined && appCtx.venta.uuidVenta !== undefined && appCtx.venta.uuidVenta !== "") {
            refreshCarrito();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const refreshCarrito = () => {
        CarritoService.refrescarVentaCarrito(appCtx.venta.uuidVenta).then(response => {
            if (response.respuestaOK) {
                appCtx.refrescarCarrito(response.registrosPendientes, response.renovacionesPendientes, response.transferenciasTitularesPendientes);
            }
        });
    };

    function calcularSubtotal(items) {
        let subTotalAux = 0;
        if (items.length > 0) {
            items.forEach((dominio) => {
                if (dominio.anios === undefined) {
                    subTotalAux = subTotalAux + (dominio.precio);
                } else {
                    subTotalAux = subTotalAux + (dominio.precio * dominio.anios);
                }
            });
        }
        return subTotalAux;
    }

    subTotalRegistros = calcularSubtotal(carritoRegistros);
    subTotalRenovaciones = calcularSubtotal(carritoRenovaciones);
    subTotalTransferenciasTitular = calcularSubtotal(carritoTransferenciasTitular);

    subTotal = subTotalRegistros + subTotalRenovaciones + subTotalTransferenciasTitular;

    //tipos de venta en el carrito
    const tiposVenta = [];
    if (carritoRegistros.length > 0) tiposVenta.push('registro');
    if (carritoRenovaciones.length > 0) tiposVenta.push('renovacion');
    if (carritoTransferenciasTitular.length > 0) tiposVenta.push('transferencia');

    useEffect(() => {
        setTiposVenta(tiposVenta);
    }, [tiposVenta, setTiposVenta]);

    const mostrarModalHandler = (event) => {
        event.preventDefault();
        setIsOpen(true);
    };

    const handleEliminarItemCarrito = (dominioId, tipoItem) => {
        DominioRegistroService.eliminarRegistroDominio(dominioId, uuidVenta)
            .then((response) => {
                if (response.respuestaOK) {
                    appCtx.eliminarItemCarrito(dominioId, tipoItem);

                    if (TipoItemCarrito.registro === tipoItem) {
                        if (appCtx.carrito.registrosPendientes.length === 1
                            && appCtx.carrito.renovacionesPendientes.length === 0) {
                            history("/consulta-disponibilidad");
                        }
                    } else if (TipoItemCarrito.renovacion === tipoItem) {
                        if (appCtx.carrito.registrosPendientes.length === 0
                            && appCtx.carrito.renovacionesPendientes.length === 1) {
                            history("/consulta-disponibilidad");
                        }
                    }
                }
            }).catch((error) => {
            // Ignore
        });
    };

    return (
        <>

            {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

            <ModalLoginRequerido isOpen={isOpen} handleOnClose={() => {setIsOpen(false)}}/>

            <ValidacionesCarrito paso={1} setError={setError}/>

            <CarritoComprasPasos paso={1}
                                 tituloCarrito={'Carrito de compras'}
                                 parrafoCarrito={'Artículos agregados al carrito y que serían parte de la compra.'}/>

            <form>
                <ListaRegistroDominiosPasoUno subTotal={subTotalRegistros} handleEliminarItemCarrito={handleEliminarItemCarrito}></ListaRegistroDominiosPasoUno>
                <ListaRenovacionDominiosPasoUno subTotal={subTotalRenovaciones} handleEliminarItemCarrito={handleEliminarItemCarrito}></ListaRenovacionDominiosPasoUno>
                <ListaTransferenciasTitularPasoUno subTotal={subTotalTransferenciasTitular} handleEliminarItemCarrito={handleEliminarItemCarrito}></ListaTransferenciasTitularPasoUno>

                <CarritoComprasTotal subTotal={subTotal} descuento={descuento}/>

                <div className="prom left">
                    Código promocional: <input type="text" id="id-cod-prom-aux" name="codigoPromocion" placeholder="Código"/>
                    <button type="button" id="id-button-aplicar-promocion">Aplicar promoción</button>
                </div>


                <br clear="all" />
                <div className="right">
                    {getOidcState().is_authenticated ?
                        <Link className="btn" to="/carrito-compra-paso-dos">Continuar con la compra</Link>
                        :
                        <button className="btn" onClick={mostrarModalHandler}>Continuar con la compra</button>
                    }
                </div>
            </form>
        </>
    );
};