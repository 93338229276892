import React from 'react';

export const defaultAppState = {
    mensajes: {
        mensaje: '',
        tipoMensaje: '',
        mostrarMensaje: false,
        mostrarMensajeLogo: false
    },
    carrito: {
        registrosPendientes: [],
        renovacionesPendientes: [],
        transferenciasTitularPendientes: []
    },
    venta: {
        idVenta: '',
        uuidVenta: ''
    },
    usuario: {
        cantidadMensajesSinLeer: 0
    }
};

const AppContext = React.createContext({
    mensajes: defaultAppState.mensajes,
    carrito: defaultAppState.carrito,
    venta: defaultAppState.venta,
    usuario: defaultAppState.usuario,

    mostrarMensaje: (mensaje, tipoMensaje) => {},
    mostrarMensajeLogo: (mensaje, tipoMensaje, logo) => {},
    ocultarMensaje: () => {},

    agregarItemCarrito: (dominio, tipoItem, atributos) => {},
    eliminarItemCarrito: (id, tipoItem) => {},
    refrescarCarrito: (dominios, renovaciones, transferenciasTitulares) => {},

    setVenta: (venta) => {},
    clean: () => {},
    setCantidadMensajesSinLeer: (cantidad) => {}
});

export default AppContext;