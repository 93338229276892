import { useState } from 'react';
import app from "../../App";
import {Contacto} from "../../view/contacto/Contacto";

export const useValidacionContacto = () => {


    const [errores, setErrores] = useState({
        error: false,
        mensaje: "Sin errores"
    });
    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    const validarDatosContacto = (contacto, setErroresFunc, checkEmailConfirmation, validarNombre) => {
        const {nombreApellido, nombre, apellido, telefono, direccion, emailPublico, email, mailConfirmacion} = contacto;
        const errorValidacion = {
            error: false,
            mensaje: "",
            campo: ""
        };

        const isFieldEmpty = (field) => !field || field.trim().length === 0;

        const requiredFields = [direccion, telefono, emailPublico];
        if (validarNombre) {
            if (nombreApellido) {
                requiredFields.push(nombreApellido);
            }
            else {
                requiredFields.push(nombre, apellido);
            }
          }
        if (checkEmailConfirmation) {
            requiredFields.push(email, mailConfirmacion);
        }
        if (requiredFields.some(isFieldEmpty)) {
            errorValidacion.error = true;
            errorValidacion.mensaje = "Todos los campos son obligatorios. Por favor, asegúrese de llenar todos los campos.";
            errorValidacion.campo = "general";
            setErroresFunc(errorValidacion);
            return errorValidacion;
        }

        if (validarNombre) {
            if (nombreApellido && (nombreApellido.trim().length < 2 || !/^[a-zA-ZÀ-ÿ0-9\s'-]+$/.test(nombreApellido.trim()))) {
                setErrorFor(errorValidacion, "nombreApellido", "El nombre debe tener al menos 2 caracteres y solo puede contener letras, números, espacios, apóstrofes ('), y guiones (-).\n");
            } else if (nombre && apellido) {
                if (isFieldEmpty(nombre) || nombre.trim().length < 2 || !/^[a-zA-ZÀ-ÿ0-9\s'-]+$/.test(nombre.trim())) {
                    setErrorFor(errorValidacion, "nombre", "El nombre debe tener al menos 2 caracteres y solo puede contener letras, espacios, tildes, apóstrofes ('), y guiones (-).\n");
                }
                if (isFieldEmpty(apellido) || apellido.trim().length < 2 || !/^[a-zA-ZÀ-ÿ\s'-]+$/.test(apellido.trim())) {
                    setErrorFor(errorValidacion, "apellido", "El apellido debe tener al menos 2 caracteres y solo puede contener letras, espacios, tildes, apóstrofes ('), y guiones (-).\n");
                }
            }
        }

        if (!/^[a-zA-Z0-9\s,]+$/.test(direccion.trim())) {
            setErrorFor(errorValidacion, "direccion", "La dirección solo puede contener letras, números y espacios.\n");
        }
        if (telefono.trim().length < 7 || telefono.trim().length > 15) {
            setErrorFor(errorValidacion, "telefono", "El teléfono debe contener solo números y tener entre 7 y 15 dígitos.\n");
        }
        if (!/^\+?\d+$/.test(telefono.trim())) {
            setErrorFor(errorValidacion, "telefono", "El teléfono debe contener solo números y puede incluir un prefijo '+'. No use espacios ni otros caracteres.\n");
        }

        validateEmails(errorValidacion, emailPublico, "emailPublico");
        if (checkEmailConfirmation && email && mailConfirmacion) {
            validateEmails(errorValidacion, email, "email");
            validateEmails(errorValidacion, mailConfirmacion, "mailConfirmacion");
            if (email.trim() !== mailConfirmacion.trim()) {
                setErrorFor(errorValidacion, "emailMismatch", "El correo electrónico y el correo electrónico de confirmación no coinciden. Por favor, asegúrese de que ambos correos electrónicos sean idénticos.\n");
            }
        }

        setErroresFunc(errorValidacion);
        return errorValidacion;

    };

    const validarDatosContactoTicket = (contacto, setErroresFunc) => {
        const { nombre, telefono, direccion, email, mailConfirmacion, asunto, mensaje, dominio } = contacto;
        const errorValidacion = {
            error: false,
            mensaje: "",
            campo: ""
        };

        const isFieldEmpty = (field) => !field || field.trim().length === 0;

        const requiredFields = [nombre, email, mailConfirmacion, asunto, mensaje];

        if (requiredFields.some(isFieldEmpty)) {
            errorValidacion.error = true;
            errorValidacion.mensaje = "Complete los campos obligatorios. Por favor, asegúrese de llenar todos los campos obligatorios.";
            errorValidacion.campo = "general";
            errorValidacion.camposError = [
                {codigo : "nombre"},
                {codigo : "email"},
                {codigo : "mailConfirmacion"},
                {codigo : "asunto"},
                {codigo : "mensaje"}];
            setErroresFunc(errorValidacion);
            return errorValidacion;
        }

        if (nombre.trim().length < 2 || !/^[a-zA-Z0-9\s'-]+$/.test(nombre.trim())) {
            setErrorFor(errorValidacion, "nombre", "El nombre debe tener al menos 2 caracteres y solo puede contener letras, espacios, tildes ('), y guiones (-).\n");
        }

        if (!isFieldEmpty(direccion) && !/^[a-zA-Z0-9\s,]+$/.test(direccion.trim())) {
            setErrorFor(errorValidacion, "direccion", "La dirección solo puede contener letras, números y espacios.\n");
        }

        if (!isFieldEmpty(telefono)){
            if (telefono.trim().length < 7 || telefono.trim().length > 15) {
                setErrorFor(errorValidacion, "telefono", "El teléfono debe contener solo números y tener entre 7 y 15 dígitos.\n");
            }
            if (!/^\+?\d+$/.test(telefono.trim())) {
                setErrorFor(errorValidacion, "telefono", "El teléfono debe contener solo números y puede incluir un prefijo '+'. No use espacios ni otros caracteres.\n");
            }
        }

        validateEmails(errorValidacion, email, "email");
        validateEmails(errorValidacion, mailConfirmacion, "mailConfirmacion");
        if (email.trim() !== mailConfirmacion.trim()) {
            setErrorFor(errorValidacion, "emailMismatch", "El correo electrónico y el correo electrónico de confirmación no coinciden. Por favor, asegúrese de que ambos correos electrónicos sean idénticos.\n");
        }

        setErroresFunc(errorValidacion);
        return errorValidacion;
    };

    function setErrorFor(errorValidacion, field, message) {
        errorValidacion.error = true;
        if (!errorValidacion.mensaje.split('\n').includes(message.endsWith('\n') ? message.substring(0, message.length-1) : message)){
            errorValidacion.mensaje = errorValidacion.mensaje + message;
        }
        errorValidacion.campo = field;
        if (errorValidacion.camposError === undefined || errorValidacion.camposError === null){
            errorValidacion.camposError = [];
        }
        errorValidacion.camposError.push({codigo : field});
    }

    function validateEmails(errorValidacion, emailField, fieldName) {
        const input = emailField.trim();
        const emailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})*$/;
        const maxLength = 254;

        if (input === '') {
            setErrorFor(errorValidacion, fieldName, "El campo de correo electrónico no puede estar vacío. Por favor, ingrese su correo electrónico.\n");
            return;
        }
        if (input.length > maxLength) {
            setErrorFor(errorValidacion, fieldName, "El correo electrónico ingresado excede el límite de caracteres permitido. Por favor, ingrese un correo electrónico más corto.\n");
            return;
        }
        if (!input.includes('@')) {
            setErrorFor(errorValidacion, fieldName, "El correo electrónico debe contener un ‘@’. Por favor, revise su entrada.\n");
            return;
        }
        if (input.indexOf('@') === input.length - 1) {
            setErrorFor(errorValidacion, fieldName, "El correo electrónico debe contener un dominio después del ‘@’. Por favor, revise su entrada.\n");
            return;
        }
        if (!/^[a-zA-Z0-9.+_-]*$/.test(input.split('@')[0])) {
            setErrorFor(errorValidacion, fieldName, "El correo electrónico contiene caracteres no permitidos. Solo se permiten letras, números, puntos, guiones y guiones bajos.\n");
            return;
        }
        if (!emailRegex.test(input)) {
            setErrorFor(errorValidacion, fieldName, "El formato del correo electrónico ingresado no es válido. Por favor, ingrese un correo electrónico en el formato ‘nombre@dominio.com’.\n");
            return;
        }
    }


    function getNombreApellido(contacto) {
        contacto.nombre = contacto.nombre ? contacto.nombre.trim() : "";
        if (contacto.nombre) {
            if (contacto.apellido && contacto.apellido === "__") {
                contacto.apellido = "";
            } else if (contacto.apellido) {
                contacto.apellido = contacto.apellido.trim();
            } else {
                contacto.apellido = "";
            }
        }
        if (contacto.nombreApellido > 0 && contacto.nombreApellido.includes("__")) {
            contacto.nombreApellido= contacto.nombreApellido.replace("__","");
        }

        return contacto;
    }



    return { errores, setErrores, setError, error, validarDatosContacto, validarDatosContactoTicket, getNombreApellido};

}
