import stylesHome from "../../HomeTabTAR.module.css";
import styles from "./Resumen.module.css";
import {
    obtenerDominioValidado,
    obtenerMediopago,
    obtenerRegistros,
    obtenerReqTransf
} from "../SolTransLocalStorageUtils";
import React, {useContext} from "react";
import AppContext from "../../../../store/AppContext";
import {useNavigate} from "react-router-dom";
import TablaRegistros from "../../componentes/TablaRegistros";

const Resumen = (props) => {
    const appCtx = useContext(AppContext);
    const dominio = obtenerDominioValidado();
    const registros = obtenerRegistros();
    const reqTransf = obtenerReqTransf();
    const datosPago = obtenerMediopago().templateCuentaPagoDTO;
    const history = useNavigate();


    const procesarSolicitudTransferencia = () => {
        history('/transferir-dominio-confirmacion');
    }

    return (
        <>
            <div id="step-content" className={stylesHome.stepsContainer}>
                <div id="step4" className="step-content" style={{width: "100%"}}>
                    <hr/>
                    <div className={styles.containerResumen}>
                        <fieldset>
                            <legend>Datos de Dominio y Contacto</legend>
                            <div id="id-dominio-contacto" className={styles.datosContacto}>
                                <div id="id-form-datos-contacto">
                                    <div className="form-item">
                                        <label htmlFor="id-dominio">Dominio</label>
                                        <input type="text" id="id-dominio" className={stylesHome.inputReadOnly} value={dominio} readOnly/>
                                    </div>

                                    <div className="form-item">
                                        <label htmlFor="id-cod-auth">Código EPP</label>
                                        <input type="text" id="id-cod-auth" className={stylesHome.inputReadOnly} value={reqTransf.codEpp} readOnly/>
                                    </div>

                                    <div className="form-item">
                                        <label htmlFor="id-nombre">Nombre</label>
                                        <input type="text" id="id-nombre" className={stylesHome.inputReadOnly} value={reqTransf.nombre} readOnly/>
                                    </div>

                                    {reqTransf.apellido !== '' &&
                                    <div className="form-item">
                                        <label htmlFor="id-apellido">Apellido</label>
                                        <input type="text" id="id-apellido" className={stylesHome.inputReadOnly} value={reqTransf.apellido} readOnly/>
                                    </div>
                                    }
                                    
                                    <div className="form-item">
                                        <label htmlFor="id-direccion">Domicilio</label>
                                        <input type="text" id="id-direccion" className={stylesHome.inputReadOnly} value={reqTransf.dir} readOnly/>
                                    </div>

                                    <div className="form-item">
                                        <label htmlFor="id-telefono">Teléfono</label>
                                        <input type="text" maxLength="30" id="id-telefono" className={stylesHome.inputReadOnly} value={reqTransf.tel} readOnly/>
                                    </div>

                                    <div className="form-item">
                                        <label htmlFor="id-mail-publico">Correo electrónico</label>
                                        <input type="text" id="id-mail-publico" className={stylesHome.inputReadOnly} value={reqTransf.email}/>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>Medio de Pago</legend>
                            <div id="id-cuenta-pago" className={styles.datosCuentaPago}>
                                <div className="form-item">
                                    <label htmlFor="id-fp">Forma de Pago</label>
                                    <input type="text" id="id-fp" className={stylesHome.inputReadOnly} value={datosPago.cuenta.formaPago.nombre} readOnly/>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="id-pais">Pais</label>
                                    <input type="text" id="id-pais" className={stylesHome.inputReadOnly} value={datosPago.cuenta.pais.nombre} readOnly/>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="id-tipo-doc">Tipo de Documento</label>
                                    <input type="text" id="id-tipo-doc" className={stylesHome.inputReadOnly} value={datosPago.cuenta.tipoDocumento.nombre} readOnly/>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="id-num-doc">Número de Documento</label>
                                    <input type="text" id="id-num-doc" className={stylesHome.inputReadOnly} value={datosPago.cuenta.nroDocumento} readOnly/>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="id-cuenta">Número de Cuenta</label>
                                    <input type="text" id="id-cuenta" className={stylesHome.inputReadOnly} value={datosPago.cuenta.cuenta} readOnly/>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div className={styles.containerResumenRegistros}>
                        <fieldset style={{width: "100%"}}>
                            <legend>Registros a importar</legend>

                            {registros && registros.length > 0?
                                <div>
                                    <TablaRegistros registros={registros} customStyles={{marginTop: "0"}}/>

                                    <p className={styles.note}>Nota: Los registros serán importados luego de que se apruebe la importación y pueden diferir a los que se muestran en la tabla</p>
                                </div>:
                                <p>No se encontraron registros asociados al dominio</p>
                            }
                        </fieldset>
                    </div>
                </div>
            </div>

            <div className="right">
                <input type="button" value="Solicitar Transferencia" id="id-submit-finalizar" className="btn edit"
                       style={{padding: "7px 12px"}} onClick={procesarSolicitudTransferencia}/>
            </div>
        </>
    )
}

export default Resumen;