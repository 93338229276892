import React, {useLayoutEffect, useReducer} from 'react';

import AppContext, {defaultAppState} from './AppContext';

const Acciones = Object.freeze({
    "mostrarMensaje": "MOSTRAR_MENSAJE",
    "mostrarMensajeLogo": "MOSTRAR_MENSAJE_LOGO",
    "ocultarMensaje": "OCULTAR_MENSAJE",

    "agregarItemCarrito": "AGREGAR_ITEM_CARRITO",
    "eliminarItemCarrito": "ELIMINAR_ITEM_CARRITO",
    "refrescarCarrito": "REFRESCAR_CARRITO",

    "setVenta": "SETEAR_VENTA",
    "reset": "RESET",
    "setCantidadMensajesSinLeer": "SET_CANTIDAD_MENSAJES_SIN_LEER"
});

export const TipoMensaje = Object.freeze({
    "ok": "register-ok",
    "error": "register-error",
    "warning": "register-warning"
});

export const TipoItemCarrito = Object.freeze({
    "registro": "Registro",
    "renovacion": "Renovacion",
    "transferenciaTitular": "TransferenciaTitular"
});

const appReducer = (state, action) => {
    if (action.type === Acciones.mostrarMensaje) {
        return {
            carrito: {...state.carrito},
            mensajes: {
                mensaje: action.mensaje,
                tipoMensaje: action.tipoMensaje,
                mostrarMensaje: true
            },
            venta: {...state.venta},
            usuario: {...state.usuario}
        };
    } else if (action.type === Acciones.mostrarMensajeLogo) {
        return {
            carrito: {...state.carrito},
            mensajes: {
                mensaje: action.mensaje,
                tipoMensaje: action.tipoMensaje,
                mostrarMensaje: false,
                mostrarMensajeLogo: true
            },
            venta: {...state.venta},
            usuario: {...state.usuario}
        };
    } else if (action.type === Acciones.ocultarMensaje) {
        return {
            carrito: {...state.carrito},
            mensajes: {
                mensaje: '',
                tipoMensaje: '',
                mostrarMensaje: false
            },
            venta: {...state.venta},
            usuario: {...state.usuario}
        }
    } else if (action.type === Acciones.agregarItemCarrito) {
        let carrito2;
        if (action.tipoItem === TipoItemCarrito.registro){
            carrito2 = {
                registrosPendientes: [...state.carrito.registrosPendientes, action.dominio],
                renovacionesPendientes: state.carrito.renovacionesPendientes,
                transferenciasTitularPendientes: state.carrito.transferenciasTitularPendientes,
            }
        } else if (action.tipoItem === TipoItemCarrito.renovacion){
            carrito2 = {
                registrosPendientes: state.carrito.registrosPendientes,
                renovacionesPendientes: [...state.carrito.renovacionesPendientes, action.dominio],
                transferenciasTitularPendientes: state.carrito.transferenciasTitularPendientes,
            }
        } else if (action.tipoItem === TipoItemCarrito.transferenciaTitular){
            carrito2 = {
                registrosPendientes: state.carrito.registrosPendientes,
                renovacionesPendientes: state.carrito.renovacionesPendientes,
                transferenciasTitularPendientes: [...state.carrito.transferenciasTitularPendientes, action.dominio]
            }
        } else {
            carrito2 = state.carrito;
        }

        window.localStorage.setItem('carrito-storaged', JSON.stringify(carrito2))
        return {
            mensajes: {...state.mensaje},
            carrito: carrito2,
            venta: {...state.venta},
            usuario: {...state.usuario}
        }
    } else if (action.type === Acciones.eliminarItemCarrito) {
        let carrito2;
        if (action.tipoItem === TipoItemCarrito.registro){
            carrito2 = {
                registrosPendientes: state.carrito.registrosPendientes.filter(dom => dom.id.toString() !== action.idDominio),
                renovacionesPendientes: state.carrito.renovacionesPendientes,
                transferenciasTitularPendientes: state.carrito.transferenciasTitularPendientes
            }
        } else if (action.tipoItem === TipoItemCarrito.renovacion){
            carrito2 = {
                registrosPendientes: state.carrito.registrosPendientes,
                renovacionesPendientes: state.carrito.renovacionesPendientes.filter(dom => dom.id.toString() !== action.idDominio),
                transferenciasTitularPendientes: state.carrito.transferenciasTitularPendientes
            }
        } else if (action.tipoItem === TipoItemCarrito.transferenciaTitular){
            carrito2 = {
                registrosPendientes: state.carrito.registrosPendientes,
                renovacionesPendientes: state.carrito.renovacionesPendientes,
                transferenciasTitularPendientes: state.carrito.transferenciasTitularPendientes.filter(dom => dom.id.toString() !== action.idDominio)
            }
        } else {
            carrito2 = state.carrito;
        }

        window.localStorage.setItem('carrito-storaged', JSON.stringify(carrito2))
        return {
            mensajes: {...state.mensaje},
            carrito: carrito2,
            venta: {...state.venta},
            usuario: {...state.usuario}
        }
    } else if (action.type === Acciones.refrescarCarrito) {
        let carrito = {
            registrosPendientes: action.dominios,
            renovacionesPendientes: action.renovaciones,
            transferenciasTitularPendientes: action.transferenciasTitulares
        }
        window.localStorage.setItem('carrito-storaged', JSON.stringify(carrito))
        return {
            mensajes: {...state.mensaje},
            carrito: carrito,
            venta: {...state.venta},
            usuario: {...state.usuario}
        }
    } else if (action.type === Acciones.setVenta) {
        localStorage.setItem('venta-storaged', JSON.stringify(action.venta))
        return {
            mensajes: {...state.mensaje},
            carrito: {...state.carrito},
            venta: {
                idVenta: action.venta.idVenta,
                uuidVenta: action.venta.uuidVenta
            },
            usuario: {...state.usuario}
        }
    } else if (action.type === Acciones.setCantidadMensajesSinLeer) {
        return {
            mensajes: {...state.mensaje},
            carrito: {...state.carrito},
            venta: {...state.venta},
            usuario: {
                cantidadMensajesSinLeer: action.cantidad
            }
        }
    }

    return defaultAppState;
};

const loadItemFromLocalStorage = (item) => {
    const itemStoraged = window.localStorage.getItem(item);
    if (itemStoraged !== "undefined"){
        return JSON.parse(itemStoraged);
    }
    return null;
}

const initFunc = (initState) => {
    const ventaStoraged = loadItemFromLocalStorage('venta-storaged');
    if (ventaStoraged && ventaStoraged !== "undefined"){
        initState = {...initState, venta: {...ventaStoraged}}
    }

    const carritoStoraged = loadItemFromLocalStorage('carrito-storaged');
    if (carritoStoraged && carritoStoraged !== "undefined"){
        initState = {...initState, carrito: {...carritoStoraged}}
    }

    return initState;
}

const AppProvider = (props) => {
    const [appState, dispatchAction] = useReducer(
        appReducer,
        defaultAppState,
        initFunc
    );

    const cleanLocalStorage = () => {
        window.localStorage.removeItem('venta-storaged');
        window.localStorage.removeItem('carrito-storaged');
        dispatchAction({
            type: Acciones.reset
        });
    }

    const mostrarMensajeHandler = (mensaje, tipoMensaje) => {
        dispatchAction({
            type: Acciones.mostrarMensaje,
            mensaje: mensaje,
            tipoMensaje: tipoMensaje
        });
    };

    const mostrarMensajeLogoHandler = (mensaje, tipoMensaje) => {
        dispatchAction({
            type: Acciones.mostrarMensajeLogo,
            mensaje: mensaje,
            tipoMensaje: tipoMensaje
        });
    };

    const ocultarMensajeHandler = () => {
        dispatchAction({type: Acciones.ocultarMensaje});
    }

    const agregarItemCarritoHandler = (dominio, tipoItem, atributos) => {
        dispatchAction({
            type: Acciones.agregarItemCarrito,
            dominio: dominio,
            tipoItem: tipoItem,
            atributos: atributos
        });
    }

    const eliminarItemCarritoHandler = (idDominio, tipoItem) => {
        dispatchAction({
            type: Acciones.eliminarItemCarrito,
            idDominio: idDominio,
            tipoItem: tipoItem
        });
    }

    const refrescarCarritoHandler = (dominios, renovaciones, transferenciasTitulares) => {
        dispatchAction({
            type: Acciones.refrescarCarrito,
            dominios: dominios,
            renovaciones: renovaciones,
            transferenciasTitulares : transferenciasTitulares
        });
    }

    const setVentaHandler = (venta) => {
        dispatchAction({
            type: Acciones.setVenta,
            venta: venta
        });
    }

    const setCantidadMensajesSinLeerHandler = (cantidad) => {
        dispatchAction({
            type: Acciones.setCantidadMensajesSinLeer,
            cantidad: cantidad
        });
    }

    const appContext = {
        mensajes: appState.mensajes,
        carrito: appState.carrito,
        venta: appState.venta,
        usuario: appState.usuario,
        mostrarMensaje: mostrarMensajeHandler,
        mostrarMensajeLogo: mostrarMensajeLogoHandler,
        ocultarMensaje: ocultarMensajeHandler,
        agregarItemCarrito: agregarItemCarritoHandler,
        eliminarItemCarrito: eliminarItemCarritoHandler,
        refrescarCarrito: refrescarCarritoHandler,
        setVenta: setVentaHandler,
        clean: cleanLocalStorage,
        setCantidadMensajesSinLeer: setCantidadMensajesSinLeerHandler
    };

    useLayoutEffect(() => {
        const ventaStoraged = loadItemFromLocalStorage('venta-storaged');
        if (ventaStoraged && ventaStoraged !== "undefined"){
            appContext.venta = ventaStoraged;
            appState.venta = ventaStoraged;
        }
        const carritoStoraged = loadItemFromLocalStorage('carrito-storaged');
        if (carritoStoraged && carritoStoraged !== "undefined"){
            appContext.carrito = carritoStoraged;
            appState.carrito = carritoStoraged;
        }
        //cleanLocalStorage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContext.Provider value={appContext}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppProvider;
