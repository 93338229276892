import React, { useState } from 'react';
import {ModalDialogoAprobarTransferencia} from "./modales/ModalDialogoAprobarTransferencia";
import {ModalDialogoRechazarTransferencia} from "./modales/ModalDialogoRechazarTransferencia";


function BotonesTransferencia({ estado, transferdata, nombreDominio, setError, setMensajeOk, setReloadTrigger }) {
    const [isOpenModalDialogoAProbar, setIsOpenModalDialogoAProbar] = useState(false);
    const [isOpenModalDialogoRechazar, setIsOpenModalDialogoRechazar] = useState(false);

    useState(() => {
        console.log("transferdata: ",transferdata);
    }, []);
    const handleOpenDialogoAprobar = () => {
        setIsOpenModalDialogoAProbar(true);  // Aquí está la corrección
    };

    const handleOpenDialogoRechazar = () => {
        setIsOpenModalDialogoRechazar(true);
    };

    function handleOnCloseDialogoAProbar() {
        setIsOpenModalDialogoAProbar(false);
    }


    function handleOnCloseDialogoRechazar() {
        setIsOpenModalDialogoRechazar(false);
    }

    if (estado === 'USED_FOR_REQUEST') {
        return (
            <tr style={{ height: '2rem' }}>
                <td>
                    <button className="btn edit" onClick={handleOpenDialogoAprobar}>
                        Aprobar Transferencia
                    </button>
                    {isOpenModalDialogoAProbar && (
                        <ModalDialogoAprobarTransferencia handleOnClose={handleOnCloseDialogoAProbar} isOpen={isOpenModalDialogoAProbar} nombreDominio={nombreDominio} transferdata={transferdata} setError={setError} setMensajeOK={setMensajeOk} />
                    )}

                    <button className="btn edit" onClick={handleOpenDialogoRechazar}>
                        Rechazar Transferencia
                    </button>
                    {isOpenModalDialogoRechazar && (
                        <ModalDialogoRechazarTransferencia handleOnClose={handleOnCloseDialogoRechazar} isOpen={isOpenModalDialogoRechazar} nombreDominio={nombreDominio} transferdata={transferdata} setError={setError} setMensajeOK={setMensajeOk} setReloadTrigger={setReloadTrigger}/>
                    )}
                </td>
            </tr>
        );
    } else {
        return null;
    }
}


export default BotonesTransferencia;

;
