import React, {useEffect} from 'react';
import SolicitarTransferencia from "./solicitarTransferencia/SolicitarTransferencia"; // CSS Module
import {useLocation} from "react-router-dom";
import MisSolicitudesTransferencia from "./administrarSolicitudesTransferencia/MisSolicitudesTransferencia";
import styles from "./HomeTabTAR.module.css";
import transferenciaService from "../../services/TransferenciaRegistradorService";

const HomeTabTAR = props => {
    const location = useLocation();
    const listadoTARPendientes = location.pathname.indexOf('/transferir-dominio/lista-solicitudes') > -1;

    useEffect(() => {
        const timer = setTimeout(() => {
            const element = document.getElementById('nav-transferir-dominio-lista');

            if (element) {
                transferenciaService.obtenerCantSolicitudesTransferenciaXUsuario().then(response => {
                    if (response.message > 0) {
                        element.className = element.className + ' ' + styles.notificationItem;
                        element.setAttribute('data-count', response.message);
                    }
                }).catch(error => {
                });
            }
        }, 100);

        return () => clearTimeout(timer);
    });

    return (
        <div>
            { !listadoTARPendientes?
                <SolicitarTransferencia/> : <MisSolicitudesTransferencia/>
            }
        </div>
    );
}
export default HomeTabTAR;







