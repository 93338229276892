import React from 'react'
import {ListadoMensajes} from "../../mensajes/ListadoMensajes";

export const ResumenMensajes = ( { mostrarMensajes, mensajes, setMensajes }) => {

    const defaultPage = 0;
    const defaultSizePage = 10;

    function handleChangePage(nroPage, size){

    }

    return <>

        { mostrarMensajes && mensajes !== undefined &&
            <div className="box">
                <div className="top">
                    <h4>Mensajes</h4>
                    <div className="right">
                        <a href="/administrar/mensajes">Ver todos</a>
                    </div>
                </div>

                <ListadoMensajes
                    mensajes={mensajes}
                    setMensajes={setMensajes}
                    handleChangePage={handleChangePage}
                    paginador={false}
                    defaultPage={defaultPage}
                    defaultSizePage={defaultSizePage}/>

            </div>
        }
    </>
}