import React from "react";
import PropTypes from 'prop-types';
import Modal from "../../../components/Modal";

const ModalEliminarCuenta = (props) => {
    const {isOpen, setIsOpen, eliminarCuentaPago} = props;
    const cuenta = props.cuenta.cuentaAEliminar;

    const handleEliminarCuenta = () => {
        eliminarCuentaPago(cuenta.id);
    }

    return (
        <Modal handleClose={props.handleOnClose} isOpen={props.isOpen} closeOnClickOutside={false}
               className="modal" modalContentClassName="modal-content" closeClassName="close-modal">
            <h3>Eliminar cuenta {cuenta.nombre}</h3>
            <p>Tenga en cuenta que los dominios que se hayan pago con esta cuenta seguirán asociados a la misma, esta acción sólo elimina la</p>
            <p>cuenta de su lista de datos pre-cargados. ¿Desea eliminar la cuenta?</p>
            <hr className={"linea"}></hr>
            <div className="right">
                <button className={'btn'} onClick={handleEliminarCuenta}>Aceptar</button>
                <button className={'btn'} onClick={props.handleOnClose}>Cancelar</button>
            </div>
        </Modal>
    )
}

ModalEliminarCuenta.propTypes = {
    handleOnClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default ModalEliminarCuenta;