export const localStorageImportarDominioClear = () => {
    localStorage.removeItem('importarDominio');
}

export const marcarRedireccionPaso2Pendiente = () => {
    localStorage.setItem('redirigirPaso2', 'true');
}

export const existeRedireccionPaso2Pendiente = () => {
    return localStorage.getItem('redirigirPaso2') === 'true';
}

export const eliminarRedireccionPaso2Pendiente = () => {
    localStorage.removeItem('redirigirPaso2');
}

export const obtenerDominioValidado = () => {
    return localStorage.getItem('importarDominio')?
        JSON.parse(localStorage.getItem('importarDominio')).dominio : null;
}

export const obtenerRegistros = () => {
    return localStorage.getItem('importarDominio')?
        JSON.parse(localStorage.getItem('importarDominio')).registros : null;
}

export const guardarDominioValidado = (dominioObj) => {
    localStorage.setItem('importarDominio', JSON.stringify(dominioObj));
}

export const marcarCodAuthValidado = () => {
    const importarDomObj = JSON.parse(localStorage.getItem('importarDominio'));

    localStorage.setItem('importarDominio', JSON.stringify({
        ...importarDomObj,
        codigoValidado: 'true'
    }));
}

export const existeCodAuthValidado = () => {
    return localStorage.getItem('importarDominio')?
        JSON.parse(localStorage.getItem('importarDominio')).codigoValidado : 'false';
}

export const obtenerReqTransf = () => {
    return localStorage.getItem('importarDominio')?
        JSON.parse(localStorage.getItem('importarDominio')).eppRequest : null;
}

export const guardarReqTransf = (codEpp, nombre, apellido, dir, tel, email) => {
    const importarDomObj = JSON.parse(localStorage.getItem('importarDominio'));

    const  eppRequest = {
        codEpp: codEpp,
        nombre: nombre,
        apellido: apellido,
        dir: dir,
        tel: tel,
        email: email
    }

    localStorage.setItem('importarDominio', JSON.stringify({
        ...importarDomObj,
        eppRequest: eppRequest
    }));
}

export const obtenerMediopago = () => {
    return localStorage.getItem('importarDominio')?
        JSON.parse(localStorage.getItem('importarDominio')).mediopago : null;
}

export const guardarMediopago = (mediopagoObj) => {
    const importarDomObj = JSON.parse(localStorage.getItem('importarDominio'));

    localStorage.setItem('importarDominio', JSON.stringify({
        ...importarDomObj,
        mediopago: mediopagoObj
    }));
};