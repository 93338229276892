import React, {useContext, useRef, useState} from 'react'
import AppContext from "../../../../store/AppContext";
import {TipoMensaje} from "../../../../store/AppProvider";
import transferenciaService from "../../../../services/TransferenciaRegistradorService";
import PropTypes from "prop-types";
import {buildImportarDominioBaseDTO} from "../../../../dto/tar/ImportarDominioBuilderDTO";
import {
    eliminarRedireccionPaso2Pendiente,
    existeRedireccionPaso2Pendiente,
    guardarDominioValidado,
    marcarRedireccionPaso2Pendiente,
    obtenerDominioValidado
} from "../SolTransLocalStorageUtils";
import stylesHome from "../../HomeTabTAR.module.css";
import ModalLoginRequerido from "../../../consultaDisponibilidad/ModalLoginRequerido";
import {getOidcState} from "../../../../security/OidcManager";
import ReCAPTCHA from "react-google-recaptcha";

const REACT_APP_SITE_KEY = process.env.REACT_APP_SITE_KEY;

export const FormularioValidacionDominio = (props) => {
    // Si el usuario vuelve autenticado luego de haber consultado la viabilidad de la transferencia, se envia
    // directamente al paso 2
    if (getOidcState().is_authenticated && existeRedireccionPaso2Pendiente()) {
        eliminarRedireccionPaso2Pendiente();
        props.handleContinuar();
    }

    const appCtx = useContext(AppContext);

    const dominio = obtenerDominioValidado();

    const domTransfRef = React.useRef(dominio);
    const recaptchaRef = useRef();
    const [hayError, setHayError] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [captchaResponse, setCaptchaResponse] = useState('');

    function esDominioUY(domain) {
        // Return true if domain ends in .uy but not in .com.uy
        return domain.endsWith('.uy') && !domain.endsWith('.com.uy');
    }

    function esDominioTransferible(domain) {
        return transferenciaService.esDominioTransferible(domain, captchaResponse);
    }

    const resetCaptcha = () => {
        if (recaptchaRef.current.getValue()) {
            recaptchaRef.current.reset();
        }
    }

    const consultarDominioTransferencia = async () => {
        let dominio = domTransfRef.current.value;

        if (dominio === '' || dominio.trim() === '') {
            props.onValidacionError();
            appCtx.mostrarMensaje('El dominio a transferir no puede estar vacío', TipoMensaje.error);
            setHayError(true);
            document.querySelector('iframe').classList.remove('box-captcha-error');
            resetCaptcha();

            return;
        }

        if (!esDominioUY(dominio)) {
            props.onValidacionError();
            appCtx.mostrarMensaje('El dominio debe ser .uy', TipoMensaje.error);
            setHayError(true);
            document.querySelector('iframe').classList.remove('box-captcha-error');
            resetCaptcha();

            return;
        }

        esDominioTransferible(dominio)
            .then(esDomTransf => {
                if (esDomTransf.ok) {
                    setHayError(false);
                    let importarDominioDTO = buildImportarDominioBaseDTO(dominio, esDomTransf.registros);
                    guardarDominioValidado(importarDominioDTO);

                    appCtx.mostrarMensaje(esDomTransf.mensaje, TipoMensaje.ok);
                    props.onValidacionOK();
                    handleContinuar();
                } else {
                    props.onValidacionError();
                    if (esDomTransf.mensaje.includes("no puede ser transferido porque ya pertenece")) {
                        appCtx.mostrarMensajeLogo(esDomTransf.mensaje, TipoMensaje.error, true);
                    } else {
                        appCtx.mostrarMensaje(esDomTransf.mensaje, TipoMensaje.error)
                    }
                    setHayError(true);

                    if (esDomTransf.errorCaptcha) {
                        document.querySelector('iframe').classList.add('box-captcha-error');
                    } else {
                        document.querySelector('iframe').classList.remove('box-captcha-error');
                    }
                }
            }).catch(error => {
            appCtx.mostrarMensaje('Se produjo un error al consultar si el dominio puede ser transferido', TipoMensaje.error);
        }).finally(() => {
            resetCaptcha();
        })
    }

    const handleContinuar = () => {
        if (!getOidcState().is_authenticated) {
            setIsOpen(true);
        } else {
            props.handleContinuar();
        }
    }

    const preRedirectActionLoginRequerido = () => {
        marcarRedireccionPaso2Pendiente();
    }

    const handleRecaptchaChanged = (value) => {
        setCaptchaResponse(value);
        document.querySelector('iframe').classList.remove('box-captcha-error');
    }

    return <>
        <ModalLoginRequerido isOpen={isOpen}
                             okModalMessage='Iniciar sesión y Continuar'
                             modalMessage='Debe iniciar sesión para continuar con la importación del dominio'
                             handleOnClose={() => {setIsOpen(false)}}
                             preRedirectAction={preRedirectActionLoginRequerido}
        />

        <div id="step-content" className={stylesHome.stepsContainer}>
            <div id="step-1" className={stylesHome.stepContent}>
                <div className="whois-search">
                    <div>
                        <p style={{fontSize: "medium"}}>Ingrese el dominio que desea transferir a <a><img
                            style={{width: "10%", verticalAlign: "middle", border: "none"}} alt="Dominiosuy"
                            src="/assets/logo.png"/></a></p>
                        <input type="text" id="id-nombre-dominio" placeholder="dominio-ejemplo.uy" ref={domTransfRef}
                               className={hayError ? stylesHome.formInputError : undefined} defaultValue={dominio}/>
                        <div className={"box-captcha cfx"}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={REACT_APP_SITE_KEY}
                                onChange={handleRecaptchaChanged}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="right">
            <input type="button" value="Continuar" id="id-validar-dominio"
                   className={`btn edit }`}
                   title={props.handleContinuarBtnDisabled ? 'Para continuar se debe consultar si el dominio es transferible' : ''}
                   style={{padding: "7px 12px"}}
                   onClick={consultarDominioTransferencia}/>
        </div>
    </>
}

FormularioValidacionDominio.propTypes = {
    handleContinuar: PropTypes.func.isRequired,
    onValidacionOK: PropTypes.func.isRequired,
    onValidacionError: PropTypes.func.isRequired,
    handleContinuarBtnDisabled: PropTypes.bool,
    completedSteps: PropTypes.number,
};