import React from 'react'
import {Link} from "react-router-dom";

export const Contacto = () => {

    const urlAccederGeneracionTicket = process.env.REACT_APP_URL_ACCEDER_TICKETS + "TICKET_AYUDA";

    return <>
        <h2>Contacto</h2>
        <div className="contactenos_body">
            <p>
                Se sugiere ingresar a las <Link to={{ pathname: "/ayuda"}}> Preguntas frencuentes</Link> antes de realizar una consulta.
            </p>
            <p>
                Si desea realizar consultas técnicas o comerciales puede hacerlo a través del <Link to={{ pathname: "/generar-ticket"}}>siguiente formulario</Link>.
            </p>
        </div>
    </>
}