import React from 'react';
import Modal from "../../../components/Modal";
import '../../Modal.css';
import { useNavigate } from 'react-router-dom';
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";


export const ModalEliminarFormaPago = ({handleOnClose, isOpen, dominiosSeleccionados, respuestaAccionOk, setError}) => {
    const navigate = useNavigate();

    const onAccept = () => {
        eliminarCuentaDominios();
        handleOnClose(false);
        window.scrollTo(0, 0);
    };

    const eliminarCuentaDominios = () => {
        AdministracionUsuarioService.eliminarFormaPago(dominiosSeleccionados)
            .then(response => {
                respuestaAccionOk('Cuenta de pago eliminada con éxito.');
            })
            .catch(error => {
                setError({
                    error: true,
                    mensaje: 'Error al eliminar la forma de pago. Por favor, intente de nuevo más tarde.'
                });
            });
    }


    return (
        <Modal
            idDivModal="idModalLiberar"
            handleClose={handleOnClose}
            isOpen={isOpen}
            closeOnClickOutside={false}
        >
            <div className="modal-content">
                <p className="modal-text">
                    ¿Desea eliminar la cuenta asociada al dominio <strong>{dominiosSeleccionados}</strong>?
                </p>
                <hr className="linea"/>
                <div className="modal-actions">
                    <button className='btn confirm' onClick={onAccept}>Eliminar</button>
                    <button className='btn cancel' onClick={handleOnClose}>Cancelar</button>
                </div>
            </div>
        </Modal>
    );
};
