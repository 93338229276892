import React, { useEffect, useState, useContext } from "react";
import { CarritoComprasPasos } from "../CarritoComprasPasos";
import { MensajeOk } from "../../MensajeOk";
import { ValidacionAutenticado } from "../../../components/validaciones/ValidacionAutenticado";
import { MensajeError } from "../../MensajeError";
import { useLocation } from "react-router-dom";

import { VentaContext } from "../VentaContext";

export const CarritoCompraPasoCinco = () => {
    const location = useLocation();
    const { tiposVenta } = useContext(VentaContext); // Obtener tiposVenta desde el contexto

    const [mensajeOk, setMensajeOk] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        limpiarMensajes();

        if (location.state) {
            if (location.state.mensajeOk) {
                setMensajeOk(location.state.mensajeOk);
            }
            if (location.state.error) {
                setError(location.state.error);
            }
        }
    }, [location]);

    const limpiarMensajes = () => {
        setMensajeOk({ mostrarMensaje: false, mensaje: '' });
        setError({ error: false, mensaje: '' });
    };

    const renderMensajePorTipoVenta = () => {
        if (!tiposVenta) return null;

        return tiposVenta.map((tipo, index) => {
            switch (tipo) {
                case 'registro':
                    return (
                        <div key={index}>
                            <p><strong>Los dominios comprados quedarán operativos en pocos minutos.</strong></p>
                            <br/>
                            <p>Por defecto los dominios son alojados en los servidores de nombre de ANTEL. Si desea
                                ingresar sus servidores de nombre (delegar) puede hacerlo eligiendo ver el dominio
                                correspondiente en <a href="/administrar/mis-dominios">Administrar Mis Dominios</a>
                            </p>
                            <br/>
                            <p>Usted figura como contacto Administrativo, Técnico y Financiero. A través de la
                                administración de los dominios se puede además de gestionar la parte técnica, realizar
                                modificaciones en la forma de pago y los contactos Administrativo, Técnico y
                                Financiero.</p>
                            <br/>
                            <p>En el caso de que durante el proceso de compra usted haya seleccionado "Usar mis datos de
                                contacto" para los datos del titular, usted también figura como contacto Titular.</p>
                            <br/>
                            <p>El correo electrónico del titular de un dominio recibe notificaciones importantes.</p>
                            <br/>

                        </div>
                    );
                case 'transferencia':
                    return (
                        <div key={index}>
                            <p><strong>La transferencia de dominio está en proceso.</strong></p>
                            <br />
                            <p>Se le notificará cuando la transferencia se haya completado.</p>
                            <br />
                            <p>Por favor, verifique su correo electrónico para cualquier actualización.</p>
                            <br/>
                        </div>
                    );
                case 'renovacion':
                    return (
                        <div key={index}>
                            <p><strong>Los dominios renovados quedarán operativos de inmediato.</strong></p>
                            <br/>
                            <p>Revise su correo electrónico para confirmar la renovación.</p>
                            <br/>
                        </div>

                    );
                // default:
                //     return (
                //         <div key={index}>
                //             <p><strong>Gracias por su compra. Su pedido está siendo procesado.</strong></p>
                //             <br />
                //         </div>
                //     );
            }
        });
    };

    return (
        <>
            {mensajeOk !== undefined && mensajeOk.mostrarMensaje && <MensajeOk mensajeOk={mensajeOk.mensaje} />}
            {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje} />}

            <ValidacionAutenticado />

            <CarritoComprasPasos paso={5}
                                 tituloCarrito={'Confirmación de la compra'}
                                 parrafoCarrito={''}
            />

            {(error === undefined || !error.error) && renderMensajePorTipoVenta()}

            <br clear="all" />
        </>
    );
};