import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import PropTypes from 'prop-types';
import {getOidcState} from "../security/OidcManager";
import transferenciaRegistradorService from "../services/TransferenciaRegistradorService";

const NavItem = (props) => {
    const location = useLocation();

    function getClassName (linkPath, appendClases = '', exact = true) {
        const isActive = exact? location.pathname === linkPath : location.pathname.indexOf(linkPath) > -1;
        const finalClass = appendClases + (isActive? ' active' : '');

        return {...(!!finalClass && { className: finalClass})};
    }

    return !props.hidden && <li id={props.id} {...getClassName(props.href, props.className, props.exact?? true)}>
        {props.external ?
            <a href={props.href}>{props.title}</a> :
            <Link to={props.href}>{props.title}</Link>
        }
    </li>
}

NavItem.propTypes = {
    hidden: PropTypes.bool,
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    external: PropTypes.bool,
    group: PropTypes.bool
};

export const Nav = (props) => {
    const location = useLocation();
    const [showTransferDomain, setshowTransferDomain] = useState(false);
    const isUserLogedIn = getOidcState().is_authenticated;
    const subNavAdministrar = location.pathname.indexOf('/administrar') > -1;
    const subNavTAR = location.pathname.indexOf('/transferir-dominio') > -1;

    const isSubNavActive = subNavAdministrar || subNavTAR;

    useEffect(() => {
        isTAREnabled();
    }, []);

    const isTAREnabled = () =>
    {
         transferenciaRegistradorService.isTAREnabled().then(response => {
             setshowTransferDomain(response.code === 'true');
         }).catch(ignoreError => { // Por el momento no hacemos nada con esto
         });
    }

    return <>
        <div id="nav" className="cfx">
            <ul>
                <NavItem id="nav-registrar" href="/consulta-disponibilidad" title="Registrar dominio"/>

                <NavItem id="nav-whois" href="/consulta-whois" title="Consultar Whois"/>

                {/*<NavItem id="nav-transferir-titular" href="/transferir-titular" title="Transferir titular"/>*/}

                {/* Mostrar NavItem basado en la propiedad showTransferDomain */}
                {showTransferDomain &&
                    <NavItem id="nav-transferir-dominio" href="/transferir-dominio" title="Importar Dominio" exact={false}/>
                }

                {/*<li id="nav-help">*/}
                {/*    <a href="http://ayuda.adinet.com.uy/ayuda/index/197" target="_blank" rel="noreferrer">Ayuda</a>*/}
                {/*</li>*/}
                <NavItem id="nav-contacto" href="/ayuda" title="Ayuda"/>

                <NavItem id="nav-contacto" href="/contacto" title="Contáctenos"/>

                {getOidcState().is_authenticated &&
                <NavItem id="nav-account" href="/administrar/resumen"
                         title="Administrar" className={isSubNavActive ? "last active" : "last"} hidden={!isUserLogedIn} external={true} group={true}/>
                }
            </ul>
        </div>

        {
            isSubNavActive && isUserLogedIn?
                <div id="subnav" className="cfx">
                    <ul>
                        {subNavAdministrar?
                            /*Sub-navegación de Administrar*/
                            <>
                                {/* eslint-disable-next-line */}
                                <li id="subnav-summary" className={location.pathname.includes('/resumen') ? "active" : ""}><a href="/administrar/resumen">Resumen</a></li>
                                {/* eslint-disable-next-line */}
                                <li id="subnav-mydomains" className={(location.pathname.includes('/mis-dominios') || location.pathname.includes('/ver-dominio')) ? "active" : ""}>
                                    <Link to="/administrar/mis-dominios">Mis dominios</Link>
                                </li>
                                {/* eslint-disable-next-line */}
                                <li id="subnav-mymessages" className={location.pathname.includes('/mensajes') ? "active" : ""}><a href="/administrar/mensajes">Mensajes</a></li>
                                {/* eslint-disable-next-line */}
                                <li id="subnav-mydata" className={location.pathname.includes('/mis-datos') ? "active" : ""}><a href="/administrar/mis-datos">Mis datos</a></li>
                            </> :
                            /*Sub-navegación de importar dominios*/
                            <>
                                {/* Si showTransferDomain es true, mostrar el elemento "Solicitar importación" */}
                                {showTransferDomain &&
                                    <NavItem id="nav-transferir-dominio" href="/transferir-dominio" title="Solicitar importación"/>
                                }
                                {/* eslint-disable-next-line */}
                                {showTransferDomain &&
                                <NavItem id="nav-transferir-dominio-lista" href="/transferir-dominio/lista-solicitudes" title="Mis Solicitudes"/>
                                }
                                {/*<li id="subnav-summary" className="active"><a href="#">Solicitar importación</a></li>
                        <li id="subnav-mydomains"><a href="#">Mis solicitudes pendientes</a></li>*/}
                            </>
                        }
                    </ul>
                </div> : <></>
        }
    </>
}
