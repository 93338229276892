import PropTypes from "prop-types";
import {buildCrearCuentaPagoRequest} from "../CuentaDTOBuilder";

export function buildSolicitudCodAutorizacion(nombreDominio) {
    return {
        domain: nombreDominio
    }
}export function buildSolicitudRechazarTransferencia(nombreDominio, codigoAutorizacion) {
    return {
        domain: nombreDominio,
        authCode: codigoAutorizacion,
    }
}
export function buildSolicitudAutorizacion(nombreDominio, codigoAutorizacion) {
    return {
        domain: nombreDominio,
        authCode: codigoAutorizacion
    }
}

export function buildSolicitudAutorizacionResponse(responseSolicitudAutorizacion) {
    return {
        codigo: responseSolicitudAutorizacion.codigoAutorizacion.codigo
    }
}

export function buildDatosContactoSolicitudTAR(nombre, apellido, direccion, telefono, email) {
    return {
        nombre,
        apellido,
        direccion,
        telefono,
        email
    };
}

export function buildDatosCuentaPagoTAR(datosCuentaDTO, formaPagoDTO) {
    return buildCrearCuentaPagoRequest(null, datosCuentaDTO, formaPagoDTO).templateCuentaPagoDTO;
}

export function buildSolicitudTransferenciaRequest(dominio, codigoAutorizacion, datosTitular, cuentaPago) {
    return {
        dominio,
        codigoAutorizacion,
        datosTitular,
        cuentaPago
    };
}

export function buildSolicitudTransferenciaResponse(response) {
    return {
        isOk: response.ok,
        message: response.message,
        data: response.ok? {
            domainName: response.domainName,
            status: response.status,
            registrar: response.registrar,
            requestDate: response.requestDate,
            newRegistrar: response.newRegistrar,
            reqExpDate: response.reqExpDate,
            newDomExpDat: response.newDomExpDate
        } : null
    }
}

export function buildObtenerDatosResponse(response) {
    return {
        codigoAutorizacion: response.codigoAutorizacion,
        dominio: response.dominio,
        estado: response.estado,
        fechaSolicitud: response.fechaSolicitud,
        fechaVencSol: response.fechaVencSol,
        registros : response.registros
    }
}

export function buildSolicitudTransferenciaResponseError(errorMesage) {
    return {
        isOk: false,
        errorMessage: errorMesage,
    }
}

export function buildCancelarTransferenciaRequest(domain, authCode) {
    return {
        domain,
        authCode
    };
}

export function builEsDominioTransferibleaRequest(domain, captcha) {
    return {
        domain,
        captcha,
        incluirConsultaRegistros: true
    };
}

buildSolicitudTransferenciaResponse.propTypes = {
    isOK: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    data: PropTypes.shape({
        domainName: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        registrar: PropTypes.string,
        requestDate: PropTypes.string,
        newRegistrar: PropTypes.string,
        reqExpDate: PropTypes.string,
        newDomExpDat: PropTypes.string
    }).isRequired
};

buildSolicitudTransferenciaResponseError.propTypes = {
    isOK: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
};