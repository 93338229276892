export function buildEliminarRegistroDominio(idDominio, uuidVenta) {
    return {
        idDominio: idDominio,
        uuidVenta: uuidVenta
    }
}

export function buildEliminarRegistroDominioResponse(responseEliminarRegistroDominio) {
    return {
        respuestaOK: responseEliminarRegistroDominio.respuestaOK,
        codigo: responseEliminarRegistroDominio.codigo,
        mensaje: responseEliminarRegistroDominio.mensaje
    }
}

export function buildObtenerPrecioProductoResponse(responseObtenerPrecioProducto) {
    return {
        precioUnitario: responseObtenerPrecioProducto.precioUnitario,
        codigo: responseObtenerPrecioProducto.codigoRespuesta,
        mensaje: responseObtenerPrecioProducto.mensajeRespuesta
    }
}