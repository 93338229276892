export function buildObtenerContactoUsuarioResponse(obtenerContactoUsuarioResponse) {
    return {
        contactoDTO : obtenerContactoUsuarioResponse.contactoDTO,
        respCodigo: obtenerContactoUsuarioResponse.codigoRespuesta,
        respMensaje: obtenerContactoUsuarioResponse.mensajeRespuesta,
    }
}

export function buildObtenerContactosUsuarioResponse(obtenerContactosUsuarioResponse) {
    return {
        contactosDTO : obtenerContactosUsuarioResponse.contactosDTO,
        respCodigo: obtenerContactosUsuarioResponse.codigoRespuesta,
        respMensaje: obtenerContactosUsuarioResponse.mensajeRespuesta,
    }
}

export function buildObtenerCuentasPagoUsuarioResponse(obtenerCuentasPagoUsuarioResponse) {
    return {
        cuentas : obtenerCuentasPagoUsuarioResponse.cuentas,
        respCodigo: obtenerCuentasPagoUsuarioResponse.codigoRespuesta,
        respMensaje: obtenerCuentasPagoUsuarioResponse.mensajeRespuesta,
    }
}

export function buildObtenerTemplateCuentaPagoResponse(obtenerTemplateCuentaPagoResponse) {
    return {
        templateCuentaPagoDTO : obtenerTemplateCuentaPagoResponse.cuentaPago,
        respCodigo: obtenerTemplateCuentaPagoResponse.codigoRespuesta,
        respMensaje: obtenerTemplateCuentaPagoResponse.mensajeRespuesta,
    }
}

export function buildCrearContactoDominioResponse(crearCuentaDominioResponse) {
    return {
        esRespOK: crearCuentaDominioResponse.respuestaOK,
        respCodigo: crearCuentaDominioResponse.codigoRespuesta,
        respMensaje: crearCuentaDominioResponse.mensajeRespuesta
    }
}


export function buildCrearCuentaPagoRequest(uuidVenta, templateCuentaPago, formaPago) {
    return {
        uuidVenta: uuidVenta,
        templateCuentaPagoDTO: {
            id: templateCuentaPago.id,
            nombre: templateCuentaPago.nombre,
            cuenta:{
                id: templateCuentaPago.cuenta.id,
                cuenta: templateCuentaPago.cuenta.cuenta,
                pais:{
                    id: templateCuentaPago.cuenta.idPais,
                    nombre: templateCuentaPago.cuenta.nombrePais
                },
                tipoDocumento:{
                    id: templateCuentaPago.cuenta.idTipoDocumento,
                    nombre: templateCuentaPago.cuenta.nombreTipoDocumento
                },
                nroDocumento: templateCuentaPago.cuenta.nroDocumento,
                formaPago: formaPago
            }
        }
    }
}

export function buildCrearCuentaPagoResponse(crearCuentaPagoResponse) {
    return {
        esRespOK: crearCuentaPagoResponse.respuestaOK,
        respCodigo: crearCuentaPagoResponse.codigoRespuesta,
        respMensaje: crearCuentaPagoResponse.mensajeRespuesta,
        templateCuentaPago: crearCuentaPagoResponse.cuentaPago
    }
}

export function buildActualizarTemplateCuentaPagoRequest(templateCuentaPagoId, templateCuentaPagoNombre) {
    let templateCuentaPagoDTO = {
        id: templateCuentaPagoId,
        nombre: templateCuentaPagoNombre
    }
    return {
        templateCuentaPagoDTO: templateCuentaPagoDTO
    }
}

export function buildActualizarTemplateCuentaPagoResponse(crearCuentaPagoResponse) {
    return {
        esRespOK: crearCuentaPagoResponse.respuestaOK,
        respCodigo: crearCuentaPagoResponse.codigoRespuesta,
        respMensaje: crearCuentaPagoResponse.mensajeRespuesta,
    }
}

export function buildEliminarCuentaPagoUsuarioResponse(crearCuentaPagoResponse) {
    return {
        contactoPredeterminadoDTO: crearCuentaPagoResponse.contactoPredeterminadoDTO,
        esRespOK: crearCuentaPagoResponse.respuestaOK,
        respCodigo: crearCuentaPagoResponse.codigoRespuesta,
        respMensaje: crearCuentaPagoResponse.mensajeRespuesta,
    }
}

export function buildActualizarContactoUsuarioResponse(actualizarContactoUsuarioResponse) {
    return {
        respCodigo: actualizarContactoUsuarioResponse.codigoRespuesta,
        respMensaje: actualizarContactoUsuarioResponse.mensajeRespuesta,
    }
}

export function buildObtenerTiposContactoResponse(tiposContactoResponse) {
    return {
        tiposContacto : tiposContactoResponse.tiposAsociacion,
        respuestaOk : tiposContactoResponse.respuestaOk,
        respCodigo: tiposContactoResponse.codigoRespuesta,
        respMensaje: tiposContactoResponse.mensajeRespuesta,
    }
}