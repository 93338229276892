import http from '../support/HttpService';

const REACT_APP_USUARIO_TERMINOS_CONDICIONES = process.env.REACT_APP_USUARIO_TERMINOS_CONDICIONES;
const REACT_APP_OBTENER_TERMINOS_CONDICIONES = process.env.REACT_APP_OBTENER_TERMINOS_CONDICIONES;
const REACT_APP_ACEPTAR_TERMINOS_CONDICIONES = process.env.REACT_APP_USUARIO_TERMINOS_CONDICIONES;


const TerminosYCondicionesService = {

    usuarioDebeAceptarTerminosYcondiciones: async function() {
        try {
            const response = await http.get(REACT_APP_USUARIO_TERMINOS_CONDICIONES);
            return response.data;
        } catch (error) {
            console.error("Error validando datos del usuario y terminos y condiciones");
        }
    },

    obtenerTerminosYCondicionesActual: async function() {
        try {
            const response = await http.get(REACT_APP_OBTENER_TERMINOS_CONDICIONES);
            return response.data;
        } catch (error) {
            console.error("Error obteniendo terminos y condiciones");
        }
    },

    aceptarTerminosYCondiciones: async function() {
        try {
            const response = await http.post(REACT_APP_ACEPTAR_TERMINOS_CONDICIONES);
            return response.data;
        } catch (error) {
            console.error("Error aceptando terminos y condiciones");
        }
    }
}
export default TerminosYCondicionesService;
