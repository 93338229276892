import {buildConsultaWhois, buildConsultaWhoisResponse} from "../dto/ConsultaWhoisDTOBuilder";
import http from '../support/HttpService';

const REACT_APP_CONSULTA_WHOIS = process.env.REACT_APP_CONSULTA_WHOIS;

const ConsultaService = {

    getWhois: async function (nombreDominio, captchaResponse) {
        try {
            const response = await http.post(REACT_APP_CONSULTA_WHOIS, buildConsultaWhois(nombreDominio, captchaResponse));
            return buildConsultaWhoisResponse(nombreDominio, response.data);

        } catch (error){
            console.error("Error procesando pedido");
        }
    }
}
export default ConsultaService;