import {useEffect, useState} from "react";

const Paginador = ({ handleChangePage, page2, sizePage2, totalRecords, mostrarCantElemXPag, cantElemXPagMap }) => {

    const [pageNumbers, setPageNumbers] = useState([]);
    const [currentPage, setCurrentPage] = useState(page2);
    const [sizePage, setSizePage] = useState(sizePage2);
    const limitePaginas = 5;
    const irPaginaAnteriorIdentificador = -1;
    const irPaginaSiguienteIdentificador = -2;
    const irPrimeraPaginaIdentificador = -3;
    const irUltimaPaginaIdentificador = -4;

    useEffect(() => {
        setCurrentPage(page2);
    }, [page2])

    function onClickChangePage(number1) {
        if (number1 === irPaginaAnteriorIdentificador){
            setCurrentPage(currentPage - 1);
            handleChangePage(currentPage - 1, sizePage);
        }else if (number1 === irPaginaSiguienteIdentificador){
            setCurrentPage(currentPage + 1);
            handleChangePage(currentPage + 1, sizePage);
        }else if (number1 === irPrimeraPaginaIdentificador){
            setCurrentPage(0);
            handleChangePage(0, sizePage);
        }else if (number1 === irUltimaPaginaIdentificador){
            setCurrentPage(pageNumbers.length - 1);
            handleChangePage(pageNumbers.length - 1, sizePage);
        }else{
            setCurrentPage(number1);
            handleChangePage(number1, sizePage);
        }
    }

    function calcularCantidadPaginas(cantidadPorPagina) {
        let pageNumbersAux = [];
        for (let i = 1; i <= Math.ceil(totalRecords / cantidadPorPagina); i++) {
            pageNumbersAux.push(i);
        }
        return pageNumbersAux;
    }

    const onChangeBlockSize = (event) => {
        setSizePage(event.target.value);
        setCurrentPage(0);
        handleChangePage(0, event.target.value);
        setPageNumbers(calcularCantidadPaginas(event.target.value))
    }

    useEffect(() => {
        setPageNumbers(calcularCantidadPaginas(sizePage));
    },[totalRecords]);

    function generarPagesNumbers () {
        let map = [];

        let limit = pageNumbers.length > limitePaginas ? limitePaginas : pageNumbers.length;
        let minPage = currentPage - 2 > 0 ? currentPage - 2 : 0;
        let maxPage = currentPage + 2 >= limit && pageNumbers.length > limit ? currentPage + 2 : 4;

        if (minPage > 0){
            map.push(
                <li key={"-3"} className={ '' } id={"idPaginadoPagPrim"} onClick={()=> onClickChangePage(irPrimeraPaginaIdentificador)}>
                    <a className={"link"}>&laquo;</a>
                </li>
            )
        }

        if (currentPage > 0 && currentPage < pageNumbers.length) {
            map.push(
                <li key={"-1"} className={ '' } id={"idPaginadoPagAnt"} onClick={()=> onClickChangePage(irPaginaAnteriorIdentificador)}>
                    <a className={"link"}>&lsaquo;</a>
                </li>
            )
        }

        let pageNumbersFirst5 = pageNumbers.filter((element, index) => index >= minPage && index <= maxPage);
        map.push(pageNumbersFirst5.map(number => (
            <li key={number} className={currentPage === number-1 ? 'active' : '' } id={"idPaginadoNroPag" + number} onClick={()=> onClickChangePage(number-1)}>
                <a className={"link"}>{ number }</a>
            </li>
        )));

        if ((currentPage === 0 && pageNumbers.length - 1 > 0)
                || (currentPage > 0 && currentPage < pageNumbers.length -1)) {
            map.push(
                <li key={"-2"} className={ '' } id={"id_paginado_pag_sig"} onClick={()=> onClickChangePage(irPaginaSiguienteIdentificador)}>
                    <a className={"link"}>&#8250;</a>
                </li>
            )
        }

        if (maxPage < pageNumbers.length - 1) {
            map.push(
                <li key={"-4"} className={ '' } id={"id_paginado_pag_ult"} onClick={()=> onClickChangePage(irUltimaPaginaIdentificador)}>
                    <a className={"link"}>&raquo;</a>
                </li>
            )
        }
        return map;
    }

    return(
        <>
            <div className="nav-pag left">
                <ul>
                    {
                        (pageNumbers.length !== 1)
                            && generarPagesNumbers()
                    }
                    {
                        mostrarCantElemXPag && cantElemXPagMap &&
                        <select className={"left"} onChange={onChangeBlockSize} defaultValue={sizePage}>
                            {cantElemXPagMap.map((option, index) => {
                                return <option key={index} >
                                    {option}
                                </option>
                            })}
                        </select>

                    }
                </ul>
                {
                    (pageNumbers.length !== 1) &&
                        <span style={{verticalAlign: 'middle'}}>por página</span>
                }

            </div>
        </>

    )

};

export default Paginador;