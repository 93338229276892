import React, {useState} from 'react'
import {Link} from "react-router-dom";
import Paginador from "../tabla/Paginador";
import {ModalMensaje} from "./ModalMensaje";
import MensajesService from "../../services/MensajesService";

export const ListadoMensajes = ( { mensajes, setMensajes, handleChangePage, paginador, mensajesTotal, defaultPage, defaultSizePage  }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [mensaje, setMensaje] = useState([]);
    const [currentPage, setCurrentPage] = useState(defaultPage);
    const [currentSizePage, setCurrentSizePage] = useState(defaultSizePage);

    function handleVerMensaje(event) {
        event.preventDefault();
        const idMensaje = event.target.id;

        setIsOpen(true);

        let msg = mensajes.find(msg => msg.id === +idMensaje);
        msg.leido = true;
        setMensaje(msg);

        MensajesService.marcarMensajeLeido(idMensaje);

    }

    function handleEliminarMensaje(event) {
        const idMensaje = event.target.id;
        MensajesService.eliminarMensajeLeido(idMensaje).then(r => {
            if (r.codigo === 'OK') {
                setMensajes(mensajes.filter(msg => msg.id !== +idMensaje));
            }
        });
    }

    function handleOnClose(){
        setIsOpen(false);
        handleBuscarMensajes(currentPage, currentSizePage);
    }

    function handleBuscarMensajes(nroPage, size) {
        setCurrentPage(nroPage);
        setCurrentSizePage(size);
        handleChangePage(nroPage, size);
    }

    return <>

            <ModalMensaje handleOnClose={handleOnClose} isOpen={isOpen} mensaje={mensaje}/>

                <div className="box-content">
                        { mensajes.map((mensaje, index) => (
                            <p className={"msg cfx "
                                + (mensaje.prioridad === "ALTA" ? "red" : mensaje.prioridad === "MEDIA" ? "yellow" : "green")
                                + (!mensaje.leido ? ' no-read' : '') }
                                key={mensaje.id}>
                                <Link className={"left"}
                                      to={{
                                        pathname: "/mensaje-detalle",
                                        state: { "idMensaje" : mensaje.id }
                                      }}
                                      id={mensaje.id}
                                      onClick={handleVerMensaje}>{ mensaje.titulo }</Link>
                                <label className="right">{ mensaje.fechaDesdeString }
                                    <a id={mensaje.id} className="close" onClick={handleEliminarMensaje}/>
                                </label>
                            </p>

                        ))}
                </div>

                { paginador &&
                    <Paginador
                        handleChangePage={handleBuscarMensajes}
                        page2={defaultPage}
                        sizePage2={defaultSizePage}
                        totalRecords={mensajesTotal}
                        mostrarCantElemXPag={true}
                        cantElemXPagMap={[5,10,25]}
                    />
                }

    </>
}