import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../../store/AppContext";
import {useLocation} from "react-router-dom";
import cuentaService from "../../../services/CuentaService";
import {useValidacionContacto} from "../../../components/validaciones/validacionesContacto";

export const ListaRegistroDominiosPasoCuatro = (props) => {
    const location = useLocation();
    const appCtx = useContext(AppContext);
    const carrito = appCtx.carrito.registrosPendientes;
    const [mapDomDatosContacto, setMapDomDatosContacto] = useState(new Map());
    let contactoDominios = new Map();
    const { getNombreApellido } = useValidacionContacto();

    useEffect(() => {
        if (carrito) {
            let cont = 0;
            carrito.forEach(regDom => {
                obtenerContactoDominio(regDom.id)
                    .then(response => {
                        cont++;
                        let contactoTemp;
                        if (response.respCodigo === 'OK') {
                            contactoTemp = {
                                nombreApellido: response.contactoDTO.nombreApellido,
                                direccion: response.contactoDTO.direccion,
                                telefono: response.contactoDTO.telefono,
                                emailPublico: response.contactoDTO.emailPublico,
                                email: response.contactoDTO.email,
                                emailConfirmacion: response.contactoDTO.email,
                                guardado: true
                            };
                        } else {
                            contactoTemp = {
                                nombreApellido: '',
                                direccion: '',
                                telefono: '',
                                emailPublico: '',
                                email: '',
                                emailConfirmacion: '',
                                guardado: false
                            };
                        }
                        console.log(contactoTemp)
                        contactoTemp= getNombreApellido(contactoTemp);
                        contactoDominios.set(
                            regDom.id.toString(),
                            contactoTemp
                        )
                        if (cont === carrito.length) {
                            setMapDomDatosContacto(contactoDominios);
                        }
                    });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // const removeFromCarrito = (e)=> {
    //     const dominioId = e.target.id;
    //     DominioRegistroService.eliminarRegistroDominio(dominioId, idVenta)
    //         .then((response) => {
    //             if (response.respuestaOK) {
    //                 appCtx.eliminarItemCarrito(dominioId, TipoItemCarrito.registro);
    //             }
    //         }).catch((error) => {
    //         // Ignore
    //     });
    // }

    const obtenerContactoDominio = (dominioId) => {
        return cuentaService.getUsuarioDominio(dominioId);
    }

    return (
        <>
            {carrito == null || carrito.length === 0 ?
                <></> :
                <>
                    <table id="id-tabla-reg-dom" className="cart">
                        <caption>Registrar dominio</caption>
                        <thead>
                        <tr>
                            <th>Artículo</th>
                            <th>Duración</th>
                            <th>Titular</th>
                            <th>Correo electrónico del titular</th>
                            <th className="tdright">Precio</th>
                        </tr>
                        </thead>
                        <tbody id="register_domains_tbody">
                        { carrito.map((dominio) => (
                            <tr className="last" key={dominio.nombre}>
                                <td>
                                    {/*<a className="close" id={dominio.id} onClick={ removeFromCarrito }></a>*/}
                                    { dominio.nombre }
                                </td>
                                <td>1&nbsp;Año</td>
                                {(mapDomDatosContacto.get(dominio.id.toString()) && mapDomDatosContacto.get(dominio.id.toString()).emailPublico.length > 0) &&
                                    <>
                                        <td>{mapDomDatosContacto.get(dominio.id.toString()).nombreApellido}</td>
                                        <td>{mapDomDatosContacto.get(dominio.id.toString()).emailPublico}</td>
                                    </>
                                }
                                <td className="tdright">$ { dominio.precio }</td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="tdright">Subtotal:</td>
                            <td className="price" id="id_shopping_cart_subtotal">$ {props.subTotal}</td>
                        </tr>
                        </tfoot>
                    </table>
                </>
            }
        </>
    );
}